import React, { useEffect } from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ViewAll = () => {
  const dispatch = useDispatch();
  const P = useParams();
  const Act = Action.product.product.view_slug;
  const view_data = useSelector(({ product: v }) => v.product.view_slug.data);

  useEffect(() => {
    dispatch(Act(`slug=${P.id}`));
  }, [dispatch, Act, P]);

  return <Custom.Product.ViewVariant view_data={view_data} level="03" />;
};

export default ViewAll;
