import Hoc from "../../../../comps/Hoc";

export const getStoteTrolly = (data, to) => {
  let items = [
    ...new Set(
      Hoc.isArr(data)
        ?.filter((v) => !!v?.StoreId)
        ?.map(({ StoreId }) => StoreId)
    ),
  ].map((StoreId) => ({ StoreId }));

  items = items.map(({ StoreId }) => {
    let items = data.filter((vt) => vt?.StoreId === StoreId);

    return {
      StoreId,
      items,
      Store: null,
    };
  });

  items = items.filter((V) => !!V?.items?.length);

  items = items.map((step03) => ({
    ...step03,
    Store: step03?.items?.find((v) => true)?.Product_Box?.Store
      ?.Collection_Address,
    Collection_Address: step03?.items?.find(() => true)?.Product_Box?.Store
      ?.Collection_Address,
  }));

  return items;
};
