import { Box } from "@material-ui/core";
import React from "react";
import { Message } from "semantic-ui-react";
import Hoc from "../../Hoc";

const ProfileView = (props) => {
  const { items } = props;

  return (
    <Box style={{ marginBottom: 10, maxHeight: "50vh", overflow: "auto" }}>
      <Hoc.Dashboard.Container title={"Latest News"}>
        {items?.map((v) => (
          <Message
            key={v.id}
            style={{ width: "100%" }}
            header={v.name}
            content={v.description}
          />
        ))}
      </Hoc.Dashboard.Container>
    </Box>
  );
};

export default ProfileView;
