import React, { useState } from "react";
import { Container, Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Message } from "semantic-ui-react";

const UseData = ({ error, loading, children }) => {
  const [loader, setLoader] = useState(!!loading);
  const CSS = useCSS();
  return error ? (
    <Container className="my-5">
      <Message content={error} color="yellow" />
    </Container>
  ) : loading ? (
    <>
      <Backdrop
        className={CSS.loading}
        open={!!loader}
        onClick={() => setLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Message content="Loading..." color="yellow" />
    </>
  ) : (
    <>{children}</>
  );
};

const useCSS = makeStyles((theme) => ({
  loading: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default UseData;
