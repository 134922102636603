import React, { useEffect, useState } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import Action from "../../../actions";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA, { useReCAPTCHA } from "../../Hoc/base02/ReCAPTCHA";

const SignIn = (props) => {
  const { reCaptcha, handleReCaptcha } = useReCAPTCHA();
  const Act_Opp = Action.base02.oop_msg.view_add;
  const act_curr = Action.base02.currency;
  const dispatch = useDispatch();
  const h = useHistory();
  const { title, endLink, submitAction, formData } = props;
  const CSS = useCSS();
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const [CurrencyId, setCurrencyId] = useState(null);
  const endContent = (
    <Box display="flex" justifyContent="flex-end">
      <Typography
        className={CSS.endText}
        component={RouterLink}
        to={endLink}
        color="textPrimary"
      >
        Already have an Account? Sign In
      </Typography>
    </Box>
  );
  const currencies = useSelector(({ base02: B }) => B.currency.list.data);
  const GN = `${title}`.includes("Customer");

  useEffect(() => {
    if (GN) {
      dispatch(act_curr.list());
    }
    // eslint-disable-next-line
  }, [dispatch, act_curr]);

  const onSubmit = async () => {
    const status = await dispatch(
      submitAction({ ...values, ...(GN && { CurrencyId }) })
    );
    if (!!status) {
      resetForm();
      h.goBack();

      dispatch(Act_Opp("Your profile registered successfully!!."));
    }
  };

  const fmSmt = {
    onSubmit,
    resetForm,
    endContent,
    submitButton: {
      disabled: setup_status(values) || !reCaptcha || !!(GN && !CurrencyId),
    },
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form {...fmSmt}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Controls.Input {...setup.first_name(values.first_name, onChange)} />
          <Controls.Input {...setup.last_name(values.last_name, onChange)} />
        </Box>
        <Controls.Input {...setup.email(values.email, onChange)} />
        <Controls.PhoneInput {...setup.contact(values.contact, onChange)} />
        {GN && (
          <Controls.SearchSelect
            {...setup.CurrencyId(CurrencyId, setCurrencyId, currencies)}
          />
        )}
        <Controls.Input {...setup.password(values.password, onChange)} />
        <ReCAPTCHA handleReCaptcha={handleReCaptcha} />
      </Form>
    </Hoc.FormContainer>
  );
};

const useCSS = makeStyles((theme) => ({
  endText: {
    marginTop: theme.spacing(1),
    fontSize: 13,
  },
}));

const setup_status = (v) =>
  !v.first_name || !v.last_name || !v.email || `${v?.password}`.length < 6;

const setup = {
  first_name: (v, onChange) =>
    Hoc.setupText(v, onChange, "first_name", "First Name"),
  last_name: (v, onChange) =>
    Hoc.setupText(v, onChange, "last_name", "Last Name"),
  email: (v, onChange) =>
    Hoc.setupText(v, onChange, "email", "Email Address", "email"),
  password: (v, onChange) =>
    Hoc.setupText(v, onChange, "password", "Password", "password"),
  contact: (v, onChange) =>
    Hoc.setupText(v, onChange, "contact", "Contact Number", "phone"),
  CurrencyId: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV(T.value),
      "CurrencyId",
      "Select Currency",
      opt.map((v) => ({
        id: v.id,
        title: `${v.name} (${v.currency})`,
      }))
    ),
};

export default SignIn;
