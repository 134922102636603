// declaring redux types for address
const types = {
  //
  ADDRESS_VIEW_ALL_REQUEST: "ADDRESS_VIEW_ALL_REQUEST",
  ADDRESS_VIEW_ALL_SUCCESS: "ADDRESS_VIEW_ALL_SUCCESS",
  ADDRESS_VIEW_ALL_FAIL: "ADDRESS_VIEW_ALL_FAIL",
  //
  ADDRESS_VIEW_REQUEST: "ADDRESS_VIEW_REQUEST",
  ADDRESS_VIEW_SUCCESS: "ADDRESS_VIEW_SUCCESS",
  ADDRESS_VIEW_FAIL: "ADDRESS_VIEW_FAIL",
  //
  ADDRESS_VIEW_QUERY_REQUEST: "ADDRESS_VIEW_QUERY_REQUEST",
  ADDRESS_VIEW_QUERY_SUCCESS: "ADDRESS_VIEW_QUERY_SUCCESS",
  ADDRESS_VIEW_QUERY_FAIL: "ADDRESS_VIEW_QUERY_FAIL",
  //
  ADDRESS_ADD_REQUEST: "ADDRESS_ADD_REQUEST",
  ADDRESS_ADD_SUCCESS: "ADDRESS_ADD_SUCCESS",
  ADDRESS_ADD_FAIL: "ADDRESS_ADD_FAIL",
  //
  ADDRESS_EDIT_REQUEST: "ADDRESS_EDIT_REQUEST",
  ADDRESS_EDIT_SUCCESS: "ADDRESS_EDIT_SUCCESS",
  ADDRESS_EDIT_FAIL: "ADDRESS_EDIT_FAIL",
  //
  ADDRESS_DELETE_REQUEST: "ADDRESS_DELETE_REQUEST",
  ADDRESS_DELETE_SUCCESS: "ADDRESS_DELETE_SUCCESS",
  ADDRESS_DELETE_FAIL: "ADDRESS_DELETE_FAIL",
};

export default types;
