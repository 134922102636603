import React, { useEffect } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import Custom from "../../../Custom";
import Hoc from "../../../Hoc";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "semantic-ui-react";

const AddressTable = ({ id, RoleId }) => {
  const Act_address = Action.base.address;
  const Act_Oop = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const data = useSelector(({ base: B }) => B.address.view_all.data);
  const { TblContainer, finalRecords } = Custom.useTable(data, cells);

  useEffect(() => {
    dispatch(Act_address.view_all(`${RoleId}=${id}`));
  }, [dispatch, Act_address, id, RoleId]);

  const nav01 = (id) => {
    let items = [];
    const TOT = (name, to) => ({ name, to });
    const TOC = (name, onClick) => ({ name, onClick });

    const onDelete = async () => {
      const status = await dispatch(Act_address.view_delete(id));
      !!status && dispatch(Act_Oop("Address Deleted Successfully."));
    };

    items = [
      TOT("View", `/address-view/${id}`),
      TOT("Edit", `/address-edit/${id}`),
      TOC("Delete", onDelete),
    ];

    return items;
  };

  return (
    <React.Fragment>
      <Header content="My Addresses" color="purple" />
      {!finalRecords().length && (
        <Hoc.EmptyMessage content="No Addresses found." />
      )}
      {!!finalRecords().length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell>{v?.region}</TableCell>
              <TableCell>{v?.postcode}</TableCell>
              <TableCell>{v?.country}</TableCell>
              <TableCell>{v?.lng}</TableCell>
              <TableCell>{v?.lat}</TableCell>
              <TableCell>
                <Hoc.Product.MenuButton actions={nav01(v.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TblContainer>
      )}
    </React.Fragment>
  );
};

const cells = [
  { id: "Region", label: "Region" },
  { id: "Postcode", label: "Postcode" },
  { id: "Country", label: "Country" },
  { id: "Lng", label: "Lng" },
  { id: "Lat", label: "Lat" },
  { id: "actions", label: "Actions", disableSorting: true },
];
export default AddressTable;
