import React from "react";
import Controls from "../../../../comps/Controls";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch } from "react-redux";

const AddTrack = (props) => {
  const { open, setOpen } = props;
  const Act_tract = Action.store.track.view_add;
  const Act_trolly = Action.store.trolly.view_edit;
  const act_notif = Action.base02.notif.view_add;
  const dispatch = useDispatch();

  const onClose = () => setOpen({ status: false, TrollyId: null });

  const { Form, useForm } = Custom.useForm;
  const formData = useForm(initTrack());
  const { values, onChange, resetForm } = formData;

  const onSubmit = () => {
    const toArr = values?.status === "Arrived" ? "to" : "from";
    let text = `Product "${open?.TrollyId?.Product_Box?.name}" is `;
    text = `${text} ${values?.status} ${toArr} the store.`;
    const notif = {
      text,
      role: "Customer",
      CustomerId: open?.TrollyId?.CustomerId,
    };
    if (values?.status === "Delivered")
      dispatch(Act_trolly(open?.TrollyId, { status: "Delivered" }));

    dispatch(Act_tract({ ...values, TrollyId: open?.TrollyId?.id }));
    dispatch(act_notif(notif));
    resetForm();
    onClose();
  };

  return (
    <Hoc.Dialog
      open={open?.status}
      onClose={onClose}
      title="Add Trolly Track"
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onClose}
        />
      }
    >
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        maxWidth="xs"
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.SearchSelect {...setup.status(values.status, onChange)} />
        <Controls.Input {...setup.description(values.description, onChange)} />
      </Form>
    </Hoc.Dialog>
  );
};

const setup_status = (v) => !v?.status || !v?.description;

const initTrack = (v) => ({
  status: v?.status ? v?.status : "",
  description: v?.description ? v?.description : "",
});

const setup = {
  status: (value, onChange) => ({
    name: "status",
    label: "Select Category",
    value: value ? value : "",
    onChange,
    options: [
      { id: "Dispatched", title: "Dispatched" },
      { id: "Arrived", title: "Arrived" },
      { id: "Left", title: "Left" },
      { id: "Delivered", title: "Delivered" },
    ],
  }),
  description: (value, onChange) => ({
    type: "text",
    name: "description",
    label: "Description",
    multiline: true,
    minRows: 2,
    maxRows: 4,
    value,
    onChange,
    required: true,
  }),
};

export default AddTrack;
