import { Box } from "@material-ui/core";
import React from "react";
import { Header } from "semantic-ui-react";

const WaitUntilGotVerified = () => {
  return (
    <Box style={{ width: "100%", textAlign: "center" }}>
      <Header
        color="orange"
        content="Sorry, You are not allowed to use this platform because of one of the following reasons. Your account has not been verified yet or You are not assigned to any Store."
      />
    </Box>
  );
};

export default WaitUntilGotVerified;
