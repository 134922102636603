import React, { useEffect } from "react";
import Carousel from "react-elastic-carousel";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hoc from "../../Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Header } from "semantic-ui-react";

const LatestProduct = (props) => {
  const Act = Action.product.box;
  const dispatch = useDispatch();
  const { latest } = props;

  const latest_data = useSelector(({ product: P }) => P.box.latest.data);
  const best_data = useSelector(({ product: P }) => P.box.best_seller.data);

  const data = !!latest ? latest_data : best_data;
  const H = useHistory();
  useEffect(() => {
    dispatch(!!latest ? Act.latest() : Act.best_seller());
  }, [dispatch, latest, Act]);

  return data?.length ? (
    <Box style={{ margin: "10px 0" }}>
      <Header {...hd01(!!latest)} />
      <Carousel breakPoints={breakPoints}>
        {data?.map((v) => (
          <ShowItem key={v?.id} H={H} data={v} />
        ))}
      </Carousel>
    </Box>
  ) : (
    <React.Fragment />
  );
};

const hd01 = (LT) => ({
  content: LT ? "Latest Products" : "Best Seller",
  color: "purple",
  textAlign: "center",
  as: "h2",
});

const ShowItem = ({ data, H }) => {
  const CSS = useCSS();
  return (
    <Box className={CSS.root}>
      <Box className={CSS.photo_root}>
        <Box>
          <Box component="img" src={data?.photo} className={CSS.photo} />
        </Box>
      </Box>
      <Typography
        onClick={() => H.push(`/product-variant/${data?.id}`)}
        className={CSS.name}
      >
        {data?.name}
      </Typography>
      <Typography align="right" className={CSS.currency}>
        <Hoc.ToCurrency currency={data?.Currency} price={data?.S_price} />
      </Typography>
    </Box>
  );
};
const useCSS = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    padding: theme.spacing(0.5),
  },
  photo_root: {
    height: theme.spacing(20),
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
  photo: {
    backgroundSize: "cover",
    backgroundOrigin: "center",
    maxWidth: "100%",
  },
  name: {
    cursor: "pointer",
    fontSize: theme.spacing(2),
    fontWeight: 300,
    marginTop: theme.spacing(0.3),
    "&:hover": {
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  currency: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    margin: theme.spacing(0.3, 0),
    color: theme.palette.info,
  },
}));

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

export default LatestProduct;
