import Helper from "../../helper";
import { Type } from "../../../redux";
import axios from "axios";

const types = Type.product_type.country_ship_tax;

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.COUNTRY_SHIP_tax_VIEW_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/country-ship-charge/${id}`);
    dispatch({
      type: types.COUNTRY_SHIP_tax_VIEW_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.COUNTRY_SHIP_tax_VIEW_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.COUNTRY_SHIP_tax_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      `${Helper.URL}/country-ship-charge${queryString ? "?" + queryString : ""}`
    );

    dispatch({
      type: types.COUNTRY_SHIP_tax_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.COUNTRY_SHIP_tax_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.COUNTRY_SHIP_tax_ADD_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(
      `${Helper.URL}/country-ship-charge`,
      initData
    );
    let output =
      Array.isArray(data?.result) && !!data?.result?.length
        ? data?.result[0]
        : data?.result;

    dispatch(
      { type: types.COUNTRY_SHIP_tax_ADD_SUCCESS, payload: output },
      Helper.multiForm
    );
    dispatch({
      type: types.COUNTRY_SHIP_tax_VIEW_ALL_SUCCESS,
      payload: [output, ...state()?.product?.country_ship_tax?.view_all?.data],
    });

    return Array.isArray(data?.result) && !!data?.result?.length;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.COUNTRY_SHIP_tax_ADD_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.COUNTRY_SHIP_tax_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(
      `${Helper.URL}/country-ship-charge/${id}`
    );

    dispatch({
      type: types.COUNTRY_SHIP_tax_DELETE_SUCCESS,
      payload: data?.result,
    });
    dispatch({
      type: types.COUNTRY_SHIP_tax_VIEW_ALL_SUCCESS,
      payload: state()?.product?.country_ship_tax?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.COUNTRY_SHIP_tax_DELETE_FAIL);
  }
};
