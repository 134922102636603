import React from "react";
import { useSelector } from "react-redux";
import Hoc from "../../../Hoc";
import Controls from "../../../Controls";

const Root = (props) => {
  const { open, setOpen } = props;
  const data = useSelector(({ product: p }) => p.category_01.view.data);

  const onOpenClose = () => {
    setOpen(false);
  };

  return (
    <Hoc.Dialog
      open={open}
      onClose={onOpenClose}
      title={`${data?.name} (${data?.id})`}
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onOpenClose}
        />
      }
    >
      <Hoc.ShowItem
        name="Category Header Count"
        value={Hoc.isArr(data?.Category_02s).length}
      />
      <Hoc.ShowItem
        name="Category Item Count"
        value={Hoc.isArr(data?.Category_02s).reduce(
          (a1, v1) => a1 + Hoc.isArr(v1.Category_03s).length,
          0
        )}
      />
    </Hoc.Dialog>
  );
};

export default Root;
