import React, { useEffect } from "react";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const MethodEnable = ({ method }) => {
  const H = useHistory();
  const dispatch = useDispatch();
  const act = action.base.payment;
  const act_pay = action.base.mobile_pay;
  const { data, loading, error } = useSelector(
    ({ base: B }) => B.payment.method
  );

  useEffect(() => {
    dispatch(act.method(method));
  }, [dispatch, act, method]);

  const toName = (v) => `${v}`.toUpperCase().split("_").join(" ");

  const finalData = Object.entries(data || {}).filter(
    (v) => !!v[1] && !["createdAt", "updatedAt", "BanksItems"].includes(v[0])
  );

  const onNewCountry = () => H.push(`/payment-country-detail-add/${data?.id}`);
  const onEdit = () => H.push(`/payment-detail-edit/${data?.id}`);

  const btn01 = {
    style: { width: "100%", margin: 15 },
    ...Hoc.flex("flex-end"),
  };

  const btn02 = (v) => ({
    content: v ? "Edit Details" : "New Country",
    color: "purple",
  });

  const t_acts = (v) => {
    let items = [];
    const onDelete = () => {
      dispatch(act_pay.view_delete(v?.id));
      dispatch(act.method(method));
    };
    items = [
      { name: "Edit", to: `/payment-country-detail-edit/${v?.id}` },
      { name: "Delete", onClick: onDelete },
    ];

    return items;
  };

  const TBL = Custom.useTable(data?.BanksItems, cells);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Box {...btn01}>
        <Button {...btn02(true)} onClick={onEdit} />
        {method === "MobilePay" && (
          <Button {...btn02(false)} onClick={onNewCountry} />
        )}
      </Box>

      <Grid container spacing={2}>
        {!!finalData.length ? (
          finalData.map((v, idx) => (
            <ShowItem key={idx} name={toName(v[0])} value={v[1]} />
          ))
        ) : (
          <Hoc.EmptyMessage
            content={`Please Update ${method} Payment Details`}
          />
        )}
      </Grid>

      {!!finalRecords()?.length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v?.id}>
              <TableCell content={v.id} />
              <TableCell content={v.name} />
              <TableCell content={v.mobile} />
              <TableCell
                content={<Hoc.Product.MenuButton actions={t_acts(v)} />}
              />
            </TableRow>
          ))}
        </TblContainer>
      )}
    </Hoc.UseData>
  );
};

const ShowItem = (rest) => (
  <Grid item xs={12} sm={6} lg={4}>
    <Hoc.ShowItem {...rest} />
  </Grid>
);

const cells = [
  { id: "id", label: "ID" },
  { id: "name", label: "Country" },
  { id: "mobile", label: "Email / Mobile" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default MethodEnable;
