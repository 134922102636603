import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import action from "../../../actions";
import Controls from "../../../comps/Controls";
import { useDispatch } from "react-redux";

const FooterForm = ({ id }) => {
  const H = useHistory();
  const act_footer = action.base02.footer;
  const act_OOP = action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const { Form, useForm } = Custom.useForm;
  const formData = useForm(initData());
  const { values, setValues, onChange, resetForm } = formData;

  const CKC = "Email Settings" === values?.category;

  const title = `${!id ? "Update" : "Create"} ${
    !CKC ? "Footer Link" : "Email Settings"
  }`;

  const onSubmit = async () => {
    const finalData = initData(values);

    let status;

    if (!!id) {
      status = await dispatch(act_footer.view_edit(id, finalData));
    } else {
      status = await dispatch(act_footer.view_add(finalData));
    }
    if (!!status) {
      H.goBack();
      act_OOP(`Details ${!id ? "Updated" : "Created"} Successfully.`);
    }
  };

  useEffect(() => {
    const SetVV = async () =>
      setValues(initData(await dispatch(act_footer.view(id))));
    !!id && SetVV();
  }, [setValues, dispatch, act_footer, id]);

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.SearchSelect
          {...setup.category(values?.category, onChange)}
        />

        {CKC ? (
          <React.Fragment>
            <Controls.SearchSelect
              {...setup.protocol(values?.protocol, onChange)}
            />
            <Controls.Input {...setup.username(values?.username, onChange)} />
            <Controls.Input {...setup.from(values?.from, onChange)} />
            <Controls.Input {...setup.password(values?.password, onChange)} />
            <Controls.Input {...setup.imap_port(values?.imap_port, onChange)} />
            <Controls.Input {...setup.pop3_port(values?.pop3_port, onChange)} />
            <Controls.Input {...setup.smtp_host(values?.smtp_host, onChange)} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Controls.FileUpload {...setup.photo(values?.photo, onChange)} />
            <Controls.Input {...setup.name(values?.name, onChange)} />
            <Controls.Input {...setup.url(values?.url, onChange)} />
          </React.Fragment>
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) => !v?.category;

const sel01 = ["Normal Link", "Social Link", "Email Settings"];
const sel02 = ["imap", "pop3"];

const toSel = (v) =>
  v.map((id) => ({
    id,
    title: id,
  }));

const setup = {
  category: (v, OC) =>
    Hoc.setupSelect(v, OC, "category", "category", toSel(sel01)),
  photo: (v, OC) => Hoc.setupFile(v, OC, "photo", "photo"),
  name: (v, OC) => Hoc.setupText(v, OC, "name", "name"),
  url: (v, OC) => Hoc.setupText(v, OC, "url", "url"),
  protocol: (v, OC) =>
    Hoc.setupSelect(v, OC, "protocol", "protocol", toSel(sel02)),
  username: (v, OC) => Hoc.setupText(v, OC, "username", "username"),
  from: (v, OC) => Hoc.setupText(v, OC, "from", "from"),
  password: (v, OC) => Hoc.setupText(v, OC, "password", "password"),
  imap_port: (v, OC) => Hoc.setupText(v, OC, "imap_port", "imap_port"),
  pop3_port: (v, OC) => Hoc.setupText(v, OC, "pop3_port", "pop3_port"),
  smtp_host: (v, OC) => Hoc.setupText(v, OC, "smtp_host", "smtp_host"),
};

const initData = (v) => ({
  category: v?.category ? v?.category : null,
  photo: v?.photo ? v?.photo : null,
  name: v?.name ? v?.name : null,
  url: v?.url ? v?.url : null,
  protocol: v?.protocol ? v?.protocol : null,
  username: v?.username ? v?.username : null,
  from: v?.from ? v?.from : null,
  password: v?.password ? v?.password : null,
  imap_port: v?.imap_port ? v?.imap_port : null,
  pop3_port: v?.pop3_port ? v?.pop3_port : null,
  smtp_host: v?.smtp_host ? v?.smtp_host : null,
});

export const AddForm = () => {
  return <FooterForm />;
};
export const EditForm = () => {
  const P = useParams();
  return <FooterForm id={P?.id} />;
};
