import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.terms;

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERMS_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      `${Helper.URL}/terms?${queryString || ""}`
    );

    dispatch({ type: types.TERMS_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERMS_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERMS_ADD_REQUEST });
    Helper.set_jwt(state());

    const root = state()?.people?.root?.data;

    let CompanyManagerId = null;
    if (root?.role === "Company_Manager") CompanyManagerId = root?.id;

    const { data } = await axios.post(`${Helper.URL}/terms`, {
      ...initData,
      ...(!!CompanyManagerId && { CompanyManagerId }),
    });

    dispatch({ type: types.TERMS_ADD_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERMS_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERMS_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${Helper.URL}/terms/${id}`, initData);
    dispatch({ type: types.TERMS_EDIT_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERMS_EDIT_FAIL);
  }
};

export const view = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERMS_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/terms/${id}`, initData);

    dispatch({ type: types.TERMS_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERMS_VIEW_FAIL);
  }
};

export const view_query = (query) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERMS_VIEW_QUERY_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/terms/query?${query}`);

    dispatch({ type: types.TERMS_VIEW_QUERY_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERMS_VIEW_QUERY_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERMS_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${Helper.URL}/terms/${id}`);
    dispatch({
      type: types.TERMS_VIEW_ALL_SUCCESS,
      payload: state().base.terms.view_all.data.filter((v) => v.id !== id),
    });

    dispatch({ type: types.TERMS_DELETE_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERMS_DELETE_FAIL);
  }
};
