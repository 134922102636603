import { combineReducers } from "redux";

// Category
import category_01 from "./category/category-01";
import category_01_types from "./category/category-01/types";

import category_02 from "./category/category-02";
import category_02_types from "./category/category-02/types";

import category_03 from "./category/category-03";
import category_03_types from "./category/category-03/types";

// Variant
import box from "./variant/box";
import box_types from "./variant/box/types";
import description from "./variant/description";
import description_types from "./variant/description/types";

import photo from "./variant/photo";
import photo_types from "./variant/photo/types";

import product from "./variant/product";
import product_types from "./variant/product/types";

import info from "./variant/product-info";
import info_types from "./variant/product-info/types";

// Base
import country_ship_tax from "./base/country-shipping-charge";
import country_ship_tax_types from "./base/country-shipping-charge/types";

import note from "./base/product-note";
import note_types from "./base/product-note/types";

import report from "./base/product-report";
import report_types from "./base/product-report/types";

import review from "./base/product-review";
import review_types from "./base/product-review/types";

import coupon from "./base/product-coupon";
import coupon_types from "./base/product-coupon/types";

const store = combineReducers({
  info,
  box,
  photo,
  description,
  product,
  category_01,
  category_02,
  category_03,
  note,
  report,
  review,
  coupon,
  country_ship_tax,
});

export const product_type = {
  info: info_types,
  box: box_types,
  photo: photo_types,
  description: description_types,
  product: product_types,
  category_01: category_01_types,
  category_02: category_02_types,
  category_03: category_03_types,
  note: note_types,
  report: report_types,
  review: review_types,
  coupon: coupon_types,

  country_ship_tax: country_ship_tax_types,
};

export default store;
