import SecureRoute from "../../../comps/layout/SecureRoute";
import view_all from "./email_templates";
import sent_mails from "./sent_mails";
import add from "./add";
import edit from "./edit";

const Expo = {
  view_all: (
    <SecureRoute grant="S2" path="/mail-templates" component={view_all} />
  ),
  add: <SecureRoute grant="S2" path="/mail-template-add" component={add} />,
  edit: (
    <SecureRoute grant="S2" path="/mail-template-edit/:id" component={edit} />
  ),
  sent_mails: (
    <SecureRoute grant="S2" path="/sent-mails" component={sent_mails} />
  ),
};

export default Expo;
