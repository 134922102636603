import React, { useState } from "react";
import Action from "../../../../../actions";
import Custom from "../../../../Custom";
import Hoc from "../../../../Hoc";
import Controls from "../../../../Controls";
import { useDispatch } from "react-redux";
import { Icon, Message } from "semantic-ui-react";
import { Box, Typography } from "@material-ui/core";
import { setup, NoRecords } from "./utils";

const Desc = (props) => {
  const Act = Action.product.description;
  const dispatch = useDispatch();
  const { ProductBoxId, setEditable } = props;
  const { editable, closeEditable, refresh } = props;
  let { items } = props;

  items = !!ProductBoxId
    ? Hoc.isArr(items).filter((v) => v.ProductBoxId === ProductBoxId)
    : items;

  const { Form, useForm } = Custom.useForm;
  const { values, setValues, onChange, resetForm } = useForm({ text: "" });

  const [descID, setDescID] = useState(null);

  const onSubmit = () => {
    if (!!descID) {
      dispatch(Act.view_edit(descID, { ...values }));
      setDescID(null);
    } else {
      dispatch(Act.view_add({ ...values, ProductBoxId }));
    }
    resetForm();
    refresh();
    closeEditable();
  };

  const onEdit = (id) => {
    const item = Hoc.isArr(items).find((v) => v.id === id);
    setEditable(true);
    setDescID(id);
    setValues({ text: item?.text });
  };
  const onDelete = (id) => {
    dispatch(Act.view_delete(id));
    refresh();
  };

  return (
    <Box>
      {!editable && (
        <Box style={{ marginTop: 20 }}>
          {!Hoc.isArr(items).length ? (
            <NoRecords />
          ) : (
            <Message
              success
              list={items.map((v) => (
                <Box display="flex" justifyContent="space-between">
                  <Typography>&emsp;{v.text}</Typography>
                  <Box>
                    <Icon
                      color="yellow"
                      name="edit outline"
                      style={{ cursor: "pointer", marginRight: 10 }}
                      onClick={() => onEdit(v.id)}
                    />
                    <Icon
                      color="red"
                      name="trash alternate"
                      style={{ cursor: "pointer" }}
                      onClick={() => onDelete(v.id)}
                    />
                  </Box>
                </Box>
              ))}
            />
          )}
        </Box>
      )}

      {!!editable && (
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          style={{ marginTop: 10 }}
          submitButton={{ disabled: !values?.text || ProductBoxId === "NA" }}
        >
          <Controls.Input {...setup.text(values.text, onChange)} />
        </Form>
      )}
    </Box>
  );
};

export default Desc;
