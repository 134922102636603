import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Typography } from "@material-ui/core";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import Controls from "../../../../comps/Controls";
import Action from "../../../../actions";
import { useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";

const AddToken = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const H = useHistory();

  const Act_report = Action.product.report;
  const act_trolly = Action.store.trolly.view;
  const act_notif = Action.base02.notif.view_add;
  const Loc = useLocation();
  const qry = queryString?.parse(Loc.search);
  const { TrollyId, name } = qry;
  const { Form, useForm } = Custom.useForm;
  const { values, setValues, onChange, resetForm } = useForm(initV);

  useEffect(() => {
    setValues((V) => ({ ...V, TrollyId }));
    // eslint-disable-next-line;
  }, [setValues, TrollyId]);

  const onSubmit = async () => {
    setLoading(true);
    dispatch(Act_report.view_add(values));
    if (!!TrollyId) {
      const res = await dispatch(act_trolly(TrollyId));
      const text = `Support Ticket (${values?.type}) raised by customer for "${res?.Product_Box?.name}" of TrollyId "${TrollyId}" and OrderId "${res?.OrderId}" with "${values.urgency}" Priority.`;
      const notif = {
        text,
        role: "Store",
        StoreId: res?.Product_Box?.Store?.id,
      };
      dispatch(act_notif(notif));
    } else {
      const text = `Support Ticket raised by customer for "${values?.type}" service with "${values.urgency}" Priority.`;
      const notif = { text, role: "Company" };
      dispatch(act_notif(notif));
    }
    setLoading(false);
    resetForm();
    H.goBack();
  };

  return (
    <Hoc.FormContainer title="Creating a Token">
      {!!name && (
        <Typography align="center" color="primary">
          {name}
        </Typography>
      )}
      {!TrollyId && (
        <Message
          content="To Raise any complaint which is related to Trolly please go to to Trollies/Orders then select a Trolly to raise a Complaint."
          color="yellow"
        />
      )}
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values) || loading,
        }}
      >
        <Controls.SearchSelect
          {...setup.type(values?.type, onChange, TrollyId)}
        />

        {["Return", "Refund", "Support"].includes(values?.type) && (
          <Controls.Input
            {...setup.TrollyId(values.TrollyId, onChange, name)}
          />
        )}

        <Controls.Input {...setup.subject(values.subject, onChange)} />
        <Controls.Input {...setup.advice(values.advice, onChange)} />
        <Controls.Input {...setup.description(values.description, onChange)} />
        <Controls.SearchSelect {...setup.urgency(values.urgency, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const initV = {
  subject: "",
  advice: "",
  description: "",
  type: "",
  urgency: "Not Urgent",
  TrollyId: "",
};

const setup_status = (v) =>
  !v.subject || !v.advice || !v.description || !v.type || !v.urgency;

const setup = {
  subject: (value, onChange) => ({
    type: "text",
    name: "subject",
    label: "Subject",
    value,
    onChange,
    required: true,
  }),
  advice: (value, onChange) => ({
    type: "text",
    name: "advice",
    label: "Advice",
    value,
    onChange,
    required: true,
  }),
  description: (value, onChange) => ({
    type: "text",
    name: "description",
    label: "Description",
    multiline: true,
    minRows: 2,
    maxRows: 4,
    value,
    onChange,
    required: true,
  }),
  type: (value, onChange, TrollyId) => ({
    name: "type",
    label: "Service Type",
    required: true,
    value,
    onChange,
    options: !!TrollyId ? Type02 : Type01,
  }),
  urgency: (value, onChange) => ({
    name: "urgency",
    label: "Select Importance",
    value,
    onChange,
    options: [
      { id: "Not Urgent", title: "Not Urgent" },
      { id: "General", title: "Normal" },
      { id: "Important", title: "Important" },
      { id: "Urgent", title: "Urgent" },
    ],
  }),
  TrollyId: (value, onChange) => ({
    type: "number",
    name: "TrollyId",
    label: "Trolly ID",
    value,
    onChange,
    disabled: true,
    inputProps: {
      min: 1,
    },
  }),
};

const Type01 = [
  { id: "General", title: "General Information" },
  { id: "Complain", title: "Raise Complaint" },
];

const Type02 = [
  { id: "Return", title: "Return An Item" },
  { id: "Refund", title: "Request Refund" },
  { id: "Support", title: "Request Support" },
];

export default AddToken;
