import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Collapse, IconButton, Table } from "@material-ui/core";
import { TableBody, TableCell, TableContainer } from "@material-ui/core";
import { TableHead, TableRow, Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { records, childCells, childLabel } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const CHArr = (v) => (Array.isArray(v) && !!v.length ? v : []);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {CHArr(records?.main).map((v, idx) => (
          <TableCell key={idx} align="center">
            {v}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {childLabel}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {CHArr(childCells).map((v, idx) => (
                      <TableCell key={idx} align="center">
                        {v}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {CHArr(records?.child).map((v, idx) => (
                    <TableRow key={idx}>
                      {CHArr(v).map((v, idx) => (
                        <TableCell key={idx} align="center">
                          {v}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { childLabel, cells, childCells, records } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {cells.map((v, idx) => (
              <TableCell key={idx} align="center">
                {v}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {records?.map((row, idx) => (
            <Row
              key={idx}
              records={row}
              childLabel={childLabel}
              childCells={childCells}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
