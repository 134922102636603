import React from "react";
import { useParams } from "react-router";
import TransactionForm from "./TransactionForm";

const Edit = () => {
  const P = useParams();

  return <TransactionForm id={P.id} />;
};

export default Edit;
