import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";

const SignIn = () => {
  const Act = Action.people.C2.sign_in;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.SignIn
      title="Company Admin Login"
      endLink="/c2-sign-up"
      forgotPsw="/c2-forgot-password"
      submitAction={Act}
      formData={formData}
      to={{ url: "/company-dashboard", role: "Company_Admin" }}
    />
  );
};

const initValues = {
  email: "",
  password: "",
};

export default SignIn;
