import useForm from "./useForm";
import Form from "./Form";
import Product from "./Product";
import Store from "./Store";
import People from "./People";
import Profile from "./Profile";
import Base from "./Base";
import Controls from "./Controls";
import useTable from "./useTable";
import CollapsibleTable from "./CollapsibleTable";

const Custom = {
  useForm,
  Base,
  People,
  Store,
  Profile,
  Form,
  Product,
  Controls,
  useTable,
  CollapsibleTable,
};

export default Custom;
