import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const Act = Action.people.S2;
  const view_all = useSelector(({ people: v }) => v.store_employee.view_all);

  return (
    <Custom.People.ViewAll
      Act={Act}
      view_all={view_all}
      to="s2"
      title="Store Employee"
      queryString="archive=true"
    />
  );
};

export default ViewAll;
