import action from "../../actions";

import slugify from "slugify";
import GeoPoint from "geopoint";
import Product from "./Product";
import Payment from "./Payment";

import Dashboard from "./base/Dashboard";
import UseData from "./base/UseData";
import Page from "./base/Page";
import PageBox from "./base/PageBox";
import PageTabs from "./base/PageTabs";
import FormContainer from "./base/FormContainer";
import Message from "./base/Message";
import ImageCarousel from "./base/ImageCarousel";
import ShowItem from "./base/ShowItem";
import Home from "./Home";

import Dialog from "./base02/Dialog";
import OopMessage from "./base02/Oop_Message";
import WaitUntilGotVerified from "./base02/WaitUntilGotVerified";
import { Box } from "@material-ui/core";
import countries from "../../assets/countries";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// When we got empty data from the request we will show thuis message to convey the message
// color - describe the message color
// content - message to show in the component
const EmptyMessage = ({
  color = "yellow",
  content = "Resource not found.",
}) => (
  <Box
    display="flex"
    justifyContent="center"
    style={{ width: "100%", margin: "10px 0" }}
  >
    <Message color={color} content={content} />
  </Box>
);

// takes the coordinates and calculates the distance
const toMiles = (P1, P2) => {
  // is any of the coordinate is a falsy value then return the null value
  if (!P1?.lat || !P1?.lng || !P2?.lat || !P2?.lng) {
    return null;
  } else {
    // declaring  first location
    const point01 = new GeoPoint(P1?.lat, P1?.lng);
    // declaring second location
    const point02 = new GeoPoint(P2?.lat, P2?.lng);
    // calculating distance in miles
    let miles = point01.distanceTo(point02, true);
    // calculating distance in kilometers
    miles = GeoPoint.kilometersToMiles(miles);
    // returning distance with only three floating values (distance = 2.123KM)
    return parseFloat(miles).toFixed(3);
  }
};

// takes the string and convert the string lowercase slugify value. (`Hello World` => `hello-world`)
const slugifyComp = (v) => slugify(v, { lower: true });

// if the given value is an array then returns the value optherwise returns an empty array
const isArr = (v) => (Array.isArray(v) ? v : []);

// This component renders the currency info
const ToCurrency = ({ currency, price = 0, empty = "0" }) => {
  // declaring action for getting GBP currency info
  const act_gbp = action.base02.currency.view_gbp;
  // declaring dispatch function to call the redux action
  const dispatch = useDispatch();

  // extracting user data from the redux state
  const root = useSelector(({ people: P }) => P.root.data);
  // extracting GBP currency data from the redux state
  const GBP = useSelector(({ base02: P }) => P.currency.view_gbp.data);
  // if user has prefered currency then it will return that currency otherwise it will return GBP currency
  const curr = root?.Currency ? root?.Currency : GBP;

  // Logic for the final price
  let finalPrice = !!(!currency?.value || !curr?.value)
    ? price
    : price / currency?.value;

  finalPrice = !curr?.value ? finalPrice : finalPrice * curr?.value;

  // Loading GBP data when page loads
  useEffect(() => {
    !root?.Currency && dispatch(act_gbp());
  }, [dispatch, act_gbp, root]);

  // returning the currency using toLocaleString which will convert number to string  in currency format
  return (
    <span>
      {!!price &&
        !!curr?.currency &&
        finalPrice.toLocaleString(curr?.currency, {
          style: "currency",
          currency: curr?.currency,
        })}
      {!price && empty}
    </span>
  );
};

// It will take amount and currency information and returns the string in currency format
const currencyTo = (price, currFrom, currTo) => {
  const PF = (v = 0) => parseFloat(v).toFixed(3);
  let finalPrice = !!(!currTo?.value || !currFrom?.value)
    ? price
    : price / currFrom?.value;
  finalPrice = !currTo?.value ? finalPrice : finalPrice * PF(currTo?.value);
  return finalPrice;
};

// it will take multiple amount to return one single final amount
const MultiCurrency = ({ items, empty = "0" }) => {
  // declaring action for getting GBP currency info
  const act_gbp = action.base02.currency.view_gbp;
  // declaring dispatch function to call the redux action
  const dispatch = useDispatch();

  // extracting user data from the redux state
  const root = useSelector(({ people: P }) => P.root.data);
  // extracting GBP currency data from the redux state
  const GBP = useSelector(({ base02: P }) => P.currency.view_gbp.data);
  // if user has prefered currency then it will return that currency otherwise it will return GBP currency
  const curr = root?.Currency ? root?.Currency : GBP;

  // transforming the amount to the price
  const ToPrice = (v) => parseFloat(v?.price / v?.curr?.value) * curr?.value;

  // creating final version of the price
  let finalPrice = isArr(items).reduce((A, B) => A + ToPrice(B), 0);

  // loading GBP currency data
  useEffect(() => {
    !root?.Currency && dispatch(act_gbp());
  }, [dispatch, act_gbp, root]);

  // returning the currency using toLocaleString which will convert number to string  in currency format
  return (
    <span>
      {!!finalPrice &&
        !!curr?.currency &&
        finalPrice.toLocaleString(curr?.currency, {
          style: "currency",
          currency: curr?.currency,
        })}
      {!finalPrice && empty}
    </span>
  );
};

// takes amount & currency info and returns string in a currency format
const toCurrency = (currency, amount, empty = "0") =>
  !!(!!amount && !!currency)
    ? amount?.toLocaleString(currency, {
        style: "currency",
        currency,
      })
    : empty;

const getADD = (v, e) => (!!e ? `${v}.` : v ? `${v}, ` : "");

// extracts the address from the data and returns as single string
const showAddress = (v) =>
  `${getADD(v?.house_name)}${getADD(v?.street_name)}${getADD(
    v?.address_3
  )}${getADD(v?.city)}${getADD(v?.region)}${getADD(v?.country)}${getADD(
    v?.postcode,
    true
  )}`;

// exporting all the components
const Hoc = {
  Payment,
  Page,
  Dashboard,
  Product,
  Home,
  //
  currencyTo,
  slugify: slugifyComp,
  isArr,
  UseData,
  PageBox,
  PageTabs,
  FormContainer,
  Message,
  ImageCarousel,
  ShowItem,
  // Base 02
  Dialog,
  OopMessage,
  WaitUntilGotVerified,
  // Basic
  toCurrency,
  ToCurrency,
  MultiCurrency,
  showAddress,
  toMiles,
  //
  EmptyMessage,
  flex: (jc = "center", ai = "center", fd) => ({
    display: "flex",
    justifyContent: jc,
    alignItems: ai,
    ...(!!fd && { flexDirection: fd }),
  }),
  setupSwitch: (checked, onChange, name, label) => ({
    checked,
    onChange,
    name,
    label,
  }),
  setupText: (v, onChange, name, label, type = "text") => ({
    onChange,
    name,
    label,
    value: !!v ? v : "",
    type,
  }),
  setupSelect: (v, onChange, name, label = "Select Country", opt = "C") => ({
    onChange,
    name,
    label,
    value: !!v ? v : "",
    options: opt === "C" ? countries : opt,
  }),
  setupLongText: (v, onChange, name, label, minRows = 2, maxRows = 4) => ({
    type: "text",
    name,
    label,
    multiline: true,
    minRows,
    maxRows,
    value: v ? v : "",
    onChange,
  }),
  setupFile: (v, onChange, name, LB, accept) => ({
    type: "file",
    id: `photo_${v || ""}`,
    name,
    label: LB ? `${!!v ? "Edit" : "Select"} ${LB}` : "Photo",
    accept: accept ? accept : "*",
    onChange,
  }),
};

export default Hoc;
