import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Hoc from "../../comps/Hoc";
import Action from "../../actions";
import { useDispatch } from "react-redux";

const act = Action.people;

export const people = [
  {
    role: "Company_Manager",
    url: "/supreme-sign-in",
    act: act.C1.verify_email,
    rst: act.C1.reset_password,
  },
  {
    role: "Company_Admin",
    url: "/c2-sign-in",
    act: act.C2.verify_email,
    rst: act.C2.reset_password,
  },
  {
    role: "Store_Manager",
    url: "/s1-sign-in",
    act: act.S1.verify_email,
    rst: act.S1.reset_password,
  },
  {
    role: "Store_Employee",
    url: "/s2-sign-in",
    act: act.S2.verify_email,
    rst: act.S2.reset_password,
  },
  {
    role: "Customer",
    url: "/sign-in",
    act: act.Customer.verify_email,
    rst: act.Customer.reset_password,
  },
];

const EmailConfirmation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const token = `${params.token}`.split("--")[0];
  const role = `${params.token}`.split("--")[1];
  const usr = people.find((v) => v.role === role);

  const [status, setStatus] = React.useState(false);

  useEffect(() => {
    const SetVv = async () => setStatus(await dispatch(usr?.act(token)));
    !!usr && SetVv();
  }, [dispatch, usr, token]);

  return (
    <Hoc.Page title="Email Verification">
      <Hoc.Dashboard.Container
        title="Email Verification"
        actions={[
          { name: "Login", to: usr?.url },
          { name: "Home", to: "/" },
        ]}
      >
        {status ? (
          <Hoc.Message variant="warning" text="Email Verified" />
        ) : (
          <Hoc.Message
            variant="warning"
            text="Email token used please try to login to check the status."
          />
        )}
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default EmailConfirmation;
