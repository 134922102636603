import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Action from "../../../../actions";
import Hoc from "../../../Hoc";
import Custom from "../../index";
import Controls from "../../../Controls";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const Act_all_stores = Action.store.item.view_all;
  const Act_cat_01 = Action.product.category_01;
  const Act_cat_03 = Action.product.category_03;
  const h = useHistory();
  const { title, id, edit, submitAction = (f) => f, formData } = props;
  const CSS = useCSS();
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const root = useSelector(({ people: v }) => v.root.data);
  const category_01 = useSelector(
    ({ product: v }) => v.category_01.view_all.data
  );
  const category_03_real = useSelector(
    ({ product: v }) => v.category_03.view_all.data
  );
  const [category, setCategory] = useState({ one: "", two: "" });

  const onSubmit = () => {
    let view = new FormData();
    Object.entries(values)
      .filter((v) => !!v[1])
      .forEach((v) => view.set(v[0], v[1]));
    view.set("slug", Hoc.slugify(values?.title));
    view.set("StoreId", !!root?.Store?.id ? root?.Store?.id : storeId);
    if (!id) {
      dispatch(submitAction(view));
    } else {
      dispatch(submitAction(id, view));
    }
    resetForm();
    h.push("/product-view-all-trolly");
  };

  const [storeId, setStoreId] = useState(root?.Store?.id);
  const stores = useSelector(({ store: s }) => s.item.view_all.data);

  useEffect(() => {
    if (!edit) dispatch(Act_cat_01.view_all());
    else dispatch(Act_cat_03.view_all());

    dispatch(Act_all_stores());
  }, [dispatch, Act_cat_01, Act_cat_03, edit, Act_all_stores]);

  const category_02 = Hoc.isArr(category_01).find(
    (v) => v.id === category.one
  )?.Category_02s;

  const category_03 = Hoc.isArr(category_02).find(
    (v) => v.id === category.two
  )?.Category_03s;

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values) || !storeId,
        }}
        className={CSS.pos_rel}
      >
        {!(
          (C_roles.includes(root.role) || !!root?.Store) &&
          !!`${root?.privilege}`.includes("Add")
        ) ? (
          <Hoc.Message
            text={`Access Denied ${
              !!root?.Store ? "" : " - Does not have any Store Access ."
            }`}
            color="yellow"
          />
        ) : (
          <Grid container justifyContent="space-around">
            {!edit && (
              <>
                <Controls.SearchSelect
                  {...setup.Category01Id(
                    category.one,
                    setCategory,
                    category_01
                  )}
                />
                {!!category.one && (
                  <Controls.SearchSelect
                    {...setup.Category02Id(
                      category.two,
                      setCategory,
                      category_02
                    )}
                  />
                )}
              </>
            )}
            {!!(!!edit || !!category.two) && (
              <Controls.SearchSelect
                {...setup.Category03Id(
                  values.Category03Id,
                  onChange,
                  !edit ? category_03 : category_03_real
                )}
              />
            )}
            {C_roles.includes(root?.role) && !root?.Store && (
              <Controls.SearchSelect
                {...setup.storeId(storeId, setStoreId, stores)}
              />
            )}
            <Controls.Input {...setup.title(values.title, onChange)} />
            <Controls.Input {...setup.brand(values.brand, onChange)} />
          </Grid>
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) =>
  !v.title || !v.brand || !v.Category03Id || !v.Category03Id;

const useCSS = makeStyles((theme) => ({
  photo: {
    maxHeight: theme.spacing(5),
    margin: theme.spacing(0.5, 1),
  },
  video: {
    height: theme.spacing(8),
  },
  pos_rel: {
    position: "relative",
  },
}));

const setup = {
  title: (v, onChange) => Hoc.setupText(v, onChange, "title", "Title"),
  brand: (v, onChange) => Hoc.setupText(v, onChange, "brand", "Brand"),

  file_type: (v, onChange) => ({
    ...Hoc.setupSelect(v, onChange, "file_type", "File Type", [
      "None",
      "PDF",
      "Photo",
      "Video",
    ]),
    noLabel: true,
  }),
  file: (v, onChange) =>
    Hoc.setupFile(v, onChange, "file", "File", "application/pdf"),
  video: (v, onChange) =>
    Hoc.setupFile(v, onChange, "video", "Video", "video/*"),

  storeId: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV(T?.value),
      "storeId",
      "Select Store",
      Hoc.isArr(opt.map((v) => ({ id: v.id, title: `${v?.name} (${v?.id})` })))
    ),
  Category01Id: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV((i) => ({ ...i, one: T.value })),
      "one",
      "Select Main Category",
      Hoc.isArr(opt).map(({ id, name }) => ({
        id,
        title: `${name} (${id})`,
      }))
    ),

  Category02Id: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV((i) => ({ ...i, two: T.value })),
      "two",
      "Select Category Header",
      Hoc.isArr(opt).map(({ id, name }) => ({
        id,
        title: `${name} (${id})`,
      }))
    ),
  Category03Id: (v, onChange, opt) =>
    Hoc.setupSelect(
      v,
      onChange,
      "Category03Id",
      "Select Category Item",
      Hoc.isArr(opt).map(({ id, name }) => ({
        id,
        title: `${name} (${id})`,
      }))
    ),
};
const C_roles = ["Company_Manager", "Company_Admin"];

export default SignIn;
