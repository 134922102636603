import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";

import TrollyNav from "./Hoc/TrollyNav";
import PreviousNext from "./Hoc/PreviousNext";
import { getStoteTrolly } from "./Hoc/utils";
import TrollyView03 from "./TrollyView/TrollyView03";
import Payment from "./Hoc/Payment";
import PayInfo from "./Hoc02/PayInfo";

const ViewAll = () => {
  const Act_order = Action.store.order;
  const Act_trolly = Action.store.trolly;

  const dispatch = useDispatch();
  const H = useHistory();
  const openIT = useSelector(({ store: S }) => S.order.open.data);
  const trollies = useSelector(({ store: v }) => v.trolly.view_all.data);
  const store_items = getStoteTrolly(trollies);

  useEffect(() => {
    dispatch(Act_order.open_view());
    dispatch(Act_trolly.view_all(`status=Trolly`));
  }, [dispatch, Act_order, Act_trolly]);

  const onForward = () => {
    H.push("/trolly-04");
  };

  return (
    <Hoc.Page title="Trollies">
      <Hoc.Dashboard.Container
        title="Trollies"
        actions={[
          { name: "Refresh", onClick: () => dispatch(Act_order.open_view()) },
        ]}
      >
        <Box style={{ width: "100%" }}>
          <TrollyNav step={2} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {store_items?.map((rest, idx) => (
                <TrollyView03 key={idx} {...rest} />
              ))}
            </Grid>

            <Grid item xs={12}>
              <PayInfo data={openIT} trollies={trollies} />
              <Payment data={openIT} trollies={trollies} />
            </Grid>
          </Grid>
          <PreviousNext from="02" to="04" onSubmit={onForward} />
        </Box>
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default ViewAll;
