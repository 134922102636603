import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};
const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.COUPON_VIEW_ALL_REQUEST,
  success: types.COUPON_VIEW_ALL_SUCCESS,
  fail: types.COUPON_VIEW_ALL_FAIL,
};

export const view_all_store = {
  name: "view_all_store",
  state: defListState,
  request: types.COUPON_VIEW_ALL_STORE_REQUEST,
  success: types.COUPON_VIEW_ALL_STORE_SUCCESS,
  fail: types.COUPON_VIEW_ALL_STORE_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.COUPON_VIEW_REQUEST,
  success: types.COUPON_VIEW_SUCCESS,
  fail: types.COUPON_VIEW_FAIL,
};

export const check_coupon = {
  name: "check_coupon",
  state: defState,
  request: types.COUPON_CKPD_REQUEST,
  success: types.COUPON_CKPD_SUCCESS,
  fail: types.COUPON_CKPD_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.COUPON_EDIT_REQUEST,
  success: types.COUPON_EDIT_SUCCESS,
  fail: types.COUPON_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.COUPON_ADD_REQUEST,
  success: types.COUPON_ADD_SUCCESS,
  fail: types.COUPON_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.COUPON_DELETE_REQUEST,
  success: types.COUPON_DELETE_SUCCESS,
  fail: types.COUPON_DELETE_FAIL,
};
