import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};
const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const variants = {
  name: "variants",
  state: defListState,
  request: types.PRODUCT_BOX_VARIANT_REQUEST,
  success: types.PRODUCT_BOX_VARIANT_SUCCESS,
  fail: types.PRODUCT_BOX_VARIANT_FAIL,
};

export const search = {
  name: "search",
  state: defListState,
  request: types.PRODUCT_BOX_SEARCH_REQUEST,
  success: types.PRODUCT_BOX_SEARCH_SUCCESS,
  fail: types.PRODUCT_BOX_SEARCH_FAIL,
};

export const random = {
  name: "random",
  state: defListState,
  request: types.PRODUCT_RAND_SEARCH_REQUEST,
  success: types.PRODUCT_RAND_SEARCH_SUCCESS,
  fail: types.PRODUCT_RAND_SEARCH_FAIL,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.PRODUCT_BOX_VIEW_ALL_REQUEST,
  success: types.PRODUCT_BOX_VIEW_ALL_SUCCESS,
  fail: types.PRODUCT_BOX_VIEW_ALL_FAIL,
};

export const best_seller = {
  name: "best_seller",
  state: defListState,
  request: types.PRODUCT_BOX_BEST_SELLER_REQUEST,
  success: types.PRODUCT_BOX_BEST_SELLER_SUCCESS,
  fail: types.PRODUCT_BOX_BEST_SELLER_FAIL,
};

export const latest = {
  name: "latest",
  state: defListState,
  request: types.PRODUCT_BOX_LATEST_REQUEST,
  success: types.PRODUCT_BOX_LATEST_SUCCESS,
  fail: types.PRODUCT_BOX_LATEST_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.PRODUCT_BOX_VIEW_REQUEST,
  success: types.PRODUCT_BOX_VIEW_SUCCESS,
  fail: types.PRODUCT_BOX_VIEW_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.PRODUCT_BOX_EDIT_REQUEST,
  success: types.PRODUCT_BOX_EDIT_SUCCESS,
  fail: types.PRODUCT_BOX_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.PRODUCT_BOX_ADD_REQUEST,
  success: types.PRODUCT_BOX_ADD_SUCCESS,
  fail: types.PRODUCT_BOX_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.PRODUCT_BOX_DELETE_REQUEST,
  success: types.PRODUCT_BOX_DELETE_SUCCESS,
  fail: types.PRODUCT_BOX_DELETE_FAIL,
};
