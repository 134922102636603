import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../Controls";
import Hoc from "../../Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Button } from "semantic-ui-react";

const Bank = ({ curr, ...rest }) => {
  const { refresh = (f) => f, ...props } = rest;
  const Act = Action.base.payment.view_all;
  const Act_send = Action.base.payment.send_bank_info;
  const Act_order_edit = Action.store.order.view_edit;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const root = useSelector(({ people: P }) => P.root.data);
  const [running, setRunning] = useState(false);
  const [bank, setBank] = useState("");
  const dispatch = useDispatch();
  const { data } = useSelector(({ base: b }) => b.payment.list);
  const [bankInfo, setBankInfo] = useState(null);

  const onCountrySelect = ({ target: v }) => {
    setBank(v.value);
    setBankInfo(data.find((i) => i.name === v.value));
  };

  useEffect(() => {
    dispatch(Act());
  }, [dispatch, Act]);

  const sendBank = () => {
    setRunning(true);
    const para01 = {
      ...props,
      ...bankInfo,
      amount: Hoc.toCurrency(curr?.currency, props.amount),
      currency: curr?.currency,
      email: root?.email,
    };
    dispatch(Act_send(para01));
    dispatch(
      Act_order_edit(props?.order_id, { payment_method: "Bank Transfer" })
    );
    refresh();
    const msg = `We have sent payment details to your email. Please refere that emaiul to pay and place the order when you have completed the payment.`;
    dispatch(Act_OOP(msg));
    setRunning(false);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Box style={{ marginBottom: 20, width: 250 }}>
          <Controls.SearchSelect {...bankProps(bank, onCountrySelect, data)} />
        </Box>
      </Box>

      {!!bank && data?.some((v) => v.name === bank) && (
        <Grid container spacing={2}>
          {Object.entries(bankInfo)
            .filter(
              (v) =>
                !!v[1] && !["enabled", "createdAt", "updatedAt"].includes(v[0])
            )
            .map((v, idx) => (
              <Grid item xs={12} sm={6} key={idx}>
                <Hoc.ShowItem
                  name={`${v[0]}`.split("_").join(" ")}
                  value={v[1] || "-"}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {!!bankInfo && (
        <Box style={{ margin: "15px 0" }} {...Hoc.flex("flex-end")}>
          <Button
            inverted
            content="Send Details"
            color="purple"
            onClick={sendBank}
            disabled={!!running}
          />
        </Box>
      )}
    </Box>
  );
};

const bankProps = (v, onChange, options) => ({
  name: "bank",
  value: v || "",
  onChange,
  label: "Select Currency",
  options: options.map((v) => ({
    id: v.name,
    title: `${v.name}`.split("_").join(" "),
  })),
});

export default Bank;
