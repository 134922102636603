import React from "react";
import Hoc from "..";
import { Box, Grid } from "@material-ui/core";

const Container = (props) => {
  const { children, ...rest } = props;
  return (
    <Box>
      <Hoc.PageBox {...rest}>
        <Grid container spacing={2} justifyContent="center">
          {children}
        </Grid>
      </Hoc.PageBox>
    </Box>
  );
};

const Item = ({ children, title, ...rest }) => (
  <Grid
    item
    component={Hoc.PageBox}
    maxWidth={350}
    title={title}
    smSize
    {...rest}
  >
    {children}
  </Grid>
);

const Expo = {
  Container,
  Item,
};

export default Expo;
