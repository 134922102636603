import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Page = forwardRef(
  (
    { children, noHeader, title = "", icon, goBack, noStyle, ...other },
    ref
  ) => {
    const CSS = useCSS();

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {!noStyle ? (
          <Container className={CSS.root}>
            <main>{children}</main>
          </Container>
        ) : (
          children
        )}
      </Box>
    );
  }
);

const useCSS = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(2),
    },
  },
}));

export default Page;
