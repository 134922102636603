import React, { useState } from "react";
import Hoc from "../../../comps/Hoc";
import OtherMethod from "./Sub/OtherMethod";
import MethodEnable from "./Sub/MethodEnable";
import Controls from "../../../comps/Controls";

import Bank from "./Sub/Bank";
import { Box } from "@material-ui/core";

const Details = () => {
  const [method, setMethod] = useState("Method");

  const navs_01 = {
    title: "Payment details",
    actions: [
      { name: "Create New", to: "/payment-detail-add" },
      { name: "Back", goBack: true },
    ],
  };
  const CKM = (v) => v === method;
  return (
    <Hoc.Dashboard.Container {...navs_01}>
      <Box style={{ width: "100%" }} {...Hoc.flex("flex-end")}>
        <Box style={{ width: 150 }}>
          <Controls.SearchSelect
            {...setup.method(method, ({ target: T }) => setMethod(T.value))}
          />
        </Box>
      </Box>

      {CKM("Bank") ? (
        <Bank />
      ) : CKM("Method") ? (
        <MethodEnable />
      ) : !!method ? (
        <OtherMethod method={method} />
      ) : (
        <Hoc.EmptyMessage content="Please select a Payment Method." />
      )}
    </Hoc.Dashboard.Container>
  );
};

const sel01 = [
  { id: "Method", title: "Payment Methods" },
  { id: "PayPal", title: "PayPal" },
  { id: "Stripe", title: "Stripe" },
  { id: "RazorPay", title: "RazorPay" },
  { id: "MobilePay", title: "MobilePay" },
  { id: "Bank", title: "Bank" },
];

const setup = {
  method: (v, OC) => Hoc.setupSelect(v, OC, "category", "Category", sel01),
};

export default Details;
