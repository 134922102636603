import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import Action from "../../../actions";

const OopMessage = () => {
  const Act_oop = Action.base02.oop_msg;
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(Act_oop.view_delete());
  };

  const oop_msg = useSelector(({ base02: B }) => B.oop_msg);

  return (
    <Modal size="tiny" open={!!oop_msg?.text} onClose={onClose}>
      <Modal.Header>
        <Header {...DT(oop_msg?.status)} />
      </Modal.Header>
      <Modal.Content content={oop_msg?.text} />
      <Modal.Actions
        content={<Button positive onClick={onClose} content="Ok" />}
      />
    </Modal>
  );
};

const DT = (v) => ({
  icon: !v ? "check" : "times",
  content: !v ? "Success" : "Failed",
  color: !v ? "green" : "red",
});

export default OopMessage;
