import SecureRoute from "../../../comps/layout/SecureRoute";
import view from "./view";
import EditProfile from "./EditProfile";
import EditAddress from "./EditAddress";
import EditPassword from "./EditPassword";

const Expo = {
  view: <SecureRoute grant="GN" path="/profile-view" component={view} />,
  EditProfile: (
    <SecureRoute grant="GN" path="/profile-edit" component={EditProfile} />
  ),
  EditAddress: (
    <SecureRoute
      grant="GN"
      path="/profile-edit-address"
      component={EditAddress}
    />
  ),
  EditPassword: (
    <SecureRoute
      grant="GN"
      path="/profile-edit-password"
      component={EditPassword}
    />
  ),
};

export default Expo;
