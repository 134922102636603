import ShipCharge from "./ShipCharge";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import SignUp from "./SignUp";
import Template from "./Template";
import Store from "./Store";
import Address from "./Address";

const Expo = {
  SignIn,
  ForgotPassword,
  SignUp,
  Template,
  Store,
  Address,
  ShipCharge,
};

export default Expo;
