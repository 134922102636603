import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.settings;

//   ---------------------------------------   Queue System

export const view_queues = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.VIEW_QUEUES_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/queue-system`);

    dispatch({ type: types.VIEW_QUEUES_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.VIEW_QUEUES_FAIL);
  }
};

//   ---------------------------------------   Queue System
