import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const Act = Action.product.category_03;
  const view_all = useSelector(({ product: v }) => v.category_03.view_all);

  return (
    <Custom.Product.CategoryTrolly
      Act={Act}
      view_all={view_all}
      to="sub-category"
      title="Sub Category"
    />
  );
};

export default ViewAll;
