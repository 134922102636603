import React from "react";
import { useParams } from "react-router";
import Custom from "../../../../comps/Custom";

const AddAbout = () => {
  const P = useParams();
  const formData = Custom.useForm.useForm(initData());

  return (
    <Custom.Base.TermForm
      title="Edit Privacy Policy Content"
      id={P?.id}
      formData={formData}
    />
  );
};

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  description: v?.description ? v?.description : "",
  level: v?.level ? v?.level : "",
  category: "Privacy",
  CompanyManagerId: null,
});

export default AddAbout;
