import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Message } from "semantic-ui-react";

const ShowItem = (props) => {
  const { name, value, color, width } = props;
  const { nameSize, valueSize, children } = props;

  return (
    <Message {...GN01(color, width)}>
      <Grid {...GN02}>
        {!!name && (
          <Grid item xs={nameSize || 4}>
            <Typography style={{ wordWrap: "break-word" }}> {name}</Typography>
          </Grid>
        )}

        <Grid item xs={valueSize || (name ? 8 : 12)}>
          <Typography style={{ wordWrap: "break-word" }}>
            {value ? value : children}
          </Typography>
        </Grid>
      </Grid>
    </Message>
  );
};

const GN01 = (color, width) => ({
  color: color || "violet",
  style: { width: width || "100%" },
});
const GN02 = {
  item: true,
  component: Grid,
  container: true,
  alignItems: "center",
};

export default ShowItem;
