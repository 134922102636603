import React, { useEffect } from "react";
import Hoc from "../../comps/Hoc";
import Custom from "../../comps/Custom";
import Controls from "../../comps/Controls";
import action from "../../actions";
import currency_codes from "../../assets/currency_codes";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const CurrencyForm = ({ id }) => {
  const act_currency = action.base02.currency;
  const act_OOP = action.base02.oop_msg.view_add;
  const { Form, useForm } = Custom.useForm;
  const dispatch = useDispatch();
  const H = useHistory();
  const formData = useForm(initData());
  const { values, setValues, onChange, resetForm } = formData;
  const title = `${!!id ? "Update" : "Create"} Currency`;

  const onSubmit = async () => {
    let status;
    if (!!id) {
      status = await dispatch(act_currency.view_edit(id, values));
    } else {
      status = await dispatch(act_currency.view_add(values));
    }

    if (!!status) {
      H.goBack();
      act_OOP(`Details ${!id ? "Updated" : "Created"} Successfully.`);
    }
  };

  let currs = useSelector(({ base02: B }) => B.currency.list.data);
  currs = Hoc.isArr(currs).map((v) => v.currency);
  currs = Hoc.isArr(currency_codes).filter(
    (v1) => !Hoc.isArr(currs).includes(v1)
  );

  useEffect(() => {
    dispatch(act_currency.list());
    const SetVV = async () =>
      setValues(initData(await dispatch(act_currency.view(id))));
    !!id && SetVV();
  }, [setValues, dispatch, act_currency, id]);

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.Input {...setup.name(values?.name, onChange)} />
        <Controls.SearchSelect
          {...setup.currency(values?.currency, onChange, currs)}
        />
        <Controls.Input {...setup.value(values?.value, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};
const setup_status = (v) => !v?.name || !v?.currency || !v?.value;

const toCurr = (arr01) => Hoc.isArr(arr01).map((id) => ({ id, title: id }));

const setup = {
  name: (v, OC) => Hoc.setupText(v, OC, "name", "Name"),
  value: (v, OC) => ({
    ...Hoc.setupText(v, OC, "value", "Currency Value / GBP", "number"),
    inputProps: {
      min: 0,
      step: 0.01,
    },
  }),
  currency: (v, OC, opts) =>
    Hoc.setupSelect(v, OC, "currency", "Currency", toCurr(opts)),
};

const initData = (v) => ({
  name: v?.name ? v?.name : null,
  value: v?.value ? v?.value : null,
  currency: v?.currency ? v?.currency : null,
});

export const AddForm = () => {
  return <CurrencyForm />;
};

export const EditForm = () => {
  const P = useParams();
  return <CurrencyForm id={P?.id} />;
};
