import React from "react";
import { TextField as MuiTextField } from "@material-ui/core";

const Input = (props) => {
  const { variant, fullWidth, type, label, name, value, onChange, ...rest } =
    props;

  return (
    <MuiTextField
      variant={variant || "outlined"}
      label={label}
      name={name}
      value={value}
      fullWidth={fullWidth || true}
      type={type}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Input;
