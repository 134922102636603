const types = {
  //
  PRODUCT_BOX_VIEW_ALL_REQUEST: "PRODUCT_BOX_VIEW_ALL_REQUEST",
  PRODUCT_BOX_VIEW_ALL_SUCCESS: "PRODUCT_BOX_VIEW_ALL_SUCCESS",
  PRODUCT_BOX_VIEW_ALL_FAIL: "PRODUCT_BOX_VIEW_ALL_FAIL",
  //
  PRODUCT_BOX_VIEW_REQUEST: "PRODUCT_BOX_VIEW_REQUEST",
  PRODUCT_BOX_VIEW_SUCCESS: "PRODUCT_BOX_VIEW_SUCCESS",
  PRODUCT_BOX_VIEW_FAIL: "PRODUCT_BOX_VIEW_FAIL",
  //
  PRODUCT_BOX_EDIT_REQUEST: "PRODUCT_BOX_EDIT_REQUEST",
  PRODUCT_BOX_EDIT_SUCCESS: "PRODUCT_BOX_EDIT_SUCCESS",
  PRODUCT_BOX_EDIT_FAIL: "PRODUCT_BOX_EDIT_FAIL",
  //
  PRODUCT_BOX_ADD_REQUEST: "PRODUCT_BOX_ADD_REQUEST",
  PRODUCT_BOX_ADD_SUCCESS: "PRODUCT_BOX_ADD_SUCCESS",
  PRODUCT_BOX_ADD_FAIL: "PRODUCT_BOX_ADD_FAIL",
  //
  PRODUCT_BOX_DELETE_REQUEST: "PRODUCT_BOX_DELETE_REQUEST",
  PRODUCT_BOX_DELETE_SUCCESS: "PRODUCT_BOX_DELETE_SUCCESS",
  PRODUCT_BOX_DELETE_FAIL: "PRODUCT_BOX_DELETE_FAIL",
  //
  PRODUCT_BOX_SEARCH_REQUEST: "PRODUCT_BOX_SEARCH_REQUEST",
  PRODUCT_BOX_SEARCH_SUCCESS: "PRODUCT_BOX_SEARCH_SUCCESS",
  PRODUCT_BOX_SEARCH_FAIL: "PRODUCT_BOX_SEARCH_FAIL",
  //
  PRODUCT_RAND_SEARCH_REQUEST: "PRODUCT_RAND_SEARCH_REQUEST",
  PRODUCT_RAND_SEARCH_SUCCESS: "PRODUCT_RAND_SEARCH_SUCCESS",
  PRODUCT_RAND_SEARCH_FAIL: "PRODUCT_RAND_SEARCH_FAIL",
  //
  PRODUCT_BOX_BEST_SELLER_REQUEST: "PRODUCT_BOX_BEST_SELLER_REQUEST",
  PRODUCT_BOX_BEST_SELLER_SUCCESS: "PRODUCT_BOX_BEST_SELLER_SUCCESS",
  PRODUCT_BOX_BEST_SELLER_FAIL: "PRODUCT_BOX_BEST_SELLER_FAIL",
  //
  PRODUCT_BOX_LATEST_REQUEST: "PRODUCT_BOX_LATEST_REQUEST",
  PRODUCT_BOX_LATEST_SUCCESS: "PRODUCT_BOX_LATEST_SUCCESS",
  PRODUCT_BOX_LATEST_FAIL: "PRODUCT_BOX_LATEST_FAIL",
  //
  PRODUCT_BOX_VARIANT_REQUEST: "PRODUCT_BOX_VARIANT_REQUEST",
  PRODUCT_BOX_VARIANT_SUCCESS: "PRODUCT_BOX_VARIANT_SUCCESS",
  PRODUCT_BOX_VARIANT_FAIL: "PRODUCT_BOX_VARIANT_FAIL",
};

export default types;
