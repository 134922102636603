import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const Select = (props) => {
  const { name, label, value, size } = props;
  const { onChange, options, fullWidth } = props;
  const { text, onTextChange } = props;

  const toDefPara = (name, value) => ({ target: { name, value } });

  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      value={value || null}
      getOptionLabel={(option) => option.title}
      onChange={(e, v) => onChange(toDefPara(name, v))}
      fullWidth={fullWidth || true}
      renderInput={(v) => (
        <TextField
          {...v}
          value={text}
          onChange={onTextChange}
          label={label}
          variant="outlined"
          fullWidth
          size={size}
        />
      )}
    />
  );
};

export default Select;
