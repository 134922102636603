import React from "react";
import { Message } from "semantic-ui-react";

const SUIMessage = ({ children, text, color, ...rest }) => {
  return (
    <Message color={color || "blue"} {...rest}>
      {children || text}
    </Message>
  );
};

export default SUIMessage;
