import SecureRoute from "../../comps/layout/SecureRoute";
import CurrencyList from "./list";
import { AddForm, EditForm } from "./CurrencyForm";
import StoreCurrencyForm from "./StoreCurrencyForm";

const Expo = {
  list: (
    <SecureRoute grant="C1" path="/currency-list" component={CurrencyList} />
  ),
  add: <SecureRoute grant="C1" path="/currency-add" component={AddForm} />,
  store_cuurency: (
    <SecureRoute
      grant="C1"
      path="/store-currency-add/:id"
      component={StoreCurrencyForm}
    />
  ),
  edit: (
    <SecureRoute grant="C1" path="/currency-edit/:id" component={EditForm} />
  ),
};

export default Expo;
