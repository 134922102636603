import React from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";

const ForgotPassword = () => {
  const Act = Action.people.Customer.forgot_password;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.ForgotPassword
      title="Forgot Password"
      submitAction={Act}
      formData={formData}
    />
  );
};

const initValues = {
  email: "",
};

export default ForgotPassword;
