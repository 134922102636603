import SecureRoute from "../../comps/layout/SecureRoute";
import order from "./order";
import store from "./store";

import trolly_01 from "./trolly/trolly-01";
import trolly_02 from "./trolly/trolly-02";
import trolly_03 from "./trolly/trolly-03";
import trolly_04 from "./trolly/trolly-04";
import SelectAddress from "./trolly/SelectAddress";

import trolly02 from "./trolly02";

const Expo = {
  order,
  store,
  trolly02,
  order_address: (
    <SecureRoute grant="GN" path="/order-address" component={SelectAddress} />
  ),
  trolly_01: <SecureRoute grant="GN" path="/trolly-01" component={trolly_01} />,
  trolly_02: <SecureRoute grant="GN" path="/trolly-02" component={trolly_02} />,
  trolly_03: <SecureRoute grant="GN" path="/trolly-03" component={trolly_03} />,
  trolly_04: <SecureRoute grant="GN" path="/trolly-04" component={trolly_04} />,
};

export default Expo;
