import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};

export const view = {
  name: "view",
  state: defState,
  request: types.BANK_ITEM_VIEW_REQUEST,
  success: types.BANK_ITEM_VIEW_SUCCESS,
  fail: types.BANK_ITEM_VIEW_FAIL,
};

export const view_add = {
  name: "view_add",
  state: defState,
  request: types.BANK_ITEM_ADD_REQUEST,
  success: types.BANK_ITEM_ADD_SUCCESS,
  fail: types.BANK_ITEM_ADD_FAIL,
};

export const view_edit = {
  name: "view_edit",
  state: defState,
  request: types.BANK_ITEM_EDIT_REQUEST,
  success: types.BANK_ITEM_EDIT_SUCCESS,
  fail: types.BANK_ITEM_EDIT_FAIL,
};

export const view_delete = {
  name: "view_delete",
  state: defState,
  request: types.BANK_ITEM_DELETE_REQUEST,
  success: types.BANK_ITEM_DELETE_SUCCESS,
  fail: types.BANK_ITEM_DELETE_FAIL,
};
