import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: sessionStorage.getItem("trolly")
    ? JSON.parse(sessionStorage.getItem("trolly"))
    : [],
  error: null,
};

export const count = {
  name: "count",
  state: defState,
  request: types.TROLLY_COUNT_REQUEST,
  success: types.TROLLY_COUNT_SUCCESS,
  fail: types.TROLLY_COUNT_FAIL,
};

export const empty_len = {
  name: "empty_len",
  state: defListState,
  request: types.TROLLY_EMPTY_LEN_REQUEST,
  success: types.TROLLY_EMPTY_LEN_SUCCESS,
  fail: types.TROLLY_EMPTY_LEN_FAIL,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.TROLLY_VIEW_ALL_REQUEST,
  success: types.TROLLY_VIEW_ALL_SUCCESS,
  fail: types.TROLLY_VIEW_ALL_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.TROLLY_VIEW_REQUEST,
  success: types.TROLLY_VIEW_SUCCESS,
  fail: types.TROLLY_VIEW_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.TROLLY_ADD_REQUEST,
  success: types.TROLLY_ADD_SUCCESS,
  fail: types.TROLLY_ADD_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.TROLLY_EDIT_REQUEST,
  success: types.TROLLY_EDIT_SUCCESS,
  fail: types.TROLLY_EDIT_FAIL,
};

export const edit_all = {
  name: "edit_all",
  state: defState,
  request: types.TROLLY_EDIT_ALL_REQUEST,
  success: types.TROLLY_EDIT_ALL_SUCCESS,
  fail: types.TROLLY_EDIT_ALL_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.TROLLY_DELETE_REQUEST,
  success: types.TROLLY_DELETE_SUCCESS,
  fail: types.TROLLY_DELETE_FAIL,
};
