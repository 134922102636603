import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.store_type.order;
const URL = `${Helper.URL}/store-order`;

export const my_view_refresh = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_MY_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.patch(`${URL}/own`);

    dispatch({ type: types.ORDER_MY_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_MY_VIEW_FAIL);
  }
};

export const my_view_all = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_MY_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/own-all`);

    dispatch({ type: types.ORDER_MY_VIEW_ALL_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_MY_VIEW_ALL_FAIL);
  }
};

export const my_view = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_MY_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/own`);

    dispatch({ type: types.ORDER_MY_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_MY_VIEW_FAIL);
  }
};

export const my_view_edit = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_MY_VIEW_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/own`, initData);

    dispatch({ type: types.ORDER_MY_VIEW_EDIT_SUCCESS, payload: data?.result });

    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_MY_VIEW_EDIT_FAIL);
  }
};

export const ordered = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDERED_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/ordered?${queryString || ""}`);

    dispatch({ type: types.ORDERED_VIEW_ALL_SUCCESS, payload: data?.result });

    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDERED_VIEW_ALL_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${queryString || ""}`);
    dispatch({ type: types.ORDER_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_VIEW_ALL_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.ORDER_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_VIEW_FAIL);
  }
};

export const open_view = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_OPEN_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/open`);

    dispatch({ type: types.ORDER_OPEN_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_OPEN_VIEW_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_ADD_REQUEST });
    Helper.set_jwt(state());
    const CustomerId = state()?.people?.root?.data?.id;

    const { data } = await axios.post(`${URL}`, {
      ...initData,
      CustomerId,
    });

    dispatch({ type: types.ORDER_ADD_SUCCESS, payload: data?.result });
    dispatch({
      type: types.ORDER_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.store?.order?.view_all?.data],
    });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.ORDER_EDIT_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ORDER_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.ORDER_ADD_SUCCESS, payload: data?.result });
    dispatch({
      type: types.ORDER_VIEW_ALL_SUCCESS,
      payload: state()?.store?.item?.view_all?.data?.filter((v) => v.id !== id),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ORDER_ADD_FAIL);
  }
};
