import React from "react";
import Action from "../../../../../actions";
import Custom from "../../../../Custom";
import Hoc from "../../../../Hoc";
import Controls from "../../../../Controls";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Badge, Box, Grid } from "@material-ui/core";
import { setup, NoRecords } from "./utils";

const Photo = (props) => {
  const { ProductBoxId, refresh } = props;
  let { items } = props;
  items = !!ProductBoxId
    ? Hoc.isArr(items).filter((v) => v.ProductBoxId === ProductBoxId)
    : items;
  const { editable, closeEditable } = props;
  const Act = Action.product.photo;
  const dispatch = useDispatch();
  const { Form, useForm } = Custom.useForm;
  const { values, onChange, resetForm } = useForm({ photo: "" });

  const onSubmit = () => {
    let view = new FormData();
    Object.entries(values)
      .filter((v) => !!v[1])
      .forEach((v) => view.set(v[0], v[1]));
    view.set("ProductBoxId", ProductBoxId);
    dispatch(Act.view_add(view));

    resetForm();
    refresh();
    closeEditable();
  };

  const CSS = useCSS();

  const onDelete = (id) => {
    dispatch(Act.view_delete(id));
    refresh();
  };

  return (
    <Box>
      {!editable && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: 20 }}
        >
          {!Hoc.isArr(items).length ? (
            <NoRecords />
          ) : (
            Hoc.isArr(items).map(({ id, photo }, idx) => (
              <Grid item xs={2} key={idx}>
                <Badge
                  badgeContent="Delete"
                  color="secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => onDelete(id)}
                >
                  <Box component="img" src={photo} height={70} alt="Photo" />
                </Badge>
              </Grid>
            ))
          )}
        </Grid>
      )}

      {!!editable && (
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          style={{ marginTop: 10 }}
          submitButton={{ disabled: !values?.photo || ProductBoxId === "NA" }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            style={{ marginTop: 10 }}
          >
            <Controls.FileUpload {...setup.photo02(values?.photo, onChange)} />
            {!!values?.photo && (
              <Box
                component="img"
                alt="Main Photo"
                className={CSS.photo}
                src={values.photo}
              />
            )}
          </Box>
        </Form>
      )}
    </Box>
  );
};

const useCSS = makeStyles((theme) => ({
  photo: {
    maxHeight: theme.spacing(5),
    margin: theme.spacing(0.5, 1),
  },
}));

export default Photo;
