const types = {
  //
  ORDERED_VIEW_ALL_REQUEST: "ORDERED_VIEW_ALL_REQUEST",
  ORDERED_VIEW_ALL_SUCCESS: "ORDERED_VIEW_ALL_SUCCESS",
  ORDERED_VIEW_ALL_FAIL: "ORDERED_VIEW_ALL_FAIL",
  //
  ORDER_VIEW_ALL_REQUEST: "ORDER_VIEW_ALL_REQUEST",
  ORDER_VIEW_ALL_SUCCESS: "ORDER_VIEW_ALL_SUCCESS",
  ORDER_VIEW_ALL_FAIL: "ORDER_VIEW_ALL_FAIL",
  //
  ORDER_VIEW_REQUEST: "ORDER_VIEW_REQUEST",
  ORDER_VIEW_SUCCESS: "ORDER_VIEW_SUCCESS",
  ORDER_VIEW_FAIL: "ORDER_VIEW_FAIL",
  //
  ORDER_OPEN_VIEW_REQUEST: "ORDER_OPEN_VIEW_REQUEST",
  ORDER_OPEN_VIEW_SUCCESS: "ORDER_OPEN_VIEW_SUCCESS",
  ORDER_OPEN_VIEW_FAIL: "ORDER_OPEN_VIEW_FAIL",
  //
  ORDER_MY_VIEW_REQUEST: "ORDER_MY_VIEW_REQUEST",
  ORDER_MY_VIEW_SUCCESS: "ORDER_MY_VIEW_SUCCESS",
  ORDER_MY_VIEW_FAIL: "ORDER_MY_VIEW_FAIL",
  //
  ORDER_MY_VIEW_ALL_REQUEST: "ORDER_MY_VIEW_ALL_REQUEST",
  ORDER_MY_VIEW_ALL_SUCCESS: "ORDER_MY_VIEW_ALL_SUCCESS",
  ORDER_MY_VIEW_ALL_FAIL: "ORDER_MY_VIEW_ALL_FAIL",
  //
  ORDER_MY_VIEW_EDIT_REQUEST: "ORDER_MY_VIEW_EDIT_REQUEST",
  ORDER_MY_VIEW_EDIT_SUCCESS: "ORDER_MY_VIEW_EDIT_SUCCESS",
  ORDER_MY_VIEW_EDIT_FAIL: "ORDER_MY_VIEW_EDIT_FAIL",
  //
  ORDER_EDIT_REQUEST: "ORDER_EDIT_REQUEST",
  ORDER_EDIT_SUCCESS: "ORDER_EDIT_SUCCESS",
  ORDER_EDIT_FAIL: "ORDER_EDIT_FAIL",
  //
  ORDER_ADD_REQUEST: "ORDER_ADD_REQUEST",
  ORDER_ADD_SUCCESS: "ORDER_ADD_SUCCESS",
  ORDER_ADD_FAIL: "ORDER_ADD_FAIL",
  //
  ORDER_DELETE_REQUEST: "ORDER_DELETE_REQUEST",
  ORDER_DELETE_SUCCESS: "ORDER_DELETE_SUCCESS",
  ORDER_DELETE_FAIL: "ORDER_DELETE_FAIL",
};

export default types;
