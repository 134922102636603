import { combineReducers } from "redux";

import { toOopMsg } from "../utils";

import notice from "./notice";
import notice_types from "./notice/types";

import footer from "./footer";
import footer_types from "./footer/types";

import currency from "./currency";
import currency_types from "./currency/types";

import store_currency from "./store_currency";
import store_currency_types from "./store_currency/types";

import notif from "./notification";
import notif_types from "./notification/types";

import sent_mail from "./sent_mail";
import sent_mail_types from "./sent_mail/types";

import oop_msg from "./oop_msg";

export const base02 = combineReducers({
  ...toOopMsg(oop_msg.oop_msg),
  notice,
  notif,
  currency,
  store_currency,
  footer,
  sent_mail,
});

export const base02_type = {
  notif: notif_types,
  store_currency: store_currency_types,
  currency: currency_types,
  sent_mail: sent_mail_types,
  footer: footer_types,
  notice: notice_types,
  oop_msg: oop_msg.oop_types,
};
