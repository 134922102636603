import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const Act = Action.store.order;
  const view_all = useSelector(({ store: v }) => v.order.my_view_all);

  return (
    <Custom.Base.CustomerOrderHistory
      Act={Act}
      view_all={view_all}
      title="Current Order"
      current
    />
  );
};

export default ViewAll;
