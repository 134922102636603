import React from "react";
import Action from "../../../actions";
import Custom from "../../../comps/Custom";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import queryString from "query-string";

const View = () => {
  const Act_add_address = Action.base.address.view_add;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const H = useHistory();
  const formData = Custom.useForm.useForm(initAddress());
  const { values } = formData;

  const Qry = queryString.parse(useLocation().search);

  const onSubmit = async () => {
    const TOV = (N, V) => !!V && { [N]: V };

    const finalData = {
      ...values,
      ...TOV("CompanyAdminId", Qry?.CompanyAdminId),
      ...TOV("StoreManagerId", Qry?.StoreManagerId),
      ...TOV("StoreEmployeeId", Qry?.StoreEmployeeId),
      ...TOV("CustomerId", Qry?.CustomerId),
      ...TOV("CollectionAddressId", Qry?.CollectionAddressId),
      ...TOV("StoreAddressId", Qry?.StoreAddressId),
    };

    const status = await dispatch(Act_add_address(finalData));

    if (!!status) {
      H.goBack();
      dispatch(Act_OOP("Address Changed Successfully."));
    }
  };

  return (
    <Custom.Form.Address
      title="Create New Address"
      formData={formData}
      onSubmit={onSubmit}
    />
  );
};

const initAddress = (v) => ({
  house_name: v?.house_name ? v?.house_name : "",
  street_name: v?.street_name ? v?.street_name : "",
  address_3: v?.address_3 ? v?.address_3 : "",
  city: v?.city ? v?.city : "",
  region: v?.region ? v?.region : "",
  postcode: v?.postcode ? v?.postcode : "",
  country: v?.country ? v?.country : "",
  default: v?.default ? v?.default : false,
});

export default View;
