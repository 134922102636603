import coupon from "./coupon";
import advertise from "./advertise";
import notices from "./notices";
import footer from "./footer";

const Expo = {
  coupon,
  advertise,
  notices,
  footer,
};

export default Expo;
