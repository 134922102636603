import React from "react";
import { Paper, Container, Box } from "@material-ui/core";
import { Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import { useHistory } from "react-router-dom";
import { Header } from "semantic-ui-react";

const FormContainer = (props) => {
  const { children, maxWidth, title } = props;
  const { titleColor = "green", noGoBack, logo } = props;
  const CSS = useCSS();
  const h = useHistory();
  return (
    <Paper
      className={CSS.root}
      component={Container}
      maxWidth={maxWidth || "sm"}
    >
      {!noGoBack && (
        <Typography
          variant="subtitle2"
          onClick={() => h.goBack()}
          style={{ cursor: "pointer" }}
        >
          Back
        </Typography>
      )}

      {title && (
        <>
          <Box className={"flex-center flex-col"} style={{ marginBottom: 15 }}>
            <span>{!!logo ? logo : <Logo size={80} />}</span>
            <Header textAlign="center" as="h3" color={titleColor}>
              {title}
            </Header>
          </Box>
          <Divider />
        </>
      )}
      {children}
    </Paper>
  );
};

const useCSS = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

export default FormContainer;
