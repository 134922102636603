import React from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import { Select as MuiSelect } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import Hoc from "../Hoc";

const Select = (props) => {
  const { onChange, options, fullWidth = true } = props;
  const { noLabel = Hoc.isArr(options).every((v) => typeof v === "string") } =
    props;
  const { name, label, value, variant, disabled } = props;

  return (
    <FormControl
      style={{ minWidth: 120 }}
      {...(variant && { variant })}
      fullWidth={fullWidth}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={"label"}
        name={name}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        disabled={disabled || false}
      >
        {Array.isArray(options) && options?.length && !noLabel
          ? options?.map((item, i) => (
              <MenuItem key={i} value={item.id} style={{ margin: "10px" }}>
                {item.title}
              </MenuItem>
            ))
          : options?.map((item) => (
              <MenuItem key={item} value={item} style={{ margin: "10px" }}>
                {item || "No Label"}
              </MenuItem>
            ))}
        {Array.isArray(options) && !options?.length && (
          <MenuItem style={{ margin: "10px" }}>No Records found</MenuItem>
        )}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
