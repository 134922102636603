import { combineReducers } from "redux";

import template from "./template";
import template_types from "./template/types";

import payment from "./payment";
import payment_types from "./payment/types";

import mobile_pay from "./bank-Item";
import mobile_pay_types from "./bank-Item/types";

import transaction from "./transaction";
import transaction_types from "./transaction/types";

import address from "./address";
import address_types from "./address/types";

import terms from "./terms";
import terms_types from "./terms/types";

import subscribes from "./subscribes";
import subscribes_types from "./subscribes/types";

export const base = combineReducers({
  transaction,
  subscribes,
  terms,
  mobile_pay,
  address,
  payment,
  template,
});

export const base_type = {
  mobile_pay: mobile_pay_types,
  subscribes: subscribes_types,
  terms: terms_types,
  address: address_types,
  template: template_types,
  payment: payment_types,
  transaction: transaction_types,
};
