import React from "react";
import Custom from "../../../comps/Custom";
import { useSelector } from "react-redux";

const SignIn = () => {
  const { useForm } = Custom.useForm;
  const root = useSelector(({ people: p }) => p.root.data);
  const formData = useForm(setInitValues(root));

  return (
    <Custom.Profile.EditProfile
      title="Editing My Profile"
      formData={formData}
      root={root}
    />
  );
};

const setInitValues = (v) => ({
  first_name: v?.first_name ? v?.first_name : "",
  last_name: v?.last_name ? v?.last_name : "",
  email: v?.email ? v?.email : "",
  contact: v?.contact ? v?.contact : "",
  role: v?.role ? v?.role : "",
});

export default SignIn;
