// importing the neccessary packages
import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.address;

// creating an action with view_all name
export const view_all =
  (qry = "") =>
  async (dispatch, state) => {
    try {
      // dispatching request type on rredux
      dispatch({ type: types.ADDRESS_VIEW_ALL_REQUEST });
      // setting auth token in the axios headers
      Helper.set_jwt(state());

      // sending request to the server
      const { data } = await axios.get(`${Helper.URL}/address?${qry}`);
      // dispatching succsess request with the payload data
      // payload changes the redux state
      dispatch({ type: types.ADDRESS_VIEW_ALL_SUCCESS, payload: data?.result });
    } catch (err) {
      // dispatching error type
      Helper.errResponse(err, dispatch, types.ADDRESS_VIEW_ALL_FAIL);
    }
  };

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ADDRESS_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${Helper.URL}/address`, initData);

    dispatch({ type: types.ADDRESS_ADD_SUCCESS, payload: data?.result });
    return data.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ADDRESS_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ADDRESS_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${Helper.URL}/address/${id}`, initData);
    dispatch({ type: types.ADDRESS_EDIT_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ADDRESS_EDIT_FAIL);
  }
};

export const view = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ADDRESS_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/address/${id}`, initData);

    dispatch({ type: types.ADDRESS_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ADDRESS_VIEW_FAIL);
  }
};

export const view_query = (query) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ADDRESS_VIEW_QUERY_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/address/query?${query}`);

    dispatch({ type: types.ADDRESS_VIEW_QUERY_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ADDRESS_VIEW_QUERY_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ADDRESS_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${Helper.URL}/address/${id}`);
    dispatch({
      type: types.ADDRESS_VIEW_ALL_SUCCESS,
      payload: state().base.template.view_all.data.filter((v) => v.id !== id),
    });
    dispatch({ type: types.ADDRESS_DELETE_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ADDRESS_DELETE_FAIL);
  }
};
