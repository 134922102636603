import React, { useEffect } from "react";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography } from "@material-ui/core";
import { Divider, Header, Message } from "semantic-ui-react";
import Hoc from "../../../Hoc";

const TermViewAll = (props) => {
  const { to } = props;
  const dispatch = useDispatch();
  const Act = Action.base.terms;

  const { data } = useSelector(({ base: B }) => B.terms.view_all);

  useEffect(() => {
    dispatch(Act.view_all(`category=${to}`));
  }, [dispatch, Act, to]);

  const CKT = (v) => v === to;

  const title = CKT("About")
    ? "About US"
    : CKT("Privacy")
    ? "Privacy Policy"
    : CKT("Terms")
    ? "Terms and Conditions"
    : null;

  return (
    <Hoc.Page title={title}>
      <Container maxWidth="lg" style={{ margin: "20px 0" }}>
        <Message>
          <Header textAlign="center" as="h2" color="purple" content={title} />
          {data.map((v) => (
            <Box key={v.id}>
              <Typography variant="h5" color="primary">
                {v?.name}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {v?.description}
              </Typography>

              <Divider />
            </Box>
          ))}
        </Message>
      </Container>
    </Hoc.Page>
  );
};

export default TermViewAll;
