import React from "react";
import Hoc from "../../comps/Hoc";
import Comp from "../../comps/Comp";
import { useSelector } from "react-redux";

const C1Dashboard = () => {
  const root = useSelector(({ people: v }) => v?.root?.data);

  const TOT = (name, to) => ({ name, to });
  const actions = () => {
    let items = [
      TOT("People", "/customer-active-view-all"),
      TOT("Products from Shops", "/product-view-all-trolly"),
      TOT("Stores", "/stores"),
    ];

    if (root?.role === "Company_Manager") {
      items = [TOT("Queues", "/queue-system"), ...items];
    }

    return items;
  };
  const title02 = `${!root?.role ? "" : root?.role}`.split("_").join(" ");

  const title =
    `${title02} - ${root?.first_name} ${root?.last_name}` ||
    "Company Dashboard";

  const nav01 = { title, actions: actions() };

  const vrfrt = !!`${root?.privilege}`.includes("Read");

  return (
    <Hoc.Page title={`${title02} Dashboard`}>
      <Hoc.Dashboard.Container {...nav01}>
        {/* Start */}
        {!vrfrt ? (
          <Hoc.WaitUntilGotVerified />
        ) : (
          <React.Fragment>
            <Comp.Showcase.Inventory role={root?.role} />
            <Comp.Showcase.Currencies role={root?.role} />
            <Comp.Showcase.Token role={root?.role} />
            <Comp.Showcase.OrderHistory role={root?.role} />
            <Comp.Showcase.News role={root?.role} />
            <Comp.Showcase.Coupons role={root?.role} />
            <Comp.Showcase.Email role={root?.role} />
            <Comp.Showcase.Terms role={root?.role} />
            <Comp.Showcase.Payment role={root?.role} />
            <Comp.Showcase.Footer role={root?.role} />
            <Comp.Showcase.Subscribers role={root?.role} />
            <Comp.Showcase.EmailNotifications role={root?.role} />
          </React.Fragment>
        )}
        <Comp.Showcase.Profile role={root?.role} />

        {/* End */}
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default C1Dashboard;
