const types = {
  //
  PRODUCT_INFO_VIEW_ALL_REQUEST: "PRODUCT_INFO_VIEW_ALL_REQUEST",
  PRODUCT_INFO_VIEW_ALL_SUCCESS: "PRODUCT_INFO_VIEW_ALL_SUCCESS",
  PRODUCT_INFO_VIEW_ALL_FAIL: "PRODUCT_INFO_VIEW_ALL_FAIL",
  //
  PRODUCT_INFO_VIEW_REQUEST: "PRODUCT_INFO_VIEW_REQUEST",
  PRODUCT_INFO_VIEW_SUCCESS: "PRODUCT_INFO_VIEW_SUCCESS",
  PRODUCT_INFO_VIEW_FAIL: "PRODUCT_INFO_VIEW_FAIL",
  //
  PRODUCT_INFO_EDIT_REQUEST: "PRODUCT_INFO_EDIT_REQUEST",
  PRODUCT_INFO_EDIT_SUCCESS: "PRODUCT_INFO_EDIT_SUCCESS",
  PRODUCT_INFO_EDIT_FAIL: "PRODUCT_INFO_EDIT_FAIL",
  //
  PRODUCT_INFO_ADD_REQUEST: "PRODUCT_INFO_ADD_REQUEST",
  PRODUCT_INFO_ADD_SUCCESS: "PRODUCT_INFO_ADD_SUCCESS",
  PRODUCT_INFO_ADD_FAIL: "PRODUCT_INFO_ADD_FAIL",
  //
  PRODUCT_INFO_DELETE_REQUEST: "PRODUCT_INFO_DELETE_REQUEST",
  PRODUCT_INFO_DELETE_SUCCESS: "PRODUCT_INFO_DELETE_SUCCESS",
  PRODUCT_INFO_DELETE_FAIL: "PRODUCT_INFO_DELETE_FAIL",
};

export default types;
