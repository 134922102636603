import React from "react";
import { useParams } from "react-router-dom";
import CustomForm from "./Hoc/CustomForm";

const TemplateEdit = () => {
  const P = useParams();
  return <CustomForm title="Update Email Template" id={P.id} />;
};

export default TemplateEdit;
