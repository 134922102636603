import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Controls from "../../../../comps/Controls";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";

const AddReview = (props) => {
  const Act_review = Action.product.review;
  const act_box = Action.product.box.view;
  const act_notif = Action.base02.notif.view_add;
  const dispatch = useDispatch();
  const { open, setOpen, root } = props;
  const [review, setReview] = useState(initReview(open?.review));
  const [reviewId, setReviewId] = useState(null);

  useEffect(() => {
    const SetVV = async () => {
      const para01 = `ProductBoxId=${open?.ProductBoxId}&CustomerId=${root?.id}`;
      const rv = await dispatch(Act_review.view_query(para01));
      !!rv && setReviewId(rv?.id);
      !!rv && setReview(initReview(rv));
    };
    SetVV();
  }, [dispatch, Act_review, open, root]);

  const onClose = () => {
    setOpen({ status: false, ProductBoxId: null });
    setReviewId(null);
    setReview(initReview());
  };

  const onRvChange = ({ target: v }) =>
    setReview({ ...review, [v.name]: v.value });

  const onReview = async () => {
    if (reviewId) {
      dispatch(Act_review.view_edit(reviewId, review));
    } else {
      dispatch(
        Act_review.view_add({ ...review, ProductBoxId: open?.ProductBoxId })
      );
    }
    const box = await dispatch(act_box(open?.ProductBoxId));
    const text = `Review posted for "${box?.name} (ID = ${box?.id})" which belongs to the "${box?.Store?.name} (ID = ${box?.Store?.id})" store.`;
    const notif = {
      text,
      role: "Company_Store",
      StoreId: box?.Store?.id,
    };
    dispatch(act_notif(notif));

    onClose();
  };

  return (
    <Hoc.Dialog
      open={!!open?.status}
      onClose={onClose}
      title="Review"
      fullWidth
      maxWidth="xs"
      actions={
        <Box>
          <Controls.Button
            text="Submit"
            size="small"
            onClick={onReview}
            disabled={!review?.description || !review?.rating}
          />
          <Controls.Button
            text="Close"
            color="secondary"
            size="small"
            onClick={onClose}
          />
        </Box>
      }
    >
      <Controls.Input {...setup.description(review.description, onRvChange)} />
      <Controls.SearchSelect {...setup.rating(review.rating, onRvChange)} />
    </Hoc.Dialog>
  );
};

const initReview = (v) => ({
  description: v?.description ? v?.description : null,
  rating: v?.rating ? v?.rating : null,
});

const setup = {
  description: (value, onChange) => ({
    type: "text",
    name: "description",
    label: "Review",
    multiline: true,
    minRows: 2,
    maxRows: 4,
    value: value ? value : "",
    onChange,
    style: { marginBottom: 20 },
  }),
  rating: (value, onChange) => ({
    name: "rating",
    label: "Rating",
    value: value ? value : "",
    onChange,
    options: [
      { id: 1, title: "Worst" },
      { id: 2, title: "Not Good" },
      { id: 3, title: "Average" },
      { id: 4, title: "Good" },
      { id: 5, title: "Excellent" },
    ],
  }),
};

export default AddReview;
