import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactRTE from "react-rte";

const Template = () => {
  const Act = Action.base.template;
  const Act_Opp = Action.base02.oop_msg.view_add;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);
  const { values } = formData;
  const dispatch = useDispatch();
  const h = useHistory();

  const onSubmit = () => {
    dispatch(Act.view_add({ ...values, actions: `${values.actions}` }));
    h.push("/template");
    dispatch(Act_Opp("Template has been created!!."));
  };

  return (
    <Custom.Form.Template
      title="Create Email Template"
      onSubmit={onSubmit}
      formData={formData}
    />
  );
};

const initValues = {
  editorState: ReactRTE.createEmptyValue(),
  name: "",
  subject: "",
  html: "",
  actions: [],
};

export default Template;
