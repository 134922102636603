import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../../Hoc";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import Custom from "../..";
import slugify from "slugify";

const Root = (props) => {
  const {
    v: { open, id },
    setV,
  } = props;
  const dispatch = useDispatch();
  const Act_cat_02 = Action.product.category_02;
  const { Form } = Custom.useForm;
  const { values, setValues, onChange, resetForm } = Custom.useForm.useForm({
    name: "",
    Category01Id: id,
  });
  const onOpenClose = () => {
    setV({ open: false, id: "" });
  };

  useEffect(() => {
    if (!!id) {
      setValues((v) => ({ ...v, Category01Id: id }));
    }
  }, [id, setValues]);

  const category_01 = useSelector(
    ({ product: p }) => p.category_01.view_all.data
  );

  const onSubmit = () => {
    dispatch(
      Act_cat_02.view_add({
        ...values,
        slug: slugify(values?.name, { lower: true }),
      })
    );
    onOpenClose();
    resetForm();
  };

  return (
    <Hoc.Dialog
      open={open}
      onClose={onOpenClose}
      title="Sub Category Header"
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onOpenClose}
        />
      }
    >
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        maxWidth="xs"
        submitButton={{
          disabled: !values?.name || !values?.Category01Id,
        }}
      >
        <Controls.SearchSelect
          {...token_setup.Category01Id(
            values.Category01Id,
            onChange,
            category_01
          )}
        />
        <Controls.Input {...token_setup.name(values.name, onChange)} />
      </Form>
    </Hoc.Dialog>
  );
};

const token_setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Name"),
  Category01Id: (v, onChange, opt) =>
    Hoc.setupSelect(
      v,
      onChange,
      "Category01Id",
      "Service Category",
      opt?.map(({ id, name }) => ({ id, title: `${name} (${id})` }))
    ),
};

export default Root;
