import React, { useEffect } from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";

const ViewAll = () => {
  const dispatch = useDispatch();
  const Act = Action.product.category_01.view_all;
  const view_data = useSelector(({ product: v }) => v.category_01.view_all);

  useEffect(() => {
    dispatch(Act());
  }, [dispatch, Act]);

  return <Custom.Product.CategoryViewAll view_data={view_data} level="00" />;
};

export default ViewAll;
