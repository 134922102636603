import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";

const View = () => {
  const P = useParams();
  const dispatch = useDispatch();
  const Act_notice = Action.base02.notice;

  const { data, error, loading } = useSelector(
    ({ base02: B }) => B.notice.view
  );

  useEffect(() => {
    dispatch(Act_notice.view(P.id));
  }, [dispatch, Act_notice, P]);

  const nav01 = {
    title: data?.name ? `${data?.name} (${data?.category})` : "Notice Details",
    actions: [
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: () => dispatch(Act_notice.view(P.id)) },
    ],
  };

  return (
    <Hoc.Dashboard.Container {...nav01}>
      <Hoc.UseData error={error} loading={loading}>
        {!data?.id && <Hoc.EmptyMessage />}

        {data?.photo && (
          <Box component="img" src={data?.photo} style={{ maxHeight: 200 }} />
        )}

        <Grid {...gd01}>
          {ShowItem("Name", data?.name)}
          {ShowItem("Category", data?.category)}
          {ShowItem("Description", data?.description, true)}
          {ShowItem("Enabled", !!data?.enabled ? "Active" : "Inactive")}
          {ShowItem("Roles", !!data?.roles ? GN01(data?.roles) : null)}
          {ShowItem(
            "add_for",
            !!data?.add_for !== "None" ? GN02(data?.add_for) : null
          )}

          {ShowItem(
            "Created Date",
            data?.createdAt ? ToDate(data?.createdAt) : null
          )}
          {ShowItem(
            "Last Modified",
            data?.updatedAt ? ToDate(data?.updatedAt) : null
          )}
        </Grid>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const GN02 = (v1) =>
  !`${v1}`.includes("Cat")
    ? v1
    : v1 === "Cat01"
    ? "Main Category"
    : v1 === "Cat01"
    ? "Sub Category"
    : v1 === "Cat01" && "Final Category";

const GN01 = (v1) =>
  `${v1}`
    .split("_")
    .map((v2) =>
      v2 === "CMP"
        ? "Company"
        : v2 === "STR"
        ? "Store"
        : v2 === "CUS" && "Customer"
    )
    .join(", ");

const ToDate = (v) => moment(v).calendar();

const ShowItem = (n, v, FW) =>
  !!v && (
    <Grid item xs={12} {...(!FW && { sm: 5, md: 4, lg: 3 })}>
      <Hoc.ShowItem name={n} value={v} />
    </Grid>
  );
const gd01 = {
  container: true,
  justifyContent: "center",
  spacing: 1,
  style: { marginBottom: 10 },
};

export default View;
