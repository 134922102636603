import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import Hoc from "../../../Hoc";
import HouseIcon from "@material-ui/icons/House";
import CatViewProducts from "./CatViewProducts";

const CatProductViewAll = (props) => {
  const H = useHistory();
  const { view_data, level } = props;
  const { data, error, loading } = view_data;
  const Arr = (v) => Hoc.isArr(v);
  const CKL = (v) => level === v;

  const cats = CKL("00")
    ? data
    : CKL("01")
    ? data?.Category_02s
    : CKL("02")
    ? data?.Category_03s
    : [];

  const EmptyMsg = () => <Hoc.Home.OneCard name="Empty Records Found" />;
  return (
    <Hoc.Page title={data?.name}>
      {level !== "00" && (
        <Breadcrumb icon="left angle" sections={sections(data, H, level)} />
      )}
      <Hoc.UseData error={error} loading={loading}>
        <Typography
          align="center"
          variant="h5"
          color="primary"
          style={{ margin: "3rem 0" }}
        >
          {level !== "00" ? data?.name : "Categories"}
        </Typography>

        <Grid container justifyContent="center" spacing={4}>
          {["00", "02"].includes(level) && (
            <React.Fragment>
              {!!Arr(cats).length ? (
                Arr(cats).map(({ name, slug }) => (
                  <Hoc.Home.OneCard
                    key={slug}
                    name={name}
                    to={`/category-${
                      CKL("00") ? "01" : CKL("01") ? "02" : "03"
                    }/${slug}`}
                  />
                ))
              ) : (
                <EmptyMsg />
              )}
            </React.Fragment>
          )}
          {["01"].includes(level) && (
            <React.Fragment>
              {!!Arr(cats).length ? (
                <CatViewProducts items={Arr(cats)} />
              ) : (
                <EmptyMsg />
              )}
            </React.Fragment>
          )}

          {level === "03" &&
          !!Arr(data?.Products).length &&
          !!Arr(data?.Products).some((v) => !!Arr(v?.Product_Boxes).length)
            ? Arr(data?.Products).map(
                (v) =>
                  !!Arr(v?.Product_Boxes).length && (
                    <Hoc.Home.ProductCard key={v.id} item={v} />
                  )
              )
            : level === "03" && <Hoc.Home.OneCard name="Empty Records Found" />}
        </Grid>
      </Hoc.UseData>
    </Hoc.Page>
  );
};
const XSpace = ({ children }) => (
  <div style={{ margin: "15px 0" }}>{children}</div>
);

const sections = (last_item, H, level) => {
  const CKL = (v) => level === v;
  const two = CKL("03") ? last_item?.Category_02 : last_item?.Category_01;
  const One = CKL("03") && two?.Category_01;

  const item_00 = {
    key: "Root Category",
    content: (
      <XSpace>
        {" "}
        <HouseIcon onClick={() => H.push(`/category-00`)} />
      </XSpace>
    ),
    link: true,
  };
  const item_01 = {
    key: "Main Category",
    content: (
      <XSpace>
        <div onClick={() => H.push(`/category-01/${One?.slug}`)}>
          {One?.name}
        </div>
      </XSpace>
    ),
    link: true,
  };

  const item_02 = {
    key: "Sub Category",
    content: (
      <XSpace>
        <div
          style={{ margin: "15px 0" }}
          onClick={() =>
            H.push(`/category-${CKL("03") ? "02" : "01"}/${two?.slug}`)
          }
        >
          {two?.name}
        </div>
      </XSpace>
    ),
    link: true,
  };

  const item_03 = {
    key: "Final Category",
    content: (
      <XSpace>
        <div style={{ margin: "15px 0" }}>{last_item?.name}</div>
      </XSpace>
    ),
    active: true,
  };

  let items = [];
  if (level === "01") {
    items = [item_03];
  } else if (level === "02") {
    items = [item_02, item_03];
  } else if (level === "03") {
    items = [item_01, item_02, item_03];
  }

  return [item_00, ...items];
};

export default CatProductViewAll;
