import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.NOTICE_VIEW_ALL_REQUEST,
  success: types.NOTICE_VIEW_ALL_SUCCESS,
  fail: types.NOTICE_VIEW_ALL_FAIL,
};

export const get_list = {
  name: "get_list",
  state: defListState,
  request: types.NOTICE_GET_LIST_REQUEST,
  success: types.NOTICE_GET_LIST_SUCCESS,
  fail: types.NOTICE_GET_LIST_FAIL,
};

export const view = {
  name: "view",
  state: defListState,
  request: types.NOTICE_VIEW_REQUEST,
  success: types.NOTICE_VIEW_SUCCESS,
  fail: types.NOTICE_VIEW_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.NOTICE_EDIT_REQUEST,
  success: types.NOTICE_EDIT_SUCCESS,
  fail: types.NOTICE_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.NOTICE_ADD_REQUEST,
  success: types.NOTICE_ADD_SUCCESS,
  fail: types.NOTICE_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.NOTICE_DELETE_REQUEST,
  success: types.NOTICE_DELETE_SUCCESS,
  fail: types.NOTICE_DELETE_FAIL,
};
