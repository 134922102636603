import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import Hoc from "../../../comps/Hoc";
import Controls from "../../../comps/Controls";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Confirm } from "semantic-ui-react";
import AddEmp from "./AddEmp";
import moment from "moment";

const Productview = () => {
  const [manager, setManager] = useState("");
  const [openEmp, setOpenEmp] = useState(false);
  const initS1 = { open: false, id: "00" };
  const [openS1, setOpenS1] = useState(initS1);
  const params = useParams();
  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(({ store: v }) => v.item.view);
  let S1 = useSelector(({ people: v }) => v.store_manager.view_all.data);
  S1 = Hoc.isArr(S1).filter((v) => !v.Store || v?.id === data?.StoreManagerId);
  S1 = Hoc.isArr(S1).reduce(
    (acc, curr) =>
      Hoc.isArr(acc)
        .map((v1) => v1?.id)
        .includes(curr?.id)
        ? acc
        : [...acc, curr],
    []
  );

  const S2 = useSelector(({ people: v }) => v.store_employee.view_all.data);
  const root = useSelector(({ people: v }) => v.root.data);

  const Act_view = Action.store.item.view;
  const Act_view_edit = Action.store.item.view_edit;
  const Act_S1_view_all = Action.people.S1.view_all;
  const Act_S2_view_all = Action.people.S2.view_all;

  useEffect(() => {
    dispatch(Act_view(params.id));
    dispatch(Act_S1_view_all("Reason=Empty"));
    dispatch(Act_S2_view_all());
    setManager(data?.StoreManagerId);
  }, [
    dispatch,
    Act_view,
    Act_S1_view_all,
    Act_S2_view_all,
    params.id,
    data?.StoreManagerId,
  ]);

  const refresh = () => {
    dispatch(Act_view(params.id));
    dispatch(Act_S1_view_all("Reason=Empty"));
    dispatch(Act_S2_view_all());
    setManager(data?.StoreManagerId);
  };

  const onManagerSelect = () => {
    dispatch(
      Act_view_edit(params.id, {
        StoreManagerId: openS1.id !== "00" ? openS1.id : null,
      })
    );
    setManager(openS1.id);
    setOpenS1(initS1);
  };
  const onS1Close = () => {
    setOpenS1(initS1);
  };
  const S1Opt = () =>
    !S1?.length
      ? []
      : [
          { id: "00", title: "Disselect" },
          ...S1?.map((v) => ({
            id: v.id,
            title: `${v?.first_name} ${v?.last_name} (${v?.id})`,
          })),
        ];

  const S1D = S1?.find((v) => v?.id === data?.StoreManagerId);

  return (
    <React.Fragment>
      <Confirm
        header="Are you Sure?"
        open={openS1.open}
        content={"Yes, I accept that this per will manage the store"}
        onCancel={onS1Close}
        onConfirm={onManagerSelect}
        size="tiny"
      />

      <AddEmp
        StoreId={params.id}
        open={openEmp}
        setOpen={setOpenEmp}
        refresh={refresh}
      />
      <Hoc.Page title={`Store - ${data?.name}`}>
        <Hoc.UseData loading={loading} error={error}>
          <Hoc.Dashboard.Container
            title={data?.name}
            actions={[
              { name: "Back", to: "/stores" },
              { name: "Home", to: "/" },
            ]}
          >
            <Grid container spacing={2} justifyContent="center">
              {GridItem("ID", data?.id)}
              {GridItem("Name", data?.name)}
              {GridItem("Branch", data?.branch)}
              {GridItem("VAT/GST", data?.vat)}
              {GridItem("VAT Price", data?.vat_price)}
              {GridItem(
                "Coordinates",
                data?.lng ? `${data?.lng}, ${data?.lat}` : false
              )}

              {GridItem(
                "Collection Address",
                Hoc.showAddress(data?.Collection_Address)
              )}
              {GridItem("Store Address", Hoc.showAddress(data?.Store_Address))}
              {GridItem("Status", data?.archive ? "Inactive" : "Active")}
              <ShowItem name="Delete Request" value={data?.delete_request} />
              <ShowItem name="Created At" value={ToDate(data?.createdAt)} />
              <ShowItem name="Updated At" value={ToDate(data?.updatedAt)} />
              <ShowItem name="Manager Id" value={data?.StoreManagerId} />
              <ShowItem
                name="Manager Name"
                value={!S1D ? null : `${S1D?.first_name} ${S1D?.last_name}`}
              />

              <ShowItem name="Account Number" value={data?.PD_account_number} />
              <ShowItem name="Account Name" value={data?.PD_account_name} />
              <ShowItem name="Bank Code" value={data?.PD_bank_code} />
              <ShowItem name="Branch Name" value={data?.PD_branch_name} />
              <ShowItem name="Country Name" value={data?.PD_country_name} />
              <ShowItem name="Transaction Id" value={data?.PD_transaction_id} />
              <ShowItem name="Reference" value={data?.PD_reference} />
              <ShowItem name="IBAN" value={data?.PD_iban} />
              <ShowItem name="BIC Code" value={data?.PD_bic_code} />
              <ShowItem name="Sort Code" value={data?.PD_sort_code} />

              {root?.role === ("Company_Manager" || "Company_Admin") ? (
                <Grid item xs={12} md={6} lg={4}>
                  <Controls.SearchSelect
                    name="Manager"
                    label="Select Manager"
                    value={manager || ""}
                    onChange={({ target: T }) =>
                      setOpenS1({ open: true, id: T.value })
                    }
                    options={S1Opt()}
                  />
                </Grid>
              ) : (
                GridItem("Manager ID", data?.StoreManagerId)
              )}
              {GridItem(
                "Employees Count",
                <Box
                  display="flex"
                  alignItems="items"
                  justifyContent="space-around"
                >
                  <Typography>
                    {S2?.filter((v) => v.StoreId === parseInt(params.id))
                      ?.length || "0"}
                  </Typography>
                  {root?.role ===
                    ("Company_Manager" ||
                      "Company_Admin" ||
                      "Store_Manager") && (
                    <Controls.Button
                      text="Add"
                      size="small"
                      onClick={() => setOpenEmp(!openEmp)}
                    />
                  )}
                </Box>,
                true
              )}
            </Grid>
          </Hoc.Dashboard.Container>
        </Hoc.UseData>
      </Hoc.Page>
    </React.Fragment>
  );
};

const ToDate = (v) => moment(v).calendar();

const GridItem = (n, v, c) =>
  !!v && (
    <Grid item xs={12} sm={6} lg={4}>
      <Hoc.ShowItem name={n} {...(!c && { value: v })}>
        {!!c && v}
      </Hoc.ShowItem>
    </Grid>
  );

const ShowItem = ({ value, ...rest }) =>
  !!value && (
    <Grid item xs={12} sm={6} lg={4}>
      <Hoc.ShowItem value={value} {...rest} />
    </Grid>
  );
export default Productview;
