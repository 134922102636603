import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../Controls";

const useCss = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  side_view: {
    backgroundColor: "red",
  },
}));

const Form = (props) => {
  const { endContent, children, name, resetForm, submitButton } = props;
  const { onSubmit, noUndo = false } = props;

  const submitHandler = () => {
    onSubmit();
  };
  const css = useCss();

  return (
    <form
      className={css.root}
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
    >
      <div style={{ margin: "20px 0" }}>{children}</div>
      <div className="flex-between">
        <Controls.Button
          fullWidth
          onClick={submitHandler}
          text={name || "Submit"}
          {...submitButton}
        />
        {!noUndo && (
          <Controls.Button
            fullWidth
            text="Undo / Back to Original"
            color="default"
            onClick={resetForm}
          />
        )}
      </div>
      {endContent}
    </form>
  );
};

const useForm = (initValues, validateOnChange = false, validate) => {
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState({});

  const onChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
    if (validateOnChange) validate({ [name]: value });
  };

  const onFileChange = ({ target: { name, value, files } }) => {
    setValues({ ...values, [name]: files[0] });
  };

  const resetForm = () => {
    setValues(initValues);
    setErrors(null);
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    onChange,
    onFileChange,
    resetForm,
  };
};

const useFormSetup = {
  Form,
  useForm,
};

export default useFormSetup;
