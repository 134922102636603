import React from "react";
import { useSelector } from "react-redux";
import Hoc from "../../Hoc";
import ShowProfile from "./Sub/ShowProfile";
import AddressTable from "./Sub/AddressTable";

const ProfileView = () => {
  const root = useSelector(({ people: P }) => P.root.data);
  const title = `${root?.first_name} ${root?.last_name}`;

  const TOT = (name, to) => ({ name, to });

  const RoleId =
    root?.role !== "Company_Manager"
      ? `${root?.role}`.split("_").join("") + "Id"
      : null;

  const nav01 = () => {
    let IT = [
      TOT("Edit Profile", "/profile-edit"),
      TOT("Edit Password", "/profile-edit-password"),
      { name: "Back", goBack: true },
    ];
    if (!!RoleId) {
      IT = [TOT("Create Address", `/address-add?${RoleId}=${root?.id}`), ...IT];
    }
    return IT;
  };

  const nav02 = {
    title: `${title} (${root?.id})`,
    actions: nav01(),
  };
  return (
    <Hoc.Page title={title || "My Profile"}>
      <Hoc.Dashboard.Container {...nav02}>
        <ShowProfile profile={root} />
        {RoleId && <AddressTable id={root?.id} RoleId={RoleId} />}
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default ProfileView;
