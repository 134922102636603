import React, { useEffect, useState } from "react";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import Controls from "../../../../comps/Controls";
import Action from "../../../../actions";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const TransactionForm = (props) => {
  const dispatch = useDispatch();
  const H = useHistory();
  const [addAddtional, setAddAddtional] = useState(false);
  const Act_transaction = Action.base.transaction;
  const act_curr = Action.base02.currency.list;
  const act_order = Action.store.order.view;
  const act_notif = Action.base02.notif.view_add;
  const { id } = props;
  const { Form, useForm } = Custom.useForm;
  const root = useSelector(({ people: B }) => B.root.data);
  const isUser = root?.role === "Customer";
  const formData = useForm(initData({}, isUser, root?.Currency?.id));
  const { values, onChange, resetForm } = formData;
  const { setValues: setV } = formData;
  const Loc = useLocation();
  const { refund, ...qry } = queryString?.parse(Loc.search);
  const currs = useSelector(({ base02: B }) => B.currency.list.data);

  useEffect(() => {
    dispatch(act_curr());

    const SetValue = async (id, rest) =>
      setV({ ...(await dispatch(Act_transaction.view(id))), ...rest });

    !!id && SetValue(id);

    !!refund && SetValue(refund, { status: "Refund" });
  }, [dispatch, setV, act_curr, Act_transaction, refund, id]);

  const onSubmit = async () => {
    let finalData = { ...values, ...qry };
    finalData = (({ id, ...rest }) => rest)(finalData);
    let status;
    if (!id) {
      status = await dispatch(Act_transaction.view_add(finalData));
    } else {
      status = await dispatch(Act_transaction.view_edit(id, finalData));
    }
    if (!!status) {
      if (values?.status === "Pending") {
        const curr = currs?.find((v) => values?.CurrencyId === v?.id);
        let text = `Payment Confirmation of ${values?.amount}${curr?.currency} for the Order Number of ${qry?.OrderId} through ${values?.method}.`;
        let trans = { role: "Company_Manager" };
        trans = { ...trans, text };
        dispatch(act_notif(trans));
      } else if (values?.status === "Refund") {
        if (!!qry?.StoreId) {
          const curr = currs?.find((v) => values?.CurrencyId === v?.id);
          let text = `Payment issued of ${values?.amount}${curr?.currency} to the Store through ${values?.method}.`;
          let trans = { role: "Store", StoreId: qry?.StoreId };
          trans = { ...trans, text };
          dispatch(act_notif(trans));
        } else {
          const order = await dispatch(act_order(qry?.OrderId));
          const curr = currs?.find((v) => values?.CurrencyId === v?.id);
          let text = `Refund issued of ${values?.amount}${curr?.currency} for the Order Number of ${qry?.OrderId} through ${values?.method}.`;
          let trans = { role: "Customer", CustomerId: order?.CustomerId };
          trans = { ...trans, text };
          dispatch(act_notif(trans));
        }
      }

      resetForm();
      H.goBack();
    }
  };
  useEffect(() => {
    const STP = (paying_to) => setV((v) => ({ ...v, paying_to }));
    if (!!qry?.StoreId) {
      STP("Store");
    }
    // eslint-disable-next-line
  }, [setV]);

  const title = qry?.OrderId
    ? "Payment Confirmation for an Order"
    : qry?.StoreId
    ? "Payment to the Store"
    : `${!id ? "Create" : "Update"} Transaction`;

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values, isUser),
        }}
      >
        <Controls.Input {...setup.amount(values.amount, onChange)} />
        <TwoItem>
          <Controls.SearchSelect
            {...setup.method(values.method, onChange, isUser)}
          />
          <Controls.SearchSelect
            {...setup.CurrencyId(values.CurrencyId, onChange, currs)}
          />
        </TwoItem>
        {!isUser && (
          <TwoItem>
            <Controls.SearchSelect
              {...setup.paying_to(values.paying_to, onChange)}
            />
            <Controls.SearchSelect
              {...setup.status(values.status, onChange, !!refund)}
            />
          </TwoItem>
        )}
        <Controls.Input
          {...setup.PD_transaction_id(values.PD_transaction_id, onChange)}
        />
        <Controls.Input
          {...setup.PD_reference(values.PD_reference, onChange)}
        />{" "}
        {!!addAddtional && (
          <React.Fragment>
            <Controls.Input {...setup.email(values.email, onChange)} />
            <Controls.Input {...setup.mobile(values.mobile, onChange)} />
            <Controls.Input
              {...setup.account_name(values.account_name, onChange)}
            />
            <Controls.Input
              {...setup.account_number(values.account_number, onChange)}
            />
            <Controls.Input {...setup.bank_code(values.bank_code, onChange)} />
            <Controls.Input
              {...setup.branch_name(values.branch_name, onChange)}
            />
            <Controls.Input
              {...setup.country_name(values.country_name, onChange)}
            />
          </React.Fragment>
        )}
        <Typography {...extra_dt} onClick={() => setAddAddtional((V) => !V)}>
          {!!addAddtional ? "Hide Extra Data" : "Add Extra Data"}
        </Typography>
      </Form>
    </Hoc.FormContainer>
  );
};

const extra_dt = {
  align: "right",
  color: "primary",
  style: { cursor: "pointer", textDecoration: "underline" },
};

const setup_status = (v, isUser) =>
  !v?.amount || !v?.method || !v?.CurrencyId || !v?.status || !v?.paying_to;

const base_setup = (v, onChange, name, label, type = "text") => ({
  ...Hoc.setupText(v, onChange, name, label, type),
  ...(type === "number" && {
    inputProps: {
      min: 0,
      step: 0.001,
    },
  }),
});

const toCurr = (v) =>
  Hoc.isArr(v).map((v) => ({
    id: v?.id,
    title: `${v?.name} (${v?.currency})`,
  }));

const setup = {
  amount: (v, OC) => ({
    ...base_setup(v, OC, "amount", "Paid Amount (0.00)", "number"),
    inputProps: {
      step: 0.01,
    },
  }),

  CurrencyId: (v, OC, currs) =>
    Hoc.setupSelect(v, OC, "CurrencyId", "Select Currency", toCurr(currs)),
  status: (v, OC, disabled) => ({
    ...Hoc.setupSelect(v, OC, "status", "Select Status", Opt02),
    disabled,
  }),

  method: (v, OC, Usr) =>
    Hoc.setupSelect(
      v,
      OC,
      "method",
      "Select Payment Method",
      !Usr ? Opt03_01 : Opt03_02
    ),
  paying_to: (v, OC) =>
    Hoc.setupSelect(v, OC, "paying_to", "Payment To", Opt04),
  PD_transaction_id: (v, OC) =>
    base_setup(v, OC, "PD_transaction_id", "Transaction ID"),
  email: (v, OC) => base_setup(v, OC, "email", "Email", "email"),
  mobile: (v, OC) => base_setup(v, OC, "mobile", "Mobile"),
  account_name: (v, OC) => base_setup(v, OC, "account_name", "Account Name"),
  account_number: (v, OC) =>
    base_setup(v, OC, "account_number", "Account Number"),
  bank_code: (v, OC) => base_setup(v, OC, "bank_code", "Bank Code"),
  branch_name: (v, OC) => base_setup(v, OC, "branch_name", "Branch Name"),
  country_name: (v, OC) => base_setup(v, OC, "country_name", "Country Name"),
  PD_reference: (v, OC) => base_setup(v, OC, "PD_reference", "Reference"),
};

const Opt02 = [
  { id: "UnPaid", title: "UnPaid" },
  { id: "Pending", title: "Pending" },
  { id: "Paid", title: "Paid" },
  { id: "Refund", title: "Refund" },
];

const Opt03_02 = [
  { id: "Unknown", title: "Unknown" },
  { id: "MobilePay", title: "MobilePay" },
  { id: "Bank", title: "Bank" },
];

const Opt03_01 = [
  ...Opt03_02,
  { id: "PayPal", title: "PayPal" },
  { id: "Stripe", title: "Stripe" },
  { id: "RazorPay", title: "RazorPay" },
];

const Opt04 = [
  { id: "Order", title: "Order" },
  { id: "Company", title: "Company" },
  { id: "Store", title: "Store" },
];

const TwoItem = ({ children }) => (
  <Box {...Hoc.flex("space-between")}>{children}</Box>
);

const initData = (v, cus, CurrencyId) => ({
  amount: v?.amount ? v?.amount : null,
  CurrencyId: cus ? CurrencyId : v?.CurrencyId ? v?.CurrencyId : null,
  paying_to: cus ? "Order" : v?.paying_to ? v?.paying_to : null,
  status: cus ? "Pending" : v?.status ? v?.status : null,
  method: v?.method ? v?.method : null,
  transaction_id: v?.PD_transaction_id ? v?.PD_transaction_id : null,
  email: v?.email ? v?.email : null,
  mobile: v?.mobile ? v?.mobile : null,
  account_name: v?.account_name ? v?.account_name : null,
  account_number: v?.account_number ? v?.account_number : null,
  bank_code: v?.bank_code ? v?.bank_code : null,
  branch_name: v?.branch_name ? v?.branch_name : null,
  country_name: v?.country_name ? v?.country_name : null,
  reference: v?.PD_reference ? v?.PD_reference : null,
  StoreId: v?.StoreId ? v?.StoreId : null,
});

export default TransactionForm;
