import React, { useEffect } from "react";
import Hoc from "../../comps/Hoc";
import Custom from "../../comps/Custom";
import Controls from "../../comps/Controls";
import action from "../../actions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@material-ui/core";

const CurrencyForm = () => {
  const act_currency = action.base02.currency;
  const act_str_cur = action.base02.store_currency;
  const act_OOP = action.base02.oop_msg.view_add;
  const { Form, useForm } = Custom.useForm;
  const dispatch = useDispatch();
  const { id: StoreId } = useParams();
  const formData = useForm(initData());
  const { values, onChange, resetForm } = formData;
  const title = `Store Currency`;

  let currencies = useSelector(({ base02: B }) => B.currency.list.data);
  let store_currency = useSelector(
    ({ base02: B }) => B.store_currency.list.data
  );
  store_currency = Hoc.isArr(store_currency).map((v) => ({
    ...v,
    Currency: currencies.find((v2) => v2.id === v.CurrencyId),
  }));
  const ids = Hoc.isArr(store_currency).map((v) => v.CurrencyId);
  currencies = Hoc.isArr(currencies).filter((v) => !ids.includes(v.id));

  useEffect(() => {
    dispatch(act_str_cur.list(`StoreId=${StoreId}`));
    dispatch(act_currency.list());
  }, [dispatch, act_str_cur, act_currency, StoreId]);

  const refresh = () => {
    dispatch(act_str_cur.list(`StoreId=${StoreId}`));
    dispatch(act_currency.list());
  };
  const onSubmit = async () => {
    const status = await dispatch(act_str_cur.view_add({ ...values, StoreId }));
    if (!!status) {
      dispatch(act_OOP(`Currency Added Successfully.`));
      refresh();
    }
  };
  const onDelete = (id) => {
    dispatch(act_str_cur.view_delete(id));
    refresh();
    dispatch(act_OOP(`Currency Removed Successfully.`));
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        {!store_currency?.length ? (
          <Hoc.EmptyMessage content="There are no currencies assigned to this Store." />
        ) : (
          store_currency?.map((v, idx) => (
            <Chip
              key={idx}
              label={`${v?.Currency?.name} (${v?.Currency?.currency})`}
              onDelete={() => onDelete(v.id)}
              color="primary"
              style={{ margin: "5px 7px" }}
            />
          ))
        )}

        <Controls.SearchSelect
          {...setup.CurrencyId(values?.CurrencyId, onChange, currencies)}
        />
      </Form>
    </Hoc.FormContainer>
  );
};
const setup_status = (v) => !v?.CurrencyId;

const setup = {
  CurrencyId: (v, OC, opt) =>
    Hoc.setupSelect(
      v,
      OC,
      "CurrencyId",
      "Select Currency",
      opt.map((v) => ({ id: v.id, title: `${v.name} (${v.currency})` }))
    ),
};

const initData = (v) => ({
  CurrencyId: v?.CurrencyId ? v?.CurrencyId : null,
});

export default CurrencyForm;
