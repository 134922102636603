import React, { useEffect } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";

const ViewInventory = () => {
  const Act_box = Action.product.box.variants;
  const dispatch = useDispatch();
  const vrns = useSelector(({ product: P }) => P.box.variants);

  const { error, loading } = vrns;
  let data = vrns?.data?.map(({ Trollies, ...T }) => ({
    ...T,
    Trollies: Trollies?.filter((I) => !!I?.Inventory),
  }));
  data = data.filter((T) => T?.Trollies?.length);

  const { finalRecords, TblContainer } = Custom.useTable(data, cells);

  useEffect(() => {
    dispatch(Act_box());
  }, [dispatch, Act_box]);

  const refresh = () => {
    dispatch(Act_box());
  };

  const nav_actions = [
    { name: "Refresh", onClick: refresh },
    { name: "Back", goBack: true },
  ];

  return (
    <Hoc.Dashboard.Container title="Inventory Management" actions={nav_actions}>
      <Hoc.UseData error={error} loading={loading}>
        {!!finalRecords().length ? (
          <TblContainer paginate>
            {finalRecords().map((v) => (
              <TableRow key={v.id}>
                <TabCell content={v?.name} />
                {/* Making */}
                <TabCell content={Bprice(v)} />
                <TabCell content={v?.total_units} />
                <TabCell content={Bvat(v)} />
                <TabCell content={Bshipping_tax(v)} />
                <TabCell content={Btotal_price(v)} />
                {/* Selling */}
                <TabCell content={Sprice(v)} />
                <TabCell content={SSUnits(v)} />
                <TabCell content={Svat(v)} />
                <TabCell content={Sshipping_tax(v)} />
                <TabCell content={Sdiscount(v)} />
                <TabCell content={Stotal_price(v)} />
                {/* Quantity */}
                <TabCell content={v?.quantity} />
                <TabCell content={v?.trolly_units} />
                <TabCell content={v?.defect_units} />
                {/*  */}
                <TabCell content={PF(Stotal_price(v) - Btotal_price(v))} />
              </TableRow>
            ))}
          </TblContainer>
        ) : (
          <Hoc.EmptyMessage />
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const PF = (v) => parseFloat(v);

const GET_INV = (v) => v?.Trollies?.map((v) => v?.Inventory);

const Bprice = (v1) => GET_INV(v1)?.reduce((a, b) => a + PF(b?.B_price), 0);
const Bshipping_tax = (v1) =>
  GET_INV(v1)?.reduce((a, b) => a + PF(b?.B_shipping_price), 0);
const Bvat = (v1) => GET_INV(v1)?.reduce((a, b) => a + PF(b?.B_vat_price), 0);
const Btotal_price = (v1) =>
  GET_INV(v1)?.reduce((a, b) => a + PF(b?.BT_price), 0);

const Sprice = (v1) => v1?.Trollies?.reduce((a, T) => a + PF(T?.S_price), 0);
const SSUnits = (v1) =>
  v1?.Trollies?.reduce((a, T) => a + PF(T?.sold_units), 0);

const Svat = (v1) => v1?.Trollies?.reduce((a, T) => a + PF(T?.ST_vat_price), 0);
const Sshipping_tax = (v1) =>
  v1?.Trollies?.reduce((a, T) => a + PF(T?.ST_shipping_price), 0);
const Sdiscount = (v1) =>
  v1?.Trollies?.reduce((a, T) => a + PF(T?.discount), 0);
const Stotal_price = (v1) =>
  v1?.Trollies?.reduce((a, T) => a + PF(T?.ST_price), 0);

const TabCell = ({ content }) => (
  <TableCell>
    <span {...(!content && { style: { color: "orangered" } })}>
      {content || "NA"}
    </span>
  </TableCell>
);

const cells = [
  { id: "Name", label: "Name" },
  // Buy
  { id: "01", label: "Base Price (Paid)" },
  { id: "02", label: "Total Units" },
  { id: "03", label: "Shipping Price (Paid)" },
  { id: "04", label: "VAT / GST (Paid)" },
  { id: "05", label: "Total Amount (Paid)" },
  // Sell
  { id: "06", label: "Base Price (Received)" },
  { id: "07", label: "Sold Units" },
  { id: "08", label: "Shipping Price (Received)" },
  { id: "09", label: "VAT / GST (Received)" },
  { id: "10", label: "discount" },
  { id: "11", label: "total_price" },
  //
  { id: "12", label: "quantity" },
  { id: "13", label: "trolly_units" },
  { id: "15", label: "defect_units" },
  { id: "16", label: "PL on Sold Items" },
];

export default ViewInventory;
