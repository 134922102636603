import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const list = {
  name: "list",
  state: defListState,
  request: types.CURRENCY_LIST_REQUEST,
  success: types.CURRENCY_LIST_SUCCESS,
  fail: types.CURRENCY_LIST_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.CURRENCY_VIEW_REQUEST,
  success: types.CURRENCY_VIEW_SUCCESS,
  fail: types.CURRENCY_VIEW_FAIL,
};

export const view_gbp = {
  name: "view_gbp",
  state: defState,
  request: types.CURRENCY_VIEW_GBP_REQUEST,
  success: types.CURRENCY_VIEW_GBP_SUCCESS,
  fail: types.CURRENCY_VIEW_GBP_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.CURRENCY_EDIT_REQUEST,
  success: types.CURRENCY_EDIT_SUCCESS,
  fail: types.CURRENCY_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.CURRENCY_ADD_REQUEST,
  success: types.CURRENCY_ADD_SUCCESS,
  fail: types.CURRENCY_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.CURRENCY_DELETE_REQUEST,
  success: types.CURRENCY_DELETE_SUCCESS,
  fail: types.CURRENCY_DELETE_FAIL,
};
