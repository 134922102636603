import React from "react";
import { Grid } from "@material-ui/core";
import { Message } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const CatViewProducts = ({ items }) => {
  const H = useHistory();

  return (
    <Grid container spacing={2}>
      {items
        ?.filter((v1) => !!v1?.Category_03s?.length)
        ?.map((v) => (
          <Grid key={v.id} item xs={12} sm={6} md={4} lg={3}>
            <Message>
              <Message.Header>{v?.name}</Message.Header>
              <Message.List>
                {v?.Category_03s?.map((v2) => (
                  <Message.Item
                    key={v2.id}
                    content={v2?.name}
                    style={{ cursor: "pointer" }}
                    onClick={() => H.push(`/category-03/${v2.slug}`)}
                  />
                ))}
              </Message.List>
            </Message>
          </Grid>
        ))}
    </Grid>
  );
};

export default CatViewProducts;
