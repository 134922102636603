import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import TrollyNav from "./Hoc/TrollyNav";
import PreviousNext from "./Hoc/PreviousNext";
import TrollyView04 from "./TrollyView/TrollyView04";
import PayInfo from "./Hoc02/PayInfo";

const ViewAll = () => {
  const Act_order = Action.store.order;
  const Act_trolly = Action.store.trolly;
  const dispatch = useDispatch();
  const openIT = useSelector(({ store: S }) => S.order.open.data);
  const trollies = useSelector(({ store: v }) => v.trolly.view_all.data);

  useEffect(() => {
    dispatch(Act_order.open_view());
    dispatch(Act_trolly.view_all(`status=Trolly`));
  }, [dispatch, Act_order, Act_trolly]);

  const nav_items = [
    { name: "Orders", to: "/orders-01" },
    {
      name: "Refresh",
      onClick: () => dispatch(Act_order.ordered("status=None")),
    },
  ];

  const CKS = (v) => !!v?.subscription && !v?.S_price;
  const CKFB = Hoc.isArr(trollies).every((v) => CKS(v?.Product_Box));

  return (
    <Hoc.Page title="Trollies">
      <Hoc.Dashboard.Container title="Trollies" actions={nav_items}>
        {!!trollies?.length && <TrollyNav step={4} />}

        {!CKFB && !!openIT && <PayInfo data={openIT} trollies={trollies} />}
        <Box style={{ width: "100%" }}>
          <TrollyView04 data={openIT} trollies={trollies} />
        </Box>

        <PreviousNext from="03" />
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default ViewAll;
