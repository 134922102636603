import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Header, Message } from "semantic-ui-react";
import moment from "moment";
import Hoc from "../../../../Hoc";
import action from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";

const Reviews = ({ reviews }) => {
  const root = useSelector(({ people: P }) => P.root.data);
  const items_01 = reviews?.filter((v) => !!v?.publishable);
  const items_02 = reviews?.filter((v) => !v?.publishable);

  return (
    <React.Fragment>
      <ShowReviews root={root} items={items_01} />
      {`${root?.role}`.includes("Company") && (
        <ShowReviews root={root} items={items_02} unp />
      )}
    </React.Fragment>
  );
};

const ShowReviews = ({ items, unp = false, root }) => {
  const act_review = action.product.review;
  const dispatch = useDispatch();
  const t_act = (v) => {
    let items = [];
    const TOC = (name, onClick) => ({ name, onClick });

    items = [
      TOC(!v?.publishable ? "Publish" : "Unpublish", () =>
        dispatch(act_review.view_edit(v?.id, { publishable: !v?.publishable }))
      ),
      TOC("Delete", () => dispatch(act_review.view_delete(v?.id))),
    ];

    return items;
  };
  return (
    <React.Fragment>
      {!!items?.length && (
        <Header
          content={`${!unp ? "" : "Unpublished "}Reviews`}
          color={unp ? "red" : "teal"}
          textAlign="center"
          as="h3"
        />
      )}
      {items?.map((v) => (
        <Message color={unp ? "red" : "teal"} key={v?.id}>
          <Grid container spacing={2}>
            <Item xs={10} content={v.description} />
            <Item xs={2}>
              <Box>{`${v?.rating}/5`}</Box>

              {`${root?.role}`.includes("Company") && (
                <Box style={{ marginLeft: 10 }}>
                  <Hoc.Product.MenuButton actions={t_act(v)} />
                </Box>
              )}
            </Item>

            <Item
              xs={12}
              fx="flex-end"
              content={moment(v.updatedAt).calendar()}
            />
          </Grid>
        </Message>
      ))}
    </React.Fragment>
  );
};

const Item = ({ xs, content, children, fx = "center" }) => (
  <Grid item xs={xs}>
    <Box style={{ width: "100%" }} {...Hoc.flex(fx)}>
      {content}
      {children}
    </Box>
  </Grid>
);

export default Reviews;
