const types = {
  //
  TEMPLATE_VIEW_ALL_REQUEST: "TEMPLATE_VIEW_ALL_REQUEST",
  TEMPLATE_VIEW_ALL_SUCCESS: "TEMPLATE_VIEW_ALL_SUCCESS",
  TEMPLATE_VIEW_ALL_FAIL: "TEMPLATE_VIEW_ALL_FAIL",
  //
  TEMPLATE_VIEW_REQUEST: "TEMPLATE_VIEW_REQUEST",
  TEMPLATE_VIEW_SUCCESS: "TEMPLATE_VIEW_SUCCESS",
  TEMPLATE_VIEW_FAIL: "TEMPLATE_VIEW_FAIL",
  //
  TEMPLATE_EDIT_REQUEST: "TEMPLATE_EDIT_REQUEST",
  TEMPLATE_EDIT_SUCCESS: "TEMPLATE_EDIT_SUCCESS",
  TEMPLATE_EDIT_FAIL: "TEMPLATE_EDIT_FAIL",
  //
  TEMPLATE_ADD_REQUEST: "TEMPLATE_ADD_REQUEST",
  TEMPLATE_ADD_SUCCESS: "TEMPLATE_ADD_SUCCESS",
  TEMPLATE_ADD_FAIL: "TEMPLATE_ADD_FAIL",
  //
  TEMPLATE_DELETE_REQUEST: "TEMPLATE_DELETE_REQUEST",
  TEMPLATE_DELETE_SUCCESS: "TEMPLATE_DELETE_SUCCESS",
  TEMPLATE_DELETE_FAIL: "TEMPLATE_DELETE_FAIL",
  //
  EMAIL_GET_REQUEST: "EMAIL_GET_REQUEST",
  EMAIL_GET_SUCCESS: "EMAIL_GET_SUCCESS",
  EMAIL_GET_FAIL: "EMAIL_GET_FAIL",
  //
  EMAIL_SET_REQUEST: "EMAIL_SET_REQUEST",
  EMAIL_SET_SUCCESS: "EMAIL_SET_SUCCESS",
  EMAIL_SET_FAIL: "EMAIL_SET_FAIL",
};

export default types;
