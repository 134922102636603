import SecureRoute from "../../../comps/layout/SecureRoute";
import category_01 from "./category-01";
import category_03 from "./category-03";

const Expo = {
  categories: (
    <SecureRoute grant="S2" path="/category-trolly" component={category_01} />
  ),
  sub_categories: (
    <SecureRoute
      grant="S2"
      path="/category-03-trolly"
      component={category_03}
    />
  ),
};

export default Expo;
