import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};
const defStateList = {
  loading: false,
  data: [],
  error: null,
};

export const list = {
  name: "list",
  state: defStateList,
  request: types.PAY_LIST_REQUEST,
  success: types.PAY_LIST_SUCCESS,
  fail: types.PAY_LIST_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.PAY_VIEW_REQUEST,
  success: types.PAY_VIEW_SUCCESS,
  fail: types.PAY_VIEW_FAIL,
};

export const method_list = {
  name: "method_list",
  state: defState,
  request: types.PAY_MTLIST_REQUEST,
  success: types.PAY_MTLIST_SUCCESS,
  fail: types.PAY_MTLIST_FAIL,
};

export const send_bank = {
  name: "send_bank",
  state: defState,
  request: types.SEND_BANK_INFO_REQUEST,
  success: types.SEND_BANK_INFO_SUCCESS,
  fail: types.SEND_BANK_INFO_FAIL,
};

export const send_mobile = {
  name: "send_mobile",
  state: defState,
  request: types.SEND_MOBILE_PAY_REQUEST,
  success: types.SEND_MOBILE_PAY_SUCCESS,
  fail: types.SEND_MOBILE_PAY_FAIL,
};

export const status = {
  name: "status",
  state: defState,
  request: types.PAY_STS_REQUEST,
  success: types.PAY_STS_SUCCESS,
  fail: types.PAY_STS_FAIL,
};

export const edit_status = {
  name: "edit_status",
  state: defState,
  request: types.PAY_ED_STS_REQUEST,
  success: types.PAY_ED_STS_SUCCESS,
  fail: types.PAY_ED_STS_FAIL,
};

export const method = {
  name: "method",
  state: defState,
  request: types.PAY_METHOD_REQUEST,
  success: types.PAY_METHOD_SUCCESS,
  fail: types.PAY_METHOD_FAIL,
};

export const view_add = {
  name: "view_add",
  state: defState,
  request: types.PAY_ADD_REQUEST,
  success: types.PAY_ADD_SUCCESS,
  fail: types.PAY_ADD_FAIL,
};

export const view_edit = {
  name: "view_edit",
  state: defState,
  request: types.PAY_EDIT_REQUEST,
  success: types.PAY_EDIT_SUCCESS,
  fail: types.PAY_EDIT_FAIL,
};

export const view_delete = {
  name: "view_delete",
  state: defState,
  request: types.PAY_DELETE_REQUEST,
  success: types.PAY_DELETE_SUCCESS,
  fail: types.PAY_DELETE_FAIL,
};

export const view_queues = {
  name: "view_queues",
  state: defState,
  request: types.VIEW_QUEUES_REQUEST,
  success: types.VIEW_QUEUES_SUCCESS,
  fail: types.VIEW_QUEUES_FAIL,
};
