const tp = {
  //
  C1_SIGN_UP_REQUEST: "C1_SIGN_UP_REQUEST",
  C1_SIGN_UP_SUCCESS: "C1_SIGN_UP_SUCCESS",
  C1_SIGN_UP_FAIL: "C1_SIGN_UP_FAIL",
  //
  C1_SIGN_IN_REQUEST: "C1_SIGN_IN_REQUEST",
  C1_SIGN_IN_SUCCESS: "C1_SIGN_IN_SUCCESS",
  C1_SIGN_IN_FAIL: "C1_SIGN_IN_FAIL",
  //
  C1_FORGOT_PSW_REQUEST: "C1_FORGOT_PSW_REQUEST",
  C1_FORGOT_PSW_SUCCESS: "C1_FORGOT_PSW_SUCCESS",
  C1_FORGOT_PSW_FAIL: "C1_FORGOT_PSW_FAIL",
  //
  C1_RESET_PSW_REQUEST: "C1_RESET_PSW_REQUEST",
  C1_RESET_PSW_SUCCESS: "C1_RESET_PSW_SUCCESS",
  C1_RESET_PSW_FAIL: "C1_RESET_PSW_FAIL",
  //
  C1_EDIT_PSW_REQUEST: "C1_EDIT_PSW_REQUEST",
  C1_EDIT_PSW_SUCCESS: "C1_EDIT_PSW_SUCCESS",
  C1_EDIT_PSW_FAIL: "C1_EDIT_PSW_FAIL",
  //
  C1_VERIFY_EMAIL_REQUEST: "C1_VERIFY_EMAIL_REQUEST",
  C1_VERIFY_EMAIL_SUCCESS: "C1_VERIFY_EMAIL_SUCCESS",
  C1_VERIFY_EMAIL_FAIL: "C1_VERIFY_EMAIL_FAIL",
  //
  C1_PROFILE_VIEW_REQUEST: "C1_PROFILE_VIEW_REQUEST",
  C1_PROFILE_VIEW_SUCCESS: "C1_PROFILE_VIEW_SUCCESS",
  C1_PROFILE_VIEW_FAIL: "C1_PROFILE_VIEW_FAIL",
  //
  C1_PROFILE_EDIT_REQUEST: "C1_PROFILE_EDIT_REQUEST",
  C1_PROFILE_EDIT_SUCCESS: "C1_PROFILE_EDIT_SUCCESS",
  C1_PROFILE_EDIT_FAIL: "C1_PROFILE_EDIT_FAIL",
  //
  //
  C1_EDIT_REQUEST: "C1_EDIT_REQUEST",
  C1_EDIT_SUCCESS: "C1_EDIT_SUCCESS",
  C1_EDIT_FAIL: "C1_EDIT_FAIL",
};

export default tp;
