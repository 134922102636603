import details from "./details";
import { AddForm, EditForm } from "./PaymentForm";
import { AddCCForm, EditCCForm } from "./Sub/DetailCountryForm";
import SecureRoute from "../../../comps/layout/SecureRoute";

const Expo = {
  country_detail_add: (
    <SecureRoute
      grant="C1"
      path="/payment-country-detail-add/:BankId"
      component={AddCCForm}
    />
  ),
  country_detail_edit: (
    <SecureRoute
      grant="C1"
      path="/payment-country-detail-edit/:id"
      component={EditCCForm}
    />
  ),
  view: <SecureRoute grant="C1" path="/payment-details" component={details} />,
  add: (
    <SecureRoute grant="C1" path="/payment-detail-add" component={AddForm} />
  ),
  edit: (
    <SecureRoute
      grant="C1"
      path="/payment-detail-edit/:id"
      component={EditForm}
    />
  ),
};

export default Expo;
