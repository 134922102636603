const types = {
  //
  COUNTRY_SHIP_tax_VIEW_ALL_REQUEST: "COUNTRY_SHIP_tax_VIEW_ALL_REQUEST",
  COUNTRY_SHIP_tax_VIEW_ALL_SUCCESS: "COUNTRY_SHIP_tax_VIEW_ALL_SUCCESS",
  COUNTRY_SHIP_tax_VIEW_ALL_FAIL: "COUNTRY_SHIP_tax_VIEW_ALL_FAIL",
  //
  COUNTRY_SHIP_tax_VIEW_REQUEST: "COUNTRY_SHIP_tax_VIEW_REQUEST",
  COUNTRY_SHIP_tax_VIEW_SUCCESS: "COUNTRY_SHIP_tax_VIEW_SUCCESS",
  COUNTRY_SHIP_tax_VIEW_FAIL: "COUNTRY_SHIP_tax_VIEW_FAIL",
  //
  COUNTRY_SHIP_tax_EDIT_REQUEST: "COUNTRY_SHIP_tax_EDIT_REQUEST",
  COUNTRY_SHIP_tax_EDIT_SUCCESS: "COUNTRY_SHIP_tax_EDIT_SUCCESS",
  COUNTRY_SHIP_tax_EDIT_FAIL: "COUNTRY_SHIP_tax_EDIT_FAIL",
  //
  COUNTRY_SHIP_tax_ADD_REQUEST: "COUNTRY_SHIP_tax_ADD_REQUEST",
  COUNTRY_SHIP_tax_ADD_SUCCESS: "COUNTRY_SHIP_tax_ADD_SUCCESS",
  COUNTRY_SHIP_tax_ADD_FAIL: "COUNTRY_SHIP_tax_ADD_FAIL",
  //
  COUNTRY_SHIP_tax_DELETE_REQUEST: "COUNTRY_SHIP_tax_DELETE_REQUEST",
  COUNTRY_SHIP_tax_DELETE_SUCCESS: "COUNTRY_SHIP_tax_DELETE_SUCCESS",
  COUNTRY_SHIP_tax_DELETE_FAIL: "COUNTRY_SHIP_tax_DELETE_FAIL",
};

export default types;
