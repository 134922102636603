import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../../Hoc";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import Custom from "../../../Custom";
import slugify from "slugify";

const Root = (props) => {
  const {
    v: { open, id },
    setV,
  } = props;

  const dispatch = useDispatch();
  const Act_cat_02 = Action.product.category_02;
  const Act_cat_03 = Action.product.category_03;
  const { Form } = Custom.useForm;
  const [cat_01, setCat_01] = useState(id);
  const { values, onChange, resetForm } = Custom.useForm.useForm({
    name: "",
    Category02Id: "",
  });

  const onOpenClose = () => {
    setV({ open: false, id: "" });
  };

  useEffect(() => {
    if (!!id) {
      setCat_01(id);
    }
  }, [Act_cat_02, id, setCat_01]);

  const category_01 = useSelector(
    ({ product: p }) => p.category_01.view_all.data
  );
  const category_02 = useSelector(
    ({ product: p }) => p.category_02.view_all.data
  );

  const onSubmit = () => {
    dispatch(
      Act_cat_03.view_add({
        ...values,
        slug: slugify(values?.name, { lower: true }),
      })
    );
    onOpenClose();
    resetForm();
  };
  return (
    <Hoc.Dialog
      open={open}
      onClose={onOpenClose}
      title="Sub Category Item"
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onOpenClose}
        />
      }
    >
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        maxWidth="xs"
        submitButton={{
          disabled: !values?.name || !values?.Category02Id,
        }}
      >
        <Controls.SearchSelect
          {...token_setup.cat_01(cat_01, setCat_01, category_01)}
        />
        {!!cat_01 && (
          <Controls.SearchSelect
            {...token_setup.Category02Id(
              values.Category02Id,
              onChange,
              category_02?.filter((v) => v.Category01Id === parseInt(cat_01))
            )}
          />
        )}

        <Controls.Input {...token_setup.name(values.name, onChange)} />
      </Form>
    </Hoc.Dialog>
  );
};

const token_setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Name"),
  cat_01: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV(T.value),
      "cat_01",
      "Service Category",
      sel01(opt)
    ),
  Category02Id: (v, onChange, opt) =>
    Hoc.setupSelect(
      v,
      onChange,
      "Category02Id",
      "Service Category",
      sel01(opt)
    ),
};

const sel01 = (v) =>
  v?.map(({ id, name }) => ({ id, title: `${name} (${id})` }));

export default Root;
