import NoticeView from "./NoticeView";
import NoticeForm from "./NoticeForm";
import NoticeViewAll from "./NoticeViewAll";

const Expo = {
  NoticeView,
  NoticeForm,
  NoticeViewAll,
};

export default Expo;
