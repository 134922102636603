import SecureRoute from "../../../comps/layout/SecureRoute";

import add from "./add";
import edit from "./edit";
import view from "./view";
import view_all from "./view_all";

const Expo = {
  add: <SecureRoute grant="S2" exact path="/add-coupon" component={add} />,
  edit: (
    <SecureRoute grant="S2" exact path="/edit-coupon/:id" component={edit} />
  ),
  view: (
    <SecureRoute grant="S2" exact path="/view-coupon/:id" component={view} />
  ),
  view_all: (
    <SecureRoute
      grant="S2"
      exact
      path="/view-all-coupons"
      component={view_all}
    />
  ),
};

export default Expo;
