import React from "react";
import Custom from "../../../../comps/Custom";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Message } from "semantic-ui-react";
import Hoc from "../../../../comps/Hoc";

const TrollyView03Sub = ({ StoreId, items }) => {
  const CSS = useCSS();
  const { finalRecords, TblContainer } = Custom.useTable(items, cells);
  const PF = (v) => parseFloat(v).toFixed(2);
  const PI = (v) => parseInt(v);

  return (
    <Message key={StoreId} className={CSS.trolly}>
      <TblContainer>
        {finalRecords().map((v, idx) => (
          <TableRow key={idx}>
            <TableCell>{v?.Product_Box?.name} </TableCell>
            <TableCell>{PF(v?.S_price)}</TableCell>
            <TableCell>{PF(v?.ST_vat_price)}</TableCell>
            <TableCell>{PF(v?.ST_shipping_price) || "Not Set"}</TableCell>
            <TableCell>{PI(v?.sold_units)}</TableCell>
            <TableCell>{PF(v?.ST_price) || "Not Set"}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Typography color="secondary">SubTotal</Typography>{" "}
          </TableCell>
          <TableCell>
            {PF(items.reduce((a, b) => a + PF(b?.S_price * b.sold_units), 0))}
          </TableCell>
          <TableCell>
            {PF(
              items.reduce((a, b) => a + PF(b?.ST_vat_price * b.sold_units), 0)
            )}
          </TableCell>
          <TableCell>
            {PF(
              items.reduce(
                (a, b) => a + PF(b?.ST_shipping_price * b.sold_units),
                0
              )
            )}
          </TableCell>
          <TableCell>
            {PI(items.reduce((a, b) => a + PI(b?.sold_units), 0))}
          </TableCell>
          <TableCell>
            <Typography color="primary">
              {new Set(Hoc.isArr(items).map((v) => v?.Currency?.id)).size ===
                1 &&
                Hoc.toCurrency(
                  items[0]?.Currency?.currency,
                  Hoc.isArr(items).reduce(
                    (a, v) => a + parseFloat(v?.ST_price),
                    0
                  )
                )}
            </Typography>
          </TableCell>
        </TableRow>
      </TblContainer>
    </Message>
  );
};

const cells = [
  { id: "name", label: "Name" },
  { id: "price", label: "Price" },
  { id: "vat", label: "VAT / GST" },
  { id: "shipping_tax", label: "Shipping Price" },
  { id: "quantity", label: "Quantity" },
  { id: "total_price", label: "Total Price" },
];

const useCSS = makeStyles((theme) => ({
  trolly: {
    display: "flex",
    alignItems: "center",
  },
}));

export default TrollyView03Sub;
