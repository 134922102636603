import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import Controls from "../../../comps/Controls";
import action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

const SelectAddress = () => {
  const Act_address = action.base.address;
  const act_order = action.store.order.view_edit;
  const H = useHistory();
  const qry = queryString.parse(useLocation().search);
  const { Form, useForm } = Custom.useForm;
  const initData = { DeliveryAddressId: "", BillingAddressId: "" };
  const { values, onChange, resetForm } = useForm(initData);

  const dispatch = useDispatch();
  const root = useSelector(({ people: S }) => S.root.data);
  const addList = useSelector(({ base: v }) => v.address.view_all.data);

  useEffect(() => {
    dispatch(Act_address.view_all(`CustomerId=${root?.id}`));
  }, [dispatch, Act_address, root]);

  const onSubmit = () => {
    dispatch(act_order(qry?.OrderId, values));
    H.goBack();
  };

  const setup_status = (v) => !v?.DeliveryAddressId || !v?.BillingAddressId;
  return (
    <Hoc.FormContainer title="Select Address for Orders.">
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.SearchSelect
          {...setup.delivery(values?.DeliveryAddressId, onChange, addList)}
        />
        <Controls.SearchSelect
          {...setup.billing(values?.BillingAddressId, onChange, addList)}
        />
      </Form>
    </Hoc.FormContainer>
  );
};
const SelAd = (v) => v?.map((v) => ({ id: v.id, title: Hoc.showAddress(v) }));
const setup = {
  billing: (V, OC, opt) =>
    Hoc.setupSelect(
      V,
      OC,
      "BillingAddressId",
      "Select Billing Address",
      SelAd(opt)
    ),
  delivery: (V, OC, opt) =>
    Hoc.setupSelect(
      V,
      OC,
      "DeliveryAddressId",
      "Select Delivery Address",
      SelAd(opt)
    ),
};

export default SelectAddress;
