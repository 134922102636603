import React, { useEffect, useState } from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Category01Form from "./Category01Form";
import Category02Form from "./Category02Form";
import Category03Form from "./Category03Form";
import Category02List from "./Category02List";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import Hoc from "../../../Hoc";
import Custom from "../..";
const TOT = (name, to) => ({ name, to });
const TOC = (name, onClick) => ({ name, onClick });

const Root = (props) => {
  const [openCat01, setOpenCat01] = useState(false);
  const [openCat02, setOpenCat02] = useState({ open: false, id: "" });
  const [openCat03, setOpenCat03] = useState({ open: false, id: "" });
  const [openCat02List, setOpenCat02List] = useState(false);
  const { Act, view_all, title, queryString } = props;
  const dispatch = useDispatch();
  const { data, error, loading } = view_all;
  const Act_product = Action.product;

  const onOpenCat01 = () => {
    setOpenCat01(true);
  };
  const onOpenCat02 = (id) => {
    setOpenCat02({ open: true, id });
    dispatch(Act_product.category_01.view_all());
  };

  const onOpenCat03 = (id) => {
    setOpenCat03({ open: true, id });
    dispatch(Act_product.category_01.view_all());
    dispatch(Act_product.category_02.view_all());
  };

  const onOpenCat02List = (id) => {
    setOpenCat02List(true);
    dispatch(Act_product.category_01.view(id));
    dispatch(Act_product.category_02.view_all());
  };

  const root = useSelector(({ people: p }) => p.root.data);

  useEffect(() => {
    dispatch(Act.view_all(queryString || null));
    // eslint-disable-next-line
  }, [dispatch, Act, queryString]);

  const onRefresh = () => dispatch(Act.view_all(queryString || null));

  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const tbl = Custom.useTable(data, headCells, filterFn);

  const { TblContainer, finalRecords } = tbl;

  const onSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const menuActions = (id) => {
    let items = [TOC("View", () => onOpenCat02List(id))];
    if (C_roles.includes(root.role) && `${root?.privilege}`.includes("Add")) {
      items = [
        ...items,
        TOC("Add Header", () => onOpenCat02(id)),
        TOC("Add Item", () => onOpenCat03(id)),
      ];
    }
    return items;
  };

  const userLength = Array.isArray(finalRecords()) && finalRecords()?.length;
  const ARR = (v) => (Array.isArray(v) ? v : []);

  return (
    <React.Fragment>
      <Category01Form open={openCat01} setOpen={setOpenCat01} />
      <Category02Form v={openCat02} setV={setOpenCat02} />
      <Category03Form v={openCat03} setV={setOpenCat03} />
      <Category02List open={openCat02List} setOpen={setOpenCat02List} />

      <Hoc.Page title={title}>
        <Hoc.PageBox
          title={title}
          actions={[
            TOT("Product", "/product-view-all-trolly"),
            C_roles.includes(root.role)
              ? TOT("Stores", "/stores")
              : TOT("My Store", "/my-store"),
          ]}
        >
          {Hoc.PageTabs(
            [
              ...nav_items(
                onRefresh,
                root?.role,
                root?.privilege,
                onOpenCat01,
                onOpenCat03
              ),
            ],
            userLength ? `Count : ${userLength}`.toUpperCase() : "Empty Records"
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ marginBottom: 10 }}
          >
            <Box style={{ maxWidth: 200 }}>
              <Controls.Input
                label="Search"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
              />
            </Box>
          </Box>
          {!!userLength ? (
            <Hoc.UseData error={error} loading={loading}>
              <TblContainer paginate>
                {finalRecords()?.map((v, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{v.id}</TableCell>
                    <TableCell>{v.name}</TableCell>
                    <TableCell>{ARR(v?.Category_02s).length}</TableCell>
                    <TableCell>
                      {ARR(v?.Category_02s).reduce(
                        (a1, v1) => a1 + ARR(v1?.Category_03s).length,
                        0
                      )}
                    </TableCell>
                    <TableCell>
                      <Hoc.Product.MenuButton
                        text="Action"
                        actions={menuActions(v.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TblContainer>
            </Hoc.UseData>
          ) : (
            <Hoc.Message text="Empty resourse on Shop." color="yellow" />
          )}
        </Hoc.PageBox>
      </Hoc.Page>
    </React.Fragment>
  );
};

const nav_items = (onClick, role, privilege, onCT) => {
  let res = [];
  if (C_roles.includes(role) && privilege && privilege?.includes("Add")) {
    res = [TOC("Create New", onCT)];
  }

  res = [
    ...res,
    TOT("Support Tickets", `/root-general-tickets`),
    TOC("Refresh", onClick),
  ];

  return res;
};

const C_roles = ["Company_Manager", "Company_Admin"];

const headCells = [
  { id: "id", label: "ID" },
  { id: "name", label: "Category Name" },
  { id: "category_02", label: "Sub Category Headers Length" },
  { id: "category_03", label: "Sub Category Items Length" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default Root;
