import { Grid } from "@material-ui/core";
import React from "react";
import { Message } from "semantic-ui-react";
import Hoc from "../../../Hoc";

const ShowProfile = ({ profile: root }) => {
  return (
    <Grid container justifyContent="center" spacing={1}>
      {!!(!root?.Currency?.currency && root?.role === "Customer") && (
        <Grid item xs={12}>
          <Message
            color="yellow"
            content="Click on Edit Profile to select the prefered currency."
          />
        </Grid>
      )}
      {GridItem("First Name", root?.first_name)}
      {GridItem("Last Name", root?.last_name)}
      {GridItem("Email", root?.email)}
      {GridItem("Currency", root?.Currency?.currency)}
      {GridItem(
        "Email Status",
        !root?.email_status ? "Email not verified" : false
      )}
      {GridItem("Role", root?.role)}
      {GridItem("Contact", root?.contact)}
      {root?.role !== "Company_Manager" &&
        GridItem("Status", !root?.status ? "Inactive" : false)}
      {GridItem("Archive", root?.archive)}
      {GridItem(
        "Privilege",
        !!root?.privilege ? `${root?.privilege}`.split("_").join(", ") : null
      )}
      {GridItem(
        "Delete Request",
        !!root?.delete_request ? "In Progress" : false
      )}
    </Grid>
  );
};

const GridItem = (n, v) =>
  !!v && (
    <Grid item xs={12} sm={6} md={4}>
      <Hoc.ShowItem name={n} value={v} />
    </Grid>
  );
export default ShowProfile;
