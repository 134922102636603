import React, { useEffect } from "react";
import { Box, TableRow, TableCell } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import Mail from "@material-ui/icons/Mail";
import Search from "@material-ui/icons/Search";
import Hoc from "../../../comps/Hoc";
import Controls from "../../../comps/Controls";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";

const Email = () => {
  const dispatch = useDispatch();
  const Act = Action.base.template;
  const { error, loading, data } = useSelector(
    ({ base: v }) => v.template.view_all
  );

  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const tbl = Custom.useTable(data, cells, filterFn);
  const { TblContainer, finalRecords } = tbl;

  useEffect(() => {
    dispatch(Act.view_all());
  }, [dispatch, Act]);

  const onTemplateDelete = (id) => {
    dispatch(Act.view_delete(id));
  };

  const onSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target?.value === "") return items;
        else
          return items.filter((x) =>
            x?.name?.toLowerCase().includes(target.value)
          );
      },
    });
  };

  return (
    <Hoc.Page icon={<Mail color="primary" />} title="Email Templates">
      <Hoc.PageBox
        title="Email Information"
        actions={[
          { name: "Create Template", to: "/template-add" },
          { name: "Refresh", onClick: () => dispatch(Act.view_all()) },
          { name: "Back", goBack: true },
        ]}
      >
        <Hoc.UseData error={error} loading={loading}>
          <Box style={{ maxWidth: 200, marginBottom: 5 }}>
            <Controls.Input
              label="Search"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={onSearch}
            />
          </Box>
          {!!data.length ? (
            <TblContainer paginate>
              {finalRecords().map((v) => (
                <TableRow key={v.id || "NA"}>
                  <TableCell>{v.id || "NA"}</TableCell>
                  <TableCell>{v.name || "NA"}</TableCell>
                  <TableCell>{v.from || "NA"}</TableCell>
                  <TableCell>{v.subject || "NA"}</TableCell>
                  <TableCell>
                    {`${v.html}`.substring(0, 10) + "..." || "NA"}
                  </TableCell>
                  <TableCell>{v.actions || "NA"}</TableCell>
                  <TableCell>
                    <Hoc.Product.MenuButton
                      actions={[
                        { name: "Edit", to: `/template-edit/${v.id}` },
                        {
                          name: "Delete",
                          onClick: () => onTemplateDelete(v.id),
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TblContainer>
          ) : (
            <Hoc.Message text="Empty templates" color="yellow" />
          )}
        </Hoc.UseData>
      </Hoc.PageBox>
    </Hoc.Page>
  );
};

const cells = [
  { id: "id", label: "id" },
  { id: "name", label: "name" },
  { id: "from", label: "from" },
  { id: "subject", label: "subject" },
  { id: "html", label: "html" },
  { id: "functions", label: "functions" },
  { id: "actions", label: "actions", disableSorting: true },
];

export default Email;
