import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../Hoc";
import Controls from "../../Controls";
import Action from "../../../actions";
import Custom from "../../Custom";
import moment from "moment";
import { Box, Chip, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Message } from "semantic-ui-react";
import UseData from "../base/UseData";
import { useHistory } from "react-router";

const OpenToken = (props) => {
  const H = useHistory();
  const { token, root, is_product } = props;
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const Act_Opp = Action.base02.oop_msg.view_add;
  const Act_report = Action.product.report;
  const Act_note = Action.product.note;
  const act_notif = Action.base02.notif.view_add;
  const Act_S1 = Action.people.S1;
  const [status, setStatus] = useState("");
  const [note, setNote] = useState();

  const [now, setNow] = useState("");
  const [message, setMessage] = useState("");
  const note_seletor = useSelector(({ product: p }) => p.note.view_all);
  const notes = Hoc.isArr(note_seletor?.data).filter(
    (v) => v?.ReportId === token?.id
  );

  const { error: note_error, loading: note_loading } = note_seletor;

  useEffect(() => {
    dispatch(Act_S1.view_all());
    if (!!token) {
      setStatus(token?.status);
      setNote(token?.note);
    }
  }, [dispatch, Act_S1, token]);

  const S1 = useSelector(({ people: v }) => v.store_manager.view_all.data);
  let S2 = useSelector(({ people: v }) => v.store_employee.view_all.data);

  const StoreId = token?.Trolly?.StoreId;

  S2 = StoreId && is_product ? S2.filter((v) => v?.StoreId === StoreId) : S2;

  const { data, loading, error } = useSelector(
    ({ product: p }) => p.report.view
  );

  const { Form } = Custom.useForm;

  const { values, onChange, resetForm } = Custom.useForm.useForm(
    setInitData(data)
  );

  const onOpenClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const res = dispatch(Act_report.view_edit(token?.id, { status, note }));
    if (now === "emp") {
      const CK = (v) => (values?.owner_type === v ? values?.owner_id : null);
      const sendData = {
        ...values,
        StoreManagerId: CK("Store_Manager"),
        StoreEmployeeId: CK("Store_Employee"),
      };
      dispatch(Act_report.view_edit(token?.id, sendData));
      setOpen(false);
      dispatch(Act_Opp("Employee set to the Report."));
      resetForm();
    } else if (now === "msg") {
      const CHR = (v) => root.role === v;
      const noteData = {
        type: "Message",
        item_type: "Report_Item",
        ReportId: token?.id,
        item_id: token?.id,
        text: message,
        to_type: "Customer",
        to_id: token?.CustomerId,
        from_type: root?.role,
        from_id: root?.id,
        CompanyManagerId: CHR("Company_Manager") ? root?.id : null,
        CompanyAdminId: CHR("Company_Admin") ? root?.id : null,
        StoreManagerId: CHR("Store_Manager") ? root?.id : null,
        StoreEmployeeId: CHR("Store_Employee") ? root?.id : null,
        CustomerId: CHR("Customer") ? root?.id : token?.CustomerId,
      };
      dispatch(Act_note?.view_add(noteData));
      setMessage("");
      dispatch(Act_Opp("Message Sent."));
    } else if (now === "status") {
      let text = null;
      if (status === "Closed") {
        text = `Support Ticket Closed : "${token?.subject}" if closed by ${root?.first_name} ${root?.last_name} (${root?.role}). Please refer to the Support ticket note for further information.`;
      } else if (status === "In Progress") {
        text = `Support Ticket is In Progress : ${root?.first_name} ${root?.last_name} (${root?.role}) is working on you support ticket "${token?.subject}". Your issue will be solved as soon as possible.`;
      }
      const notif = {
        text,
        role: "Customer",
        CustomerId: token?.CustomerId,
      };
      !!text && dispatch(act_notif(notif));
      !!res && dispatch(Act_Opp("Report Status changed."));
    }
  };

  const owner =
    root?.role === token?.owner_type && root?.id === token?.owner_id
      ? true
      : false;

  const CSS = useCSS();

  const S1_up = ["Company_Manager", "Company_Admin", "Store_Manager"];

  const ShowTitle = (
    <Box {...Hoc.flex("space-between")}>
      <Typography
        color="primary"
        style={{ cursor: "pointer" }}
        onClick={() =>
          H.push(`/product-variant/${token?.Trolly?.ProductBoxId}`)
        }
      >
        {token?.subject} ({toDate(token?.createdAt, "LLL")})
      </Typography>
      {!["General", "Complain"].includes(token?.type) && (
        <Button
          content="Go To Order"
          size="tiny"
          color="orange"
          onClick={() => H.push(`/view-order/${token?.Trolly?.OrderId}`)}
        />
      )}
    </Box>
  );

  const GTT = (v) => new Date(v).getTime();
  const WTR = (v) => v === root?.role;

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Hoc.Dialog
        open={open}
        onClose={onOpenClose}
        title={ShowTitle}
        fullWidth
        maxWidth="xl"
        actions={
          <Controls.Button
            text="Close"
            color="secondary"
            size="small"
            onClick={onOpenClose}
          />
        }
      >
        <Grid
          container
          justifyContent="center"
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          {ShowItem("Subject", token?.subject)}
          {ShowItem("Advice", token?.advice)}

          {ShowItem("Ticket Type", token?.type)}
          {ShowItem("Priority", token?.urgency)}
          {ShowItem("status", token?.status)}
          {ShowItem("Trolly ID", token?.TrollyId)}

          {ShowItem(
            "Owner Role",
            !!token?.owner_type
              ? `${token?.owner_type}`.split("_").join(" ")
              : false
          )}
          {ShowItem("Owner ID", token?.owner_id)}

          {ShowItem("Customer ID", token?.CustomerId)}
          {ShowItem("Store ID", token?.Trolly?.StoreId)}

          {ShowItem("Order ID", token?.OrderId)}
          {ShowItem("Product ID", token?.Trolly?.ProductBoxId)}

          {ShowItem("Created At", toDate(token?.createdAt))}
          {ShowItem("Updated At", toDate(token?.updatedAt))}
          {!!token?.description && (
            <Grid item xs={12} sm={10}>
              <Hoc.ShowItem name="Description" value={token?.description} />
            </Grid>
          )}
          {!!token?.Trolly && (
            <React.Fragment>
              <GridHeader text="Trolly Item Details" />
              {ShowItem02("Store ID", token?.Trolly?.StoreId)}
              {ShowItem02("Order ID", token?.Trolly?.OrderId)}
              {ShowItem02("Item Type", token?.Trolly?.item_type)}
              {ShowItem02("status", token?.Trolly?.status)}
              {ShowItem02(
                "Coordinates",
                token?.Trolly?.lat
                  ? `${token?.Trolly?.lat}, ${token?.Trolly?.lng}`
                  : false
              )}
              {ShowItem02("Price", token?.Trolly?.price)}
              {ShowItem02("Quantity", token?.Trolly?.quantity)}
              {ShowItem02("Shipping Price", token?.Trolly?.shipping_tax)}
              {ShowItem02("Total Price", token?.Trolly?.total_price)}
            </React.Fragment>
          )}
        </Grid>

        <Form
          noUndo
          onSubmit={onSubmit}
          resetForm={resetForm}
          maxWidth="xs"
          submitButton={{
            disabled:
              now === "emp"
                ? setup_status(values)
                : now === "msg"
                ? !message
                : now === "status"
                ? !status
                : true,
          }}
        >
          {token?.status !== "Closed" &&
            !!(!!owner || `${root?.role}`.includes("Company")) && (
              <ButtonItem
                text="Status"
                active={now === "status"}
                onClick={() => setNow((v) => (v === "status" ? "" : "status"))}
              />
            )}
          {!token?.owner_id && S1_up.includes(root?.role) && (
            <ButtonItem
              text="Set Employee"
              active={now === "emp"}
              onClick={() => setNow((v) => (v === "emp" ? "" : "emp"))}
            />
          )}
          <ButtonItem
            text="Messages"
            active={now === "msg"}
            onClick={() => setNow((v) => (v === "msg" ? "" : "msg"))}
          />
          {now === "msg" && (
            <ButtonItem
              text="Refresh Messages"
              onClick={() =>
                dispatch(Act_note.view_all(`ReportId=${token?.id}`))
              }
            />
          )}
          {now === "msg" && Array.isArray(notes) && !!notes.length && (
            <UseData error={note_error} loading={note_loading}>
              <Container
                maxWidth="sm"
                style={{ margin: "0 auto" }}
                component={Message}
                color="teal"
                className={CSS.message_root}
              >
                {notes
                  ?.sort((v1, v2) =>
                    GTT(v1.createdAt) > GTT(v2.createdAt)
                      ? -1
                      : GTT(v1.createdAt) < GTT(v2.createdAt)
                      ? 1
                      : 0
                  )
                  ?.map((v, idx) => (
                    <Box key={idx}>
                      <Box style={{ width: "100%" }}>
                        <Typography align="center" style={{ fontSize: 10 }}>
                          {moment(v.createdAt).format("lll")}
                        </Typography>
                      </Box>
                      <Box
                        style={{ width: "100%" }}
                        {...Hoc.flex(
                          WTR(v?.from_type)
                            ? "flex-end"
                            : WTR(v?.to_type)
                            ? "flex-start"
                            : "center"
                        )}
                      >
                        <Chip
                          style={{ margin: 2 }}
                          label={v?.text}
                          color="primary"
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  ))}
              </Container>
            </UseData>
          )}

          {token?.status !== "Closed" && now === "status" && (
            <Grid container justifyContent="center" spacing={1}>
              <GridItem>
                <Controls.SearchSelect
                  {...token_setup.status(status, setStatus)}
                />
                <Controls.Input {...token_setup.note(note, setNote)} />
              </GridItem>
            </Grid>
          )}

          <Grid container justifyContent="center" spacing={1}>
            {!token?.owner_id && S1_up.includes(root?.role) && now === "emp" && (
              <React.Fragment>
                <GridItem>
                  <Controls.SearchSelect
                    {...token_setup.owner_type(
                      values?.owner_type,
                      onChange,
                      S1,
                      S2
                    )}
                  />
                </GridItem>
                <GridItem>
                  <Controls.SearchSelect
                    {...token_setup.owner_id(values, onChange, S1, S2, root)}
                  />
                </GridItem>
              </React.Fragment>
            )}

            {token?.status !== "Closed" && now === "msg" && (
              <Grid item xs={12} sm={6}>
                <Controls.Input {...token_setup.message(message, setMessage)} />
              </Grid>
            )}
          </Grid>
        </Form>
      </Hoc.Dialog>
    </Hoc.UseData>
  );
};

const GridHeader = ({ text }) => (
  <Grid
    item
    xs={12}
    component={Typography}
    variant="h5"
    color="primary"
    align="center"
    style={{ marginTop: 25 }}
  >
    {text}
  </Grid>
);

const useCSS = makeStyles((theme) => ({
  message_root: {
    display: "flex",
    flexDirection: "column-reverse",
    marginTop: theme.spacing(2),
  },
  message_left: {
    alignSelf: "flex-start",
    marginBottom: theme.spacing(1),
  },
  message_right: {
    alignSelf: "flex-end",
    marginBottom: theme.spacing(1),
  },
  message_middle: {
    alignSelf: "center",
    marginBottom: theme.spacing(1),
  },
}));

const ButtonItem = ({ text, active, ...rest }) => (
  <Controls.Button
    text={text}
    size="small"
    {...(!!active && { variant: "contained" })}
    {...rest}
  />
);

const GridItem = ({ children }) => (
  <Grid item xs={12} sm={5} md={4} lg={3}>
    {children}
  </Grid>
);

const ShowItem = (n, v) =>
  !!v && (
    <Grid item xs={12} sm={5} md={4} lg={3}>
      <Hoc.ShowItem name={n} value={v} />
    </Grid>
  );

const ShowItem02 = (n, v) =>
  !!v && (
    <Grid item xs={12} sm={5} md={4} lg={3}>
      <Hoc.ShowItem name={n} value={v} color="teal" />
    </Grid>
  );

const toDate = (v, f) => (v ? moment(v).format(f || "DD-MMM-YYYY") : "");

const setup_status = (v) => !v?.owner_id || !v?.owner_type;

const setInitData = (v) => ({
  owner_type: v?.item_type ? v?.item_type : "",
  owner_id: v?.item_id ? v?.item_id : "",
});

const ARR = (v) => (Array.isArray(v) ? v : []);

const token_setup = {
  owner_type: (value, onChange, S1, S2) => ({
    name: "owner_type",
    label: "Select Role",
    value,
    onChange,
    options: getSType(S1, S2),
  }),
  owner_id: (values, onChange, S1, S2, root) => ({
    name: "owner_id",
    label: "Select Person",
    value: values?.owner_id || "",
    onChange,
    options:
      values?.owner_type === "Store_Manager"
        ? getUser(S1, root)
        : values?.owner_type === "Store_Employee"
        ? getUser(S2, root)
        : [],
  }),
  status: (value, setV) => ({
    name: "status",
    label: "Set Status",
    value: value ? value : "",
    onChange: ({ target: v }) => setV(v.value),
    options: [
      { id: "Open", title: "Open" },
      { id: "In Progress", title: "In Progress" },
      { id: "Closed", title: "Close the Ticket" },
    ],
  }),
  message: (value, setV) => ({
    type: "text",
    name: "message",
    label: "Message",
    multiline: true,
    minRows: 2,
    maxRows: 4,
    value: value ? value : "",
    required: true,
    onChange: ({ target: v }) => setV(v.value),
  }),
  note: (value, setV) => ({
    type: "text",
    name: "note",
    label: "Leave some Note",
    multiline: true,
    minRows: 2,
    maxRows: 4,
    value: value ? value : "",
    required: true,
    onChange: ({ target: v }) => setV(v.value),
  }),
};

const getSType = (S1, S2) => {
  let items = [];

  if (!!ARR(S1).length)
    items = [{ id: "Store_Manager", title: "Store Manager" }];
  if (!!ARR(S2).length)
    items = [...items, { id: "Store_Employee", title: "Store Employee" }];
  return items;
};

const getUser = (v1, root) => {
  const getT = (v, t) =>
    `${v?.first_name} ${v?.last_name} (${v?.id})${t ? " - Myself" : ""}`;

  const person = ARR(v1).find((v) => v.role === root.role && v.id === root.id);

  return !!person
    ? [{ id: root?.id, title: getT(root, true) }]
    : ARR(v1).map((v) => ({
        id: v?.id,
        title: getT(v),
      }));
};

export default OpenToken;
