import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_add = {
  name: "view_add",
  state: defState,
  request: types.ADD_TRANSACTION_REQUEST,
  success: types.ADD_TRANSACTION_SUCCESS,
  fail: types.ADD_TRANSACTION_FAIL,
};

export const pay_stripe = {
  name: "pay_stripe",
  state: defState,
  request: types.PAY_STRIPE_REQUEST,
  success: types.PAY_STRIPE_SUCCESS,
  fail: types.PAY_STRIPE_FAIL,
};

export const view_edit = {
  name: "view_edit",
  state: defState,
  request: types.EDIT_TRANSACTION_REQUEST,
  success: types.EDIT_TRANSACTION_SUCCESS,
  fail: types.EDIT_TRANSACTION_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.GET_TRANSACTION_REQUEST,
  success: types.GET_TRANSACTION_SUCCESS,
  fail: types.GET_TRANSACTION_FAIL,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.GET_ALL_TRANSACTION_REQUEST,
  success: types.GET_ALL_TRANSACTION_SUCCESS,
  fail: types.GET_ALL_TRANSACTION_FAIL,
};

export const view_delete = {
  name: "view_delete",
  state: defListState,
  request: types.TRANSACTION_DELETE_REQUEST,
  success: types.TRANSACTION_DELETE_SUCCESS,
  fail: types.TRANSACTION_DELETE_FAIL,
};
