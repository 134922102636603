import React, { useEffect } from "react";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "..";
import Action from "../../../actions";
import { Button } from "semantic-ui-react";
import { Box } from "@material-ui/core";
import { useState } from "react";

const StripeForm = (props) => {
  const dispatch = useDispatch();
  const Act_transaction = Action.base.transaction;
  const Act_edit_order = Action.store.order.view_edit;

  const Act_OOP = Action.base02.oop_msg.view_add;
  const { order_id, refresh = (f) => f } = props;
  const { amount = 0, curr } = props;
  const root = useSelector(({ people: P }) => P.root.data);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const initData = { type: "card", card: elements.getElement(CardElement) };
    const { error, paymentMethod } = await stripe.createPaymentMethod(initData);

    if (!error) {
      try {
        const finalData = {
          id: paymentMethod?.id,
          amount,
          order_id,
          currency: curr?.currency,
          customer: root?.email,
        };
        const data = await dispatch(Act_transaction.pay_stripe(finalData));

        if (!!data?.status) {
          const res = data?.result;
          await dispatch(
            Act_transaction.view_add({
              amount: parseFloat(res?.amount / 100),
              CurrencyId: curr?.id,
              status: res?.status === "succeeded" ? "Paid" : "Pending",
              method: "Stripe",
              PD_transaction_id: res?.id,
              PD_reference: res?.payment_method,
              OrderId: order_id,
            })
          );
          await dispatch(Act_edit_order(order_id, { phase: "04" }));
          refresh();
          dispatch(Act_OOP(`Stripe Payment Successful.`));
        }
      } catch (err) {
        dispatch(Act_OOP(`Error : ${err}`, true));
      }
    } else {
      dispatch(Act_OOP(`Stripe Error : ${error.message}`, true));
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box style={{ border: "1px solid #c4f0ff", padding: 10 }}>
        <CardElement options={options} />
      </Box>
      <Box style={{ margin: "15px 0" }} {...Hoc.flex("flex-end")}>
        <Button
          inverted
          color="purple"
          content={
            !!loading
              ? "Loading..."
              : `Pay ${Hoc.toCurrency(curr?.currency, amount)}`
          }
          onClick={onSubmit}
          disabled={!!loading}
        />
      </Box>
    </Box>
  );
};

const StripePayment = (props) => {
  const dispatch = useDispatch();

  const act_pay = Action.base.payment.method;
  const PData = useSelector(({ base: b }) => b.payment.method);

  useEffect(() => {
    dispatch(act_pay("Stripe"));
  }, [dispatch, act_pay]);

  const { data, loading, error } = PData;
  const stripePromise = loadStripe(data?.publish_key);

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Elements stripe={stripePromise}>
        <StripeForm {...props} />
      </Elements>
    </Hoc.UseData>
  );
};

const options = {
  hidePostalCode: true,
};

export default StripePayment;
