import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import TrollyView01 from "./TrollyView/TrollyView01";
import TrollyNav from "./Hoc/TrollyNav";
import PreviousNext from "./Hoc/PreviousNext";
import { Message } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const ViewAll = () => {
  const Act_trolly = Action.store.trolly;
  const H = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Act_trolly.view_all(`status=Trolly`));
  }, [dispatch, Act_trolly]);

  const refresh = () => {
    dispatch(Act_trolly.view_all(`status=Trolly`));
  };

  const nav_items = [
    { name: "Back", goBack: true },
    { name: "Refresh", onClick: refresh },
  ];

  let trollies = useSelector(({ store: v }) => v.trolly.view_all.data);

  const CKS = (v) => !!v?.subscription && !v?.S_price;
  const CKFB = Hoc.isArr(trollies).every((v) => CKS(v?.Product_Box));

  const onForward = async () => H.push("/trolly-02");

  return (
    <Hoc.Page title="Trollies">
      <Hoc.Dashboard.Container title="Trollies" actions={nav_items}>
        <Box style={{ width: "100%" }}>
          {!!trollies?.length ? (
            <React.Fragment>
              <TrollyNav step={0} />
              <TrollyView01 editable data={trollies} />
              <PreviousNext
                to={!CKFB ? "02" : "04"}
                onSubmit={onForward}
                refresh={refresh}
              />
            </React.Fragment>
          ) : (
            <Message content="Empty Trollies" color="yellow" />
          )}
        </Box>
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default ViewAll;
