import Helper from "../../helper";
import { Type } from "../../../redux";
import axios from "axios";

const types = Type.product_type.description;

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DESCRIPTION_VIEW_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/product-description/${id}`);
    dispatch({ type: types.DESCRIPTION_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.DESCRIPTION_VIEW_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.DESCRIPTION_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      `${Helper.URL}/product-description?${queryString || ""}`
    );

    dispatch({
      type: types.DESCRIPTION_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.DESCRIPTION_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.DESCRIPTION_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(
      `${Helper.URL}/product-description`,
      initData
    );

    dispatch(
      { type: types.DESCRIPTION_ADD_SUCCESS, payload: data?.result },
      Helper.multiForm
    );
    dispatch({
      type: types.DESCRIPTION_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.product?.description?.view_all?.data],
    });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.DESCRIPTION_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.DESCRIPTION_EDIT_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(
      `${Helper.URL}/product-description/${id}`,
      initData
    );

    dispatch({ type: types.DESCRIPTION_EDIT_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.DESCRIPTION_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.DESCRIPTION_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(
      `${Helper.URL}/product-description/${id}`
    );

    dispatch({ type: types.DESCRIPTION_DELETE_SUCCESS, payload: data?.result });
    dispatch({
      type: types.DESCRIPTION_VIEW_ALL_SUCCESS,
      payload: state()?.product?.description?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.DESCRIPTION_DELETE_FAIL);
  }
};
