import React, { useEffect, useState } from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../Controls";
import Hoc from "../../Hoc";
import Custom from "../../Custom";
import { Confirm } from "semantic-ui-react";

const Root = (props) => {
  const [open, setOpen] = useState(false);
  const [privilage, setPrivilage] = useState("");
  const [personInfo, setPersonInfo] = useState(null);

  const { Act, view_all, to, title, queryString } = props;
  const dispatch = useDispatch();
  const root = useSelector(({ people: p }) => p.root.data);
  const { data, error, loading } = view_all;

  useEffect(() => {
    dispatch(Act.view_all(queryString || null));
  }, [dispatch, Act, queryString]);

  const refresh = () => dispatch(Act.view_all(queryString || null));

  const tbl = Custom.useTable(data, headCells(to === "customer"));
  const { TblContainer, finalRecords } = tbl;

  const userLength = Array.isArray(finalRecords()) ? finalRecords().length : 0;

  const onGrant = (id) => {
    dispatch(Act.view_edit(id, { status: 1 }));
  };

  const onDelete = (id) => {
    dispatch(Act.view_delete(id));
  };

  const onRevoke = (id) => {
    dispatch(Act.view_edit(id, { status: 0 }));
  };

  const onArchive = (id) => {
    dispatch(Act.view_edit(id, { archive: 1 }));
  };

  const onUnarchive = (id) => {
    dispatch(Act.view_edit(id, { archive: 0 }));
  };

  const onPrivilages = (id) => {
    setOpen(true);
    const selectedPerson = finalRecords()?.find((v) => v.id === id);
    setPersonInfo(selectedPerson);
    setPrivilage(selectedPerson?.privilege);
  };

  const CKRead = `${root?.privilege}`.includes("Read");
  const CKAdd = `${root?.privilege}`.includes("Add");

  const initConfirmMsg = {
    open: false,
    id: null,
    name: "",
    content: "",
    header: "Confirm",
  };
  const [ConfirmMsg, setConfirmMsg] = useState(initConfirmMsg);

  const onConfirm = () => {
    const CKCM = (v) => ConfirmMsg.name === v;

    if (CKCM("Archive")) {
      onArchive(ConfirmMsg.id);
    } else if (CKCM("Unarchive")) {
      onUnarchive(ConfirmMsg.id);
    } else if (CKCM("Grant")) {
      onGrant(ConfirmMsg.id);
    } else if (CKCM("Revoke")) {
      onRevoke(ConfirmMsg.id);
    } else if (CKCM("Delete")) {
      onDelete(ConfirmMsg.id);
    }

    setConfirmMsg(initConfirmMsg);
    refresh();
  };

  const menuActions = (id, status, archive) => {
    let result = [];
    const CKP = `${root?.privilege}`.includes("Edit");
    const CKC = to !== "customer";
    const TOC = (name, onClick) => ({ name, onClick });

    const YesConfirm = (name, content) =>
      setConfirmMsg({
        open: true,
        id,
        name,
        content,
        header: "Are you Sure ?",
      });

    if (!archive && CKP) {
      result = [
        ...result,
        TOC("Archive", () =>
          YesConfirm("Archive", "Yes, I want to Archive this account.")
        ),
      ];
    }

    if (!!archive && CKP) {
      result = [
        ...result,
        TOC("Unarchive", () =>
          YesConfirm("Unarchive", "Yes, I want to Unarchive this account.")
        ),
        TOC("Delete", () =>
          YesConfirm("Delete", "Yes, I want to Delete from this account.")
        ),
      ];
    }

    if (!status && CKP && CKC) {
      result = [
        ...result,
        TOC("Grant", () =>
          YesConfirm("Grant", "Yes, I want to give access to this account.")
        ),
      ];
    }
    if (!!status && CKP && CKC) {
      result = [
        ...result,
        TOC("Revoke", () =>
          YesConfirm("Revoke", "Yes, I want to Revoke from this account.")
        ),
      ];
    }

    const S1 = ["Company_Manager", "Company_Admin", "Store_Manager"];
    if (S1.includes(root?.role) && to !== "customer" && CKP) {
      result = [...result, TOC("Privileges", () => onPrivilages(id))];
    }

    return result;
  };

  const openClose = () => {
    setOpen(false);
    setPrivilage(null);
    setPersonInfo(null);
  };
  const onStatus = (id, status) => {
    dispatch(Act.view_edit(id, { status, privilege: "Read_Add_Edit_Delete" }));
    refresh();
  };
  const onEmailStatus = (id, email_status) => {
    dispatch(Act.view_edit(id, { email_status, email_token: null }));
    refresh();
  };
  return (
    <React.Fragment>
      <Hoc.Dialog
        open={open}
        onClose={openClose}
        title="Person Privileges"
        fullWidth
        maxWidth="xs"
        actions={
          <Box>
            <Controls.Button
              text="Set Privilage"
              size="small"
              onClick={() => {
                dispatch(
                  Act.view_edit(personInfo?.id, { privilege: privilage })
                );
                dispatch(Act.view_all(queryString || null));
                openClose();
              }}
            />
            <Controls.Button
              text="Close"
              color="secondary"
              size="small"
              onClick={openClose}
            />
          </Box>
        }
      >
        <Hoc.ShowItem
          name="Full Name"
          value={`${personInfo?.first_name} ${personInfo?.last_name}`}
        />
        <Hoc.ShowItem
          name="Position"
          value={`${personInfo?.role}`.split("_").join(" ")}
        />

        <Box style={{ marginTop: 15 }}>
          <Controls.SearchSelect
            {...previlage_setup(privilage, setPrivilage, to === "s1")}
          />
        </Box>
      </Hoc.Dialog>

      <Confirm
        header={ConfirmMsg.header}
        open={ConfirmMsg.open}
        content={ConfirmMsg.content}
        onCancel={() => setConfirmMsg(initConfirmMsg)}
        onConfirm={onConfirm}
        size="tiny"
      />

      <Hoc.Page title={title}>
        <Hoc.PageBox
          title={title}
          actions={[
            ...list_people(root.role, refresh)?.filter(
              (v, refresh) => !`${v.to}`.includes(to)
            ),
          ]}
        >
          {Hoc.PageTabs(
            [...list_actions(to, !!CKAdd)],
            userLength ? `People Count : ${userLength}` : "Empty Records"
          )}

          {!CKRead ? (
            <Hoc.Message text="Access Denied to view!." color="yellow" />
          ) : (
            <React.Fragment>
              <Hoc.UseData error={error} loading={loading}>
                {!!userLength ? (
                  <TblContainer paginate>
                    {finalRecords().map((v, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{v.first_name}</TableCell>
                        <TableCell>{v.last_name}</TableCell>
                        <TableCell>{v.email}</TableCell>
                        {to !== "customer" && (
                          <TableCell>
                            {`${v?.privilege}`.split("_").join(", ")}
                          </TableCell>
                        )}
                        <TableCell>
                          <Controls.Switch
                            name="status"
                            checked={!!v.status}
                            onChange={({ target: T }) =>
                              onStatus(v?.id, T?.value)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Controls.Switch
                            name="email_status"
                            checked={!!v.email_status}
                            onChange={({ target: T }) =>
                              !v.email_status && onEmailStatus(v?.id, T?.value)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Hoc.Product.MenuButton
                            text="Action"
                            actions={menuActions(v.id, v.status, v.archive)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TblContainer>
                ) : (
                  <Hoc.EmptyMessage />
                )}
              </Hoc.UseData>
            </React.Fragment>
          )}
        </Hoc.PageBox>
      </Hoc.Page>
    </React.Fragment>
  );
};

const previlage_setup = (v, setV, SM) => ({
  name: "country",
  value: v || "",
  onChange: ({ target: v }) => setV(v.value),
  label: "Select Privilage",
  options: getPR(SM),
});

const TOC01 = (id, title) => ({ id, title });
const getPR = (v) => {
  let items = [
    TOC01("None", "No Privilages"),
    TOC01("Read_Add_Edit_Delete", "All Privilages"),
  ];

  if (!v) {
    items = [
      ...items,
      TOC01("Read", "Only View"),
      TOC01("Read_Add", "View & Create"),
      TOC01("Read_Edit", "View & Update"),
      TOC01("Read_Delete", "View & Delete"),
      TOC01("Read_Add_Edit", "View, Create & Update"),
    ];
  }

  return items;
};

const list_people = (v, refresh) => {
  let ret = [];
  const C1 = ["Company_Manager"];
  const C2 = [...C1, "Company_Admin"];
  const S1 = [...C2, "Store_Manager"];
  const S2 = [...S1, "Store_Employee"];
  const TOT = (name, to) => ({ name, to });

  if (C1.includes(v)) {
    ret = [TOT("Company Admins", "/c2-active-view-all")];
  }
  if (C2.includes(v)) {
    ret = [...ret, TOT("Store Managers", "/s1-active-view-all")];
  }
  if (S1.includes(v)) {
    ret = [...ret, TOT("Store Employees", "/s2-active-view-all")];
  }
  if (S2.includes(v)) {
    ret = [...ret, TOT("Customers", "/customer-active-view-all")];
  }
  ret = [...ret, { name: "Refresh", onClick: () => refresh() }];

  return ret;
};

const list_actions = (v, add) => {
  let items = [
    { name: "Active", to: `/${v}-active-view-all` },
    { name: "Inactive", to: `/${v}-inactive-view-all` },
    { name: "Archived", to: `/${v}-archive-view-all` },
  ];
  if (!!add) {
    items = [{ name: "Create New", to: `/${v}-sign-up` }, ...items];
  }
  return items;
};

const headCells = (v) => {
  const TOT = (id, label) => ({ id, label });
  let items = [
    TOT("first_name", "First Name"),
    TOT("last_name", "Last Name"),
    TOT("email", "Email"),
  ];

  if (!v) {
    items = [...items, TOT("Privilege", "Privilege")];
  }

  items = [
    ...items,
    TOT("Account_status", "Account Status"),
    TOT("email_status", "Email Status"),
    { label: "Actions", disableSorting: true },
  ];

  return items;
};
export default Root;
