// this compoenent generates redux reducer by taking some values
// name - reducer name
// state - initial data
// request - Redux type for request
// success - Redux type for success
// fail - Redux type for failure
export const toReducer = (v) =>
  v
    ? {
        [v.name]: (state = v.state, { type, payload }) => {
          switch (type) {
            case v.request:
              return { ...state, loading: true };
            case v.success:
              return { loading: false, data: payload };
            case v.fail:
              return { ...state, loading: false, error: payload };
            default:
              return state;
          }
        },
      }
    : null;

export const toOopMsg = (v) =>
  v
    ? {
        [v.name]: (state = v.state, { type, payload }) => {
          switch (type) {
            case v.create:
              return payload;
            case v.delete:
              return payload;
            default:
              return state;
          }
        },
      }
    : null;
