import Helper from "../../helper";
import { Type } from "../../../redux";
import axios from "axios";

const types = Type.product_type.category_01;

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.CATEGORY_01_VIEW_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/category-01/${id}`);
    dispatch({ type: types.CATEGORY_01_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_01_VIEW_FAIL);
  }
};

export const view_slug = (slug) => async (dispatch) => {
  try {
    dispatch({ type: types.CATEGORY_01_VIEW_SLUG_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/category-01/slug?${slug}`);
    dispatch({
      type: types.CATEGORY_01_VIEW_SLUG_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_01_VIEW_SLUG_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch) => {
  try {
    dispatch({ type: types.CATEGORY_01_VIEW_ALL_REQUEST });

    const { data } = await axios.get(
      `${Helper.URL}/category-01?${queryString || ""}`
    );
    dispatch({
      type: types.CATEGORY_01_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_01_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_01_ADD_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(`${Helper.URL}/category-01`, initData);

    dispatch(
      { type: types.CATEGORY_01_ADD_SUCCESS, payload: data?.result },
      Helper.multiForm
    );
    dispatch({
      type: types.CATEGORY_01_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.product?.category_01?.view_all?.data],
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_01_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_01_EDIT_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(
      `${Helper.URL}/category-01/${id}`,
      initData
    );

    dispatch({ type: types.CATEGORY_01_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_01_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_01_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${Helper.URL}/category-01/${id}`);

    dispatch({ type: types.CATEGORY_01_DELETE_SUCCESS, payload: data?.result });
    dispatch({
      type: types.CATEGORY_01_VIEW_ALL_SUCCESS,
      payload: state()?.product?.category_01?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_01_DELETE_FAIL);
  }
};
