import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hoc from "..";
import { Message } from "semantic-ui-react";

const Banner02 = ({ items }) => {
  const CSS = useCSS();
  return (
    <Box style={{ marginBottom: 20, maxHeight: "70vh", overflow: "auto" }}>
      {items?.map((v, idx) => (
        <Message key={idx}>
          <Box {...Hoc.flex("space-around")}>
            {!!(idx % 2) && <ItemPhoto CSS={CSS} src={v.photo} />}
            <ItemDesc v={v} />
            {!(idx % 2) && <ItemPhoto CSS={CSS} src={v.photo} />}
          </Box>
        </Message>
      ))}
    </Box>
  );
};

const ItemPhoto = ({ CSS, src }) => (
  <Box component="img" src={src} className={CSS.logo} />
);

const ItemDesc = ({ v }) => (
  <Box
    style={{ margin: "0 20px" }}
    {...Hoc.flex("flex-start", "center", "column")}
  >
    <Typography variant="h5" color="primary">
      {v.name}
    </Typography>
    <Typography variant="subtitle1" style={{ textAlign: "justify" }}>
      {v.description}
    </Typography>
  </Box>
);

const useCSS = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
  },
  logo_root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    height: theme.spacing(15),
  },
}));

export default Banner02;
