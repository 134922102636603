import React, { useState } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Action from "../../../actions";
import { Box, Typography } from "@material-ui/core";
import { Button } from "semantic-ui-react";

const StoreFoprm = (props) => {
  const Act_Opp = Action.base02.oop_msg.view_add;
  const Act_store = Action.store.item;
  const dispatch = useDispatch();
  const h = useHistory();
  const [showable, setShowable] = useState(false);
  const { title, id, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;

  const onSubmit = async (e) => {
    let view = new FormData();
    Object.entries(values)
      .filter((v) => !!v[1])
      .forEach((v) => view.set(v[0], v[1]));

    let status;
    if (!id) {
      status = await dispatch(Act_store.view_add(view));
    } else {
      status = await dispatch(Act_store.view_edit(id, view));
    }

    if (!!status) {
      resetForm();
      h.goBack();

      dispatch(Act_Opp(id ? "Store updated!!." : "Store created!!."));
    }
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: setup_status(values) }}
      >
        <Controls.Input {...setup.name(values.name, onChange)} />
        <Controls.Input {...setup.branch(values.branch, onChange)} />
        <Controls.Input {...setup.email(values.email, onChange)} />
        <Controls.PhoneInput {...setup.contact(values.contact, onChange)} />
        <Controls.Input {...setup.vat_price(values.vat_price, onChange)} />
        {!!showable && (
          <React.Fragment>
            <Controls.Input {...setup.PD_email(values.PD_email, onChange)} />
            <Controls.Input {...setup.PD_mobile(values.PD_mobile, onChange)} />
            <Controls.Input
              {...setup.PD_account_number(values.PD_account_number, onChange)}
            />
            <Controls.Input
              {...setup.PD_account_name(values.PD_account_name, onChange)}
            />
            <Controls.Input
              {...setup.PD_bank_code(values.PD_bank_code, onChange)}
            />
            <Controls.Input
              {...setup.PD_branch_name(values.PD_branch_name, onChange)}
            />
            <Controls.Input
              {...setup.PD_country_name(values.PD_country_name, onChange)}
            />
            <Controls.Input
              {...setup.PD_transaction_id(values.PD_transaction_id, onChange)}
            />
            <Controls.Input
              {...setup.PD_reference(values.PD_reference, onChange)}
            />
            <Controls.Input {...setup.PD_iban(values.PD_iban, onChange)} />
            <Controls.Input
              {...setup.PD_bic_code(values.PD_bic_code, onChange)}
            />
            <Controls.Input
              {...setup.PD_sort_code(values.PD_sort_code, onChange)}
            />
          </React.Fragment>
        )}
        <Box {...Hoc.flex("flex-end")}>
          <Button
            content={!showable ? "Show" : "Hide"}
            onClick={() => setShowable(!showable)}
            inverted
            color="orange"
            size="tiny"
          />
        </Box>

        <Typography align="center">
          <Controls.Switch
            {...setup.one_address(values.one_address, onChange)}
          />
        </Typography>
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) => !v.name || !v.branch || !v.email || !v.contact;

const setup = {
  one_address: (v, onChange) =>
    Hoc.setupSwitch(
      v,
      onChange,
      "one_address",
      "Use same Address for both Collection & Store"
    ),
  email: (v, onChange) => Hoc.setupText(v, onChange, "email", "Email", "email"),
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Name"),
  branch: (v, onChange) => Hoc.setupText(v, onChange, "branch", "Branch"),

  vat_price: (v, onChange) => ({
    ...Hoc.setupText(v, onChange, "vat_price", "VAT / GST (0.85)", "number"),
    inputProps: {
      min: 0,
      max: 99.99,
      step: 0.01,
    },
  }),
  contact: (v, onChange) =>
    Hoc.setupText(v, onChange, "contact", "Contact Number", "phone"),

  PD_email: (v, OC) => Hoc.setupText(v, OC, "PD_email", "Email", "email"),
  PD_mobile: (v, OC) => Hoc.setupText(v, OC, "PD_mobile", "Mobile"),
  PD_account_number: (v, OC) =>
    Hoc.setupText(v, OC, "PD_account_number", "Account Number"),
  PD_account_name: (v, OC) =>
    Hoc.setupText(v, OC, "PD_account_name", "Account Name"),
  PD_bank_code: (v, OC) => Hoc.setupText(v, OC, "PD_bank_code", "Bank Code"),
  PD_branch_name: (v, OC) =>
    Hoc.setupText(v, OC, "PD_branch_name", "Branch Name"),
  PD_country_name: (v, OC) =>
    Hoc.setupText(v, OC, "PD_country_name", "Country Name"),
  PD_transaction_id: (v, OC) =>
    Hoc.setupText(v, OC, "PD_transaction_id", "Transaction Id"),
  PD_reference: (v, OC) => Hoc.setupText(v, OC, "PD_reference", "Reference"),
  PD_iban: (v, OC) => Hoc.setupText(v, OC, "PD_iban", "IBAN"),
  PD_bic_code: (v, OC) => Hoc.setupText(v, OC, "PD_bic_code", "BIC Code"),
  PD_sort_code: (v, OC) => Hoc.setupText(v, OC, "PD_sort_code", "SORT Code"),
};

export default StoreFoprm;
