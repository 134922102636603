import SecureRoute from "../../../comps/layout/SecureRoute";
import view from "./view";
import view_all from "./view_all";

const Expo = {
  view: <SecureRoute grant="GN" path="/subscribe-view/:id" component={view} />,
  view_all: (
    <SecureRoute grant="GN" path="/view-all-subscribe" component={view_all} />
  ),
};

export default Expo;
