import { Route } from "react-router-dom";
import SecureRoute from "../../../comps/layout/SecureRoute";

import add from "./editable/add";
import edit from "./editable/edit";

import category_00 from "./category/category_00";
import category_01 from "./category/category_01";
import category_02 from "./category/category_02";
import category_03 from "./category/category_03";

import view from "./base/view";
import product_variant from "./base/product_variant";
import StoreProduct from "./base/StoreProduct";
import product_slug from "./base/product_slug";
import view_all_trolly from "./base/view_all_trolly";
import view_my_products from "./base/view_my_products";
import ship_tax from "./base/ship-charge";

const Expo = {
  add: <SecureRoute grant="S2" path="/product-add" component={add} />,
  edit: <SecureRoute grant="S2" path="/product-edit/:id" component={edit} />,
  ship_tax: (
    <SecureRoute
      grant="S2"
      path="/product-ship-charge/:id"
      component={ship_tax}
    />
  ),

  view_my_product: (
    <SecureRoute
      grant="S2"
      path="/store-product-view/:id"
      component={StoreProduct}
    />
  ),
  view_my_products: (
    <SecureRoute
      grant="S2"
      path="/store-products"
      component={view_my_products}
    />
  ),
  view_all_trolly: (
    <SecureRoute
      grant="S2"
      path="/product-view-all-trolly"
      component={view_all_trolly}
    />
  ),

  view: <Route exact path="/product-view" component={view} />,
  category_00: <Route exact path="/category-00" component={category_00} />,
  category_01: <Route exact path="/category-01/:id" component={category_01} />,
  category_02: <Route exact path="/category-02/:id" component={category_02} />,
  category_03: <Route exact path="/category-03/:id" component={category_03} />,
  product_variant: (
    <Route exact path="/product-variant/:id" component={product_variant} />
  ),
  product_slug: (
    <Route exact path="/product-slug/:id" component={product_slug} />
  ),
};

export default Expo;
