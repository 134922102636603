import Helper from "../../helper";
import { Type } from "../../../redux";
import axios from "axios";

const types = Type.product_type.product;

export const view_slug = (slug) => async (dispatch) => {
  try {
    dispatch({ type: types.PRODUCT_VIEW_SLUG_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/product-item/slug?${slug}`);

    dispatch({
      type: types.PRODUCT_VIEW_SLUG_SUCCESS,
      payload: data?.result,
    });

    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_VIEW_SLUG_FAIL);
  }
};

export const search_items = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_SEARCH_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.get(
      `${Helper.URL}/product-item/search?${queryString || ""}`
    );

    dispatch({ type: types.PRODUCT_SEARCH_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_SEARCH_FAIL);
  }
};

export const view_latest = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_LATEST_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/product-item/latest`);
    dispatch({ type: types.PRODUCT_LATEST_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_LATEST_FAIL);
  }
};

export const view_best_seller = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BEST_SELLER_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/product-item/best-seller`);
    dispatch({
      type: types.PRODUCT_BEST_SELLER_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BEST_SELLER_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.PRODUCT_VIEW_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/product-item/${id}`);
    dispatch({ type: types.PRODUCT_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_VIEW_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      `${Helper.URL}/product-item?${queryString || ""}`
    );

    dispatch({ type: types.PRODUCT_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_ADD_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(`${Helper.URL}/product-item`, initData);

    dispatch(
      { type: types.PRODUCT_ADD_SUCCESS, payload: data?.result },
      Helper.multiForm
    );
    dispatch({
      type: types.PRODUCT_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.product?.product?.view_all?.data],
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_EDIT_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(
      `${Helper.URL}/product-item/${id}`,
      initData
    );

    dispatch({ type: types.PRODUCT_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${Helper.URL}/product-item/${id}`);

    dispatch({ type: types.PRODUCT_DELETE_SUCCESS, payload: data?.result });
    dispatch({
      type: types.PRODUCT_VIEW_ALL_SUCCESS,
      payload: state()?.product?.product?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_DELETE_FAIL);
  }
};
