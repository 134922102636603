import React, { useEffect } from "react";
import Custom from "../../../../comps/Custom";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ShipChargeEdit = () => {
  const Act = Action.product.box;
  const dispatch = useDispatch();
  const params = useParams();
  const box = useSelector(({ product: p }) => p.box.view.data);

  const { useForm } = Custom.useForm;
  const formData = useForm(setInitValues(params?.id));
  const title = `${box?.Product?.title || "Product"} Shipping Charges`;

  useEffect(() => {
    dispatch(Act.view(params?.id));
  }, [dispatch, Act, params]);

  return (
    <Hoc.Page title={title}>
      <Custom.Form.ShipCharge
        title={title}
        formData={formData}
        id={params.id}
      />
    </Hoc.Page>
  );
};

const setInitValues = (id) => ({
  country: "",
  charge: "",
  ProductBoxId: id || "",
});

export default ShipChargeEdit;
