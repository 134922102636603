import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Hoc from "../../comps/Hoc";
import Custom from "../../comps/Custom";
import Controls from "../../comps/Controls";
import Action from "../../actions";
import { people } from "./email-confirmation";

const EmailConfirmation = () => {
  const params = useParams();
  const H = useHistory();
  const dispatch = useDispatch();
  const Act_Opp = Action.base02.oop_msg.view_add;
  const token = `${params.token}`.split("--")[0];
  const role = `${params.token}`.split("--")[1];
  const { Form, useForm } = Custom.useForm;
  const { values, onChange, resetForm } = useForm(initValues);

  const usr = people.find((v) => v.role === role);

  const onSubmit = () => {
    dispatch(usr?.rst({ password: values?.password, token }));
    resetForm();
    H.push(usr?.url);
    dispatch(Act_Opp("Password Changed!."));
  };

  return (
    <Hoc.Page title="Reset Password">
      <Hoc.Dashboard.Container
        title="Reset Password"
        actions={[{ name: "Home", to: "/" }]}
      >
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          submitButton={{ disabled: setup_status(values, token) || !usr }}
        >
          <Controls.Input {...setup.password(values.password, onChange)} />
          <Controls.Input {...setup.password2(values.password2, onChange)} />
        </Form>
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

const setup_status = (v, token) =>
  `${v?.password}`.length < 6 || !token || v.password !== v.password2;

const initValues = {
  password: "",
  password2: "",
};
const setup = {
  password: (value, onChange) => ({
    type: "password",
    name: "password",
    label: "Password",
    value,
    onChange,
    required: true,
  }),
  password2: (value, onChange) => ({
    type: "password",
    name: "password2",
    label: "Confirm Password",
    value,
    onChange,
    required: true,
    inputProps: {
      minLength: 6,
    },
  }),
};

export default EmailConfirmation;
