import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};
const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const best_seller = {
  name: "best_seller",
  state: defListState,
  request: types.PRODUCT_BEST_SELLER_REQUEST,
  success: types.PRODUCT_BEST_SELLER_SUCCESS,
  fail: types.PRODUCT_BEST_SELLER_FAIL,
};

export const latest = {
  name: "latest",
  state: defListState,
  request: types.PRODUCT_LATEST_REQUEST,
  success: types.PRODUCT_LATEST_SUCCESS,
  fail: types.PRODUCT_LATEST_FAIL,
};

export const search = {
  name: "search",
  state: defListState,
  request: types.PRODUCT_SEARCH_REQUEST,
  success: types.PRODUCT_SEARCH_SUCCESS,
  fail: types.PRODUCT_SEARCH_FAIL,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.PRODUCT_VIEW_ALL_REQUEST,
  success: types.PRODUCT_VIEW_ALL_SUCCESS,
  fail: types.PRODUCT_VIEW_ALL_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.PRODUCT_VIEW_REQUEST,
  success: types.PRODUCT_VIEW_SUCCESS,
  fail: types.PRODUCT_VIEW_FAIL,
};

export const view_slug = {
  name: "view_slug",
  state: defState,
  request: types.PRODUCT_VIEW_SLUG_REQUEST,
  success: types.PRODUCT_VIEW_SLUG_SUCCESS,
  fail: types.PRODUCT_VIEW_SLUG_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.PRODUCT_EDIT_REQUEST,
  success: types.PRODUCT_EDIT_SUCCESS,
  fail: types.PRODUCT_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.PRODUCT_ADD_REQUEST,
  success: types.PRODUCT_ADD_SUCCESS,
  fail: types.PRODUCT_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.PRODUCT_DELETE_REQUEST,
  success: types.PRODUCT_DELETE_SUCCESS,
  fail: types.PRODUCT_DELETE_FAIL,
};
