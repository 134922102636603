import SecureRoute from "../../comps/layout/SecureRoute";
import mail_templates from "./mail_templates";
import notification_list from "./notification_list";

const Expo = {
  mail_templates,
  notification_list: (
    <SecureRoute
      grant="GN"
      path="/notification-list"
      component={notification_list}
    />
  ),
};

export default Expo;
