import axios from "axios";
import Action from "./index";
import { Type } from "../redux";
const root_types = Type.people_type.root;

// placing login urls in an array
const urls = [
  { role: "Company_Manager", url: "/supreme-sign-in" },
  { role: "Company_Admin", url: "/c2-sign-in" },
  { role: "Store_Manager", url: "/s1-sign-in" },
  { role: "Store_Employee", url: "/s2-sign-in" },
  { role: "Customer", url: "/sign-in" },
];

// Deals with the error message from the request.
const errResponse = (error, dispatch, type) => {
  // action for the popup message
  const add_oop = Action.base02.oop_msg.view_add;

  // extracting the error from the the error response
  const payload =
    error.response && error.response.data.error
      ? error.response.data.error
      : error.error;

  // checking of the error belongs to the authentication
  if (`${payload}`.includes("Authentication Error Occurred")) {
    // extracting user data
    const root = JSON.parse(sessionStorage.getItem("root"));
    // extracting login url for the user
    const url = urls.find((v) => v.role === root.role)?.url;

    // logging out the user
    dispatch({ type: root_types.ROOT_SUCCESS, payload: null });
    sessionStorage.removeItem("root");
    sessionStorage.removeItem("trolly");
    // !!payload && dispatch(opp.view_add(`You session is expired. Please login again.`, true));
    // setTimeout(() => {}, 5000);
    // redirecting to the login page
    window.location.replace(url);
  } else {
    // dispatching the both error & error message
    dispatch && dispatch({ type, payload });
    !!payload && dispatch(add_oop(`${payload}`, true));
  }
};

// takes Auth token assign to the axios headers for the authentication on the request
const setJwtToken = (token) => {
  // if token is available then it will set the headers otherwise it deletes the token
  if (!!token) {
    axios.defaults.headers.common["Authorization"] = `Bearer___${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// This component extracts the error message from the error and returns
const getErr = (err) => {
  const payload =
    err.response && err.response.data.error
      ? err.response.data.error
      : err.error;
  return payload;
};

// it takes the state & direct keywords and if direct is true then it directly paases that state to the
// setJwtToken component otherwise it extract the token from the state and passes to the setJwtToken component
const set_jwt = (state, direct) =>
  !!direct ? setJwtToken(state) : setJwtToken(state?.people?.root?.data?.token);

// create content type for the multiform FormData
const multiForm = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// takes the amount & current and returns the string in a currency format
const toCurrency = (currency, amount) =>
  amount
    ? amount?.toLocaleString(currency, {
        style: "currency",
        currency,
      })
    : 0;

// extracting the all components
const Expo = {
  set_jwt,
  errResponse,
  setJwtToken,
  getErr,
  toCurrency,
  multiForm,
  // extracting the server url from the environment variables.
  URL: process.env.REACT_APP_API,
};

export default Expo;
