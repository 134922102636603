// import { lazy } from "react";
import View from "./View";
import EditProfile from "./EditProfile";
import EditPassword from "./EditPassword";

const Expo = {
  View,
  EditProfile,
  EditPassword,
};

export default Expo;
