import React from "react";
import { InputLabel } from "@material-ui/core";
import Button from "./Button";
import Action from "../../actions";
import Resizer from "react-image-file-resizer";
import { useDispatch } from "react-redux";

const Input = (p01) => {
  const { id: fid, name, label, accept, ...props } = p01;
  const { color, onChange, ...rest } = props;

  const dispatch = useDispatch();
  const Act_Oop = Action.base02.oop_msg.view_add;
  const id = fid || "file_id";
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const onFileChange = async ({ target: v }) => {
    try {
      const file = v.files[0];
      if (`${file.type}`.startsWith("image")) {
        const value = await resizeFile(v.files[0]);
        return onChange({ target: { name, value } });
      } else {
        return onChange({ target: { name, value: v.files[0] } });
      }
    } catch (err) {
      dispatch(Act_Oop(`${err}`));
    }
  };

  const smi = { type: "file", accept, id, hidden: true, name };

  return (
    <>
      <Button style={{ padding: "10px 15px" }}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <input {...smi} onChange={onFileChange} {...rest} />
      </Button>
    </>
  );
};

export default Input;
