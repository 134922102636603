const types = {
  //
  STR_CURRENCY_LIST_REQUEST: "STR_CURRENCY_LIST_REQUEST",
  STR_CURRENCY_LIST_SUCCESS: "STR_CURRENCY_LIST_SUCCESS",
  STR_CURRENCY_LIST_FAIL: "STR_CURRENCY_LIST_FAIL",
  //
  STR_CURRENCY_VIEW_REQUEST: "STR_CURRENCY_VIEW_REQUEST",
  STR_CURRENCY_VIEW_SUCCESS: "STR_CURRENCY_VIEW_SUCCESS",
  STR_CURRENCY_VIEW_FAIL: "STR_CURRENCY_VIEW_FAIL",
  //
  STR_CURRENCY_ADD_REQUEST: "STR_CURRENCY_ADD_REQUEST",
  STR_CURRENCY_ADD_SUCCESS: "STR_CURRENCY_ADD_SUCCESS",
  STR_CURRENCY_ADD_FAIL: "STR_CURRENCY_ADD_FAIL",
  //
  STR_CURRENCY_EDIT_REQUEST: "STR_CURRENCY_EDIT_REQUEST",
  STR_CURRENCY_EDIT_SUCCESS: "STR_CURRENCY_EDIT_SUCCESS",
  STR_CURRENCY_EDIT_FAIL: "STR_CURRENCY_EDIT_FAIL",
  //
  STR_CURRENCY_DELETE_REQUEST: "STR_CURRENCY_DELETE_REQUEST",
  STR_CURRENCY_DELETE_SUCCESS: "STR_CURRENCY_DELETE_SUCCESS",
  STR_CURRENCY_DELETE_FAIL: "STR_CURRENCY_DELETE_FAIL",
};

export default types;
