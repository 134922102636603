import React, { useEffect } from "react";
import { useParams } from "react-router";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "semantic-ui-react";

const View = () => {
  const Act_address = Action.base.address.view;
  const P = useParams();
  const dispatch = useDispatch();

  const { data, error, loading } = useSelector(({ base: B }) => B.address.view);

  useEffect(() => {
    dispatch(Act_address(P.id));
  }, [dispatch, Act_address, P]);

  const nav01 = {
    title: "View Page",
    actions: [
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: () => dispatch(Act_address(P.id)) },
    ],
  };

  return (
    <Hoc.Dashboard.Container {...nav01}>
      <Hoc.UseData error={error} loading={loading}>
        <Header content={Hoc.showAddress(data)} />
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

export default View;
