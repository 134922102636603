const types = {
  //
  PRODUCT_PHOTO_VIEW_ALL_REQUEST: "PRODUCT_PHOTO_VIEW_ALL_REQUEST",
  PRODUCT_PHOTO_VIEW_ALL_SUCCESS: "PRODUCT_PHOTO_VIEW_ALL_SUCCESS",
  PRODUCT_PHOTO_VIEW_ALL_FAIL: "PRODUCT_PHOTO_VIEW_ALL_FAIL",
  //
  PRODUCT_PHOTO_VIEW_REQUEST: "PRODUCT_PHOTO_VIEW_REQUEST",
  PRODUCT_PHOTO_VIEW_SUCCESS: "PRODUCT_PHOTO_VIEW_SUCCESS",
  PRODUCT_PHOTO_VIEW_FAIL: "PRODUCT_PHOTO_VIEW_FAIL",
  //
  PRODUCT_PHOTO_EDIT_REQUEST: "PRODUCT_PHOTO_EDIT_REQUEST",
  PRODUCT_PHOTO_EDIT_SUCCESS: "PRODUCT_PHOTO_EDIT_SUCCESS",
  PRODUCT_PHOTO_EDIT_FAIL: "PRODUCT_PHOTO_EDIT_FAIL",
  //
  PRODUCT_PHOTO_ADD_REQUEST: "PRODUCT_PHOTO_ADD_REQUEST",
  PRODUCT_PHOTO_ADD_SUCCESS: "PRODUCT_PHOTO_ADD_SUCCESS",
  PRODUCT_PHOTO_ADD_FAIL: "PRODUCT_PHOTO_ADD_FAIL",
  //
  PRODUCT_PHOTO_DELETE_REQUEST: "PRODUCT_PHOTO_DELETE_REQUEST",
  PRODUCT_PHOTO_DELETE_SUCCESS: "PRODUCT_PHOTO_DELETE_SUCCESS",
  PRODUCT_PHOTO_DELETE_FAIL: "PRODUCT_PHOTO_DELETE_FAIL",
};

export default types;
