import Helper from "../../helper";
import { Type } from "../../../redux";
import axios from "axios";

const types = Type.product_type.category_03;

const URL = `${Helper.URL}/category-03`;

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.CATEGORY_03_VIEW_REQUEST });

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.CATEGORY_03_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_03_VIEW_FAIL);
  }
};

export const view_slug = (slug) => async (dispatch) => {
  try {
    dispatch({ type: types.CATEGORY_03_VIEW_SLUG_REQUEST });
    const { data } = await axios.get(`${URL}/slug?${slug}`);
    dispatch({
      type: types.CATEGORY_03_VIEW_SLUG_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_03_VIEW_SLUG_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_03_VIEW_ALL_REQUEST });

    const { data } = await axios.get(
      !queryString ? URL : `${URL}?${queryString || ""}`
    );

    dispatch({
      type: types.CATEGORY_03_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_03_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_03_ADD_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(`${URL}`, initData);

    dispatch(
      { type: types.CATEGORY_03_ADD_SUCCESS, payload: data?.result },
      Helper.multiForm
    );
    dispatch({
      type: types.CATEGORY_03_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.product?.category_03?.view_all?.data],
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_03_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_03_EDIT_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(`${URL}/${id}`, initData);

    dispatch({ type: types.CATEGORY_03_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_03_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.CATEGORY_03_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.CATEGORY_03_DELETE_SUCCESS, payload: data?.result });
    dispatch({
      type: types.CATEGORY_03_VIEW_ALL_SUCCESS,
      payload: state()?.product?.category_03?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.CATEGORY_03_DELETE_FAIL);
  }
};
