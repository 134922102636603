import React from "react";
import { Button } from "semantic-ui-react";
import { Menu, MenuItem, Fade, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function FadeMenu(props) {
  const h = useHistory();
  const { icon, text = "Action", actions } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!!actions?.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Btn = () => (
    <Button
      content={!!actions?.length ? text : "No Actions"}
      size="mini"
      color={!!actions?.length ? "purple" : "orange"}
      disabled={!actions?.length}
    />
  );
  return (
    <div>
      <Box onClick={handleClick}>
        {!!icon && icon}

        {!!text && <Btn />}
      </Box>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {Array.isArray(actions) && !!actions.length ? (
          actions.map((v, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                !!v.onClick && v.onClick();
                !!v.to && h.push(v.to);
                !!v.goBack && h.goBack();
                handleClose();
              }}
            >
              {v?.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>No Actions</MenuItem>
        )}
      </Menu>
    </div>
  );
}
