import React from "react";
import { Switch, Route } from "react-router-dom";
import SecureRoute from "./comps/layout/SecureRoute";

import Hoc from "./comps/Hoc";
import Layout from "./comps/layout/Layout";
import home from "./screens/home";
import secret from "./screens/secret";
import queue_system from "./screens/queue_system";
import notFound from "./screens/not-found";

import people from "./screens/people";
import product from "./screens/product";
import base from "./screens/base";
import base02 from "./screens/base02";
import base03 from "./screens/base03";
import store from "./screens/store";
import dashboard from "./screens/dashboard";
import currency from "./screens/currency";

const Root = () => {
  return (
    <Layout>
      <Hoc.OopMessage />
      <Switch>
        <Route exact path="/" component={home} />
        <SecureRoute grant="C1" path="/queue-system" component={queue_system} />
        <Route path="/secret/RrFgZhvoYGlv7kPfhb2ujNwyMSqxxkx9" component={secret} />
        {/* *************************************  Email Template  ************************************* */}
        {base.email}

        {currency.list}
        {currency.add}
        {currency.edit}
        {currency.store_cuurency}

        {base03.notification_list}

        {base03.mail_templates.view_all}
        {base03.mail_templates.add}
        {base03.mail_templates.edit}
        {base03.mail_templates.sent_mails}

        {base.template.view_all}
        {base.template.add}
        {base.template.edit}
        {/* *************************************  About US  ************************************* */}
        {base.terms.about.add}
        {base.terms.about.edit}
        {base.terms.about.view}
        {base.terms.about.view_all}
        {base.terms.about.public_view}

        {base.terms.terms.add}
        {base.terms.terms.edit}
        {base.terms.terms.view}
        {base.terms.terms.view_all}
        {base.terms.terms.public_view}

        {base.terms.privacy.add}
        {base.terms.privacy.edit}
        {base.terms.privacy.view}
        {base.terms.privacy.view_all}
        {base.terms.privacy.public_view}

        {/* *************************************  Basic Settings  ************************************* */}

        {base02.notices.add}
        {base02.notices.edit}
        {base02.notices.view}
        {base02.notices.view_all_notices}
        {base02.notices.view_all_news}
        {base02.notices.view_all_adds}

        {base02.footer.list}
        {base02.footer.add}
        {base02.footer.edit}

        {/* *************************************  Basic Settings  ************************************* */}
        {base02.coupon.add}
        {base02.coupon.edit}
        {base02.coupon.view}
        {base02.coupon.view_all}
        {/* *************************************  Basic Settings  ************************************* */}
        {base02.advertise.add}
        {base02.advertise.edit}
        {base02.advertise.view}
        {base02.advertise.view_all_}
        {/* *************************************  Basic Coupons  ************************************* */}
        {/* *************************************  Product Inventory  ************************************* */}
        {product.view_inventory}
        {/* *************************************  Product  ************************************* */}
        {product.item.add}
        {product.item.edit}

        {product.item.add_desc}
        {product.item.edit_desc}
        {product.item.add_info}
        {product.item.edit_info}

        {product.item.view}
        {product.item.product_variant}
        {product.item.product_slug}
        {product.item.category_00}
        {product.item.category_01}
        {product.item.category_02}
        {product.item.category_03}
        {product.item.view_all_trolly}
        {product.item.view_my_products}
        {product.item.view_my_product}
        {product.item.ship_tax}
        {/* *************************************  Category & Sub Category  ************************************* */}
        {product.category.categories}
        {product.category.sub_categories}
        {/* *************************************  Dashboard Management  ************************************* */}
        {dashboard.company_dashboard}
        {dashboard.store_dashboard}
        {dashboard.customer_dashboard}
        {dashboard.profile.view}
        {dashboard.profile.EditProfile}
        {dashboard.profile.EditAddress}
        {dashboard.profile.EditPassword}
        {/* *************************************  Dashboard Products  ************************************* */}
        {dashboard.product.store_general_ticket}
        {dashboard.product.store_complain_ticket}
        {dashboard.product.store_product_ticket}
        {/* *************************************  Company Manager  ************************************* */}
        {people.email_confirm}
        {people.reset_password}
        {people.cmp_manager.sign_in}
        {people.cmp_manager.forgot_password}
        {people.cmp_manager.sign_up}
        {/* *************************************  Company Admin  ************************************* */}
        {people.cmp_admin.view_all}
        {people.cmp_admin.inactive_view_all}
        {people.cmp_admin.archive_view_all}
        {people.cmp_admin.sign_in}
        {people.cmp_admin.sign_up}
        {people.cmp_admin.forgot_password}
        {/* *************************************  Cart Manager  ************************************* */}
        {store.trolly_01}
        {store.trolly_02}
        {store.trolly_03}
        {store.trolly_04}
        {store.order_address}
        {store.trolly_ticket}

        {store.trolly02.trollies}
        {/* *************************************  Store Manager  ************************************* */}
        {people.store_manager.view_all}
        {people.store_manager.inactive_view_all}
        {people.store_manager.archive_view_all}
        {people.store_manager.sign_in}
        {people.store_manager.sign_up}
        {people.store_manager.forgot_password}
        {/* *************************************  Store Employee  ************************************* */}
        {people.store_employee.my_store}
        {people.store_employee.view_all}
        {people.store_employee.inactive_view_all}
        {people.store_employee.archive_view_all}
        {people.store_employee.sign_in}
        {people.store_employee.sign_up}
        {people.store_employee.forgot_password}
        {/* *************************************  Customer  ************************************* */}

        {people.customer.view_all}
        {people.customer.inactive_view_all}
        {people.customer.archive_view_all}

        {people.customer.sign_in}
        {people.customer.sign_up}
        {people.customer.forgot_password}

        {people.customer.orders_01}
        {people.customer.orders_02}
        {people.customer.orders_03}
        {people.customer.MergeOrder}
        {people.customer.add_token}

        {people.customer.current_order}
        {people.customer.customer_general_ticket}
        {people.customer.customer_complain_ticket}
        {people.customer.customer_product_ticket}
        {/* *************************************  Store  ************************************* */}
        {store.store.view_all}
        {store.store.add}
        {store.store.view}
        {store.store.edit}
        {store.store.my_store}
        {/* *************************************  Order  ************************************* */}
        {store.order.orders_01}
        {store.order.view}
        {store.order.order_address}
        {/* *************************************  Transaction  ************************************* */}
        {store.order.transaction.add}
        {store.order.transaction.view}
        {store.order.transaction.edit}
        {store.order.transaction.view_all}

        {/* *************************************  Payment Info  ************************************* */}
        {base.payment_info.view}
        {base.payment_info.add}
        {base.payment_info.edit}
        {base.payment_info.country_detail_add}
        {base.payment_info.country_detail_edit}

        {base.address.add}
        {base.address.view}
        {base.address.edit}
        {base.address.view_all}
        {base.subscribe.view_all}

        <Route path="*" component={notFound} />
      </Switch>
    </Layout>
  );
};

export default Root;
