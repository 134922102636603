import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../actions";

// ['C1', 'C2', 'S1', 'S2', 'Customer']

const ProtectedRoute = ({ grant, exact, ...rest }) => {
  const root = useSelector(({ people: p }) => p.root.data);

  const dispatch = useDispatch();
  const Act = Action.people.root.verify_token_expire;

  useEffect(() => {
    dispatch(Act(root?.token));
  }, [dispatch, Act, root]);

  const c_1 = ["Company_Manager", "Company_Admin"];
  const s_1 = ["Store_Manager", "Store_Employee"];

  const C1 = ["Company_Manager"].includes(root?.role);
  const C2 = c_1.includes(root?.role);
  const S1 = [...c_1, "Store_Manager"].includes(root?.role);
  const S2 = [...c_1, ...s_1].includes(root?.role);
  const GN = [...c_1, ...s_1, "Customer"].includes(root?.role);

  if (!grant && (!root?.token || !root?.role)) {
    return <Route exact={exact || true} {...rest} />;
  } else if (!!grant) {
    if (
      (grant === "C" && !!c_1.includes(root?.role)) || //C means (P01 & P02)
      (grant === "S" && !!s_1.includes(root?.role)) || //S means (P03 & P04)
      (grant === "G" && !!["Customer"].includes(root?.role)) || //G means (P05)
      (grant === "C1" && !!C1) || //C1 means P01
      (grant === "C2" && !!C2) || //C2 means P01 & P02
      (grant === "S1" && !!S1) || //S1 means P01, P02 & P03
      (grant === "S2" && !!S2) || //S2 means P01, P02, P03 & P04
      (grant === "GN" && !!GN) //Customer means All Authenticated People (P01, P02, P03, P04 & P05)
    ) {
      return <Route exact={exact || true} {...rest} />;
    } else {
      return <Redirect to="/sign-in" />;
    }
  } else {
    return <Redirect to="/sign-in" />;
  }
};

export default ProtectedRoute;

//P01 = Company_Manager
//P02 = Company_Admin
//P03 = Store_Manager
//P04 = Store_Employee
//P05 = Customer
