import React, { useEffect } from "react";
import Hoc from "../../../Hoc";
import Custom from "../..";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { Header } from "semantic-ui-react";
import qryStr from "query-string";

const NoticeForm = (props) => {
  const Act_notice = Action.base02.notice;
  const Act_Opp = Action.base02.oop_msg.view_add;
  const root = useSelector(({ people: P }) => P.root.data);
  const dispatch = useDispatch();
  const H = useHistory();
  const { id, title, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const { setValues: setV } = formData;
  const qry = qryStr.parse(useLocation().search);
  const get_list = useSelector(({ base02: B }) => B.notice.get_list.data);
  const { stores, products, cat01s, cat02s, cat03s } = get_list;
  const category = qry?.category ? qry?.category : "News";

  useEffect(() => {
    dispatch(Act_notice.get_list());
  }, [dispatch, Act_notice]);

  useEffect(() => {
    !id && setV((v) => ({ ...v, category }));
    const setDD = async () => {
      const res = await dispatch(Act_notice.view(id));
      setV(initData(res));
    };
    !!id && setDD();
  }, [dispatch, setV, category, Act_notice, id]);

  const onSubmit = async () => {
    const ck01 = root?.role === "Store_Manager" && !stores?.length;
    const ck02 = ["Store_Employee", "Customer"].includes(root?.role);
    if (ck01 || ck02) {
      H.goBack();
      dispatch(Act_Opp("Sorry, You are not allowed to do this action.", true));
      return;
    }

    const finalData = {
      ...values,
      ...(values?.category === "News" && { photo: null }),
    };
    const status = await dispatch(
      !!id
        ? Act_notice.view_edit(id, finalData)
        : Act_notice.view_add(finalData)
    );

    if (!!status) {
      H.goBack();
      const msg =
        `${finalData?.category}`.toUpperCase() + ` Created successfully.`;
      dispatch(Act_Opp(msg));
    }
  };
  const plsel =
    "Please select any category fron News, Notices & Advertisements.";

  return (
    <Hoc.FormContainer title={title}>
      {!values?.category && <Header content={plsel} color="red" as="h3" />}
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.Input {...setup.name(values?.name, onChange)} />
        <Controls.Input {...setup.description(values?.description, onChange)} />

        <ItemBox>
          {values?.category !== "News" && (
            <Controls.FileUpload {...setup.photo(values?.photo, onChange)} />
          )}
          <Controls.Switch {...setup.enabled(values.enabled, onChange)} />
        </ItemBox>
        <ItemBox>
          <Controls.SearchSelect
            {...setup.category(values?.category, onChange)}
          />
          <Controls.SearchSelect
            {...setup.roles(values?.roles, onChange, root?.role)}
          />
        </ItemBox>
        {values?.category === "Add" && (
          <ItemBox>
            <Controls.SearchSelect
              {...setup.add_for(values?.add_for, onChange)}
            />
            {values?.add_for === "Store" && (
              <Controls.SearchSelect
                {...setup.StoreId(values?.StoreId, setV, stores)}
              />
            )}
            {values?.add_for === "Product" && (
              <Controls.SearchSelect
                {...setup.ProductBoxId(values?.ProductBoxId, setV, products)}
              />
            )}
            {values?.add_for === "Cat01" && (
              <Controls.SearchSelect
                {...setup.Category01Id(values?.Category01Id, setV, cat01s)}
              />
            )}
            {values?.add_for === "Cat02" && (
              <Controls.SearchSelect
                {...setup.Category02Id(values?.Category02Id, setV, cat02s)}
              />
            )}
            {values?.add_for === "Cat03" && (
              <Controls.SearchSelect
                {...setup.Category03Id(values?.Category03Id, setV, cat03s)}
              />
            )}
          </ItemBox>
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const ItemBox = ({ children }) => (
  <Box display="flex" justifyContent="space-around" alignItems="center">
    {children}
  </Box>
);
const initData = (v) => ({
  name: v?.name ? v?.name : "",
  description: v?.description ? v?.description : "",
  photo: v?.photo ? v?.photo : "",
  category: v?.category ? v?.category : "",
  roles: v?.roles ? v?.roles : "",
  add_for: v?.add_for ? v?.add_for : "None",
  enabled: v?.enabled ? v?.enabled : false,
  Category01Id: v?.Category01Id ? v?.Category01Id : null,
  Category02Id: v?.Category02Id ? v?.Category02Id : null,
  Category03Id: v?.Category03Id ? v?.Category03Id : null,
  ProductBoxId: v?.ProductBoxId ? v?.ProductBoxId : null,
  StoreId: v?.StoreId ? v?.StoreId : null,
});

const setup_status = (v) =>
  !v?.name ||
  !v.description ||
  !v?.name ||
  !v.description ||
  !v?.category ||
  !v?.roles;

const select_setup02 = (v, setV, name, label, options) => ({
  name,
  label,
  value: v ? v : "",
  onChange: ({ target: T }) =>
    setV((V1) => ({
      ...V1,
      StoreId: T.name === "StoreId" ? T.value : null,
      ProductBoxId: T.name === "ProductBoxId" ? T.value : null,
      Category01Id: T.name === "Category01Id" ? T.value : null,
      Category02Id: T.name === "Category02Id" ? T.value : null,
      Category03Id: T.name === "Category03Id" ? T.value : null,
    })),
  options,
});

const setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", `Name`),
  description: (v, onChange) =>
    Hoc.setupLongText(v, onChange, "description", `Description`),
  photo: (v, onChange) => Hoc.setupFile(v, onChange, "photo"),
  StoreId: (v, setV, opt) =>
    select_setup02(v, setV, "StoreId", "Select Store", TOL(opt)),
  ProductBoxId: (v, setV, opt) =>
    select_setup02(v, setV, "ProductBoxId", "Select Product", TOL(opt)),
  Category01Id: (v, setV, opt) =>
    select_setup02(v, setV, "Category01Id", "Select Main Category", TOL(opt)),
  Category02Id: (v, setV, opt) =>
    select_setup02(v, setV, "Category02Id", "Select Sub Category", TOL(opt)),
  Category03Id: (v, setV, opt) =>
    select_setup02(v, setV, "Category03Id", "Select Last Category", TOL(opt)),
  category: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "category", "Select Category", sel_cat),
  roles: (v, onChange, role) =>
    Hoc.setupSelect(v, onChange, "roles", "Who can see this", sel_role(role)),
  add_for: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "add_for", "Add For", sel_add),
  enabled: (checked, onChange) =>
    Hoc.setupSwitch(checked, onChange, "enabled", "Enabled"),
};

const TOL = (v) =>
  v?.map((v2) => ({ id: v2.id, title: `${v2.name} (${v2.id})` }));

const TOL02 = (id, title) => ({ id, title });

const sel_add = [
  TOL02("None", "For Site"),
  TOL02("Store", "For Store"),
  TOL02("Product", "For Product"),
  TOL02("Cat01", "For Main Category"),
  TOL02("Cat02", "For Sub Category"),
  TOL02("Cat03", "For Final Category"),
];

const sel_role = (role) => {
  const GNC = [TOL02("CUS", "Customers Only")];
  const GNC01 = ["Store_Employee"];
  const STR = [
    ...GNC,
    TOL02("STR", "Store People Only"),
    TOL02("STR_CUS", "Store People & Customers"),
  ];
  const STR01 = ["Store_Manager", "Company_Admin"];
  const CMP = [
    ...STR,
    TOL02("CMP", "Company People Only"),
    TOL02("CMP_STR", "Company & Store People"),
    TOL02("CMP_STR_CUS", "Everyone"),
  ];
  const CMP01 = ["Company_Manager"];
  const CKR01 = (v) => v.includes(role);

  if (CKR01(GNC01)) return GNC;
  else if (CKR01(STR01)) return STR;
  else if (CKR01(CMP01)) return CMP;
  else return [];
};

const sel_cat = [
  { id: "News", title: "News" },
  { id: "Notice", title: "Notice" },
  { id: "Add", title: "Advertisement" },
];

export default NoticeForm;
