import moment from "moment";
import React from "react";
import Custom from "../../../comps/Custom";

const AddAbout = () => {
  const formData = Custom.useForm.useForm(initData());

  return <Custom.Base.CouponForm title="Create a Coupon" formData={formData} />;
};

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  ratio: v?.ratio ? v?.ratio : "",
  description: v?.description ? v?.description : "",
  category: "For All",
  start_date: addDate(1),
  end_date: addDate(10),
  Category03Id: null,
  StoreId: null,
  ProductBoxId: null,
});

const addDate = (v) => moment().add(v, "quarter").format("YYYY-MM-DD");

export default AddAbout;
