import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import Action from "../../../../actions";
import Hoc from "../../../Hoc";

const AddtionalViewVariant = ({ slug }) => {
  const Act = Action.product.box.random_items;
  const dispatch = useDispatch();
  const random = useSelector(({ product: p }) => p.box.random.data);

  useEffect(() => {
    dispatch(Act(`search=${slug}`));
  }, [dispatch, Act, slug]);

  return (
    <>
      <Grid container>
        <Hoc.Home.ProductCard box item={random} size="sm" />
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Typography
          color="textSecondary"
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(Act(`search=${slug}`))}
        >
          Refresh
        </Typography>
      </Box>
    </>
  );
};

export default AddtionalViewVariant;
