const types = {
  //
  PAY_LIST_REQUEST: "PAY_LIST_REQUEST",
  PAY_LIST_SUCCESS: "PAY_LIST_SUCCESS",
  PAY_LIST_FAIL: "PAY_LIST_FAIL",
  //
  PAY_VIEW_REQUEST: "PAY_VIEW_REQUEST",
  PAY_VIEW_SUCCESS: "PAY_VIEW_SUCCESS",
  PAY_VIEW_FAIL: "PAY_VIEW_FAIL",
  //
  PAY_METHOD_REQUEST: "PAY_METHOD_REQUEST",
  PAY_METHOD_SUCCESS: "PAY_METHOD_SUCCESS",
  PAY_METHOD_FAIL: "PAY_METHOD_FAIL",
  //
  PAY_ADD_REQUEST: "PAY_ADD_REQUEST",
  PAY_ADD_SUCCESS: "PAY_ADD_SUCCESS",
  PAY_ADD_FAIL: "PAY_ADD_FAIL",
  //
  PAY_EDIT_REQUEST: "PAY_EDIT_REQUEST",
  PAY_EDIT_SUCCESS: "PAY_EDIT_SUCCESS",
  PAY_EDIT_FAIL: "PAY_EDIT_FAIL",
  //
  PAY_DELETE_REQUEST: "PAY_DELETE_REQUEST",
  PAY_DELETE_SUCCESS: "PAY_DELETE_SUCCESS",
  PAY_DELETE_FAIL: "PAY_DELETE_FAIL",
  //
  PAY_STS_REQUEST: "PAY_STS_REQUEST",
  PAY_STS_SUCCESS: "PAY_STS_SUCCESS",
  PAY_STS_FAIL: "PAY_STS_FAIL",
  //
  PAY_MTLIST_REQUEST: "PAY_MTLIST_REQUEST",
  PAY_MTLIST_SUCCESS: "PAY_MTLIST_SUCCESS",
  PAY_MTLIST_FAIL: "PAY_MTLIST_FAIL",
  //
  PAY_ED_STS_REQUEST: "PAY_ED_STS_REQUEST",
  PAY_ED_STS_SUCCESS: "PAY_ED_STS_SUCCESS",
  PAY_ED_STS_FAIL: "PAY_ED_STS_FAIL",
  //
  SEND_BANK_INFO_REQUEST: "SEND_BANK_INFO_REQUEST",
  SEND_BANK_INFO_SUCCESS: "SEND_BANK_INFO_SUCCESS",
  SEND_BANK_INFO_FAIL: "SEND_BANK_INFO_FAIL",
  //
  SEND_MOBILE_PAY_REQUEST: "SEND_MOBILE_PAY_REQUEST",
  SEND_MOBILE_PAY_SUCCESS: "SEND_MOBILE_PAY_SUCCESS",
  SEND_MOBILE_PAY_FAIL: "SEND_MOBILE_PAY_FAIL",
  //
  VIEW_QUEUES_REQUEST: "VIEW_QUEUES_REQUEST",
  VIEW_QUEUES_SUCCESS: "VIEW_QUEUES_SUCCESS",
  VIEW_QUEUES_FAIL: "VIEW_QUEUES_FAIL",
};

export default types;
