const types = {
  //
  NOTIF_LIST_REQUEST: "NOTIF_LIST_REQUEST",
  NOTIF_LIST_SUCCESS: "NOTIF_LIST_SUCCESS",
  NOTIF_LIST_FAIL: "NOTIF_LIST_FAIL",
  //
  NOTIF_ADD_REQUEST: "NOTIF_ADD_REQUEST",
  NOTIF_ADD_SUCCESS: "NOTIF_ADD_SUCCESS",
  NOTIF_ADD_FAIL: "NOTIF_ADD_FAIL",
  //
  NOTIF_EDIT_REQUEST: "NOTIF_EDIT_REQUEST",
  NOTIF_EDIT_SUCCESS: "NOTIF_EDIT_SUCCESS",
  NOTIF_EDIT_FAIL: "NOTIF_EDIT_FAIL",
  //
  NOTIF_RD_ALL_REQUEST: "NOTIF_RD_ALL_REQUEST",
  NOTIF_RD_ALL_SUCCESS: "NOTIF_RD_ALL_SUCCESS",
  NOTIF_RD_ALL_FAIL: "NOTIF_RD_ALL_FAIL",
  //
  NOTIF_DEL_ALL_REQUEST: "NOTIF_DEL_ALL_REQUEST",
  NOTIF_DEL_ALL_SUCCESS: "NOTIF_DEL_ALL_SUCCESS",
  NOTIF_DEL_ALL_FAIL: "NOTIF_DEL_ALL_FAIL",
  //
  NOTIF_DELETE_REQUEST: "NOTIF_DELETE_REQUEST",
  NOTIF_DELETE_SUCCESS: "NOTIF_DELETE_SUCCESS",
  NOTIF_DELETE_FAIL: "NOTIF_DELETE_FAIL",
  //
  NOTIF_LEN_REQUEST: "NOTIF_LEN_REQUEST",
  NOTIF_LEN_SUCCESS: "NOTIF_LEN_SUCCESS",
  NOTIF_LEN_FAIL: "NOTIF_LEN_FAIL",
};

export default types;
