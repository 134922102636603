import SecureRoute from "../../../../comps/layout/SecureRoute";
import { Route } from "react-router-dom";
import add from "./add";
import edit from "./edit";
import view from "./view";
import view_all from "./view_all";
import public_view from "./public_view";

const Expo = {
  add: <SecureRoute grant="C1" path="/add-terms" component={add} />,
  edit: <SecureRoute grant="C1" path="/edit-terms/:id" component={edit} />,
  view: <SecureRoute grant="C1" path="/view-terms/:id" component={view} />,
  view_all: (
    <SecureRoute grant="C1" path="/view-all-terms" component={view_all} />
  ),
  public_view: (
    <Route exact path="/terms-and-conditions" component={public_view} />
  ),
};

export default Expo;
