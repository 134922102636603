import * as notice from "./notice";
import * as footer from "./footer";
import * as currency from "./currency";
import * as store_currency from "./store_currency";
import * as sent_mail from "./sent-mail";
import * as oop_msg from "./oop_msg";
import * as notif from "./notification";

const Expo = {
  notice,
  notif,
  footer,
  currency,
  store_currency,
  sent_mail,
  oop_msg,
};

export default Expo;
