const types = {
  //
  STORE_VIEW_ALL_REQUEST: "STORE_VIEW_ALL_REQUEST",
  STORE_VIEW_ALL_SUCCESS: "STORE_VIEW_ALL_SUCCESS",
  STORE_VIEW_ALL_FAIL: "STORE_VIEW_ALL_FAIL",
  //
  STOCK_VIEW_ALL_REQUEST: "STOCK_VIEW_ALL_REQUEST",
  STOCK_VIEW_ALL_SUCCESS: "STOCK_VIEW_ALL_SUCCESS",
  STOCK_VIEW_ALL_FAIL: "STOCK_VIEW_ALL_FAIL",
  //
  STORE_VIEW_REQUEST: "STORE_VIEW_REQUEST",
  STORE_VIEW_SUCCESS: "STORE_VIEW_SUCCESS",
  STORE_VIEW_FAIL: "STORE_VIEW_FAIL",
  //
  MY_STORE_REQUEST: "MY_STORE_REQUEST",
  MY_STORE_SUCCESS: "MY_STORE_SUCCESS",
  MY_STORE_FAIL: "MY_STORE_FAIL",
  //
  STORE_EDIT_REQUEST: "STORE_EDIT_REQUEST",
  STORE_EDIT_SUCCESS: "STORE_EDIT_SUCCESS",
  STORE_EDIT_FAIL: "STORE_EDIT_FAIL",
  //
  STORE_ADD_REQUEST: "STORE_ADD_REQUEST",
  STORE_ADD_SUCCESS: "STORE_ADD_SUCCESS",
  STORE_ADD_FAIL: "STORE_ADD_FAIL",
  //
  STORE_PRODUCT_ADD_REQUEST: "STORE_PRODUCT_ADD_REQUEST",
  STORE_PRODUCT_ADD_SUCCESS: "STORE_PRODUCT_ADD_SUCCESS",
  STORE_PRODUCT_ADD_FAIL: "STORE_PRODUCT_ADD_FAIL",
  //
  STORE_PRODUCT_VIEW_REQUEST: "STORE_PRODUCT_VIEW_REQUEST",
  STORE_PRODUCT_VIEW_SUCCESS: "STORE_PRODUCT_VIEW_SUCCESS",
  STORE_PRODUCT_VIEW_FAIL: "STORE_PRODUCT_VIEW_FAIL",
  //
  STORE_DELETE_REQUEST: "STORE_DELETE_REQUEST",
  STORE_DELETE_SUCCESS: "STORE_DELETE_SUCCESS",
  STORE_DELETE_FAIL: "STORE_DELETE_FAIL",
};

export default types;
