import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "..";

import { Grid } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import { useState } from "react";

const MobilePay = (rest) => {
  const Act = Action.base.payment.method;
  const GetCountry = Action.base.payment.GetCountry;
  const Act_send = Action.base.payment.send_mobile_payinfo;
  const Act_order_edit = Action.store.order.view_edit;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const root = useSelector(({ people: P }) => P.root.data);
  const { refresh, curr, ...props } = rest;
  const { country } = props;
  const { data } = useSelector(({ base: b }) => b.payment.method);
  const [running, setRunning] = useState(false);
  useEffect(() => {
    dispatch(Act("MobilePay"));
  }, [dispatch, Act, GetCountry]);

  const mobile = data?.BanksItems?.some((v) => v.name === country)
    ? data?.BanksItems?.find((v) => v.name === country)?.mobile
    : "NA";

  const sendBank = () => {
    setRunning(true);
    let info = (({ BanksItems, ...v }) => v)({
      ...props,
      ...data,
      mobile,
      amount: Hoc.toCurrency(curr?.currency, props.amount),
      currency: curr?.currency,
      email: root?.email,
    });

    dispatch(Act_send(info));
    dispatch(Act_order_edit(props?.order_id, { payment_method: "Becash" }));
    refresh();
    dispatch(
      Act_OOP(
        "We have sent payment details to your email. Please refere that emaiul to pay and place the order when you have completed the payment."
      )
    );
    setRunning(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <GridItem name="Name" value={data?.name || "Na"} />
        <GridItem name="Email" value={data?.email || "Na"} />
        <GridItem name="Order / Payment Reference" value={data?.ref || "Na"} />
        <GridItem name="Mobile" value={mobile} />
      </Grid>
      <Box style={{ margin: "15px 0" }} {...Hoc.flex("flex-end")}>
        {!!country ? (
          <Button
            inverted
            content="Send Details"
            color="purple"
            onClick={sendBank}
            disabled={!!running}
          />
        ) : (
          <Hoc.EmptyMessage content="Please Select Country" />
        )}
      </Box>
    </React.Fragment>
  );
};

const GridItem = (rest) => (
  <Grid item xs={6}>
    <Hoc.ShowItem {...rest} />
  </Grid>
);

export default MobilePay;
