import React from "react";
import { useDispatch } from "react-redux";
import Hoc from "..";
import Custom from "../../Custom";
import Action from "../../../actions";
import Controls from "../../Controls";
import { Grid, Box } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import { Button } from "semantic-ui-react";

const SubscriberForm = () => {
  const Act_subscribe = Action.base.subscribes;

  const Act_Opp = Action.base02.oop_msg;

  const initData = { name: "", email: "" };
  const formData = Custom.useForm.useForm(initData);

  const { values, setValues, onChange } = formData;

  const dispatch = useDispatch();

  const onSubmit = async () => {
    const status = await dispatch(Act_subscribe.view_add(values));
    setValues(initData);
    !!status && dispatch(Act_Opp.view_add("Subscribed Successfully."));
  };

  return (
    <Hoc.Dashboard.Container title={"Subscribe"}>
      <Grid style={{ justifyContent: "center" }} container spacing={2}>
        <Grid item style={{ textAlign: "center", marginTop: 8 }} xs={12} md={4}>
          <TextIcon
            text="Signup for our latest offers "
            icon={<MailOutline color="primary" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={2}>
          <GridInput {...setup.name(values.name, onChange)} />
          <GridInput {...setup.email(values.email, onChange)} />
          <Grid
            item
            xs={12}
            container
            component={Box}
            {...Hoc.flex("flex-end")}
          >
            <Button content="Submit" color="purple" onClick={onSubmit} />
          </Grid>
        </Grid>
      </Grid>
    </Hoc.Dashboard.Container>
  );
};

const GridInput = ({ ...rest }) => (
  <Grid item xs={12}>
    <Controls.Input {...rest} />
  </Grid>
);

const TextIcon = ({ text, icon }) => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <Box style={{ marginRight: 10 }}>{icon}</Box>
    <Box style={{ fontSize: 20 }}>{text}</Box>
  </Box>
);

const setup = {
  name: (v, onChange) => ({
    type: "name",
    name: "name",
    label: "Name",
    value: v ? v : "",
    onChange,
    required: true,
  }),

  email: (v, onChange) => ({
    type: "email",
    name: "email",
    label: "Email Address",
    value: v ? v : "",
    onChange,
    required: true,
  }),
};

export default SubscriberForm;
