const types = {
  //
  TERMS_VIEW_ALL_REQUEST: "TERMS_VIEW_ALL_REQUEST",
  TERMS_VIEW_ALL_SUCCESS: "TERMS_VIEW_ALL_SUCCESS",
  TERMS_VIEW_ALL_FAIL: "TERMS_VIEW_ALL_FAIL",
  //
  TERMS_VIEW_REQUEST: "TERMS_VIEW_REQUEST",
  TERMS_VIEW_SUCCESS: "TERMS_VIEW_SUCCESS",
  TERMS_VIEW_FAIL: "TERMS_VIEW_FAIL",
  //
  TERMS_ADD_REQUEST: "TERMS_ADD_REQUEST",
  TERMS_ADD_SUCCESS: "TERMS_ADD_SUCCESS",
  TERMS_ADD_FAIL: "TERMS_ADD_FAIL",
  //
  TERMS_EDIT_REQUEST: "TERMS_EDIT_REQUEST",
  TERMS_EDIT_SUCCESS: "TERMS_EDIT_SUCCESS",
  TERMS_EDIT_FAIL: "TERMS_EDIT_FAIL",
  //
  TERMS_DELETE_REQUEST: "TERMS_DELETE_REQUEST",
  TERMS_DELETE_SUCCESS: "TERMS_DELETE_SUCCESS",
  TERMS_DELETE_FAIL: "TERMS_DELETE_FAIL",
};

export default types;
