import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../../Controls";
import Hoc from "../../../Hoc";
import Action from "../../../../actions";
import Variant from "./Info/Variant";
import Desc from "./Info/Desc";
import Info from "./Info/Info";
import Photo from "./Info/Photo";
import { setup } from "./Info/utils";
import { useDispatch, useSelector } from "react-redux";

const ProductInfoAddForm = (props) => {
  const dispatch = useDispatch();
  const Act_product = Action.product.product;
  const { data } = props;

  const [task, setTask] = useState("variant");

  const [variant, setVariant] = useState("NA");
  const [editable, setEditable] = useState(false);
  const closeEditable = () => setEditable(false);
  const root = useSelector(({ people: p }) => p.root.data);
  const product = useSelector(({ product: p }) => p.product.view.data);

  useEffect(() => {
    dispatch(Act_product.view(data?.productID));
  }, [dispatch, Act_product, data]);

  const CKS = (v) => task === v;

  const isArr = Hoc.isArr;
  const BoxVariant = isArr(product?.Product_Boxes);
  const DescItems = BoxVariant?.map((v) => v.Descriptions).flat(Infinity);
  const PhotoItems = BoxVariant?.map((v) => v.Photos).flat(Infinity);
  const InfoItems = BoxVariant?.map((v) => v.Product_Infos).flat(Infinity);

  const refresh = () => dispatch(Act_product.view(data?.productID));
  return (
    <Box>
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item xs={12} sm={5}>
          <form>
            <Controls.SearchSelect {...setup.task(task, setTask)} />
          </form>
        </Grid>

        {task !== "variant" && !CKS("detail") && (
          <Grid item xs={12} sm={5}>
            <form>
              <Controls.SearchSelect
                {...setup.variant(
                  variant,
                  setVariant,
                  Hoc.isArr(product?.Product_Boxes).filter((v) =>
                    ["Company_Manager", "Company_Admin"].includes(root?.role)
                      ? true
                      : v.StoreId === root?.Store?.id
                  )
                )}
              />
            </form>
          </Grid>
        )}

        {task === "variant" && !!product?.title && (
          <Grid item xs={12} sm={5}>
            <h5>{product?.title}</h5>
          </Grid>
        )}
      </Grid>

      {CKS("desc") ? (
        <Desc
          refresh={refresh}
          editable={editable}
          setEditable={setEditable}
          closeEditable={closeEditable}
          ProductBoxId={variant}
          items={DescItems}
        />
      ) : CKS("photo") ? (
        <Photo
          refresh={refresh}
          editable={editable}
          closeEditable={closeEditable}
          ProductBoxId={variant}
          items={PhotoItems}
        />
      ) : CKS("info") ? (
        <Info
          refresh={refresh}
          editable={editable}
          setEditable={setEditable}
          closeEditable={closeEditable}
          ProductBoxId={variant}
          items={InfoItems}
        />
      ) : CKS("variant") ? (
        <Variant
          title={product?.title}
          refresh={refresh}
          editable={editable}
          closeEditable={closeEditable}
          ProductId={product?.id}
          StoreId={product?.StoreId}
          items={product?.Product_Boxes}
          root={root}
        />
      ) : null}
    </Box>
  );
};
export default ProductInfoAddForm;
