import Helper from "../helper";
import { Type } from "../../redux";
import axios from "axios";

const types = Type.store_type.track;

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRACK_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${Helper.URL}/trolly-tracks`, initData);

    dispatch({ type: types.TRACK_ADD_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRACK_ADD_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.TRACK_VIEW_REQUEST });
    const { data } = await axios.get(`${Helper.URL}/trolly-tracks/${id}`);
    dispatch({ type: types.TRACK_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRACK_VIEW_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRACK_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      `${Helper.URL}/trolly-tracks?${queryString || ""}`
    );

    dispatch({
      type: types.TRACK_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRACK_VIEW_ALL_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRACK_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(
      `${Helper.URL}/trolly-tracks/${id}`,
      initData
    );

    dispatch({ type: types.TRACK_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRACK_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRACK_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${Helper.URL}/trolly-tracks/${id}`);

    dispatch({ type: types.TRACK_DELETE_SUCCESS, payload: data?.result });

    dispatch({
      type: types.TRACK_VIEW_ALL_SUCCESS,
      payload: state()?.store?.track?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRACK_DELETE_FAIL);
  }
};
