import React from "react";
import Hoc from "../../../../comps/Hoc";
import Controls from "../../../../comps/Controls";
import { Message } from "semantic-ui-react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";

const ShowTrackDialog = (props) => {
  const { open, setOpen } = props;

  const onClose = () => setOpen({ status: false, name: null, tracks: [] });

  return (
    <Hoc.Dialog
      open={open?.status}
      onClose={onClose}
      title={open?.name}
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onClose}
        />
      }
    >
      {!!open?.tracks?.length ? (
        open?.tracks?.map((Tr) => (
          <Message key={Tr?.id} color={getClr(Tr?.status)}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                Package {Tr.status} At {moment(Tr.createdAt).calendar()} -{" "}
                {Tr.description}
              </Typography>
            </Box>
          </Message>
        ))
      ) : (
        <Message color="red" content="No Tracks Found" />
      )}
    </Hoc.Dialog>
  );
};

const getClr = (v) =>
  v === "Dispatched"
    ? "brown"
    : v === "Arrived"
    ? "olive"
    : v === "Left"
    ? "teal"
    : v === "Delivered"
    ? "purple"
    : "pink";

export default ShowTrackDialog;
