import React, { useEffect } from "react";
import Hoc from "../comps/Hoc";
import Action from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useLocation } from "react-router";

const QueueSystem = () => {
  const dispatch = useDispatch();
  const Act_queue = Action.base.payment.view_queues;
  const loc = useLocation();
  const data = useSelector(({ base: B }) => B.payment.view_queues.data);

  useEffect(() => {
    const PATH = loc?.pathname === "/queue-system";
    const queueInterval = setInterval(
      () => (PATH ? dispatch(Act_queue()) : null),
      1000
    );
    return () => clearInterval(queueInterval);
  }, [loc, dispatch, Act_queue]);

  const nav01 = {
    title: "Queues",
    actions: [{ name: "Refresh", onClick: () => dispatch(Act_queue()) }],
  };

  const queues = Hoc.isArr(data)?.filter((v) => !v?.is_empty);

  const GetCount = (job) =>
    queues?.reduce(
      (a, b) =>
        a + parseInt(!job ? b?.pending_size : CKI(b?.running_job) ? 1 : 0),
      0
    );

  const base = {
    QueueCount: queues?.length,
    RunningJobCount: GetCount(true),
    WaitingJobsCount: GetCount(false),
  };

  return (
    <Hoc.Dashboard.Container {...nav01}>
      {!!base.QueueCount && (
        <Segment style={{ width: "100%" }}>
          <Header content="Statistics" color="purple" textAlign="center" />
          <Box {...Hoc.flex("space-between")}>
            <ViewCount content={`Queues Count : ${base.QueueCount}`} />
            <ViewCount
              content={`Running Job Count : ${base.RunningJobCount}`}
            />
            <ViewCount
              content={`Waiting Jobs Count : ${base.WaitingJobsCount}`}
            />
          </Box>
        </Segment>
      )}
      {!!queues.length ? (
        queues.map((Q, idx) => (
          <Segment key={idx} style={{ width: "100%" }} color="purple">
            <Box {...Hoc.flex("space-between")} style={{ margin: "10px 0" }}>
              <BoxNM nm content={Q?.name} />
              <BoxNM content={Q.pending_size} />
            </Box>

            {CKI(Q?.running_job) && (
              <React.Fragment>
                <Divider />
                <Header content="Current Job" color="green" />
                <ViewJob content={Q?.running_job} />
              </React.Fragment>
            )}

            {CKID(Q?.pending_jobs) && (
              <React.Fragment>
                <Divider />
                <Header
                  content={`Waiting Jobs (${Q?.pending_jobs.length})`}
                  color="green"
                />
                {Hoc.isArr(Q?.pending_jobs).map((V1, idx) => (
                  <ViewJob key={idx} content={V1} count={idx + 1} />
                ))}
              </React.Fragment>
            )}
          </Segment>
        ))
      ) : (
        <Hoc.EmptyMessage content="No Actions running Now." />
      )}
    </Hoc.Dashboard.Container>
  );
};

const ViewCount = ({ content }) => (
  <Box>
    <Message>
      <Header color="purple" content={content} />
    </Message>
  </Box>
);

const BoxNM = ({ content, nm = false }) => (
  <Box>
    <Header
      {...(!!nm && { textAlign: "center" })}
      as={nm ? "h3" : "h5"}
      color={nm ? "purple" : "orange"}
      content={`${!nm && "Waitings Size : "}${content}`}
    />
  </Box>
);

const CKI = (V) => Object.entries(V).length !== 0;
const CKID = (V) => Object.entries(V).filter((v) => v[0] !== "0").length !== 0;

const ViewJob = ({ content, count }) => {
  const job = Object.entries(content);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!!count && (
          <React.Fragment>
            <Divider />
            <Header content={`Job Number ${count}`} color="purple" />
          </React.Fragment>
        )}
      </Grid>
      {Hoc.isArr(job).map((J, idx) => (
        <Grid item xs={12} sm={6} lg={4} key={idx}>
          <Hoc.ShowItem name={J[0]} value={J[1]} />
        </Grid>
      ))}
    </Grid>
  );
};

export default QueueSystem;
