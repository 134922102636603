import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, TableCell, TableRow } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../../../actions";
import Custom from "../../../../comps/Custom";
import Controls from "../../../../comps/Controls";
import Hoc from "../../../../comps/Hoc";

const ViewAll = () => {
  const Act_transaction = Action.base.transaction;
  const dispatch = useDispatch();

  const { data, error, loading } = useSelector(
    ({ base: B }) => B.transaction.view_all
  );

  const [item, setItem] = useState({ name: "status", value: "Paid" });

  const para01 = item.name !== "None" && `${item.name}=${item.value}`;

  useEffect(() => {
    dispatch(Act_transaction.view_all(para01));
  }, [dispatch, Act_transaction, para01]);

  const refresh = () => {
    dispatch(Act_transaction.view_all(para01));
  };

  const { TblContainer, finalRecords } = Custom.useTable(data, cells);

  const onIChange = ({ target: T }) => setItem({ ...item, [T.name]: T.value });

  const TOT = (name, to) => ({ name, to });
  const TOC = (name, onClick) => ({ name, onClick });

  const nav_01 = [
    TOT("Create New", "/transaction-add"),
    { name: "Refresh", onClick: () => refresh() },
  ];

  const t_actions = (v) => {
    const onDelete = () => {
      dispatch(Act_transaction.view_delete(v?.id));
      refresh();
    };

    const onStatus = (status) => {
      dispatch(Act_transaction.view_edit(v?.id, { status }));
      refresh();
    };

    let items = [
      TOT("View", `/transaction-view/${v.id}`),
      TOT("Edit", `/transaction-edit/${v.id}`),
      TOC("Delete", () => onDelete()),
    ];

    const CKS = (v1) => v1 === v?.status;
    if (CKS("Paid")) {
      items = [...items, TOT("Refund", `/transaction-add?refund=${v.id}`)];
    } else if (CKS("Pending")) {
      items = [...items, TOC("Mark as Paid", () => onStatus("Paid"))];
    }

    return items;
  };

  return (
    <Hoc.Dashboard.Container title="Transactions" actions={nav_01}>
      <Hoc.UseData data error={error} loading={loading}>
        <TwoItem>
          <ItemSel {...setup.name(item.name, onIChange)} />
          {item.name !== "None" && (
            <ItemSel {...setup.value(item, onIChange)} />
          )}
        </TwoItem>

        {!!finalRecords().length ? (
          <TblContainer paginate>
            {finalRecords().map((v) => (
              <TableRow key={v.id}>
                <TableCell>{v.id}</TableCell>
                <TableCell>
                  <Hoc.ToCurrency currency={v?.Currency} price={v.amount} />
                </TableCell>

                <TableCell>{v.method}</TableCell>
                <TableCell>{v.paying_to}</TableCell>
                <TableCell>{v.status}</TableCell>
                <TableCell>{ToDate(v.createdAt)}</TableCell>
                <TableCell>
                  <Hoc.Product.MenuButton actions={t_actions(v)} />
                </TableCell>
              </TableRow>
            ))}
          </TblContainer>
        ) : (
          <Hoc.EmptyMessage />
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const ItemSel = ({ ...rest }) => (
  <Box style={{ width: "150px" }}>
    <Controls.SearchSelect {...rest} />
  </Box>
);

const TOT01 = (id, title) => ({ id, title });

const setup_01 = (v, onChange, name, label, options) => ({
  name,
  label,
  value: v ? v : "",
  onChange,
  options,
});

const setup = {
  name: (v, onChange) => setup_01(v, onChange, "name", "Select Name", Opt01),
  value: (v, onChange) =>
    setup_01(v.value, onChange, "value", "Select Value", Val01(v.name)),
};

const Val01 = (v) => {
  if (v === "currency") {
    return [];
  } else if (v === "method") {
    return Opt02;
  } else if (v === "status") {
    return Opt03;
  } else if (v === "paying_to") {
    return Opt04;
  }
};
const Opt01 = [
  TOT01("None", "Select All"),
  TOT01("currency", "Currency"),
  TOT01("method", "Pay Method"),
  TOT01("status", "Status"),
  TOT01("paying_to", "Paying To"),
];

const Opt02 = [
  TOT01("Unknown", "Unknown"),
  TOT01("PayPal", "PayPal"),
  TOT01("Stripe", "Stripe"),
  TOT01("RazorPay", "RazorPay"),
  TOT01("MobilePay", "MobilePay"),
  TOT01("Bank", "Bank"),
];

const Opt03 = [
  TOT01("UnPaid", "UnPaid"),
  TOT01("Pending", "Pending"),
  TOT01("Paid", "Paid"),
  TOT01("Refund", "Refund"),
];

const Opt04 = [
  TOT01("Order", "Order"),
  TOT01("Company", "Company"),
  TOT01("Store", "Store"),
];

const TwoItem = ({ children }) => (
  <Box style={{ width: "50%" }} {...Hoc.flex("flex-end")}>
    <Box style={{ width: "100%" }} {...Hoc.flex("space-between")}>
      {children}
    </Box>
  </Box>
);

const ToDate = (v) => moment(v).calendar();

const TOL = (name, label) => ({ name, label });

const cells = [
  TOL("id", "ID"),
  TOL("amount", "Amount"),
  TOL("method", "Method"),
  TOL("paying_to", "Paying To"),
  TOL("status", "Status"),
  TOL("createdAt", "Paid At"),
  TOL("07", "Action"),
];

export default ViewAll;
