import React from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const Act = Action.product.report;
  const view_all = useSelector(({ product: v }) => v.report.view_all);

  return (
    <Custom.Base.CustomerTokens
      Act={Act}
      view_all={view_all}
      title="Support Ticket For Complaint"
      to="complain"
    />
  );
};

export default ViewAll;
