import React, { useEffect } from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ViewAll = () => {
  const dispatch = useDispatch();
  const P = useParams();
  const Act_variant = Action.product.box.view;
  const Act_coupon = Action.product.coupon.view_all;

  const box = useSelector(({ product: P }) => P.box.view.data);
  const coupons = useSelector(({ product: P }) => P.coupon.view_all.data);

  const PB01 = box?.id ? `ProductBoxId=${box?.id}&` : "";
  const PB02 = box?.Store?.id ? `StoreId=${box?.Store?.id}&` : "";
  const PB03 = box?.Product?.Category03Id
    ? `Category03Id=${box?.Product?.Category03Id}`
    : "";

  const qryStr = `${PB01}${PB02}${PB03}`;

  useEffect(() => {
    dispatch(Act_coupon(qryStr));
    dispatch(Act_variant(P.id));
  }, [dispatch, Act_variant, Act_coupon, P, qryStr]);

  const refresh = () => {
    dispatch(Act_coupon(qryStr));
    dispatch(Act_variant(P.id));
  };

  return <Custom.Product.ViewVariant refresh={refresh} coupons={coupons} />;
};

export default ViewAll;
