import React, { useState } from "react";
import { Table, TableHead, TableRow } from "@material-ui/core";
import { TableCell, makeStyles } from "@material-ui/core";
import { TablePagination, TableSortLabel } from "@material-ui/core";
import { TableBody, TableContainer } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200,
    overflow: "scroll",
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  paginate: {
    marginLeft: "auto",
  },
}));

export default function useTable(
  records,
  headCells = [],
  filterFn = { fn: (f) => f }
) {
  const classes = useStyles();

  const pages = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = ({ children, paginate }) => (
    <TableContainer>
      <Table className={classes.table}>
        <TblHead />
        <TableBody>{children}</TableBody>
      </Table>
      {!!paginate && <TblPagination />}
    </TableContainer>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell, idx) => (
            <TableCell
              key={idx}
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              {headCell?.disableSorting ? (
                headCell?.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell?.id}
                  direction={orderBy === headCell?.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell?.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component={"div"}
      className={classes.paginate}
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records?.length || 0}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = Array.isArray(array)
      ? array?.map((el, index) => [el, index])
      : [];
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const finalRecords = () => {
    return stableSort(
      filterFn?.fn(records),
      getComparator(order, orderBy)
    )?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    finalRecords,
    TableRow,
    TableCell: ({ content, children }) => (
      <TableCell>
        {content}
        {children}
        {!content && !children && "-"}
      </TableCell>
    ),
  };
}
