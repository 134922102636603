import { Grid, Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Hoc from "../../Hoc";
import { makeStyles } from "@material-ui/core/styles";

const ProfileView = () => {
  const { loading, error } = useSelector(({ people: p }) => p.root);
  const CSS = useCSSItem();

  return (
    <Hoc.UseData error={error} loading={loading}>
      <Hoc.Page>
        <Grid container sm={12} xs={12} md={12} xl={12}>
          <Grid sm={6} xs={6} md={6} xl={6}>
            <Box
              component="img"
              src="/photos/pic-02.jpg"
              className={CSS.photo}
            />
          </Grid>
          <Grid sm={6} xs={6} md={6} xl={6}>
            <Grid container sm={12} xs={12} md={12} xl={12}>
              <Grid sm={6} xs={6} md={6} xl={6}>
                <Box
                  component="img"
                  src="/photos/pic-02.jpg"
                  className={CSS.photo1}
                />
              </Grid>
              <Grid sm={6} xs={6} md={6} xl={6}>
                <Box
                  component="img"
                  src="/photos/pic-02.jpg"
                  className={CSS.photo1}
                />
              </Grid>
            </Grid>
            <Grid container sm={12} xs={12} md={12} xl={12}>
              <Grid sm={12} xs={12} md={12} xl={12}>
                <Box
                  component="img"
                  src="/photos/pic-02.jpg"
                  className={CSS.photo2}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hoc.Page>
    </Hoc.UseData>
  );
};

const useCSSItem = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  photo: {
    borderRadius: theme.spacing(0.3),
    backgroundColor: "#fff",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: "100%",
    height: "auto",
  },
  photo1: {
    borderRadius: theme.spacing(0.3),
    backgroundColor: "#fff",
    padding: theme.spacing(1),
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: theme.spacing(80),
    width: "100%",
    height: "auto",
  },
  photo2: {
    borderRadius: theme.spacing(0.3),
    backgroundColor: "#fff",
    padding: theme.spacing(1),
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: theme.spacing(80),
    width: "100%",
    height: "50%",
  },

  desc: {
    fontSize: 13,
    fontWeight: 100,
    maxWidth: theme.spacing(35),
  },
}));

export default ProfileView;
