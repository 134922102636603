import Controls from "../../../../Controls";
import { Container } from "@material-ui/core";
import { Message } from "semantic-ui-react";
import Hoc from "../../../../Hoc";

export const inputProps = (min = 0, step = 1, max) => ({
  min,
  step,
  ...(!!max && { max }),
});

const getTotalPrice = (v) =>
  PF(PF(PF(v?.B_price) * PF(v?.total_units)) + PF(v?.BT_shipping_price));

const acceptAll = "image/*,video/*,audio/*,application/pdf";
const fileTypes = ["None", "PDF", "Photo", "Video", "Other"];

export const setup = {
  // Box Item
  expires: (v, onChange) =>
    Hoc.setupText(v, onChange, "expires", "Choose Expire Date", "date"),
  photo: (v, onChange) => Hoc.setupFile(v, onChange, "photo"),
  file_type: (v, onChange) => ({
    ...Hoc.setupSelect(v, onChange, "file_type", "File Type", fileTypes),
    noLabel: true,
  }),
  photo02: (v, onChange) => Hoc.setupFile(v, onChange, "photo"),
  preview: (v, onChange) =>
    Hoc.setupFile(v, onChange, "preview", "Preview", acceptAll),
  file: (v, onChange) => Hoc.setupFile(v, onChange, "file", "File", acceptAll),
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Name"),
  InpNumber: (v, name, label, onChange) => ({
    ...Hoc.setupText(v, onChange, name, label, "number"),
    inputProps: inputProps(),
  }),
  InpRate: (v, name, label, onChange) => ({
    ...Hoc.setupText(v, onChange, name, label, "number"),
    inputProps: inputProps(0, 1, 100),
  }),
  InpFloat: (v, name, label, onChange) => ({
    ...Hoc.setupText(v, onChange, name, label, "number"),
    inputProps: inputProps(0, 0.0001),
  }),
  Inp_total_price: (v, p) => ({
    type: "number",
    name:
      p === "TP"
        ? "BT_total_price"
        : p === "UP"
        ? "unit_price"
        : p === "EP"
        ? "estimate"
        : "none",
    label:
      p === "TP"
        ? "Total Price"
        : p === "UP"
        ? "Unit Price"
        : p === "EP"
        ? "Estimation"
        : "Price",
    value:
      p === "TP"
        ? getTotalPrice(v)
        : p === "UP"
        ? PF(
            PF(getTotalPrice(v) + PF(v?.BT_vat_price)) / PF(v?.total_units)
          ).toFixed(2)
        : p === "EP" &&
          PF(
            PF(PF(v?.ST_vat_rate) * PF(PF(v?.S_price) / 100)) +
              PF(v?.S_price) +
              PF(v?.NT_shipping_price)
          ).toFixed(2),
    onChange: (f) => f,
    disabled: true,
  }),
  // All Other
  title: (v, onChange) => Hoc.setupText(v, onChange, "title", "Title"),
  info: (v, onChange) =>
    Hoc.setupLongText(
      v,
      onChange,
      "info",
      "Description (Double Comma to seperate Ex: One,,Two,,Three)"
    ),
  text: (v, onChange) => Hoc.setupLongText(v, onChange, "text", "Description"),
  description: (v, onChange) =>
    Hoc.setupLongText(v, onChange, "description", "Description"),

  destination: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "destination", "Set Deliverability", [
      { id: "Local", title: "Only Local" },
      { id: "Global", title: "Only Global" },
      { id: "Local_National", title: "Local & National" },
      { id: "Local_National_Global", title: "Everywhere" },
    ]),
  collectable: (v, onChange) =>
    Hoc.setupSwitch(v, onChange, "collectable", "Collectable"),
  subscription: (v, onChange) =>
    Hoc.setupSwitch(v, onChange, "subscription", "Subscription"),
  publish: (v, onChange) =>
    Hoc.setupSwitch(v, onChange, "publish", "Publish Direclty"),

  variant: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV(T.value),
      "variant",
      "Select a Product Variant",
      [
        { id: "NA", title: "None" },
        ...opt.map((v) => ({ id: v.id, title: v.name })),
      ]
    ),
  StoreId: (v, OC, opt, root) =>
    Hoc.setupSelect(
      v,
      OC,
      "StoreId",
      "Select Store",
      Hoc.isArr(opt)
        ?.filter((v) =>
          !["Company_Manager", "Company_Admin"].includes(root?.role)
            ? root?.Store?.id === v?.id
            : true
        )
        ?.map((v) => ({ id: v.id, title: `${v.name} (${v.id})` }))
    ),
  CurrencyId: (v, OC, opt) =>
    Hoc.setupSelect(
      v,
      OC,
      "CurrencyId",
      "Select Currency",
      opt.map((v) => ({ id: v.id, title: `${v.name} (${v.currency})` }))
    ),
  task: (v, setV) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV(T.value),
      "task",
      "Select a Product Task",
      [
        { id: "variant", title: "Product Variants" },
        { id: "desc", title: "Descriptions" },
        { id: "photo", title: "Photos" },
        { id: "info", title: "Features" },
      ]
    ),
};

export const variant_status = (v) => {
  const PI = (v) => parseInt(v);
  let status = !v?.name || !v?.expires || !v?.photo || !v?.StoreId;
  status = status || !v?.height || !v?.width || !v?.length || !v?.CurrencyId;
  status = status || !v?.weight || !v?.total_units || !v?.quantity;
  status = status || PI(v?.total_units) < PI(v?.quantity);

  return status;
};

export const initData = (v) => ({
  StoreId: v?.StoreId ? v?.StoreId : null,
  CurrencyId: v?.CurrencyId ? v?.CurrencyId : null,
  name: v?.name ? v?.name : null,
  expires: v?.expires ? v?.expires : null,
  height: v?.height ? v?.height : null,
  width: v?.width ? v?.width : null,
  length: v?.length ? v?.length : null,
  weight: v?.weight ? v?.weight : null,
  // Buying Price
  B_price: v?.B_price ? v?.B_price : null,
  total_units: v?.total_units ? v?.total_units : null,
  BT_shipping_price: v?.BT_shipping_price ? v?.BT_shipping_price : null,
  BT_total_price: v?.BT_total_price ? v?.BT_total_price : null,
  BT_vat_price: v?.BT_vat_price ? v?.BT_vat_price : null,
  // Selling Price
  S_price: v?.S_price ? v?.S_price : null,
  NT_shipping_price: v?.NT_shipping_price ? v?.NT_shipping_price : null,
  ST_vat_rate: v?.ST_vat_rate ? v?.ST_vat_rate : null,
  quantity: v?.quantity ? v?.quantity : null,
  //
  collectable: v?.collectable ? v?.collectable : false,
  subscription: v?.subscription ? v?.subscription : false,
  publish: v?.publish ? v?.publish : false,
  destination: v?.destination ? v?.destination : null,
  photo: v?.photo ? v?.photo : null,
  file_type: v?.file_type ? v?.file_type : "None",
  file: v?.file ? v?.file : null,
  preview: v?.preview ? v?.preview : null,
});

const PF = (v = 0) => parseFloat(v);

export const cells = [
  { id: "Store_id", label: "Store ID" },
  { id: "Name", label: "Name" },
  { id: "Price", label: "Price" },
  { id: "total_units", label: "Quantity" },
  { id: "subscription", label: "Subscription" },
  { id: "publish", label: "Published" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export const xs_02 = {
  item: true,
  xs: 2,
  component: Controls.Input,
};

export const xsDP_05 = {
  style: { marginLeft: "auto" },
  component: Controls.DatePicker,
};

export const xs_05 = {
  item: true,
  xs: 5,
  component: Controls.Input,
};

export const xs_12 = {
  item: true,
  xs: 10,
  component: Controls.Input,
};

export const NoRecords = () => (
  <Container maxWidth="sm">
    <Message color="yellow">Empty Records found</Message>
  </Container>
);
