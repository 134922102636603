import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "semantic-ui-react";
import Action from "../../../../actions";
import Hoc from "../../../Hoc";

const TermView = (props) => {
  const Act_term = Action.base.terms.view;
  const dispatch = useDispatch();
  const { id } = props;

  const { data } = useSelector(({ base: B }) => B.terms.view);

  useEffect(() => {
    dispatch(Act_term(id));
  }, [dispatch, Act_term, id]);

  const actions = [{ name: "Back", goBack: true }];
  const title = `${data?.name} (Level - ${data?.level})`;

  return (
    <Hoc.Dashboard.Container title={title} actions={actions}>
      <Header textAlign="center" as="h5" color="teal">
        {data?.description}
      </Header>
    </Hoc.Dashboard.Container>
  );
};

export default TermView;
