import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import action from "../../../actions";
import Controls from "../../../comps/Controls";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";

const PaymentForm = ({ id }) => {
  const H = useHistory();
  const act_footer = action.base.payment;
  const act_OOP = action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const { Form, useForm } = Custom.useForm;
  const formData = useForm(initData());
  const { values, setValues, onChange, resetForm } = formData;
  const title = `${!id ? "Update" : "Create"} Payment Detail`;

  const onSubmit = async () => {
    let status;

    if (!!id) {
      status = await dispatch(act_footer.view_edit(id, values));
    } else {
      status = await dispatch(act_footer.view_add(values));
    }

    if (!!status) {
      H.goBack();
      act_OOP(`Details ${!id ? "Updated" : "Created"} Successfully.`);
    }
  };

  const CKC = (v) => v === values?.category;

  useEffect(() => {
    const SetVV = async () =>
      setValues(initData(await dispatch(act_footer.view(id))));
    !!id && SetVV();
  }, [setValues, dispatch, act_footer, id]);

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Box style={{ width: "100%" }} {...Hoc.flex()}>
          <Controls.Switch {...setup.enabled(values?.enabled, onChange)} />
        </Box>
        <Controls.SearchSelect
          {...setup.category(values?.category, onChange)}
        />

        {CKC("PayPal") ? (
          <React.Fragment>
            <Controls.Input {...setup.email(values?.email, onChange)} />
            <Controls.Input {...setup.currency(values?.currency, onChange)} />
            <Controls.Input {...setup.api(values?.api, onChange)} />

            <Controls.Input {...setup.client_id(values?.client_id, onChange)} />
            <Controls.Input {...setup.secret(values?.secret, onChange)} />
          </React.Fragment>
        ) : CKC("Stripe") ? (
          <React.Fragment>
            <Controls.Input {...setup.email(values?.email, onChange)} />
            <Controls.Input {...setup.currency(values?.currency, onChange)} />
            <Controls.Input {...setup.api(values?.api, onChange)} />

            <Controls.Input
              {...setup.publish_key(values?.publish_key, onChange)}
            />
            <Controls.Input
              {...setup.secret_key(values?.secret_key, onChange)}
            />
          </React.Fragment>
        ) : CKC("RazorPay") ? (
          <React.Fragment>
            <Controls.Input {...setup.name(values?.name, onChange)} />
            <Controls.Input {...setup.email(values?.email, onChange)} />
            <Controls.Input {...setup.currency(values?.currency, onChange)} />
            <Controls.Input {...setup.api(values?.api, onChange)} />

            <Controls.Input {...setup.key(values?.key, onChange)} />
            <Controls.Input {...setup.secret(values?.secret, onChange)} />
          </React.Fragment>
        ) : CKC("MobilePay") ? (
          <React.Fragment>
            <Controls.Input {...setup.name(values?.name, onChange)} />
            <Controls.Input {...setup.email(values?.email, onChange)} />
            <Controls.Input {...setup.ref(values?.ref, onChange)} />
          </React.Fragment>
        ) : CKC("Bank") ? (
          <React.Fragment>
            <Controls.Input {...setup.name(values?.name, onChange)} />
            <Controls.Input
              {...setup.Account_Type(values?.Account_Type, onChange)}
            />
            <Controls.Input
              {...setup.Account_Holder(values?.Account_Holder, onChange)}
            />
            <Controls.Input
              {...setup.Account_Number(values?.Account_Number, onChange)}
            />
            <Controls.Input
              {...setup.Institution_Name(values?.Institution_Name, onChange)}
            />
            <Controls.Input
              {...setup.Routing_Number(values?.Routing_Number, onChange)}
            />
            <Controls.Input
              {...setup.Transit_Number(values?.Transit_Number, onChange)}
            />
            <Controls.Input {...setup.Bank_Code(values?.Bank_Code, onChange)} />
            <Controls.Input {...setup.Sort_Code(values?.Sort_Code, onChange)} />
            <Controls.Input {...setup.BIC(values?.BIC, onChange)} />
            <Controls.Input {...setup.BAB_Code(values?.BAB_Code, onChange)} />
            <Controls.Input {...setup.IBAN(values?.IBAN, onChange)} />
          </React.Fragment>
        ) : (
          <Hoc.EmptyMessage content="Please select a category." />
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) => !v?.category;

const sel01 = ["PayPal", "Stripe", "RazorPay", "MobilePay", "Bank"];

const toSel = (v) =>
  v.map((id) => ({
    id,
    title: id,
  }));

const setup = {
  category: (v, OC) =>
    Hoc.setupSelect(v, OC, "category", "Category", toSel(sel01)),
  enabled: (v, OC) => Hoc.setupSwitch(v, OC, "enabled", "Enable the service"),
  name: (v, OC) => Hoc.setupText(v, OC, "name", "Name"),
  email: (v, OC) => Hoc.setupText(v, OC, "email", "Email", "email"),
  currency: (v, OC) => Hoc.setupText(v, OC, "currency", "Currency"),
  ref: (v, OC) => Hoc.setupText(v, OC, "ref", "Reference"),
  key: (v, OC) => Hoc.setupText(v, OC, "key", "Key"),
  secret: (v, OC) => Hoc.setupText(v, OC, "secret", "Secret"),
  api: (v, OC) => Hoc.setupText(v, OC, "api", "API"),
  client_id: (v, OC) => Hoc.setupText(v, OC, "client_id", "Client ID"),
  publish_key: (v, OC) => Hoc.setupText(v, OC, "publish_key", "Publish Key"),
  secret_key: (v, OC) => Hoc.setupText(v, OC, "secret_key", "Secret Key"),
  Account_Type: (v, OC) => Hoc.setupText(v, OC, "Account_Type", "Account Type"),
  Account_Holder: (v, OC) =>
    Hoc.setupText(v, OC, "Account_Holder", "Account Holder"),
  Account_Number: (v, OC) =>
    Hoc.setupText(v, OC, "Account_Number", "Account Number"),
  Bank_Code: (v, OC) => Hoc.setupText(v, OC, "Bank_Code", "Bank Code"),
  Routing_Number: (v, OC) =>
    Hoc.setupText(v, OC, "Routing_Number", "Routing Number"),
  Institution_Name: (v, OC) =>
    Hoc.setupText(v, OC, "Institution_Name", "Institution Name"),
  Sort_Code: (v, OC) => Hoc.setupText(v, OC, "Sort_Code", "Sort Code"),
  BIC: (v, OC) => Hoc.setupText(v, OC, "BIC", "BIC"),
  Transit_Number: (v, OC) =>
    Hoc.setupText(v, OC, "Transit_Number", "Transit Number"),
  BAB_Code: (v, OC) => Hoc.setupText(v, OC, "BAB_Code", "BAB Code"),
  IBAN: (v, OC) => Hoc.setupText(v, OC, "IBAN", "IBAN"),
};

const initData = (v) => ({
  category: v?.category ? v?.category : null,
  enabled: v?.enabled ? v?.enabled : null,
  name: v?.name ? v?.name : null,
  email: v?.email ? v?.email : null,
  currency: v?.currency ? v?.currency : null,
  ref: v?.ref ? v?.ref : null,
  key: v?.key ? v?.key : null,
  secret: v?.secret ? v?.secret : null,
  api: v?.api ? v?.api : null,
  client_id: v?.client_id ? v?.client_id : null,
  publish_key: v?.publish_key ? v?.publish_key : null,
  secret_key: v?.secret_key ? v?.secret_key : null,
  Account_Type: v?.Account_Type ? v?.Account_Type : null,
  Account_Holder: v?.Account_Holder ? v?.Account_Holder : null,
  Account_Number: v?.Account_Number ? v?.Account_Number : null,
  Bank_Code: v?.Bank_Code ? v?.Bank_Code : null,
  Routing_Number: v?.Routing_Number ? v?.Routing_Number : null,
  Institution_Name: v?.Institution_Name ? v?.Institution_Name : null,
  Sort_Code: v?.Sort_Code ? v?.Sort_Code : null,
  BIC: v?.BIC ? v?.BIC : null,
  Transit_Number: v?.Transit_Number ? v?.Transit_Number : null,
  BAB_Code: v?.BAB_Code ? v?.BAB_Code : null,
  IBAN: v?.IBAN ? v?.IBAN : null,
});

export const AddForm = () => {
  return <PaymentForm />;
};
export const EditForm = () => {
  const P = useParams();
  return <PaymentForm id={P?.id} />;
};
