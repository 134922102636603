import * as item from "./store";
import * as order from "./order";
import * as trolly from "./trolly";
import * as track from "./track";
import * as inventories from "./inventories";

const Expo = {
  item,
  order,
  trolly,
  track,
  inventories,
};

export default Expo;
