import { combineReducers } from "redux";
import { toReducer } from "../../../utils";
import * as reducer from "./reducer";

const cmp_admin = combineReducers({
  ...toReducer(reducer.view_all),
  ...toReducer(reducer.view),
  ...toReducer(reducer.view_query),
  ...toReducer(reducer.view_add),
  ...toReducer(reducer.view_edit),
  ...toReducer(reducer.view_delete),
});

export default cmp_admin;
