import React from "react";
import { useDispatch } from "react-redux";
import slugify from "slugify";
import Hoc from "../../../Hoc";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import Custom from "../..";

const Root = (props) => {
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const Act_cat = Action.product.category_01;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = Custom.useForm.useForm({ name: "" });

  const onOpenClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    dispatch(
      Act_cat.view_add({
        ...values,
        slug: slugify(values?.name, { lower: true }),
      })
    );
    setOpen(false);
    resetForm();
  };

  return (
    <Hoc.Dialog
      open={open}
      onClose={onOpenClose}
      title="Main Category"
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onOpenClose}
        />
      }
    >
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        maxWidth="xs"
        submitButton={{
          disabled: !values?.name,
        }}
      >
        <Controls.Input {...token_setup.name(values.name, onChange)} />
      </Form>
    </Hoc.Dialog>
  );
};

const token_setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Name"),
};

export default Root;
