import { Box } from "@material-ui/core";
import React from "react";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { LinkedinShareButton, LinkedinIcon } from "react-share";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { RedditShareButton, RedditIcon } from "react-share";
import Hoc from "../../Hoc";
import Controls from "../../Controls";

const Share = ({ url, share, setShare }) => {
  const social_items = [
    { Btn: WhatsappShareButton, Icon: WhatsappIcon, url },
    { Btn: FacebookShareButton, Icon: FacebookIcon, url },
    { Btn: LinkedinShareButton, Icon: LinkedinIcon, url },
    { Btn: TwitterShareButton, Icon: TwitterIcon, url },
    { Btn: RedditShareButton, Icon: RedditIcon, url },
  ];
  const ShowShare = ({ Btn, Icon, ...v }) => (
    <Box style={{ margin: 5 }}>
      <Btn {...v}>
        <Icon round size={30} />
      </Btn>
    </Box>
  );

  const closeShare = () => {
    setShare(false);
  };
  return (
    <Hoc.Dialog
      open={share}
      onClose={closeShare}
      title="Share"
      maxWidth="xs"
      actions={
        <Controls.Button
          size="small"
          color="secondary"
          text="Close"
          onClick={closeShare}
        />
      }
    >
      <Box display="flex">
        {social_items.map(({ Btn, Icon, ...v }, idx) => (
          <ShowShare key={idx} Btn={Btn} Icon={Icon} {...v} />
        ))}
      </Box>
    </Hoc.Dialog>
  );
};

export default Share;
