import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";

const ForgotPassword = () => {
  const Act = Action.people.C2.forgot_password;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.ForgotPassword
      title="Company Admin Forgot Password"
      submitAction={Act}
      formData={formData}
    />
  );
};

const initValues = {
  email: "",
};

export default ForgotPassword;
