import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const root_types = Type.people_type.root;

const urls = [
  { role: "Company_Manager", url: "/supreme-sign-in" },
  { role: "Company_Admin", url: "/c2-sign-in" },
  { role: "Store_Manager", url: "/s1-sign-in" },
  { role: "Store_Employee", url: "/s2-sign-in" },
  { role: "Customer", url: "/sign-in" },
];

export const sign_out = () => (dispatch, state) => {
  const role = state()?.people?.root?.data?.role;
  const url = urls.find((v) => v.role === role)?.url;

  dispatch({ type: root_types.ROOT_SUCCESS, payload: null });
  sessionStorage.removeItem("root");
  sessionStorage.removeItem("trolly");

  return url;
};

export const verify_token_expire = (token) => async (dispatch) => {
  try {
    dispatch({ type: root_types.TOKEN_EXPIRE_REQUEST });

    const { data } = await axios.post(`${Helper.URL}/token-expire`, {
      token,
    });

    const { result, status } = data;

    const CKStatus =
      !!result?.expired ||
      !!result?.person?.archive ||
      !result?.person?.email_status;

    if (CKStatus) {
      dispatch({ type: root_types.ROOT_SUCCESS, payload: null });
      sessionStorage.removeItem("root");
      sessionStorage.removeItem("trolly");
    }

    dispatch({ type: root_types.TOKEN_EXPIRE_SUCCESS, payload: result });
    return status;
  } catch (err) {
    Helper.errResponse(err, dispatch, root_types.TOKEN_EXPIRE_FAIL);
  }
};
