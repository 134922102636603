import React, { useEffect } from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ViewAll = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const Act = Action.product.category_01.view_slug;
  const view_data = useSelector(({ product: v }) => v.category_01.view_slug);

  useEffect(() => {
    dispatch(Act(`slug=${params.id}`));
  }, [dispatch, Act, params]);

  return <Custom.Product.CategoryViewAll view_data={view_data} level="01" />;
};

export default ViewAll;
