import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Grid } from "@material-ui/core";
import Action from "../../../actions";
import Hoc from "../../../comps/Hoc";
import moment from "moment";

const CouponAdd = () => {
  const Act_coupon = Action.product.coupon;
  const dispatch = useDispatch();
  const P = useParams();
  const { data, error, loading } = useSelector(
    ({ product: P }) => P.coupon.view
  );

  useEffect(() => {
    dispatch(Act_coupon.view(P.id));
  }, [dispatch, Act_coupon, P]);

  const refresh = () => dispatch(Act_coupon.view(P.id));

  const nav01 = {
    title: data?.name || "Coupon",
    actions: [
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: refresh },
    ],
  };

  return (
    <Hoc.Dashboard.Container {...nav01}>
      <Hoc.UseData error={error} loading={loading}>
        {!data?.id && <Hoc.EmptyMessage />}

        <Grid
          container
          justifyContent="center"
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          {ShowItem("Name", data?.name)}
          {ShowItem("Percentage", data?.ratio ? `${data?.ratio} %` : null)}
          {ShowItem("Description", data?.description, true)}
          {ShowItem("Category", data?.category)}
          {ShowItem("Product Id", data?.ProductBoxId)}
          {ShowItem("Product Name", data?.Product_Box?.name)}
          {ShowItem("Category Id", data?.Category03Id)}
          {ShowItem("Store Id", data?.StoreId)}
          {ShowItem("Store Name", data?.Store?.name)}

          {ShowItem(
            "Start Date",
            data?.start_date ? ToDate(data?.start_date) : null
          )}
          {ShowItem("End Date", data?.end_date ? ToDate(data?.end_date) : null)}
        </Grid>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const ToDate = (v) => moment(v).format("lll");

const ShowItem = (n, v, FW) =>
  !!v && (
    <Grid item xs={12} {...(!FW && { sm: 5, md: 4, lg: 3 })}>
      <Hoc.ShowItem name={n} value={v} />
    </Grid>
  );

export default CouponAdd;
