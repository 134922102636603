import SecureRoute from "../../../comps/layout/SecureRoute";
import list from "./list";
import { AddForm, EditForm } from "./FooterForm";

const Expo = {
  list: <SecureRoute grant="C1" path="/footer-list" component={list} />,
  add: <SecureRoute grant="C1" path="/footer-add" component={AddForm} />,
  edit: <SecureRoute grant="C1" path="/footer-edit/:id" component={EditForm} />,
};

export default Expo;
