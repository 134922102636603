import React, { useEffect } from "react";
import Hoc from "../../../Hoc";
import Custom from "../../../Custom";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SignIn = (props) => {
  const Act = Action.base.terms;
  const dispatch = useDispatch();
  const H = useHistory();
  const { id, news, title, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const { setValues } = formData;

  const root = useSelector(({ people: v }) => v.root.data);

  useEffect(() => {
    if (!!id) {
      (async () => {
        const res = await dispatch(Act.view(id));
        setValues(initData(res));
      })();
    }
  }, [dispatch, setValues, Act, id]);

  const onSubmit = async () => {
    if (root.role !== "Company_Manager") return;

    const finalData = {
      ...values,
      CompanyManagerId: root.id,
      level: values?.level ? values?.level : null,
    };

    const status = await dispatch(
      !!id ? Act.view_edit(id, finalData) : Act.view_add(finalData)
    );

    if (!!status) {
      H.goBack();
    }
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values) || (!news && !values?.level),
        }}
      >
        <Controls.Input {...setup.name(values?.name, onChange)} />
        <Controls.Input {...setup.level(values?.level, onChange)} />
        <Controls.Input {...setup.description(values?.description, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  description: v?.description ? v?.description : "",
  level: v?.level ? v?.level : "",
  category: v?.category ? v?.category : "",
  CompanyManagerId: v?.CompanyManagerId ? v?.CompanyManagerId : "",
});

const setup_status = (v) => !v?.name || !v.description;

const setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", `Name`),
  level: (v, onChange) => ({
    ...Hoc.setupText(v, onChange, "level", `Level`, "number"),
    inputProps: { min: 0 },
  }),
  description: (v, onChange) =>
    Hoc.setupLongText(v, onChange, "description", `Description`),
};

export default SignIn;
