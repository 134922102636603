import React from "react";
import { Box } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const TrollyView04 = (props) => {
  const { data, trollies } = props;
  const act_order = Action.store.order.view_edit;
  const act_trolly = Action.store.trolly.view_edit;
  const act_notif = Action.base02.notif.view_add;
  const dispatch = useDispatch();
  const H = useHistory();
  const PF = (v) => parseFloat(v).toFixed(2);

  const due =
    PF(trollies?.reduce((a, b) => a + PF(b?.ST_price), 0)) -
      PF(
        data?.Transactions?.filter((v) => v.status === "Paid")?.reduce(
          (a, b) => a + PF(b?.amount),
          0
        )
      ) || 0;

  const CKS = (v) => data?.status === v;
  const onPlaceOrder = () => {
    dispatch(act_order(data?.id, { status: "Ordered" }));
    Hoc.isArr(trollies).forEach((TL) => {
      const saveTrolly = (rest) =>
        dispatch(act_trolly(TL.id, { OrderId: data?.id, ...rest }));

      if (due <= 0) {
        saveTrolly({ status: "Open" });
        const curr = TL?.Currency;
        let text = `Product Ordered : The product "${TL?.Product_Box?.name}"`;
        text = `${text} is waiting for delivery on `;
        text = `${text} "${Hoc.showAddress(
          data?.Delivery_Address
        )}" which costs`;
        text = `${text} ${TL?.ST_price} ${curr?.name} (${curr?.currency}) currency.`;

        let para01 = { role: "Store", StoreId: TL?.Product_Box?.Store?.id };
        para01 = { ...para01, text };
        dispatch(act_notif(para01));
      } else {
        saveTrolly();
      }
    });
    H.push(`/view-order/${data?.id}`);
  };

  const btn01 = {
    content: "Place an Order",
    color: "twitter",
    onClick: onPlaceOrder,
  };

  return (
    <Box>
      <Box style={{ margin: "10px 0" }}>
        {CKS("None") && (
          <Box {...Hoc.flex("flex-end")}>
            <Button {...btn01} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TrollyView04;
