import { combineReducers } from "redux";
import { toReducer } from "../../utils";
import * as reducer from "./reducer";

const reducers = combineReducers({
  ...toReducer(reducer.list),
  ...toReducer(reducer.view),
  ...toReducer(reducer.status),
  ...toReducer(reducer.edit_status),
  ...toReducer(reducer.method),
  ...toReducer(reducer.send_bank),
  ...toReducer(reducer.send_mobile),
  ...toReducer(reducer.method_list),
  ...toReducer(reducer.view_add),
  ...toReducer(reducer.view_edit),
  ...toReducer(reducer.view_delete),
  ...toReducer(reducer.view_queues),
});

export default reducers;
