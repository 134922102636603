import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Breadcrumb, Message } from "semantic-ui-react";
import HouseIcon from "@material-ui/icons/House";
import { Grid } from "@material-ui/core";
import { Container } from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import Hoc from "../../../../Hoc";
import AddtionalViewVariant from "../AddtionalViewVariant";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import Reviews from "./Reviews";

const ViewVariant = (props) => {
  const H = useHistory();
  const Arr = Hoc.isArr;
  const data = useSelector(({ product: p }) => p.box.view.data);
  const [photoID, setPhotoID] = useState(null);
  const root = useSelector(({ people: p }) => p.root.data);

  const { coupons } = props;

  if (!!Arr(data?.Photos).length && !photoID) {
    setPhotoID(Arr(data?.Photos)[0]?.id);
  }

  return (
    <Hoc.Page title={data?.name}>
      <Container maxWidth="md">
        <Breadcrumb
          icon="left angle"
          sections={sections(data?.Product?.Category_03, H, data?.name)}
        />

        {!root?.role && (
          <Message
            color="yellow"
            content="You need to login in order to order any item"
          />
        )}
        <Grid container spacing={1} style={{ margin: "2rem 0" }}>
          <LeftSide data={data} />
          <RightSide data={data} coupons={coupons} root={root} />
        </Grid>
        <AddtionalViewVariant slug={data?.slug} />
        <Reviews reviews={data?.Reviews} />
      </Container>
    </Hoc.Page>
  );
};

const XSpace = ({ children }) => (
  <div style={{ margin: "15px 0" }}>{children}</div>
);

const sections = (three, H, name) => {
  const two = three?.Category_02;
  const one = two?.Category_01;

  return [
    {
      key: "category_00",
      content: (
        <XSpace>
          <HouseIcon onClick={() => H.push(`/category-00`)} />
        </XSpace>
      ),
      link: true,
    },
    {
      key: "category_01",
      content: (
        <XSpace>
          <div onClick={() => H.push(`/category-01/${one?.slug}`)}>
            {one?.name}
          </div>
        </XSpace>
      ),
      link: true,
    },
    {
      key: "category_02",
      content: (
        <XSpace>
          <div
            style={{ margin: "15px 0" }}
            onClick={() => H.push(`/category-02/${two?.slug}`)}
          >
            {two?.name}
          </div>
        </XSpace>
      ),
      link: true,
    },
    {
      key: "category_03",
      content: (
        <XSpace>
          {" "}
          <div
            style={{ margin: "15px 0" }}
            onClick={() => H.push(`/category-03/${three?.slug}`)}
          >
            {three?.name}
          </div>
        </XSpace>
      ),
      link: true,
    },
    {
      key: "product",
      content: (
        <XSpace>
          <div style={{ margin: "15px 0" }}>{name}</div>
        </XSpace>
      ),
      active: true,
    },
  ];
};

export default ViewVariant;
