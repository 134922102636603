import React, { useState } from "react";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getStoteTrolly } from "../../trolly/Hoc/utils";
import AddTrackDialog from "../../trolly/Hoc02/AddTrackDialog";
import { Message } from "semantic-ui-react";
import { useHistory } from "react-router";
import Hoc from "../../../../comps/Hoc";

import AddReview from "./AddReview";
import ShowTrackDialog from "./ShowTrackDialog";
import { useSelector } from "react-redux";

const ShowOrderDetail01 = ({ trollies }) => {
  const H = useHistory();
  const [openAddTrack, setOpenAddTrack] = useState({
    status: false,
    TrollyId: null,
  });
  const onAddTrack = (TrollyId) => setOpenAddTrack({ status: true, TrollyId });
  const items = getStoteTrolly(trollies);

  const CSS = useCSS();
  const GridPhoto = ({ data }) => (
    <Grid {...T1} className={CSS?.trolly_photo_root}>
      <Box
        component="img"
        src={data?.Product_Box?.photo}
        className={CSS.trolly_photo}
      />
    </Grid>
  );

  const GridText = ({ data }) => (
    <Grid
      {...T2}
      onClick={() => H.push(`/product-variant/${data?.Product_Box?.id}`)}
    >
      <Typography className={CSS.trolly_remove}>
        {data?.Product_Box?.name} (Qty {data?.quantity})
      </Typography>
    </Grid>
  );
  const GridCollect = ({ data }) => (
    <Grid {...T3}>
      <Typography className={CSS.trolly_remove}>
        {data?.collectable === "None" ? "Delivery Service" : data?.collectable}
      </Typography>
    </Grid>
  );

  const GridPrice = ({ data }) => (
    <Grid {...T4}>
      <Typography color="primary" variant="h6">
        <Hoc.ToCurrency
          currency={data?.Currency}
          price={data?.ST_price ? data?.ST_price : data?.S_price}
        />{" "}
        {!data?.S_price && "+"}
      </Typography>
    </Grid>
  );

  const GridDivider = () => (
    <Grid item xs={12}>
      <Divider color="primary" />
    </Grid>
  );

  const [review, setReview] = useState({ status: false, ProductBoxId: null });
  const [openTrack, setOpenTrack] = useState({
    status: false,
    name: null,
    tracks: [],
  });

  const onTrack = (v) =>
    setOpenTrack({
      status: true,
      name: v?.Product_Box?.name,
      tracks: v?.Tracks,
    });

  const root = useSelector(({ people: P }) => P.root.data);

  const trollyActions = (v) => {
    let items = [];
    const TOC = (name, onClick) => ({ name, onClick });
    const TOT = (name, to) => ({ name, to });
    const GN = root?.role === "Customer";
    const Trace = TOC("View Tracks", () => onTrack(v));
    const AddTrack = TOC("Create Track", () => onAddTrack(v));

    if (root?.role && !GN) items = [AddTrack, ...items];
    if (v?.collectable === "None") items = [Trace, ...items];

    if (!!GN) {
      const onReview = () =>
        setReview({ status: true, ProductBoxId: v?.Product_Box?.id });
      const Review = TOC("Review", onReview);

      const raise_ticket = TOT(
        "Raise Complaint",
        `/add-token?TrollyId=${v?.id}&name=${v?.Product_Box?.name}`
      );

      items = [Review, raise_ticket, ...items];
    }

    return items;
  };
  const ToML = (v1) => ({
    curr: v1?.Currency,
    price: parseFloat(v1?.ST_price),
  });
  return (
    <Box>
      <AddReview open={review} setOpen={setReview} root={root} />

      <ShowTrackDialog open={openTrack} setOpen={setOpenTrack} />
      <AddTrackDialog open={openAddTrack} setOpen={setOpenAddTrack} />

      {items.map(({ StoreId, items, Store, total_price }) => (
        <Message key={StoreId} className={CSS.trolly}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={7}>
              {items?.map((v) => (
                <Grid
                  key={v?.id}
                  className={CSS.trolly_container}
                  container
                  spacing={1}
                >
                  <GridPhoto data={v} />
                  <GridText data={v} />

                  <GridCollect data={v} />
                  <GridPrice data={v} />
                  <Grid {...T5}>
                    <Hoc.Product.MenuButton
                      text="Action"
                      actions={trollyActions(v)}
                    />
                    <GridDivider />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <Message color="purple">
                <Box className={CSS.trolly_details}>
                  {!!Store && (
                    <React.Fragment>
                      <Typography variant="subtitle2" color="textPrimary">
                        Address : {Hoc.showAddress(Store)}
                      </Typography>

                      <Typography variant="subtitle1" color="textSecondary">
                        Destination :{" "}
                        {`${items?.find((T) => T.destination)?.destination}`}
                      </Typography>
                    </React.Fragment>
                  )}

                  <Typography className={CSS.sub_price}>
                    Sub Total :{" "}
                    <Hoc.MultiCurrency
                      items={Hoc.isArr(items).map((v1) => ToML(v1))}
                    />
                  </Typography>
                </Box>
              </Message>
            </Grid>
          </Grid>
        </Message>
      ))}
    </Box>
  );
};

const useCSS = makeStyles((theme) => ({
  trolly: {
    display: "flex",
    alignItems: "center",
  },
  trolly_container: {
    margin: theme.spacing(1, 0),
  },
  trolly_details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  trolly_photo: {
    height: "100%",
  },
  trolly_photo_root: {
    maxHeight: theme.spacing(4),
    ...fx,
  },
  trolly_remove: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: theme.spacing(1.6),
    "&:hover": {
      textDecoration: "underline",
      fontSize: theme.spacing(1.8),
    },
  },
  sub_price: {
    fontWeight: 700,
    fontSize: 20,
  },
}));

const T1 = {
  item: true,
  xs: 1,
};
const T2 = {
  item: true,
  xs: 4,
};
const T3 = {
  item: true,
  xs: 2,
};
const T4 = {
  item: true,
  xs: 3,
};
const T5 = {
  item: true,
  xs: 2,
};

const fx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default ShowOrderDetail01;
