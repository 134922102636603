import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const ordered = {
  name: "ordered",
  state: defListState,
  request: types.ORDERED_VIEW_ALL_REQUEST,
  success: types.ORDERED_VIEW_ALL_SUCCESS,
  fail: types.ORDERED_VIEW_ALL_FAIL,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.ORDER_VIEW_ALL_REQUEST,
  success: types.ORDER_VIEW_ALL_SUCCESS,
  fail: types.ORDER_VIEW_ALL_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.ORDER_VIEW_REQUEST,
  success: types.ORDER_VIEW_SUCCESS,
  fail: types.ORDER_VIEW_FAIL,
};

export const open = {
  name: "open",
  state: defState,
  request: types.ORDER_OPEN_VIEW_REQUEST,
  success: types.ORDER_OPEN_VIEW_SUCCESS,
  fail: types.ORDER_OPEN_VIEW_FAIL,
};

export const my_view_all = {
  name: "my_view_all",
  state: defListState,
  request: types.ORDER_MY_VIEW_ALL_REQUEST,
  success: types.ORDER_MY_VIEW_ALL_SUCCESS,
  fail: types.ORDER_MY_VIEW_ALL_FAIL,
};

export const my_view = {
  name: "my_view",
  state: defState,
  request: types.ORDER_MY_VIEW_REQUEST,
  success: types.ORDER_MY_VIEW_SUCCESS,
  fail: types.ORDER_MY_VIEW_FAIL,
};

export const my_view_edit = {
  name: "my_view_edit",
  state: defState,
  request: types.ORDER_MY_VIEW_EDIT_REQUEST,
  success: types.ORDER_MY_VIEW_EDIT_SUCCESS,
  fail: types.ORDER_MY_VIEW_EDIT_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.ORDER_EDIT_REQUEST,
  success: types.ORDER_EDIT_SUCCESS,
  fail: types.ORDER_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.ORDER_ADD_REQUEST,
  success: types.ORDER_ADD_SUCCESS,
  fail: types.ORDER_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.ORDER_DELETE_REQUEST,
  success: types.ORDER_DELETE_SUCCESS,
  fail: types.ORDER_DELETE_FAIL,
};
