import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import action from "../../../../actions";
import Controls from "../../../../comps/Controls";
import { useDispatch } from "react-redux";

const PaymentForm = ({ id }) => {
  const P = useParams();
  const H = useHistory();
  const act = action.base.mobile_pay;
  const act_OOP = action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const { Form, useForm } = Custom.useForm;
  const formData = useForm(initData());
  const { values, setValues, onChange, resetForm } = formData;
  const title = `${!id ? "Update" : "Create"} Footer Link`;

  const onSubmit = async () => {
    let status;
    if (!!id) {
      status = await dispatch(act.view_edit(id, values));
    } else {
      status = await dispatch(act.view_add({ ...values, ...P }));
    }

    if (!!status) {
      H.goBack();
      act_OOP(`Details ${!id ? "Updated" : "Created"} Successfully.`);
    }
  };

  useEffect(() => {
    const SetVV = async () => setValues(initData(await dispatch(act.view(id))));
    !!id && SetVV();
  }, [setValues, dispatch, act, id]);

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.SearchSelect {...setup.name(values?.name, onChange)} />
        <Controls.Input {...setup.mobile(values?.mobile, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) => !v?.name || !v?.mobile;

const setup = {
  name: (v, OC) => Hoc.setupSelect(v, OC, "name"),
  mobile: (v, OC) => Hoc.setupText(v, OC, "mobile", "Email / Mobile"),
};

const initData = (v) => ({
  name: v?.name ? v?.name : null,
  mobile: v?.mobile ? v?.mobile : null,
});

export const AddCCForm = () => {
  return <PaymentForm />;
};
export const EditCCForm = () => {
  const P = useParams();
  return <PaymentForm id={P?.id} />;
};
