import React from "react";
import { Card, Grid, CardContent, Box } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const OneCard = (props) => {
  const { name, photo, url } = props;

  const { to } = props;

  const H = useHistory();

  const CSS = useCSS();

  const toUrl = (v) => (`${v}`.includes("://") ? v : `https://${v}`);

  return (
    <Card {...rtcls(CSS.root)}>
      <CardContent>
        {!!to ? (
          <Box onClick={() => H.push(to)} className={CSS.root_01}>
            <div className={CSS.card}>
              {!!photo && <img src={photo} alt="" className={CSS.photo} />}
              <p className={CSS.text}>{name}</p>
            </div>
          </Box>
        ) : (
          <Link target="_blank" href={toUrl(url)} className={CSS.root_02}>
            <div className={CSS.card}>
              {!!photo && <img src={photo} alt="" className={CSS.photo} />}
              <p className={CSS.text}>{name}</p>
            </div>
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

const rtcls = (className) => ({
  component: Grid,
  item: true,
  xs: 11,
  sm: 5,
  md: 3,
  className,
});

const useCSS = makeStyles((theme) => ({
  root: { margin: theme.spacing(1) },
  root_01: { cursor: "pointer" },
  root_02: { textDecoration: "none" },

  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  photo: {
    width: "50px",
    height: "45px",
    marginRight: "5px",
  },
  text: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#9c27b0",
  },
}));

export default OneCard;
