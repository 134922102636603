import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../../../actions";
import Controls from "../../../../comps/Controls";
import Hoc from "../../../../comps/Hoc";

const Payment = ({ data, trollies }) => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState(null);
  const Act_order = Action.store.order;
  const act_pay = Action.base.payment;
  const act_curr = Action.base02.currency;
  const currs = useSelector(({ base02: B }) => B.currency.list.data);
  useEffect(() => {
    const SetVV = async () => {
      let res = await dispatch(act_pay.status());
      setMethods(res);
      dispatch(act_curr.list());
    };
    SetVV();
  }, [dispatch, act_pay, act_curr]);

  const [defaultMethod, setDefaultMethod] = useState();
  const [curr, setCurr] = useState(null);
  const [methods, setMethods] = useState(initValues);

  let paid_total = Hoc.isArr(data?.Transactions).filter(
    (P) => P.status === "Paid"
  );
  paid_total = paid_total?.reduce((a, V) => a + parseFloat(V?.amount), 0);

  const PF = (v) => parseFloat(v);

  const curr01 =
    new Set(Hoc.isArr(trollies).map((v) => v?.Currency?.id)).size === 1
      ? trollies[0]?.Currency
      : null;
  const curr02 = currs.find((v) => v.id === curr) || null;
  const total_amount = trollies?.reduce((a, v) => a + PF(v.ST_price), 0);
  const amount = PF((total_amount - paid_total).toFixed(2));

  const payDetails = {
    amount: Hoc.currencyTo(amount, curr01, curr02),
    curr: curr02,
    order_id: data?.id,
    refresh: () => dispatch(Act_order.open_view()),
  };

  const CKM = (v) => !!curr && defaultMethod === v;

  if (amount <= 0) {
    return <span />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box>
          <Controls.SearchSelect
            {...setup.defaultMethod(
              defaultMethod,
              setDefaultMethod,
              M1(methods)
            )}
          />
          <Controls.SearchSelect {...setup.curr(curr, setCurr, currs)} />
          {defaultMethod === "MobilePay" && (
            <Controls.SearchSelect
              {...Hoc.setupSelect(
                country,
                ({ target: T }) => setCountry(T.value),
                "country"
              )}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={9}>
        {CKM("PayPal") ? (
          <Hoc.Payment.PayPal {...payDetails} />
        ) : CKM("Stripe") ? (
          <Hoc.Payment.Stripe {...payDetails} />
        ) : CKM("RazorPay") ? (
          <Hoc.Payment.RazerPay {...payDetails} />
        ) : CKM("Bank") ? (
          <Hoc.Payment.Bank {...payDetails} />
        ) : CKM("MobilePay") ? (
          <Hoc.Payment.MobilePay {...payDetails} country={country} />
        ) : (
          <Hoc.Message
            color="yellow"
            text="Please select Currency & Payment Method."
          />
        )}
      </Grid>
    </Grid>
  );
};

const M1 = (v) => ({
  ...(!!v?.PayPal && { PayPal: true }),
  ...(!!v?.Stripe && { Stripe: true }),
  ...(!!v?.RazorPay && { RazorPay: true }),
  ...(!!v?.MobilePay && { MobilePay: true }),
  ...(!!v?.Bank && { Bank: true }),
});

const initValues = (v) => ({
  PayPal: v?.PayPal ? v?.PayPal : false,
  Stripe: v?.Stripe ? v?.Stripe : false,
  RazorPay: v?.RazorPay ? v?.RazorPay : false,
  MobilePay: v?.MobilePay ? v?.MobilePay : false,
  Bank: v?.Bank ? v?.Bank : false,
});

const setup = {
  defaultMethod: (v, setV, methods) => ({
    name: "defaultMethod",
    label: "Select Payment Method",
    required: true,
    value: v ? v : null,
    onChange: ({ target: T }) => setV(T.value),
    options: payment_methods(methods),
  }),
  curr: (v, setV, opts) => ({
    name: "curr",
    label: "Select Currency",
    value: v ? v : null,
    onChange: ({ target: T }) => setV(T.value),
    options: opts?.map((v) => ({
      id: v?.id,
      title: `${v?.name} (${v?.currency})`,
    })),
  }),
};

const payment_methods = (v) =>
  Object.entries(v).map((MT) => ({ id: MT[0], title: MT[0] }));

export default Payment;
