import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Custom from "../../../../Custom";
import Hoc from "../../../../Hoc";
import Action from "../../../../../actions";
import { initData, cells } from "./utils";

import VariantForm from "./Util/VariantForm";
import VariantTable from "./Util/VariantTable";

const Variant = (props) => {
  const { ProductId, StoreId, items } = props;
  const { editable, setEditable, closeEditable, refresh } = props;
  const { root, formData } = props;
  const dispatch = useDispatch();
  const Act_box = Action.product.box;
  const Act_store = Action.store.item;
  const { values, setValues, resetForm } = formData;

  const TBL = Custom.useTable(items, cells);

  useEffect(() => {
    dispatch(Act_store.view_all());
  }, [dispatch, Act_store, editable]);

  const PF = (v) => parseFloat(v || 0);
  const getTotalPrice = (v) =>
    PF(PF(PF(v?.B_price) * PF(v?.total_units)) + PF(v?.BT_shipping_price));

  const onSubmit = async () => {
    let finalForm = new FormData();
    Object.entries(values)
      .filter((v) => !!v[1])
      .forEach((v) => finalForm.set(v[0], v[1]));
    finalForm.set("slug", Hoc.slugify(values.name));
    finalForm.set("BT_total_price", getTotalPrice(values));
    finalForm.set("publish", !!values?.publish);
    finalForm.set("ProductId", ProductId);

    let status;
    if (!!id) {
      status = await dispatch(Act_box.view_edit(id, finalForm));
    } else {
      status = await dispatch(Act_box.view_add(finalForm));
    }

    if (!!status) {
      resetForm();
      refresh();
      closeEditable();
      setValues(initData());
    }
  };
  const [id, setId] = useState(null);

  const onEdit = (v) => {
    setEditable(true);
    setId(v?.id);
    setValues(initData(v));
  };

  return (
    <Box>
      {!!editable && (
        <VariantForm
          id={id}
          formData={formData}
          onSubmit={onSubmit}
          StoreId={StoreId}
          root={root}
        />
      )}
      {!editable && (
        <VariantTable onEdit={onEdit} TBL={TBL} refresh={refresh} />
      )}
    </Box>
  );
};

export default Variant;
