import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import Hoc from "../../../../comps/Hoc";
import { Message } from "semantic-ui-react";

const ShowOrderDetail01 = ({ order_view }) => {
  const { data, error, loading } = order_view;

  return (
    <Hoc.UseData loading={loading} error={error}>
      {GridItem("Customer Ref", data?.CustomerId)}
      {GridItem("Status", data?.status)}
      {GridItem("Current Status", !data?.is_open ? "Closed" : null)}
      {GridItem("Archive Status", !!data?.archive ? "Archived" : null)}

      {GridItem(
        "Coordinates",
        !(data?.lat && data?.lng) ? null : `${data?.lat}, ${data?.lng}`
      )}

      {GridItem("Paid Amount", data?.paid_amount)}
      {GridItem("Paid Total", data?.paid_total)}
      {GridItem("Payment ID", data?.payment_id)}
      {GridItem("Payment Method", data?.payment_method)}
      {GridItem("Payment Status", data?.payment_status)}
      {GridItem("Delivery Date", toDate(data?.delivery_date))}

      {GridItem("Price", data?.price)}
      {GridItem("VAT / GST", data?.vat_price)}
      {GridItem("Shipping Price", data?.shipping_tax)}
      {GridItem("Discount", data?.discount)}
      {GridItem("Total Price", data?.total_price)}

      {GridItem("Total Units", data?.total_units)}
      {GridItem("Sold Units", data?.sold_units)}
      {GridItem("Return Units", data?.return_units)}
      {GridItem("Defect Units", data?.defect_units)}
      {GridItem("Quantity", data?.quantity)}

      {GridItem("Created At", toDate(data?.createdAt))}
      {GridItem("Last Modified", toDate(data?.updatedAt))}
      <GridItem02 add01 content={Hoc.showAddress(data?.Billing_Address)} />
      <GridItem02 content={Hoc.showAddress(data?.Delivery_Address)} />
    </Hoc.UseData>
  );
};

const toDate = (v) => (v ? moment(v).format("DD-MM-YYYY") : "Na");

const GridItem = (name, value) =>
  !!value && (
    <Grid item xs={12} sm={6} lg={4}>
      <Hoc.ShowItem name={name} value={value} />
    </Grid>
  );

const GridItem02 = ({ add01 = false, content }) =>
  !!content && (
    <Grid item xs={12} lg={6}>
      <Message
        color="purple"
        header={`${!add01 ? "Delivery" : "Billing"} Address`}
        content={content}
      />
    </Grid>
  );

export default ShowOrderDetail01;
