import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const list = {
  name: "list",
  state: defListState,
  request: types.NOTIF_LIST_REQUEST,
  success: types.NOTIF_LIST_SUCCESS,
  fail: types.NOTIF_LIST_FAIL,
};

export const count = {
  name: "count",
  state: defState,
  request: types.NOTIF_LEN_REQUEST,
  success: types.NOTIF_LEN_SUCCESS,
  fail: types.NOTIF_LEN_FAIL,
};

export const read_all = {
  name: "read_all",
  state: defState,
  request: types.NOTIF_RD_ALL_REQUEST,
  success: types.NOTIF_RD_ALL_SUCCESS,
  fail: types.NOTIF_RD_ALL_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.NOTIF_EDIT_REQUEST,
  success: types.NOTIF_EDIT_SUCCESS,
  fail: types.NOTIF_EDIT_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.NOTIF_ADD_REQUEST,
  success: types.NOTIF_ADD_SUCCESS,
  fail: types.NOTIF_ADD_FAIL,
};

export const delete_all = {
  name: "delete_all",
  state: defState,
  request: types.NOTIF_ADD_REQUEST,
  success: types.NOTIF_ADD_SUCCESS,
  fail: types.NOTIF_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.NOTIF_DELETE_REQUEST,
  success: types.NOTIF_DELETE_SUCCESS,
  fail: types.NOTIF_DELETE_FAIL,
};
