// import required packages
import { combineReducers } from "redux";
import { toReducer } from "../../utils";
import * as reducer from "./reducer";

// merrging reducers with combineReducers from redux
const Expo = combineReducers({
  // renerating redux reducer by toReducer component and passing the reducers to the combineReducers
  ...toReducer(reducer.view_all),
  ...toReducer(reducer.view),
  ...toReducer(reducer.view_query),
  ...toReducer(reducer.view_add),
  ...toReducer(reducer.view_edit),
  ...toReducer(reducer.view_delete),
});

export default Expo;
