import React, { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Action from "../../actions";
import Hoc from "../Hoc";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function App({ open, setOpen }) {
  return (
    <Box>
      <Box onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
        Shop
      </Box>
      {open && <DropdownMenu setOpen={setOpen}></DropdownMenu>}
    </Box>
  );
}

function DropdownMenu({ setOpen }) {
  const H = useHistory();
  const CSS = useCSS();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const Act = Action.product.category_01;
  const category_01 = useSelector(
    ({ product: p }) => p.category_01.view_all.data
  );
  useEffect(() => {
    dispatch(Act.view_all());
  }, [dispatch, Act]);

  function DropdownItem(props) {
    const { leftIcon, rightIcon } = props;
    const { v3, cat_00 } = props;
    const { empty, title, children } = props;

    const onClick = () => {
      if (!!v3) {
        H.push(`/category-01/${v3.slug}`);
        setOpen(false);
      } else if (!!cat_00) {
        H.push("/category-00");
        setOpen(false);
      }
    };

    return (
      <Box className={CSS.menu_item} onClick={onClick}>
        {!!leftIcon && <span className={CSS.icon_button}>{leftIcon}</span>}
        <span {...(!!empty && { style: { color: "orangered" } })}>
          {title || children}
        </span>
        {!!rightIcon && <span className={CSS.icon_right}>{rightIcon}</span>}
      </Box>
    );
  }

  return (
    <div className={CSS.dropdown} ref={dropdownRef}>
      <CSSTransition
        in
        timeout={500}
        className={CSS.menu_primary}
        unmountOnExit
      >
        <div className={CSS.menu}>
          <DropdownItem title="All Categories" cat_00 />
          {!!Hoc.isArr(category_01).length ? (
            Hoc.isArr(category_01).map((v3) => (
              <DropdownItem key={v3?.id} cat_02={v3?.name} v3={v3}>
                {v3?.name}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem title="Empty" empty />
          )}
        </div>
      </CSSTransition>
    </div>
  );
}

const useCSS = makeStyles((theme) => ({
  icon_button: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "filter 300ms",
    zIndex: 10,
    "&:hover": {
      filter: "brightness(1.2)",
      zIndex: 10,
    },
    "& .svg": {
      fill: "#dadce1",
      width: "20px",
      height: "20px",
      zIndex: 10,
    },
  },
  icon_right: {
    marginLeft: "auto",
    zIndex: 10,
  },
  dropdown: {
    position: "absolute",
    top: "58px",
    width: "300px",
    maxHeight: "50vh",
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: "8px",
    padding: "1rem",
    overflow: "scroll",
    /* transition: height var(--speed) ease, */
    zIndex: 10,
  },
  menu: {
    width: "100%",
    zIndex: 10,
  },
  menu_item: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    transition: "background 500ms",
    padding: "0.5rem",
    color: theme.palette.common.white,
    cursor: "pointer",
    zIndex: 10,
  },
}));

export default App;
