// importing required packages for redux state management
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// importing people reducer
import people from "./people";
// importing people reducer types
import { people_type } from "./people";

import product from "./product";
import { product_type } from "./product";

import store from "./store";
import { store_type } from "./store";

import { base, base_type } from "./base";
import { base02, base02_type } from "./base02";

// combining reducers to the root reducers with combineReducers package
const reducers = combineReducers({
  people,
  product,
  store,
  base,
  base02,
});

// Redux-thunk middleware let us create the actions with async
const middleware = [thunk];

// exporting redux types
export const Type = {
  people_type,
  product_type,
  store_type,
  base_type,
  base02_type,
};

// creating a Redux store which manages the entire reduix state
export default createStore(
  reducers,
  {},
  // Integrating the redux middleware with composeWithDevTools tool which let us monitor the redux data
  composeWithDevTools(applyMiddleware(...middleware))
);
