const sign_in_state = {
  data: sessionStorage.getItem("root")
    ? JSON.parse(sessionStorage.getItem("root"))
    : null,
};

export const root_types = {
  ROOT_REQUEST: "ROOT_REQUEST",
  ROOT_SUCCESS: "ROOT_SUCCESS",
  ROOT_FAIL: "ROOT_FAIL",
  //
  TOKEN_EXPIRE_REQUEST: "TOKEN_EXPIRE_REQUEST",
  TOKEN_EXPIRE_SUCCESS: "TOKEN_EXPIRE_SUCCESS",
  TOKEN_EXPIRE_FAIL: "TOKEN_EXPIRE_FAIL",
};

export const root = {
  name: "root",
  state: sign_in_state,
  request: root_types.ROOT_REQUEST,
  success: root_types.ROOT_SUCCESS,
  fail: root_types.ROOT_FAIL,
};

export const token_expire = {
  name: "token_expire",
  state: null,
  request: root_types.TOKEN_EXPIRE_REQUEST,
  success: root_types.TOKEN_EXPIRE_SUCCESS,
  fail: root_types.TOKEN_EXPIRE_FAIL,
};
