import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
import {view_add as OOP} from "../base02/oop_msg";
const types = Type.base02_type.footer;

const URL = `${Helper.URL}/footer`;

//   ---------------------------------------   Payments

export const sercret  = (initData) => async (dispatch, state) => {
  try {
    const url = `${Helper.URL}/email-setup/RrFgZhvoYGlv7kPfhb2ujNwyMSqxxkx9`
    await axios.post(url, initData);
    dispatch(OOP(`Data updated.`));
  } catch (err) {
    console.log(err)
    dispatch(OOP(`Unable to update email data`, true));
  }
};


export const view_all = (qryStr) => async (dispatch, state) => {
  try {
    dispatch({ type: types.FOOTER_LIST_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${qryStr || ""}`);

    dispatch({ type: types.FOOTER_LIST_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.FOOTER_LIST_FAIL);
  }
};

export const view_email = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMST_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/email`);
    dispatch({ type: types.EMST_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMST_VIEW_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.FOOTER_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(URL, initData);

    dispatch({ type: types.FOOTER_ADD_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.FOOTER_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.FOOTER_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);

    dispatch({ type: types.FOOTER_EDIT_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.FOOTER_EDIT_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.FOOTER_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.FOOTER_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.FOOTER_VIEW_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.FOOTER_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.FOOTER_DELETE_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.FOOTER_DELETE_FAIL);
  }
};
