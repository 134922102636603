import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Addvertisement = ({ items }) => {
  const CSS = useCSSItem();
  return (
    <Carousel indicators={false} animation="slide" className={CSS.root01}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

function Item({ item }) {
  const CSS = useCSSItem();

  const { name, photo, description } = item;
  return (
    <Grid
      container
      component={Box}
      style={{ backgroundImage: `url(${photo})` }}
      className={CSS.root}
    >
      <Grid item xs={12} sm={6} md={5} className={CSS.content}>
        <h2>{name}</h2>
        <p>{description}</p>
      </Grid>
    </Grid>
  );
}

const useCSSItem = makeStyles((theme) => ({
  root01: {
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
  },
  root: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh",
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    opacity: 0.75,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Addvertisement;
