import React, { useState } from "react";
import GoogleReCAPTCHA from "react-google-recaptcha";
// import axios from "axios";

export const useReCAPTCHA = () => {
  const [reCaptcha, setReCaptcha] = useState(false);

  const handleReCaptcha = async (response) => {
    // const { data } = await axios.post(
    //   `https://www.google.com/recaptcha/api/siteverify`,
    //   { secret: RECAPTCHA_SITE_KEY, response }
    // );
    setReCaptcha(true);
  };

  return {
    reCaptcha,
    handleReCaptcha,
  };
};

const ReCAPTCHA = ({ handleReCaptcha }) => {
  return (
    <GoogleReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleReCaptcha} />
  );
};

const { REACT_APP_RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY } = process.env;

export default ReCAPTCHA;
