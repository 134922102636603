import React, { useEffect } from "react";
import Custom from "../../../comps/Custom";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const ProductEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const Act_view = Action.store.item.view;

  const { useForm } = Custom.useForm;
  const formData = useForm(setInitValues());
  const { setValues } = formData;

  useEffect(() => {
    (async () => {
      setValues(setInitValues(await dispatch(Act_view(params.id))));
    })();
  }, [dispatch, Act_view, params.id, setValues]);

  return (
    <Hoc.Page title="Update Store">
      <Custom.Form.Store
        title="Update Store"
        formData={formData}
        id={params.id}
      />
    </Hoc.Page>
  );
};

const setInitValues = (v) => ({
  name: v?.name ? v?.name : null,
  branch: v?.branch ? v?.branch : null,
  vat_price: v?.vat_price ? v?.vat_price : null,
  contact: v?.contact ? v?.contact : null,
  email: v?.email ? v?.email : null,
  one_address: v?.one_address ? v?.one_address : true,
  // PD
  PD_email: v?.PD_email ? v?.PD_email : null,
  PD_mobile: v?.PD_mobile ? v?.PD_mobile : null,
  PD_account_number: v?.PD_account_number ? v?.PD_account_number : null,
  PD_account_name: v?.PD_account_name ? v?.PD_account_name : null,
  PD_bank_code: v?.PD_bank_code ? v?.PD_bank_code : null,
  PD_branch_name: v?.PD_branch_name ? v?.PD_branch_name : null,
  PD_country_name: v?.PD_country_name ? v?.PD_country_name : null,
  PD_transaction_id: v?.PD_transaction_id ? v?.PD_transaction_id : null,
  PD_reference: v?.PD_reference ? v?.PD_reference : null,
  PD_iban: v?.PD_iban ? v?.PD_iban : null,
  PD_bic_code: v?.PD_bic_code ? v?.PD_bic_code : null,
  PD_sort_code: v?.PD_sort_code ? v?.PD_sort_code : null,
});

export default ProductEdit;
