import React, { useEffect, useState } from "react";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Message, Icon, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { getStoteTrolly } from "../Hoc/utils";

const ViewAll = (props) => {
  const CSS = useCSS();
  const H = useHistory();
  const { editable, data } = props;
  let { loading, error } = useSelector(({ store: v }) => v.trolly.view_all);
  const [qtyLoad, setQtyLoad] = useState(false);
  const Act_trolly = Action.store.trolly;
  const Act_order_del = Action.store.order.view_delete;
  const Act_trollies = Action.store.trolly.view_all;
  const refresh = () => dispatch(Act_trollies("status=Trolly"));
  const root = useSelector(({ people: p }) => p.root.data);
  const PFP = (v) => parseFloat(v).toFixed(2);

  const dispatch = useDispatch();
  const [store_items, setStore_items] = useState([]);

  useEffect(() => {
    setStore_items(getStoteTrolly(data));
  }, [data, loading]);

  const deleteTrolly = (id, count, OrderId) => {
    setQtyLoad(true);

    if (count === 1 && !!OrderId) {
      dispatch(Act_order_del(OrderId));
    }
    dispatch(Act_trolly.view_delete(id));
    refresh();
    setQtyLoad(false);
  };

  const onMinus = (v) => {
    if (v?.sold_units === 1) {
      const count = store_items.reduce(
        (a, b) => a + parseInt(b?.items?.length || 0),
        0
      );
      deleteTrolly(v?.id, count, v?.OrderId);
    } else {
      const para01 = { sold_units: v?.sold_units - 1 };
      dispatch(Act_trolly.view_edit(v?.id, para01));
      refresh();
    }
  };
  const onPlus = (v) => {
    const para01 = { sold_units: v?.sold_units + 1 };
    dispatch(Act_trolly.view_edit(v?.id, para01));
    refresh();
  };

  const GridPhoto = ({ data }) => (
    <Grid {...T1} className={CSS?.trolly_photo_root}>
      <Box
        component="img"
        src={data?.Product_Box?.photo}
        className={CSS.trolly_photo}
      />
    </Grid>
  );

  const GridText = ({ data }) => (
    <Grid
      {...T2}
      onClick={() => H.push(`/product-variant/${data?.Product_Box?.id}`)}
    >
      <Typography className={CSS.trolly_remove}>
        {data?.Product_Box?.name}
      </Typography>
    </Grid>
  );

  const GridQtyView = ({ data }) => (
    <Grid {...T3}>
      <BtnQty
        data={data}
        onPlus={onPlus}
        onMinus={onMinus}
        role={root?.role}
        editable={!!editable}
        loading={qtyLoad}
      />
      <Typography
        className={CSS.trolly_remove}
        onClick={() => deleteTrolly(data?.id)}
      >
        {!qtyLoad ? "Remove" : "Loading"}
      </Typography>
    </Grid>
  );

  const GridPrice = ({ data }) => (
    <Grid {...T4}>
      <Typography color="primary" variant="h6">
        <Hoc.ToCurrency
          currency={data?.Currency}
          price={PFP(data?.S_price) * parseInt(data?.sold_units)}
        />
      </Typography>
      {!!data?.S_price && parseInt(data?.sold_units) > 1 && (
        <Typography>
          <Hoc.ToCurrency
            currency={data?.Currency}
            price={PFP(data?.S_price)}
          />{" "}
          / Item
        </Typography>
      )}
    </Grid>
  );

  const GridDivider = () => (
    <Grid item xs={12}>
      <Divider color="primary" />
    </Grid>
  );

  const ToML = (v1) => ({
    curr: v1?.Currency,
    price: PFP(v1?.S_price) * parseInt(v1?.sold_units),
  });

  return (
    <Hoc.UseData loading={loading} error={error}>
      {store_items.map(({ items, Store }, idx) => (
        <Message key={idx} className={CSS.trolly}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={7}>
              {items?.map((v, idx) => (
                <Grid
                  key={idx}
                  className={CSS.trolly_container}
                  container
                  spacing={1}
                >
                  <GridPhoto data={v} />
                  <GridText data={v} />
                  <GridQtyView data={v} />
                  <GridPrice data={v} />
                  <GridDivider />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <Message color="purple">
                <Box className={CSS.trolly_details}>
                  {!!Store && (
                    <Typography variant="subtitle2" color="textPrimary">
                      Address : {Hoc.showAddress(Store)}
                    </Typography>
                  )}
                  <Typography className={CSS.sub_price}>
                    Sub Total :{" "}
                    <Hoc.MultiCurrency
                      items={Hoc.isArr(items).map((v1) => ToML(v1))}
                    />
                  </Typography>
                </Box>
              </Message>
            </Grid>
          </Grid>
        </Message>
      ))}

      <Message color="green">
        <Typography align="right" className={CSS.sub_price}>
          Total Price :{" "}
          <Hoc.MultiCurrency
            items={Hoc.isArr(store_items)
              .reduce((a, b) => [...a, b.items], [])
              ?.flat(Infinity)
              ?.map((v1) => ToML(v1))}
          />
        </Typography>
      </Message>
    </Hoc.UseData>
  );
};

const useCSS = makeStyles((theme) => ({
  trolly: {
    display: "flex",
    alignItems: "center",
  },
  trolly_container: {
    margin: theme.spacing(1, 0),
  },
  trolly_details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  trolly_photo: {
    height: "100%",
  },
  trolly_photo_root: {
    maxHeight: theme.spacing(4),
    ...fx,
  },
  trolly_remove: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: theme.spacing(1.6),
    "&:hover": {
      textDecoration: "underline",
      fontSize: theme.spacing(1.8),
    },
  },
  sub_price: {
    fontWeight: 700,
    fontSize: 20,
  },
}));

const T1 = {
  item: true,
  xs: 2,
};
const T2 = {
  item: true,
  xs: 4,
};
const T3 = {
  item: true,
  xs: 3,
};
const T4 = {
  item: true,
  xs: 3,
};

const Btn = ({ icon, content, ...rest }) => (
  <Button {...rest} size="tiny">
    {!!icon ? <Icon name={icon} /> : content || ""}
  </Button>
);

const BtnQty = (props) => {
  const { onPlus, onMinus, loading } = props;
  const { role, data, editable } = props;
  const show = role === "Customer";
  return (
    <Button.Group icon size="tiny">
      {!!editable && !!show && (
        <Btn icon="minus" disabled={!!loading} onClick={() => onMinus(data)} />
      )}
      <Btn content={data?.sold_units} />
      {!!editable && !!show && (
        <Btn icon="plus" disabled={!!loading} onClick={() => onPlus(data)} />
      )}
    </Button.Group>
  );
};

const fx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default ViewAll;
