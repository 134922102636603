const tp = {
  //
  C2_SIGN_UP_REQUEST: "C2_SIGN_UP_REQUEST",
  C2_SIGN_UP_SUCCESS: "C2_SIGN_UP_SUCCESS",
  C2_SIGN_UP_FAIL: "C2_SIGN_UP_FAIL",
  //
  C2_SIGN_IN_REQUEST: "C2_SIGN_IN_REQUEST",
  C2_SIGN_IN_SUCCESS: "C2_SIGN_IN_SUCCESS",
  C2_SIGN_IN_FAIL: "C2_SIGN_IN_FAIL",
  //
  C2_FORGOT_PSW_REQUEST: "C2_FORGOT_PSW_REQUEST",
  C2_FORGOT_PSW_SUCCESS: "C2_FORGOT_PSW_SUCCESS",
  C2_FORGOT_PSW_FAIL: "C2_FORGOT_PSW_FAIL",
  //
  C2_RESET_PSW_REQUEST: "C2_RESET_PSW_REQUEST",
  C2_RESET_PSW_SUCCESS: "C2_RESET_PSW_SUCCESS",
  C2_RESET_PSW_FAIL: "C2_RESET_PSW_FAIL",
  //
  C2_EDIT_PSW_REQUEST: "C2_EDIT_PSW_REQUEST",
  C2_EDIT_PSW_SUCCESS: "C2_EDIT_PSW_SUCCESS",
  C2_EDIT_PSW_FAIL: "C2_EDIT_PSW_FAIL",
  //
  C2_VERIFY_EMAIL_REQUEST: "C2_VERIFY_EMAIL_REQUEST",
  C2_VERIFY_EMAIL_SUCCESS: "C2_VERIFY_EMAIL_SUCCESS",
  C2_VERIFY_EMAIL_FAIL: "C2_VERIFY_EMAIL_FAIL",
  //
  C2_PROFILE_VIEW_REQUEST: "C2_PROFILE_VIEW_REQUEST",
  C2_PROFILE_VIEW_SUCCESS: "C2_PROFILE_VIEW_SUCCESS",
  C2_PROFILE_VIEW_FAIL: "C2_PROFILE_VIEW_FAIL",
  //
  C2_PROFILE_EDIT_REQUEST: "C2_PROFILE_EDIT_REQUEST",
  C2_PROFILE_EDIT_SUCCESS: "C2_PROFILE_EDIT_SUCCESS",
  C2_PROFILE_EDIT_FAIL: "C2_PROFILE_EDIT_FAIL",
  //
  //
  //
  //
  C2_VIEW_ALL_REQUEST: "C2_VIEW_ALL_REQUEST",
  C2_VIEW_ALL_SUCCESS: "C2_VIEW_ALL_SUCCESS",
  C2_VIEW_ALL_FAIL: "C2_VIEW_ALL_FAIL",
  //
  C2_EDIT_REQUEST: "C2_EDIT_REQUEST",
  C2_EDIT_SUCCESS: "C2_EDIT_SUCCESS",
  C2_EDIT_FAIL: "C2_EDIT_FAIL",
  //
  C2_ADD_REQUEST: "C2_ADD_REQUEST",
  C2_ADD_SUCCESS: "C2_ADD_SUCCESS",
  C2_ADD_FAIL: "C2_ADD_FAIL",
  //
  C2_DELETE_REQUEST: "C2_DELETE_REQUEST",
  C2_DELETE_SUCCESS: "C2_DELETE_SUCCESS",
  C2_DELETE_FAIL: "C2_DELETE_FAIL",
};

export default tp;
