import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base02_type.sent_mail;

const URL = `${Helper.URL}/sent-mails`;

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_TMP_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${queryString || ""}`);

    dispatch({ type: types.EMAIL_TMP_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_TMP_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_TMP_ADD_REQUEST });

    const { data } = await axios.post(`${URL}`, initData);

    dispatch({ type: types.EMAIL_TMP_ADD_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_TMP_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_TMP_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.EMAIL_TMP_EDIT_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_TMP_EDIT_FAIL);
  }
};

export const view_send = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_SND_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/send?id=${id}`);

    dispatch({ type: types.EMAIL_SND_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_SND_VIEW_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_TMP_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.EMAIL_TMP_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_TMP_VIEW_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_TMP_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);
    dispatch({
      type: types.EMAIL_TMP_VIEW_ALL_SUCCESS,
      payload: state().base02.sent_mail.view_all.data.filter(
        (v) => v.id !== id
      ),
    });

    dispatch({ type: types.EMAIL_TMP_DELETE_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_TMP_DELETE_FAIL);
  }
};
