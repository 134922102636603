import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Hoc from "../comps/Hoc";
import { Box, Typography } from "@material-ui/core";

const NotFound = () => {
  const loc = useLocation();
  const history = useHistory();

  return (
    <Hoc.Page title="Page not found">
      <Hoc.PageBox
        title={`Page not found - ${loc.pathname}`}
        actions={[
          { name: "Back", onClick: () => history.goBack() },
          { name: "Home", onClick: () => history.push("/") },
        ]}
      >
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">
            <Box
              component="img"
              src="/photos/404.jpg"
              style={{ maxHeight: 250 }}
            />
          </Typography>
        </Box>
      </Hoc.PageBox>
    </Hoc.Page>
  );
};

export default NotFound;
