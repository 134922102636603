import React from "react";
import Hoc from "../Hoc";
import Controls from "../Controls";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Segment } from "semantic-ui-react";

const C1 = ["Company_Manager"];
const C2 = [...C1, "Company_Admin"];
const S1 = [...C2, "Store_Manager"];
const S2 = [...S1, "Store_Employee"];
const GN = [...S2, "Customer"];

const CKR = (NM, RL) =>
  NM === "C1"
    ? !C1.includes(RL)
    : NM === "C2"
    ? !C2.includes(RL)
    : NM === "S1"
    ? !S1.includes(RL)
    : NM === "S2"
    ? !S2.includes(RL)
    : NM === "GN"
    ? !GN.includes(RL)
    : true;

const DashboardItem = (props) => {
  const H = useHistory();

  const { title, content, actions } = props;

  return (
    <Hoc.Dashboard.Item title={title}>
      <Segment color="blue">{content}</Segment>

      <Box display="flex">
        {Hoc.isArr(actions)
          ?.filter((V) => !V.status)
          ?.map((v, idx) => (
            <Controls.Button
              key={idx}
              text={v?.name}
              fullWidth
              onClick={() => H.push(v?.to)}
            />
          ))}
      </Box>
    </Hoc.Dashboard.Item>
  );
};

const TOT = (name, to, status = false) => ({ name, to, status });

const Terms = ({ role }) => (
  <DashboardItem
    title="About, Terms and Conditions"
    content="Here you can manage About US, Terms & Conditions page content."
    actions={[
      TOT("About US", "/view-all-about", CKR("C1", role)),
      TOT("Terms & Conditions", "/view-all-terms", CKR("C1", role)),
      TOT("Privacy Policy", "/view-all-privacy", CKR("C1", role)),
    ]}
  />
);

const Currencies = ({ role }) => (
  <DashboardItem
    title="Currencies"
    content="All Currencies in the site."
    actions={[TOT("View", "/currency-list", CKR("C1", role))]}
  />
);

const Email = ({ role }) => (
  <DashboardItem
    title="Email"
    content="Click on settings to manage Email settings. You can also create you dynamic email templates for specific actions."
    actions={[
      TOT("Settings", "/email", CKR("C1", role)),
      TOT("Templates", "/template", CKR("C1", role)),
    ]}
  />
);
const Payment = ({ role }) => (
  <DashboardItem
    title="Payment System"
    content="It completely deals with Payment system. Can enable and disable payment services."
    actions={[
      TOT("Transactions", "/view-all-transactions", CKR("C1", role)),
      TOT("Payment System", "/payment-details", CKR("C1", role)),
    ]}
  />
);
const Profile = ({ role }) => (
  <DashboardItem
    title="Profile"
    content="You can manage your own profile details."
    actions={[
      TOT("Edit", "/profile-edit", CKR("GN", role)),
      TOT("View", "/profile-view", CKR("GN", role)),
    ]}
  />
);
const OrderHistory = ({ role }) => (
  <DashboardItem
    title="Orders & Trollies"
    content="Completely deals with Orders & Trollies. Go to Orders/Trollies page and you can also select different categories of Orders/Trollies."
    actions={[
      TOT("Orders", "/orders-01", CKR("GN", role)),
      TOT("Trollies", "/trollies", CKR("GN", role)),
    ]}
  />
);

const CK02 = (GN) => (GN ? "customer" : "root");

const Token = ({ GN, role }) => (
  <DashboardItem
    title="Support Ticket"
    content="Support ticket is like a complaint / Request from customer to store. This page helps us to manage support ticket."
    actions={[
      TOT("General", `/${CK02(GN)}-general-tickets`, CKR("GN", role)),
      TOT("Complain", `/${CK02(GN)}-complain-tickets`, CKR("GN", role)),
      TOT("Products", `/${CK02(GN)}-product-tickets`, CKR("GN", role)),
    ]}
  />
);

const News = ({ role }) => (
  <DashboardItem
    title="News, Notice & Advertisement"
    content="These three categories are related to notification to all people. We can post recent news. we can advertise and provide notice. on the home page."
    actions={[TOT("View", "/view-all-notices", CKR("S1", role))]}
  />
);

const Coupons = ({ role }) => (
  <DashboardItem
    title="Coupons / Discounts"
    content="You can manage Discounts / Coupons here which can reduce the price of a product."
    actions={[
      TOT("Create", "/add-coupon", CKR("S2", role)),
      TOT("View", "/view-all-coupons", CKR("S2", role)),
    ]}
  />
);
const Inventory = ({ role }) => (
  <DashboardItem
    title="Inventory Management"
    content="Inventory Management helps us to understand the money flow and Profit and losses."
    actions={[TOT("View", "/view-inventory", CKR("S2", role))]}
  />
);

const Footer = ({ role }) => (
  <DashboardItem
    title="Footer Detail"
    content="Able to manage Social media links and footer links."
    actions={[TOT("View", "/footer-list", CKR("C1", role))]}
  />
);

const Subscribers = ({ role }) => (
  <DashboardItem
    title="Subscribed Customers"
    content="We can view subscribers information here."
    actions={[TOT("View", "/view-all-subscribe", CKR("C1", role))]}
  />
);

const EmailNotifications = ({ role }) => (
  <DashboardItem
    title="Email Notifications"
    content="We can send email to any category of People here."
    actions={[
      TOT("Active Templates", "/mail-templates", CKR("S2", role)),
      TOT("Inactive Templates", "/sent-mails", CKR("S2", role)),
    ]}
  />
);

const Expo = {
  EmailNotifications,
  Subscribers,
  Terms,
  Email,
  Payment,
  Profile,
  OrderHistory,
  Token,
  News,
  Coupons,
  Inventory,
  Footer,
  Currencies,
};

export default Expo;
