import SecureRoute from "../../../comps/layout/SecureRoute";
import view_all from "./view_all";
import add from "./add";
import edit from "./edit";

const Expo = {
  view_all: <SecureRoute grant="C1" path="/template" component={view_all} />,
  add: <SecureRoute grant="C1" path="/template-add" component={add} />,
  edit: <SecureRoute grant="C1" path="/template-edit/:id" component={edit} />,
};

export default Expo;
