const types = {
  //
  SUBSCRIBE_VIEW_ALL_REQUEST: "SUBSCRIBE_VIEW_ALL_REQUEST",
  SUBSCRIBE_VIEW_ALL_SUCCESS: "SUBSCRIBE_VIEW_ALL_SUCCESS",
  SUBSCRIBE_VIEW_ALL_FAIL: "SUBSCRIBE_VIEW_ALL_FAIL",
  //
  SUBSCRIBE_VIEW_REQUEST: "SUBSCRIBE_VIEW_REQUEST",
  SUBSCRIBE_VIEW_SUCCESS: "SUBSCRIBE_VIEW_SUCCESS",
  SUBSCRIBE_VIEW_FAIL: "SUBSCRIBE_VIEW_FAIL",
  //
  SUBSCRIBE_ADD_REQUEST: "SUBSCRIBE_ADD_REQUEST",
  SUBSCRIBE_ADD_SUCCESS: "SUBSCRIBE_ADD_SUCCESS",
  SUBSCRIBE_ADD_FAIL: "SUBSCRIBE_ADD_FAIL",
  //
  SUBSCRIBE_EDIT_REQUEST: "SUBSCRIBE_EDIT_REQUEST",
  SUBSCRIBE_EDIT_SUCCESS: "SUBSCRIBE_EDIT_SUCCESS",
  SUBSCRIBE_EDIT_FAIL: "SUBSCRIBE_EDIT_FAIL",
  //
  SUBSCRIBE_DELETE_REQUEST: "SUBSCRIBE_DELETE_REQUEST",
  SUBSCRIBE_DELETE_SUCCESS: "SUBSCRIBE_DELETE_SUCCESS",
  SUBSCRIBE_DELETE_FAIL: "SUBSCRIBE_DELETE_FAIL",
};

export default types;
