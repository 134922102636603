import SecureRoute from "../../../../comps/layout/SecureRoute";
import view_all from "./view_all";
import add from "./add";
import view from "./view";
import edit from "./edit";

const Expo = {
  view: (
    <SecureRoute grant="C1" path="/transaction-view/:id" component={view} />
  ),
  add: <SecureRoute grant="GN" path="/transaction-add" component={add} />,
  edit: (
    <SecureRoute grant="GN" path="/transaction-edit/:id" component={edit} />
  ),
  view_all: (
    <SecureRoute
      grant="C1"
      path="/view-all-transactions"
      component={view_all}
    />
  ),
};

export default Expo;
