const tp = {
  //
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAIL: "SIGN_UP_FAIL",
  //
  SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAIL: "SIGN_IN_FAIL",
  //
  FORGOT_PSW_REQUEST: "FORGOT_PSW_REQUEST",
  FORGOT_PSW_SUCCESS: "FORGOT_PSW_SUCCESS",
  FORGOT_PSW_FAIL: "FORGOT_PSW_FAIL",
  //
  RESET_PSW_REQUEST: "RESET_PSW_REQUEST",
  RESET_PSW_SUCCESS: "RESET_PSW_SUCCESS",
  RESET_PSW_FAIL: "RESET_PSW_FAIL",
  //
  EDIT_PSW_REQUEST: "EDIT_PSW_REQUEST",
  EDIT_PSW_SUCCESS: "EDIT_PSW_SUCCESS",
  EDIT_PSW_FAIL: "EDIT_PSW_FAIL",
  //
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAIL: "VERIFY_EMAIL_FAIL",
  //
  PROFILE_VIEW_REQUEST: "PROFILE_VIEW_REQUEST",
  PROFILE_VIEW_SUCCESS: "PROFILE_VIEW_SUCCESS",
  PROFILE_VIEW_FAIL: "PROFILE_VIEW_FAIL",
  //
  PROFILE_EDIT_REQUEST: "PROFILE_EDIT_REQUEST",
  PROFILE_EDIT_SUCCESS: "PROFILE_EDIT_SUCCESS",
  PROFILE_EDIT_FAIL: "PROFILE_EDIT_FAIL",
  //
  //
  //
  VIEW_ALL_REQUEST: "VIEW_ALL_REQUEST",
  VIEW_ALL_SUCCESS: "VIEW_ALL_SUCCESS",
  VIEW_ALL_FAIL: "VIEW_ALL_FAIL",
  //
  EDIT_REQUEST: "EDIT_REQUEST",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  EDIT_FAIL: "EDIT_FAIL",
  //
  ADD_REQUEST: "ADD_REQUEST",
  ADD_SUCCESS: "ADD_SUCCESS",
  ADD_FAIL: "ADD_FAIL",
  //
  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAIL: "DELETE_FAIL",
};

export default tp;
