import React, { useEffect, useState } from "react";
import Custom from "../../../../comps/Custom";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const EditProduct = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const Act_view = Action.product.product.view;
  const Act_edit = Action.product.product.view_edit;
  const { useForm } = Custom.useForm;
  const [product, setProduct] = useState(null);
  const formData = useForm(setInitValues());
  const { setValues } = formData;

  useEffect(() => {
    (async () => {
      const data = await dispatch(Act_view(params.id));
      setValues(setInitValues(data));
      setProduct(data);
    })();
  }, [Act_view, dispatch, setValues, params.id]);

  const title = product?.title
    ? `Editing ${product?.title} Information`
    : "Editing Product";

  return (
    <Hoc.Page title={title}>
      <Custom.Product.ProductForm
        title={title}
        formData={formData}
        submitAction={Act_edit}
        id={product?.id}
        edit
      />
    </Hoc.Page>
  );
};

const setInitValues = (v) => ({
  title: v?.title ? v?.title : "",
  slug: v?.slug ? v?.slug : "",
  brand: v?.brand ? v?.brand : "",
  collectable: v?.collectable ? v?.collectable : false,
  subscription: v?.subscription ? v?.subscription : false,
  publish: v?.publish ? v?.publish : false,
  destination: v?.destination ? v?.destination : "",
  Category03Id: v?.Category03Id ? v?.Category03Id : "",
  StoreId: v?.StoreId ? v?.StoreId : "",
});

export default EditProduct;
