import item from "./item";
import category from "./category";
import view_inventory from "./inventory/view";
import SecureRoute from "../../comps/layout/SecureRoute";

const Expo = {
  item,
  category,
  view_inventory: (
    <SecureRoute grant="S2" path="/view-inventory" component={view_inventory} />
  ),
};

export default Expo;
