import React from "react";
import Hoc from "../../comps/Hoc";
import Comp from "../../comps/Comp";
import { useSelector } from "react-redux";

const C1Dashboard = () => {
  const root = useSelector(({ people: v }) => v?.root?.data);

  const toAction = (name, to) => ({ name, to });
  const nav_01 = [
    toAction("People", "/customer-active-view-all"),
    toAction("Products from Shops", "/product-view-all-trolly"),
    toAction("My Store", "/my-store"),
  ];

  const title02 = `${!root?.role ? "" : root?.role}`.split("_").join(" ");
  const title =
    `${title02} - ${root?.first_name} ${root?.last_name}` || "Store Manager";

  const vrfrt = !!`${root?.privilege}`.includes("Read") && !!root?.Store;
  const nav_02 = {
    title,
    actions: !!vrfrt ? nav_01 : [],
  };
  return (
    <Hoc.Page title={`${title02} Dashboard`}>
      <Hoc.Dashboard.Container {...nav_02}>
        {/* Start */}

        {!vrfrt ? (
          <Hoc.WaitUntilGotVerified />
        ) : (
          <React.Fragment>
            <Comp.Showcase.Inventory role={root?.role} />
            <Comp.Showcase.Token role={root?.role} />

            <Comp.Showcase.News role={root?.role} />
            <Comp.Showcase.OrderHistory role={root?.role} />
            <Comp.Showcase.Coupons role={root?.role} />
            <Comp.Showcase.EmailNotifications role={root?.role} />
          </React.Fragment>
        )}

        <Comp.Showcase.Profile role={root?.role} />

        {/* End */}
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default C1Dashboard;
