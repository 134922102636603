import CustomerOrder from "./CustomerOrder";
import CustomerOrderHistory from "./CustomerOrderHistory";
import CustomerTokens from "./CustomerTokens";

// Terms
import TermForm from "./Term/TermForm";
import TermView from "./Term/TermView";
import TermViewAll from "./Term/TermViewAll";
import PublicView from "./Term/PublicView";

// CouponForm
import CouponForm from "./Coupon/CouponForm";
import Notices from "./Notices";
import AdvertiseForm from "./Coupon/AdvertiseForm";

const Expo = {
  CustomerOrder,
  CustomerOrderHistory,
  CustomerTokens,
  TermForm,
  TermView,
  TermViewAll,
  PublicView,

  //
  Notices,

  CouponForm,
  AdvertiseForm,
};

export default Expo;
