import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "semantic-ui-react";
import Hoc from "../../Hoc";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../../actions";
import ItemShare from "./ItemShare";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

const Arr = (v) => Hoc.isArr(v);

const OneCard = ({ item, box }) => {
  const Act_trolly = Action.store.trolly;
  const H = useHistory();
  const dispatch = useDispatch();
  const root = useSelector(({ people: p }) => p.root.data);
  const CSS = useCSS();
  const [share, setShare] = useState(false);

  const addToTrolly = (v) => {
    dispatch(Act_trolly.view_add(1, v));
  };

  const url = (id) => `${window.location.origin}/product-variant/${id}`;

  return (
    <Grid container spacing={2} justifyContent="center">
      {Arr(!!box ? item : item?.Product_Boxes).map(
        (v, idx) =>
          !!v?.photo && (
            <Grid item key={idx}>
              <ItemShare url={url(v?.id)} share={share} setShare={setShare} />
              <ShowItem data={v} H={H}>
                <Box style={{ marginTop: 15 }} {...Hoc.flex("space-around")}>
                  <Typography className={CSS.currency}>
                    <Hoc.ToCurrency currency={v?.Currency} price={v?.S_price} />
                  </Typography>
                  <ShareOutlinedIcon
                    onClick={() => setShare(true)}
                    color="primary"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                {root?.role === "Customer" && (
                  <Button
                    content={!!v?.quantity ? "Add to Trolly" : "Out of Stock"}
                    color={!!v?.quantity ? "blue" : "orange"}
                    inverted
                    fluid
                    size="tiny"
                    {...(!!v?.quantity && { onClick: () => addToTrolly(v) })}
                  />
                )}
              </ShowItem>
            </Grid>
          )
      )}
    </Grid>
  );
};

const useCSS = makeStyles((theme) => ({
  root: {
    //
    padding: theme.spacing(0.5),
  },
  photo_root: {
    height: theme.spacing(20),
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
  photo: {
    backgroundSize: "cover",
    backgroundOrigin: "center",
    maxWidth: "100%",
  },
  name: {
    cursor: "pointer",
    fontSize: theme.spacing(2),
    fontWeight: 300,
    marginTop: theme.spacing(0.3),
    "&:hover": {
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  currency: {
    cursor: "pointer",
    fontSize: theme.spacing(2),
    fontWeight: 700,
    margin: theme.spacing(0.3, 0),
  },
}));
// ******************************************************************
const ShowItem = ({ children, data, H }) => {
  const CSS = useCSS02();
  return (
    <Box className={CSS.root}>
      <Box className={CSS.photo_root}>
        <Box>
          <Box component="img" src={data?.photo} className={CSS.photo} />
        </Box>
      </Box>
      <Typography
        onClick={() => H.push(`/product-variant/${data?.id}`)}
        className={CSS.name}
      >
        {data?.name}
      </Typography>

      {children}
    </Box>
  );
};
const useCSS02 = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    padding: theme.spacing(0.5),
  },
  photo_root: {
    height: theme.spacing(20),
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
  photo: {
    backgroundSize: "cover",
    backgroundOrigin: "center",
    maxWidth: "100%",
  },
  name: {
    cursor: "pointer",
    fontSize: theme.spacing(2),
    fontWeight: 300,
    marginTop: theme.spacing(0.3),
    "&:hover": {
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  currency: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    margin: theme.spacing(0.3, 0),
    color: theme.palette.info,
  },
}));

export default OneCard;
