import SecureRoute from "../../comps/layout/SecureRoute";
import company_dashboard from "./company";
import store_dashboard from "./store";
import customer_dashboard from "./customer";
import product from "./product";
import profile from "./profile";

const Expo = {
  product,
  profile,
  company_dashboard: (
    <SecureRoute
      grant="C"
      path="/company-dashboard"
      component={company_dashboard}
    />
  ),
  store_dashboard: (
    <SecureRoute
      grant="S"
      path="/store-dashboard"
      component={store_dashboard}
    />
  ),
  customer_dashboard: (
    <SecureRoute
      grant="G"
      path="/customer-dashboard"
      component={customer_dashboard}
    />
  ),
};

export default Expo;
