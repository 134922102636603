import React from "react";
import Hoc from "../comps/Hoc";
import Custom from "../comps/Custom";
import Controls from "../comps/Controls";
import {sercret} from "../actions/base02/footer";
import { useDispatch } from "react-redux";

const Secret = () => {
  const dispatch = useDispatch();
  const { Form, useForm } = Custom.useForm;
  const { values, onChange, resetForm } = useForm(initData);

  const onSubmit = () => {
    dispatch(sercret(values));
    resetForm();
  };

  return (
    <Hoc.FormContainer title="Update Email Setup">
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          submitButton={{
            disabled: setup_status(values) 
          }}
        >
          <Controls.Input {...setup.protocol(values?.protocol, onChange)} />
          <Controls.Input {...setup.username(values?.username, onChange)} />
          <Controls.Input {...setup.from(values?.from, onChange)} />
          <Controls.Input {...setup.password(values?.password, onChange)} />
          <Controls.Input {...setup.imap_port(values?.imap_port, onChange)} />
          <Controls.Input {...setup.pop3_port(values?.pop3_port, onChange)} />
          <Controls.Input {...setup.smtp_host(values?.smtp_host, onChange)} />
        </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) =>  !['imap','pop3'].includes(v?.protocol) || !v?.username|| !v?.from|| !v?.password|| !v?.imap_port|| !v?.pop3_port || !v?.smtp_host


const initData = {
  protocol:'imap',
  username:'',
  from:'',
  password:'',
  imap_port:'465',
  pop3_port:'465',
  smtp_host:'',
}

const setup = {
  protocol: (v, OC) => Hoc.setupText(v, OC, "protocol", "protocol"),
  username: (v, OC) => Hoc.setupText(v, OC, "username", "username"),
  from: (v, OC) => Hoc.setupText(v, OC, "from", "from"),
  password: (v, OC) => Hoc.setupText(v, OC, "password", "password"),
  imap_port: (v, OC) => Hoc.setupText(v, OC, "imap_port", "imap_port"),
  pop3_port: (v, OC) => Hoc.setupText(v, OC, "pop3_port", "pop3_port"),
  smtp_host: (v, OC) => Hoc.setupText(v, OC, "smtp_host", "smtp_host"),
};

export default Secret;
