import React, { useState } from "react";
import { Box, CardActionArea, CardActions, Grid } from "@material-ui/core";
import { CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../Controls";
import Hoc from "../../Hoc";

export default function ImgMediaCard(props) {
  const { store, onClose, item } = props;
  const CSS = useCSS();
  const gd = { nameSize: 3, valueSize: 9 };
  const [showCnt, setShowCnt] = useState(false);
  const CKD = (v) => item?.destination === v;

  const cntLen = `${item?.countries?.length} countries`;

  const deliver_areas = CKD("Local")
    ? `Only Local (${store?.city})`
    : CKD("Local_National")
    ? `With in the country (${store?.country})`
    : CKD("Local_National_Global")
    ? `International (${cntLen})`
    : CKD("Global")
    ? `Only International (${cntLen})`
    : "";

  const countries = item?.countries;
  const address = store?.Address;
  return (
    <Box>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {store?.name}
          </Typography>
          <Grid container>
            <Hoc.ShowItem {...gd} name="Branch" value={store?.branch} />
            <Hoc.ShowItem
              {...gd}
              name="Address"
              value={`${address?.house_name} ${address?.street_name} ${address?.address_3} ${address?.city} ${address?.region} ${address?.postcode} ${address?.country}  `}
            />
            <Hoc.ShowItem {...gd} name="Delivery" value={deliver_areas} />
            {!!`${item?.destination}`?.includes("Global") && (
              <Controls.Switch
                name="show"
                label={showCnt ? "Hide Countries" : "Show Countries"}
                checked={showCnt}
                onChange={() => setShowCnt(!showCnt)}
              />
            )}

            {!!showCnt &&
              countries?.map((v, idx) => (
                <Hoc.ShowItem
                  key={idx}
                  {...gd}
                  name={v.country}
                  value={v.charge}
                />
              ))}
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions className={CSS.action}>
        <Controls.Button
          size="small"
          color="secondary"
          text="Close"
          onClick={onClose}
        />
      </CardActions>
    </Box>
  );
}

const useCSS = makeStyles((theme) => ({
  action: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
