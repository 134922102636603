import React from "react";
import Action from "../../../actions";
import Custom from "../../../comps/Custom";
import { useParams, useHistory } from "react-router";
import { useDispatch } from "react-redux";

const View = () => {
  const Act_edit_address = Action.base.address.view_edit;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const P = useParams();
  const H = useHistory();
  const formData = Custom.useForm.useForm(initAddress());
  const { values } = formData;

  const onSubmit = async () => {
    const status = await dispatch(Act_edit_address(P.id, values));
    if (!!status) {
      H.goBack();
      dispatch(Act_OOP("Address Changed Successfully."));
    }
  };

  return (
    <Custom.Form.Address
      title="Edit Address"
      formData={formData}
      onSubmit={onSubmit}
      id={P.id}
    />
  );
};

const initAddress = (v) => ({
  house_name: v?.house_name ? v?.house_name : "",
  street_name: v?.street_name ? v?.street_name : "",
  address_3: v?.address_3 ? v?.address_3 : "",
  city: v?.city ? v?.city : "",
  region: v?.region ? v?.region : "",
  postcode: v?.postcode ? v?.postcode : "",
  country: v?.country ? v?.country : "",
  default: v?.default ? v?.default : false,
});

export default View;
