import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Custom from "../../../comps/Custom";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import ReactRTE from "react-rte";

const TemplateEdit = () => {
  const Act = Action.base.template;
  const Act_Opp = Action.base02.oop_msg.view_add;
  const params = useParams();
  const h = useHistory();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(({ base: v }) => v.template.view);
  const { useForm } = Custom.useForm;
  const formData = useForm(initData());
  const { values, setValues } = formData;

  const onSubmit = async () => {
    const para01 = { ...values, actions: `${values.actions}` };
    await dispatch(Act.view_edit(params?.id, para01));
    h.push("/template");
    dispatch(Act_Opp("Email Template Updated!!."));
  };

  useEffect(() => {
    const SetVV = async () => {
      let dt = await dispatch(Act.view(params.id));
      setValues(initData(dt));
    };
    SetVV();
  }, [dispatch, Act, params.id, setValues]);

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Custom.Form.Template
        title="Update Email Template"
        onSubmit={onSubmit}
        formData={formData}
        editable
      />
    </Hoc.UseData>
  );
};
const initData = (v) => ({
  editorState: ReactRTE.createValueFromString(v?.html, "html"),

  name: v?.name || "",
  subject: v?.subject || "",
  html: v?.html || "",
  actions: `${v?.actions}`.split(",") || [],
});

export default TemplateEdit;
