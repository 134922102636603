import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./redux";
import "semantic-ui-css/semantic.min.css";

import { StylesProvider } from "@material-ui/core/styles";
import ThemeConfig from "./theme/index";

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ThemeConfig>
        <StylesProvider injectFirst>
          <Provider store={store}>
            <Root />
          </Provider>
        </StylesProvider>
      </ThemeConfig>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);
