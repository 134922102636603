import React from "react";
import Custom from "../../../comps/Custom";

const AddAbout = () => {
  const formData = Custom.useForm.useForm(initData());
  const cat = formData?.values?.category;

  return (
    <Custom.Base.Notices.NoticeForm
      title={`Create ${cat !== "Add" ? cat : "Advertisement"}`}
      formData={formData}
      news
    />
  );
};

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  description: v?.description ? v?.description : "",
  photo: "",
  category: "News",
  roles: "CUS",
  add_for: "None",
  enabled: false,
  Category01Id: null,
  Category02Id: null,
  Category03Id: null,
  ProductBoxId: null,
  StoreId: null,
});

export default AddAbout;
