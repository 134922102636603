import Stripe from "./Stripe";
import PayPal from "./PayPal";
import RazerPay from "./RazerPay";
import Bank from "./Bank";
import MobilePay from "./MobilePay";

const Expo = {
  Stripe,
  PayPal,
  RazerPay,
  Bank,
  MobilePay,
};

export default Expo;
