import React, { useEffect } from "react";
import Hoc from "../../../Hoc";
import Custom from "../..";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import moment from "moment";

const CouponForm = (props) => {
  const Act_coupon = Action.product.coupon;
  const Act_product = Action.product.box.view_all;
  const Act_store = Action.store.item.view_all;
  const dispatch = useDispatch();
  const H = useHistory();
  const { id, title, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const { setValues } = formData;

  const root = useSelector(({ people: v }) => v.root.data);

  const TO_ID = (v) =>
    v?.map((v) => ({
      id: v.id,
      title: `${v?.name} (${v?.id})`,
      StoreId: v.StoreId,
    }));

  let product_list = useSelector(({ product: P }) => P.box.view_all.data);
  product_list = TO_ID(product_list);

  let store_list = useSelector(({ store: P }) => P.item.view_all.data);
  store_list = TO_ID(store_list);

  const onCatChange = ({ target: T }) => {
    setValues((VL) => ({ ...VL, category: T.value }));
    if (T.value === "Product") {
      dispatch(Act_product());
    } else if (T.value === "Store") {
      dispatch(Act_store());
    } else if (T.value === "For All") {
      TOSV("00", setValues, null);
    }
  };

  useEffect(() => {
    const SetVV = async () => {
      const res = await dispatch(Act_coupon.view(id));
      setValues(initData(res));
      if (res?.category === "Product") {
        dispatch(Act_product());
      } else if (res?.category === "Store") {
        dispatch(Act_store());
      }
    };
    SetVV();
  }, [dispatch, Act_store, setValues, Act_product, Act_coupon, id]);

  const onSubmit = async () => {
    const finalData = {
      ...values,
      ...(values?.category === "For All" && {
        ProductBoxId: null,
        Category03Id: null,
        StoreId: null,
      }),
    };

    const status = await dispatch(
      !!id
        ? Act_coupon.view_edit(id, finalData)
        : Act_coupon.view_add(finalData)
    );
    if (!!status) H.goBack();
  };

  const onPDChange = ({ target: T }) => TOSV("01", setValues, T.value);
  const onSTChange = ({ target: T }) => TOSV("03", setValues, T.value);

  const CKcat = (v) => values?.category === v;

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(values),
        }}
      >
        <Controls.Input {...setup.name(values?.name, onChange)} />
        <Controls.Input {...setup.ratio(values?.ratio, onChange)} />
        <Controls.Input {...setup.description(values?.description, onChange)} />
        <ItemBox>
          <SelectBox {...setup.category(values?.category, onCatChange, root)} />
          {CKcat("For All") ? (
            <SelectBox>
              <Hoc.EmptyMessage content="Coupon For All" />
            </SelectBox>
          ) : CKcat("Store") ? (
            <SelectBox
              {...setup.StoreId(values?.StoreId, onSTChange, store_list, root)}
            />
          ) : (
            CKcat("Product") && (
              <SelectBox
                {...setup.ProductBoxId(
                  values?.ProductBoxId,
                  onPDChange,
                  product_list,
                  root
                )}
              />
            )
          )}
        </ItemBox>
        <ItemBox>
          <Controls.DatePicker
            {...setup.start_date(values?.start_date, onChange)}
          />

          <Controls.DatePicker
            {...setup.end_date(values?.end_date, onChange)}
          />
        </ItemBox>
      </Form>
    </Hoc.FormContainer>
  );
};

const TOSV = (v1, setV, v2) =>
  setV((v3) => ({
    ...v3,
    ProductBoxId: v1 === "01" ? v2 : null,
    Category03Id: v1 === "02" ? v2 : null,
    StoreId: v1 === "03" ? v2 : null,
  }));

const SelectBox = ({ children, ...rest }) => (
  <Box style={{ width: 150 }}>
    {children ? children : <Controls.SearchSelect {...rest} />}
  </Box>
);

const ItemBox = ({ children }) => (
  <Box display="flex" justifyContent="space-around" alignItems="center">
    {children}
  </Box>
);

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  ratio: v?.ratio ? v?.ratio : "",
  description: v?.description ? v?.description : "",
  category: v?.category ? v?.category : "",
  start_date: v?.start_date ? addDate(v?.start_date) : null,
  end_date: v?.end_date ? addDate(v?.end_date) : null,
  Category03Id: !!v?.Category03Id ? v?.Category03Id : null,
  StoreId: !!v?.StoreId ? v?.StoreId : null,
  ProductBoxId: !!v?.ProductBoxId ? v?.ProductBoxId : null,
});

const addDate = (v) => moment(v).format("YYYY-MM-DD");

const setup_status = (v) =>
  !v?.name ||
  !v.ratio ||
  !v.description ||
  !v?.category ||
  !v?.start_date ||
  !v?.end_date ||
  (v?.category === "Category" && !v?.Category03Id) ||
  (v?.category === "Store" && !v?.StoreId) ||
  (v?.category === "Product" && !v?.ProductBoxId);

const setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Coupon Code"),
  description: (v, onChange) =>
    Hoc.setupLongText(v, onChange, "description", "Description"),
  ratio: (v, onChange) => ({
    ...Hoc.setupText(v, onChange, "ratio", "Percentage", "number"),
    inputProps: { min: 0 },
  }),
  start_date: (v, onChange) =>
    Hoc.setupText(v, onChange, "start_date", "Select Start Date", "date"),
  end_date: (v, onChange) =>
    Hoc.setupText(v, onChange, "end_date", "Select End Date", "date"),
  category: (v, onChange, root) =>
    Hoc.setupSelect(v, onChange, "category", "Select Category", cats(root)),
  Category03Id: (v, onChange, opt) =>
    Hoc.setupSelect(v, onChange, "Category03Id", "Select Category", opt),
  ProductBoxId: (v, onChange, op, rt) =>
    Hoc.setupSelect(v, onChange, "ProductBoxId", "Select Product", PBS(op, rt)),
  StoreId: (v, onChange, op, rt) =>
    Hoc.setupSelect(v, onChange, "StoreId", "Select Store", STS(op, rt)),
};

const STS = (op, rt) => {
  let items = [];
  const INC = (v) => `${rt.role}`.includes(v);

  if (INC("Company")) {
    items = op;
  } else if (INC("Store") && rt?.Store?.id) {
    items = op
      ?.filter((v) => v.id === rt?.Store?.id)
      .map(({ StoreId, ...T }) => T);
  }

  return items;
};

const PBS = (op, rt) => {
  let items = [];
  const INC = (v) => `${rt.role}`.includes(v);

  if (INC("Store") && rt?.Store?.id) {
    items = op
      ?.filter((v) => v.StoreId === rt?.Store?.id)
      .map(({ StoreId, ...T }) => T);
  } else if (INC("Company")) {
    items = op;
  }
  return items;
};

const cats = (root) => {
  let items = [];
  const INC = (v) => `${root.role}`.includes(v);
  const PD01 = { id: "For All", title: "All Products" };
  const PD02 = { id: "Product", title: "Single Product" };
  const PD03 = { id: "Store", title: "Store" };
  const PD04 = { id: "Store", title: "All Products" };

  if (INC("Company")) {
    items = [PD01, PD02, PD03];
  } else if (INC("Store")) {
    items = [PD04, PD02];
  }
  return items;
};

export default CouponForm;
