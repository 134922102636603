import React, { useEffect } from "react";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Grid } from "@material-ui/core";
import moment from "moment";

const View = () => {
  const P = useParams();
  const dispatch = useDispatch();
  const Act_transaction = Action.base.transaction;

  const { data, error, loading } = useSelector(
    ({ base: B }) => B.transaction.view
  );

  useEffect(() => {
    dispatch(Act_transaction.view(P.id));
  }, [dispatch, Act_transaction, P]);

  const nav01 = {
    title: "Transaction Details",
    actions: [
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: () => dispatch(Act_transaction.view(P.id)) },
    ],
  };

  return (
    <Hoc.Dashboard.Container {...nav01}>
      <Hoc.UseData error={error} loading={loading}>
        {!data?.id && <Hoc.EmptyMessage />}

        <Grid {...gd01}>
          {ShowItem("Amount", data?.amount)}
          {ShowItem("Currency", data?.currency)}
          {ShowItem("Paid For", data?.paying_to)}
          {ShowItem("Person Role", data?.person_role)}
          {ShowItem("Person ID", data?.person_id)}
          {ShowItem("Status", data?.status)}
          {ShowItem("Payment Method", data?.method)}
          {ShowItem("Email", data?.email)}
          {ShowItem("Mobile", data?.mobile)}
          {ShowItem("Account Number", data?.account_number)}
          {ShowItem("account Name", data?.account_name)}
          {ShowItem("Bank Code", data?.bank_code)}
          {ShowItem("Branch Name", data?.branch_name)}
          {ShowItem("Country Name", data?.country_name)}
          {ShowItem("Transaction ID", data?.PD_transaction_id)}
          {ShowItem("Reference", data?.PD_reference)}
          {ShowItem(
            "Created Date",
            data?.createdAt ? ToDate(data?.createdAt) : null
          )}
          {ShowItem(
            "Last Modified",
            data?.updatedAt ? ToDate(data?.updatedAt) : null
          )}
          {ShowItem("Customer Id", data?.CustomerId)}
          {ShowItem("Order Id", data?.OrderId)}
          {ShowItem("Store Id", data?.StoreId)}
        </Grid>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const ToDate = (v) => moment(v).calendar();

const ShowItem = (n, v, FW) =>
  !!v && (
    <Grid item xs={12} {...(!FW && { sm: 5, md: 4, lg: 3 })}>
      <Hoc.ShowItem name={n} value={v} />
    </Grid>
  );
const gd01 = {
  container: true,
  justifyContent: "center",
  spacing: 1,
  style: { marginBottom: 10 },
};

export default View;
