import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.STORE_VIEW_ALL_REQUEST,
  success: types.STORE_VIEW_ALL_SUCCESS,
  fail: types.STORE_VIEW_ALL_FAIL,
};

export const stock_view_all = {
  name: "stock",
  state: defListState,
  request: types.STOCK_VIEW_ALL_REQUEST,
  success: types.STOCK_VIEW_ALL_SUCCESS,
  fail: types.STOCK_VIEW_ALL_FAIL,
};

export const my_store = {
  name: "my_store",
  state: defState,
  request: types.MY_STORE_REQUEST,
  success: types.MY_STORE_SUCCESS,
  fail: types.MY_STORE_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.STORE_VIEW_REQUEST,
  success: types.STORE_VIEW_SUCCESS,
  fail: types.STORE_VIEW_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.STORE_ADD_REQUEST,
  success: types.STORE_ADD_SUCCESS,
  fail: types.STORE_ADD_FAIL,
};

export const product_add = {
  name: "product_add",
  state: defState,
  request: types.STORE_PRODUCT_ADD_REQUEST,
  success: types.STORE_PRODUCT_ADD_SUCCESS,
  fail: types.STORE_PRODUCT_ADD_FAIL,
};

export const product_view = {
  name: "product_view",
  state: defState,
  request: types.STORE_PRODUCT_VIEW_REQUEST,
  success: types.STORE_PRODUCT_VIEW_SUCCESS,
  fail: types.STORE_PRODUCT_VIEW_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.STORE_EDIT_REQUEST,
  success: types.STORE_EDIT_SUCCESS,
  fail: types.STORE_EDIT_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.STORE_DELETE_REQUEST,
  success: types.STORE_DELETE_SUCCESS,
  fail: types.STORE_DELETE_FAIL,
};
