import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../../Controls";
import Custom from "../../../Custom";
import Hoc from "../../../Hoc";
import Action from "../../../../actions";
import Variant from "./Info/Variant";
import Desc from "./Info/Desc";
import Info from "./Info/Info";
import Photo from "./Info/Photo";
import { setup, initData } from "./Info/utils";
import { useDispatch, useSelector } from "react-redux";

const ProductInfoAddForm = (props) => {
  const dispatch = useDispatch();
  const Act_product = Action.product.product;
  const { data, setData } = props;
  const VFormData = Custom.useForm.useForm(initData());

  const [task, setTask] = useState("variant");

  const [variant, setVariant] = useState("NA");
  const [editable, setEditable] = useState(false);
  const closeEditable = () => setEditable(false);
  const root = useSelector(({ people: p }) => p.root.data);
  const product = useSelector(({ product: p }) => p.product.view.data);
  const onModelClose = () => {
    setData({ open: false, product: null });
    closeEditable();
    setTask("desc");
  };

  useEffect(() => {
    dispatch(Act_product.view(data?.productID));
  }, [dispatch, Act_product, data]);

  const CKS = (v) => task === v;

  const editableName = !!editable
    ? "Cancel"
    : CKS("desc")
    ? `Create Description`
    : CKS("photo")
    ? `Create Photo`
    : CKS("variant")
    ? `Create variant`
    : CKS("info")
    ? `Create Feature`
    : null;

  const isArr = Hoc.isArr;
  const BoxVariant = isArr(product?.Product_Boxes);
  const DescItems = BoxVariant?.map((v) => v.Descriptions).flat(Infinity);
  const PhotoItems = BoxVariant?.map((v) => v.Photos).flat(Infinity);
  const InfoItems = BoxVariant?.map((v) => v.Product_Infos).flat(Infinity);

  const refresh = () => dispatch(Act_product.view(data?.productID));

  const onCancelOrSave = () => {
    setEditable(!editable);
    VFormData?.setValues(initData());
  };
  return (
    <Hoc.Dialog
      open={data.open}
      onClose={onModelClose}
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="primary">
            {product?.title}
          </Typography>{" "}
          <Typography
            variant="subtitle2"
            color="secondary"
            style={{ cursor: "pointer" }}
            onClick={refresh}
          >
            Refresh
          </Typography>
        </Box>
      }
      fullWidth
      maxWidth="lg"
      actions={
        <Box>
          <Controls.Button
            text={editableName}
            variant={editable ? "contained" : null}
            onClick={onCancelOrSave}
            size="small"
          />
          <Controls.Button
            text="Close"
            color="secondary"
            size="small"
            onClick={onModelClose}
          />
        </Box>
      }
    >
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item xs={12} sm={5}>
          <form>
            <Controls.SearchSelect {...setup.task(task, setTask)} />
          </form>
        </Grid>

        {task !== "variant" && !CKS("detail") && (
          <Grid item xs={12} sm={5}>
            <form>
              <Controls.SearchSelect
                {...setup.variant(
                  variant,
                  setVariant,
                  Hoc.isArr(product?.Product_Boxes).filter((v) =>
                    ["Company_Manager", "Company_Admin"].includes(root?.role)
                      ? true
                      : v.StoreId === root?.Store?.id
                  )
                )}
              />
            </form>
          </Grid>
        )}

        {task === "variant" && !!product?.title && (
          <Grid item xs={12} sm={5}>
            <h5>{product?.title}</h5>
          </Grid>
        )}
      </Grid>

      {CKS("desc") ? (
        <Desc
          refresh={refresh}
          editable={editable}
          setEditable={setEditable}
          closeEditable={closeEditable}
          ProductBoxId={variant}
          items={DescItems}
        />
      ) : CKS("photo") ? (
        <Photo
          refresh={refresh}
          editable={editable}
          closeEditable={closeEditable}
          ProductBoxId={variant}
          items={PhotoItems}
        />
      ) : CKS("info") ? (
        <Info
          refresh={refresh}
          editable={editable}
          setEditable={setEditable}
          closeEditable={closeEditable}
          ProductBoxId={variant}
          items={InfoItems}
        />
      ) : CKS("variant") ? (
        <Variant
          title={product?.title}
          refresh={refresh}
          editable={editable}
          setEditable={setEditable}
          closeEditable={closeEditable}
          ProductId={product?.id}
          StoreId={product?.StoreId}
          items={product?.Product_Boxes}
          root={root}
          formData={VFormData}
        />
      ) : null}
    </Hoc.Dialog>
  );
};

export default ProductInfoAddForm;
