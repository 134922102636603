import React, { useEffect } from "react";
import Hoc from "../../../../comps/Hoc";
import Controls from "../../../../comps/Controls";
import action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";
import { Message } from "semantic-ui-react";

const MethodEnable = ({ method }) => {
  const dispatch = useDispatch();
  const act = action.base.payment;

  const { data, loading, error } = useSelector(
    ({ base: B }) => B.payment.status
  );

  useEffect(() => {
    dispatch(act.status());
  }, [dispatch, act]);

  const refresh = () => dispatch(act.status());

  const onChange = ({ target: T }) => {
    dispatch(act.edit_status(T.name, { enabled: T.value }));
    refresh();
  };
  const setInp = (name, value) => setup.PayMethod(name, value, onChange);

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Grid container spacing={2}>
        <GridItem {...setInp("PayPal", data?.PayPal)} />
        <GridItem {...setInp("Stripe", data?.Stripe)} />
        <GridItem {...setInp("RazorPay", data?.RazorPay)} />
        <GridItem {...setInp("MobilePay", data?.MobilePay)} />
        <GridItem {...setInp("Bank", data?.Bank)} />
      </Grid>
    </Hoc.UseData>
  );
};

const GridItem = (props) => {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Message size="tiny" color={!!props?.checked ? "blue" : "yellow"}>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Typography variant="subtitle1" color="primary">
            {props?.name}
          </Typography>
          <Controls.Switch {...props} />
        </Box>
      </Message>
    </Grid>
  );
};

const setup = {
  PayMethod: (name, checked, onChange) => ({
    name: name,
    label: !!checked ? "Enabled" : "Disabled",
    checked,
    onChange,
  }),
};

export default MethodEnable;
