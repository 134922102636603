import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Box } from "@material-ui/core";

import TrollyView02 from "./TrollyView/TrollyView02";
import TrollyNav from "./Hoc/TrollyNav";
import PreviousNext from "./Hoc/PreviousNext";
import { Message, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const ViewAll = () => {
  const Act_trolly = Action.store.trolly;
  const Act_order = Action.store.order;
  const Act_inventories = Action.store.inventories;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const Act_address = Action.base.address;
  const H = useHistory();
  const dispatch = useDispatch();
  const root = useSelector(({ people: S }) => S.root.data);
  const openIT = useSelector(({ store: S }) => S.order.open.data);
  const trollies = useSelector(({ store: v }) => v.trolly.view_all.data);
  const addList = useSelector(({ base: v }) => v.address.view_all.data);

  useEffect(() => {
    dispatch(Act_order.open_view());
    dispatch(Act_trolly.view_all(`status=Trolly`));
    dispatch(Act_address.view_all(`CustomerId=${root?.id}`));
  }, [dispatch, Act_order, Act_address, root, Act_trolly]);

  const refresh = () => {
    dispatch(Act_order.open_view());
    dispatch(Act_trolly.view_all(`status=Trolly`));
    dispatch(Act_address.view_all(`CustomerId=${root?.id}`));
  };

  const nav_items = [
    { name: "Back", goBack: true },
    { name: "Refresh", onClick: refresh },
  ];

  const onForward = async () => {
    const CKB = (v, ck) =>
      !ck ? !v?.Product_Box?.Store?.Collection_Address : v?.Product_Box?.name;
    let missing = trollies.filter((v) => CKB(v, 0)).map((v) => CKB(v, true));
    if (!!missing?.length) {
      H.push("/trolly-01");
      const its = Hoc.isArr(missing).join(", ");
      const msg = `${its}. These items does not have any store address. So please remove these items from trolly.`;
      return dispatch(Act_OOP(msg, true));
    }
    trollies?.forEach((T) => {
      const box = T?.Product_Box;
      const address01 = openIT?.Delivery_Address;
      const address02 = box?.Store?.Collection_Address;

      let Trolly = {
        country: address01?.country ? address01?.country : null,
        destination: T?.destination ? T?.destination : null,
        distance: T?.distance ? T?.distance : null,
        ST_shipping_price: T?.ST_shipping_price ? T?.ST_shipping_price : null,
        ST_vat_price: T?.ST_vat_price ? T?.ST_vat_price : null,
        ST_price: T?.ST_price ? T?.ST_price : null,
      };
      Trolly.ST_vat_price = box?.ST_vat_rate * (T?.S_price / 100);

      const distance = Hoc.toMiles(address01, address02);
      Trolly.destination =
        address01?.country !== address02?.country
          ? "Global"
          : distance < 70
          ? "Local"
          : "National";

      const CKD = (v) => Trolly.destination === v;

      if (T?.collectable === "None") {
        if (CKD("Local")) {
          Trolly.distance = distance;
          Trolly.ST_shipping_price = box?.Store?.vat_price * distance;
        } else if (CKD("National")) {
          Trolly.ST_shipping_price = box?.NT_shipping_price;
        } else if (CKD("Global")) {
          Trolly.ST_shipping_price = box?.NT_shipping_price + 5;
        }

        Trolly.ST_price =
          (T?.S_price + Trolly?.ST_shipping_price + Trolly?.ST_vat_price) *
          T?.sold_units;
      } else {
        Trolly.ST_price = (T?.S_price + Trolly?.ST_vat_price) * T?.sold_units;
      }

      let inventory = {
        B_price: box?.B_price,
        B_shipping_price: box?.BT_shipping_price / box?.total_units,
        B_vat_price: box?.BT_vat_price / box?.total_units,
        BT_price: null,
        TrollyId: T?.id,
      };

      inventory.BT_price =
        inventory.B_price + inventory.B_shipping_price + inventory.B_vat_price;

      dispatch(Act_inventories.view_add(inventory));
      dispatch(Act_trolly.view_edit(T?.id, Trolly));
    });
    dispatch(
      Act_order.view_edit(openIT?.id, {
        ...(openIT?.phase === "02" && { phase: "03" }),
      })
    );

    H.push("/trolly-03");
  };

  const Trefresh = () => dispatch(Act_trolly.view_all(`status=Trolly`));

  const CKAddress = !!openIT?.Billing_Address && !!openIT?.Delivery_Address;

  return (
    <Hoc.Page title="Trollies">
      <Hoc.Dashboard.Container title="Trollies" actions={nav_items}>
        <Box style={{ width: "100%" }}>
          {!!trollies?.length ? (
            <React.Fragment>
              <TrollyNav step={1} />
              <Box {...Hoc.flex("center")}>
                {!!addList?.length && !openIT?.Delivery_Address && (
                  <Button
                    content="Select Address"
                    color="teal"
                    onClick={() =>
                      H.push(`/order-address?OrderId=${openIT?.id}`)
                    }
                  />
                )}
                {!!openIT?.Delivery_Address && (
                  <Badge
                    badgeContent={"Edit"}
                    color="secondary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      H.push(`/order-address?OrderId=${openIT?.id}`)
                    }
                  >
                    <Message
                      style={{ maxWidth: 500, textAlign: "center", margin: 10 }}
                      content={Hoc.showAddress(openIT?.Delivery_Address)}
                      color="purple"
                      size="large"
                    />
                  </Badge>
                )}
                <Button
                  content="Add Address"
                  color="teal"
                  onClick={() => H.push(`/address-add?CustomerId=${root?.id}`)}
                />
              </Box>
              <TrollyView02 editable refresh={Trefresh} data={trollies} />

              <PreviousNext
                from="01"
                {...(CKAddress && { to: "03", onSubmit: onForward })}
              />
            </React.Fragment>
          ) : (
            <Message content="Empty Trollies" color="yellow" />
          )}
        </Box>
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default ViewAll;
