const oop_types = {
  CREATE_OOP: "CREATE_OOP",
  DELETE_OOP: "DELETE_OOP",
};

const oop_msg = {
  name: "oop_msg",
  state: { text: null, status: false },
  create: oop_types.CREATE_OOP,
  delete: oop_types.DELETE_OOP,
};

const Expo = {
  oop_msg,
  oop_types,
};

export default Expo;
