import React, { useState, useEffect } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import Action from "../../../actions";
import ReactRTE from "react-rte";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Template = (props) => {
  const Act = Action.base.template.action_name_view_all;
  const CSS = useCSS();
  const { title, onSubmit, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm, setValues } = formData;
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const SetVV = async () => {
      setActions(await Act());
    };
    SetVV();
  }, [Act]);

  let template_variables = actions?.filter((v) =>
    values?.actions?.includes(v?.name)
  );
  template_variables = template_variables?.map((v) => [...v.values]);
  template_variables = [...new Set(template_variables?.flat(1))];

  const onRteChange = (v) => {
    setValues({ ...values, editorState: v, html: v.toString("html") });
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: setup_status(values) }}
      >
        <Controls.Input {...setup.name(values.name, onChange)} />
        <Controls.Input {...setup.subject(values.subject, onChange)} />
        <Custom.Controls.EmailActionMultiSelect
          {...setup.actions(values.actions, actions, onChange)}
        />
        <ReactRTE value={values.editorState} onChange={onRteChange} />
      </Form>

      <Hoc.Dashboard.Container title="Template Variables">
        <Chip label="%url-(url name)%" className={CSS.chip} />
        {!!template_variables?.length &&
          template_variables.map((v) => (
            <Chip key={v} label={v} className={CSS.chip} />
          ))}
      </Hoc.Dashboard.Container>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) =>
  !v?.name || !v?.subject || !v?.html || v?.html === "<p><br></p>";

const setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Template Name"),
  subject: (v, onChange) =>
    Hoc.setupText(v, onChange, "subject", "Email Subject"),
  html: (v, onChange) => Hoc.setupText(v, onChange, "html", "html"),
  actions: (v, opt, onChange) =>
    Hoc.setupSelect(v, onChange, "actions", "Select Actions", opt),
};

const useCSS = makeStyles((theme) => ({
  chip: {
    margin: 2,
  },
}));

export default Template;
