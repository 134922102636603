import { combineReducers } from "redux";
import { toReducer } from "../../utils";
import * as reducer from "./reducer";

const cmp_admin = combineReducers({
  ...toReducer(reducer.sign_up),
  ...toReducer(reducer.sign_in),
  ...toReducer(reducer.verify_email),
  ...toReducer(reducer.view_profile),
  ...toReducer(reducer.edit_profile),
  ...toReducer(reducer.forgot_password),
  ...toReducer(reducer.reset_password),
  ...toReducer(reducer.edit_password),
  // CRUD
  ...toReducer(reducer.view_all),
  ...toReducer(reducer.view_add),
  ...toReducer(reducer.view_edit),
  ...toReducer(reducer.view_delete),
});

export default cmp_admin;
