import React, { useEffect } from "react";
import Hoc from "../../../../comps/Hoc";
import action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { Header } from "semantic-ui-react";

const ViewProduct = () => {
  const act_box = action.product.box;
  const P = useParams();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(({ product: P }) => P.box.view);
  useEffect(() => {
    dispatch(act_box.view(P.id));
  }, [dispatch, act_box, P]);

  const nav_01 = {
    title: "Product",
    actions: [
      { name: "Public View", to: `/product-variant/${data?.id}` },
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: (f) => f },
    ],
  };
  const GridTitle = ({ content }) => (
    <Grid item xs={12} style={{ margin: "10px 0" }}>
      <Header textAlign="center" color="green" content={content} />
    </Grid>
  );

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData loading={loading} error={error}>
        <GridTitle content="Basic details" />
        {ShowItem("Name", data?.name)}
        {ShowItem(
          "Currency",
          `${data?.Currency?.name} (${data?.Currency?.currency})`
        )}
        {ShowItem("Collectability", data?.collectable)}
        {ShowItem("Destination", data?.destination)}
        {ShowItem("Expiry date", moment(data?.expires).format("LL"))}
        {ShowItem("Subscription", data?.subscription)}
        {ShowItem("Published", !data?.publish ? "No" : "Yes")}

        <GridTitle content="Product Dimentions" />
        {ShowItem("Weight", data?.weight)}
        {ShowItem("Length", data?.length)}
        {ShowItem("Height", data?.height)}
        {ShowItem("Width", data?.width)}

        <GridTitle content="Price details (Making)" />

        {ShowItem(
          "Shipping Price",
          Hoc.toCurrency(data?.Currency?.currency, data?.BT_shipping_price)
        )}
        {ShowItem(
          "Total Price",
          Hoc.toCurrency(data?.Currency?.currency, data?.BT_total_price)
        )}
        {ShowItem(
          "VAT / GST",
          Hoc.toCurrency(data?.Currency?.currency, data?.BT_vat_price)
        )}
        {ShowItem(
          "Unit rice",
          Hoc.toCurrency(data?.Currency?.currency, data?.B_price)
        )}

        <GridTitle content="Price details (Selling)" />
        {ShowItem("VAT / GST", `${data?.ST_vat_rate} %`)}

        {ShowItem(
          "Shipping Price (National)",
          Hoc.toCurrency(data?.Currency?.currency, data?.NT_shipping_price)
        )}
        {ShowItem(
          "Unit Price",
          Hoc.toCurrency(data?.Currency?.currency, data?.S_price)
        )}

        <GridTitle content="Quantity details" />
        {ShowItem("Total Units", data?.total_units)}
        {ShowItem("Trolly Units", data?.trolly_units)}
        {ShowItem("Defect Units", data?.defect_units)}
        {ShowItem("Sold Units", data?.sold_units)}
        {ShowItem("Quantity", data?.quantity)}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const ShowItem = (n, v) =>
  !!v && (
    <Grid item xs={12} sm={5} lg={4}>
      <Hoc.ShowItem name={n} value={v} />
    </Grid>
  );

export default ViewProduct;
