import React, { useEffect, useState } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import { Typography, Box } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA, { useReCAPTCHA } from "../../Hoc/base02/ReCAPTCHA";

const SignIn = (props) => {
  const { reCaptcha, handleReCaptcha } = useReCAPTCHA();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const h = useHistory();
  const { title, endLink, forgotPsw } = props;
  const { submitAction = (f) => f, to, formData } = props;
  const CSS = useCSS();
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;

  const root = useSelector(({ people: v }) => v.root.data);

  useEffect(() => {
    if (root?.token && to.role === root?.role) {
      h.push(to?.url);
    }
  }, [root, h, to]);

  const endContent = (
    <Box display="flex" justifyContent="space-between">
      <Typography
        className={CSS.endText}
        component={RouterLink}
        to={forgotPsw}
        color="secondary"
      >
        Forgot Password!.
      </Typography>
      <Typography
        className={CSS.endText}
        component={RouterLink}
        to={endLink}
        color="primary"
      >
        Don't have an account? Sign UP
      </Typography>
    </Box>
  );

  const onSubmit = () => {
    setLoading(true);
    dispatch(submitAction(values));
    setLoading(false);
    resetForm();
  };

  let RTXT = !!root?.role ? `${root?.role}`.split("_").join(" ") : null;
  RTXT = !!RTXT
    ? `Hi there, You have already logged in as ${RTXT}. If you want to proceed with ${title} please logout your existing account and try.`
    : null;

  const title01 = !RTXT ? title : RTXT;

  return (
    <Hoc.FormContainer title={title01}>
      {!RTXT && (
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          endContent={endContent}
          submitButton={{
            disabled: setup_status(values) || loading || !reCaptcha,
          }}
        >
          <Controls.Input {...setup.email(values.email, onChange)} />
          <Controls.Input {...setup.password(values.password, onChange)} />
          <ReCAPTCHA handleReCaptcha={handleReCaptcha} />
        </Form>
      )}
    </Hoc.FormContainer>
  );
};

const setup_status = (v) => !v.email || `${v?.password}`.length < 6;

const useCSS = makeStyles((theme) => ({
  endText: {
    marginTop: theme.spacing(1),
    fontSize: 13,
  },
}));

const setup = {
  email: (v, onChange) =>
    Hoc.setupText(v, onChange, "email", "Email Address", "email"),
  password: (v, onChange) => ({
    ...Hoc.setupText(v, onChange, "password", "Password", "password"),
    inputProps: {
      minLength: 6,
    },
  }),
};

export default SignIn;
