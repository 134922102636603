import React from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import Action from "../../../actions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const SignIn = (props) => {
  const Act_Opp = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const H = useHistory();
  const { title, formData, root } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const CKR = (v) => root?.role === v;

  const Act = CKR("Company_Manager")
    ? Action.people.C1.edit_password
    : CKR("Company_Admin")
    ? Action.people.C2.edit_password
    : CKR("Store_Manager")
    ? Action.people.S1.edit_password
    : CKR("Store_Employee")
    ? Action.people.S2.edit_password
    : CKR("Customer")
    ? Action.people.Customer.edit_password
    : null;

  const Act_logout = Action.people.root.sign_out;

  const onSubmit = async () => {
    const res = await dispatch(
      Act({
        id: root?.id,
        old_password: values.old_password,
        password: values.password,
      })
    );
    if (!!res) {
      resetForm();
      const url = dispatch(Act_logout());
      H.push(url);

      dispatch(Act_Opp("Password Updated successfully!!."));
    }
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: setup_status(values) }}
      >
        <Controls.Input
          {...setup.old_password(values.old_password, onChange)}
        />
        <Controls.Input {...setup.password(values.password, onChange)} />
        <Controls.Input {...setup.password2(values.password2, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) =>
  !v?.old_password ||
  `${v?.password}`.length < 6 ||
  v?.password !== v?.password2;

const setup = {
  old_password: (value, onChange) => ({
    type: "password",
    name: "old_password",
    label: "Current Password",
    value,
    onChange,
    required: true,
  }),

  password: (value, onChange) => ({
    type: "password",
    name: "password",
    label: "New Password",
    value,
    onChange,
    required: true,
  }),
  password2: (value, onChange) => ({
    type: "password",
    name: "password2",
    label: "Confirm Password",
    value,
    onChange,
    required: true,
    inputProps: {
      minLength: 6,
    },
  }),
};

export default SignIn;
