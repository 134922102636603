import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.payment;

const URL = `${Helper.URL}/payment`;

//   ---------------------------------------   Payments

export const status = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_STS_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/status`);

    dispatch({ type: types.PAY_STS_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_STS_FAIL);
  }
};

export const method_list = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_MTLIST_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/method-list`);

    dispatch({ type: types.PAY_MTLIST_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_MTLIST_FAIL);
  }
};

export const edit_status = (cat, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_ED_STS_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/status?category=${cat}`, initData);

    dispatch({ type: types.PAY_ED_STS_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_ED_STS_FAIL);
  }
};

export const view_all = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_LIST_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(URL);

    dispatch({ type: types.PAY_LIST_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_LIST_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(URL, initData);

    dispatch({ type: types.PAY_ADD_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);

    dispatch({ type: types.PAY_EDIT_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_EDIT_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.PAY_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_VIEW_FAIL);
  }
};

export const method = (method) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_METHOD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/method?category=${method}`);

    dispatch({ type: types.PAY_METHOD_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_METHOD_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.PAY_DELETE_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_DELETE_FAIL);
  }
};

export const send_bank_info = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SEND_BANK_INFO_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${URL}/bank-info`, {
      email: state()?.people?.root?.data?.email,
      ...initData,
    });

    dispatch({ type: types.SEND_BANK_INFO_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SEND_BANK_INFO_FAIL);
  }
};

export const send_mobile_payinfo = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SEND_MOBILE_PAY_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(`${URL}/mobile-pay-info`, {
      ...initData,
      email: state()?.people?.root?.data?.email,
    });

    dispatch({
      type: types.SEND_MOBILE_PAY_SUCCESS,
      payload: data?.result,
    });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SEND_MOBILE_PAY_FAIL);
  }
};

export const view_queues = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.VIEW_QUEUES_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/queue-system`);

    dispatch({ type: types.VIEW_QUEUES_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.VIEW_QUEUES_FAIL);
  }
};
