import React, { useEffect, useState } from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../Controls";
import Hoc from "../../../Hoc";
import Custom from "../../../Custom";
import ProductInfoAddForm from "./ProductInfoAddForm";

const ProductTrolly = (props) => {
  const [openInfo, setOpenInfo] = useState({ open: false, productID: null });
  const { Act, view_all, title } = props;
  const dispatch = useDispatch();
  const { data, error, loading } = view_all;

  const root = useSelector(({ people: p }) => p.root.data);

  useEffect(() => {
    dispatch(Act.view_all());
  }, [dispatch, Act]);

  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const tbl = Custom.useTable(data, headCells, filterFn);

  const { TblContainer, finalRecords } = tbl;

  const onSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.title.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const CKR = (v) => `${root?.role}`.includes(v);

  const rolevrf = !!(CKR("Company") || !!(CKR("Store") && !!root?.Store));

  const menuActions = (v) => {
    let result = [];

    const TOT = (name, to) => ({ name, to });
    const TOC = (name, onClick) => ({ name, onClick });

    if (rolevrf && root?.privilege?.includes("Edit")) {
      result = [
        ...result,
        TOC("Product Variant", () =>
          setOpenInfo({ open: true, productID: v.id })
        ),
        TOT("Edit", `product-edit/${v.id}`),
      ];
    }

    if (rolevrf && root?.privilege?.includes("Delete")) {
      result = [
        ...result,
        TOC("Delete", () => dispatch(Act.view_delete(v.id))),
      ];
    }

    return result;
  };

  const onRefresh = () => dispatch(Act.view_all());

  const userLength = Array.isArray(finalRecords()) && finalRecords()?.length;
  const C_roles = ["Company_Manager", "Company_Admin"];

  const TOT = (name, to) => ({ name, to });

  const nav01 = {
    title: title,
    actions: [
      !root?.Store?.id
        ? TOT("Category", "/category-trolly")
        : [
            TOT("My Products", "/store-products"),
            TOT("Category", "/category-trolly"),
          ],
      C_roles.includes(root.role)
        ? TOT("Stores", "/stores")
        : TOT("My Store", "/my-store"),
    ].flat(1),
  };

  return (
    <React.Fragment>
      <ProductInfoAddForm data={openInfo} setData={setOpenInfo} />
      <Hoc.Page title={title}>
        <Hoc.PageBox {...nav01}>
          {Hoc.PageTabs(
            [...top_nav(onRefresh, root, rolevrf)],
            userLength
              ? `Products Count : ${userLength}`.toUpperCase()
              : "Empty Records"
          )}
          <Box {...Hoc.flex("space-between")} style={{ marginBottom: 10 }}>
            <Box style={{ maxWidth: 200 }}>
              <Controls.Input
                label="Search"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
              />
            </Box>
          </Box>

          <Hoc.UseData error={error} loading={loading}>
            {!!userLength ? (
              <TblContainer paginate>
                {finalRecords()?.map((v, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{v.id}</TableCell>
                    <TableCell>{v.title}</TableCell>
                    <TableCell>{v.brand}</TableCell>
                    <TableCell>
                      {v?.Store?.name ? v?.Store?.name : v.StoreId}
                    </TableCell>
                    <TableCell>
                      {v?.Category_03?.name
                        ? v?.Category_03?.name
                        : v?.Category03Id}
                    </TableCell>
                    <TableCell>{Hoc.isArr(v?.Product_Boxes).length}</TableCell>
                    <TableCell>
                      <Hoc.Product.MenuButton actions={menuActions(v)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TblContainer>
            ) : (
              <Hoc.Message text="Empty resourse on Shop." color="yellow" />
            )}
          </Hoc.UseData>
        </Hoc.PageBox>
      </Hoc.Page>
    </React.Fragment>
  );
};

const top_nav = (onClick, root, rolevrf) => {
  let res = [];

  const vrf = rolevrf && root?.privilege?.includes("Add");
  if (!!vrf) {
    res = [{ name: "Create New", to: "/product-add" }];
  }

  res = [
    ...res,
    { name: "Support Ticket", to: `/root-general-tickets` },
    { name: "Refresh", onClick },
  ];

  return res;
};

const headCells = [
  { id: "id", label: "ID" },
  { id: "title", label: "Title" },
  { id: "brand", label: "Brand" },
  { id: "StoreId", label: "Store Name" },
  { id: "Category03Id", label: "Category Name" },
  { id: "variants", label: "Variants Quantity" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default ProductTrolly;
