import React from "react";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";

const SignIn = () => {
  const Act = Action.people.Customer.sign_in;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.SignIn
      title="Customer Sign In"
      endLink="/customer-sign-up"
      forgotPsw="/customer-forgot-password"
      submitAction={Act}
      formData={formData}
      to={{ url: "/customer-dashboard", role: "Customer" }}
    />
  );
};

const initValues = {
  email: "",
  password: "",
};

export default SignIn;
