import React, { useEffect, useState } from "react";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import Controls from "../../../../comps/Controls";
import Action from "../../../../actions";
import ReactRTE from "react-rte";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const Template = (props) => {
  const Act_mail = Action.base02.sent_mail;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const H = useHistory();
  const [multiRole, setMultiRole] = useState(false);
  const { title, id } = props;
  const { Form, useForm } = Custom.useForm;
  const formData = useForm(initData());
  const { values, onChange, resetForm, setValues } = formData;

  useEffect(() => {
    const SetVV = async () => {
      const data = await dispatch(Act_mail.view(id));
      setValues(initData(data));
      setMultiRole(`${data?.role}`.includes("_"));
    };

    !!id && SetVV();
  }, [dispatch, setValues, Act_mail, id]);

  const onSubmit = async () => {
    const finalData = {
      ...(({ editorState, ...rest }) => rest)(values),
    };

    const status = await dispatch(
      !id ? Act_mail.view_add(finalData) : Act_mail.view_edit(id, finalData)
    );

    if (!!status) {
      H.goBack();
      dispatch(
        Act_OOP(`Email Template ${!id ? "Created" : "Updated"} Succesfully.`)
      );
    }
  };

  const onRteChange = (v) =>
    setValues({ ...values, editorState: v, html: v.toString("html") });

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: setup_status(values) }}
      >
        <Controls.Input {...setup.name(values.name, onChange)} />
        <Controls.Input {...setup.email_from(values.email_from, onChange)} />
        <Controls.Input {...setup.subject(values.subject, onChange)} />
        <ControlsSwitch
          {...setup.multiRole(multiRole, ({ target: T }) =>
            setMultiRole(T.value)
          )}
        />
        <TwoItems>
          <OneInput item="inp" {...setup.duration(values.duration, onChange)} />
          <OneInput
            item="select"
            {...setup.role(values.role, onChange, multiRole)}
          />
        </TwoItems>
        <ControlsSwitch {...setup.custom_date(values.custom_date, onChange)} />
        <Box {...Hoc.flex()}></Box>
        {!!values?.custom_date && (
          <TwoItems>
            <OneInput {...setup.date(values.date, onChange)} />
            <OneInput {...setup.time(values.time, onChange)} />
          </TwoItems>
        )}
        <Box style={{ marginTop: 20 }} />
        <ReactRTE value={values.editorState} onChange={onRteChange} />
      </Form>
    </Hoc.FormContainer>
  );
};

const TwoItems = ({ children }) => (
  <Box {...Hoc.flex("space-around")}> {children}</Box>
);

const ControlsSwitch = (rest) => (
  <Box {...Hoc.flex()}>
    <Controls.Switch {...rest} />
  </Box>
);

const OneInput = ({ item, ...rest }) => (
  <Box>
    {item === "select" ? (
      <Controls.SearchSelect {...rest} />
    ) : item === "inp" ? (
      <Controls.Input {...rest} />
    ) : (
      <Controls.DatePicker {...rest} />
    )}
  </Box>
);

const initData = (v) => ({
  editorState: v?.html
    ? ReactRTE.createValueFromString(v?.html, "html")
    : ReactRTE.createEmptyValue(),
  name: v?.name ? v?.name : null,
  email_from: v?.email_from ? v?.email_from : null,
  subject: v?.subject ? v?.subject : null,
  html: v?.html ? v?.html : null,
  duration: v?.duration ? v?.duration : null,
  custom_date: v?.custom_date ? v?.custom_date : null,
  role: v?.role ? v?.role : null,
  date: v?.date ? v?.date : null,
  time: v?.time ? v?.time : null,
});

const SS01 = (v) =>
  !v?.name ||
  !v?.email_from ||
  !v?.subject ||
  !v?.duration ||
  !v?.html ||
  v?.html === "<p><br></p>";
const SS02 = (v) =>
  !v?.role || !!(!!v?.custom_date && !!(!v?.date || !v?.time));
const setup_status = (v) => SS01(v) || SS02(v);

const setup = {
  custom_date: (v, onChange) =>
    Hoc.setupSwitch(v, onChange, "custom_date", "Send on a Specific date."),
  multiRole: (v, onChange) =>
    Hoc.setupSwitch(v, onChange, "multiRole", "Send mail to multiple Groups."),
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Template Name"),
  date: (v, onChange) => Hoc.setupText(v, onChange, "date", "Date", "date"),
  time: (v, onChange) => Hoc.setupText(v, onChange, "time", "Time", "time"),
  role: (v, onChange, ml) =>
    Hoc.setupSelect(v, onChange, "role", "Select Role", RL01(ml)),
  duration: (v, onChange) => ({
    ...Hoc.setupText(
      v,
      onChange,
      "duration",
      "Duration (1Sec = 1000)",
      "number"
    ),
    inputProps: { min: 0 },
  }),

  email_from: (v, onChange) =>
    Hoc.setupText(v, onChange, "email_from", "From Message"),
  subject: (v, onChange) =>
    Hoc.setupText(v, onChange, "subject", "Email Subject"),
  html: (v, onChange) => Hoc.setupText(v, onChange, "html", "html"),
};

const TOTL = (id, title) => ({ id, title });

const RL01 = (ml) => (!ml ? oneRow : multiRows);

const oneRow = [
  TOTL("SB", "For Subscribers"),
  TOTL("GN", "For Customer"),
  TOTL("S2", "For Store Employees"),
  TOTL("S1", "For Store Managers"),
  TOTL("C2", "For Company Admins"),
  TOTL("C1", "For Company Managers"),
];

const multiRows = [
  TOTL("SB_GN_S2_S1_C2_C1", "For Everyone"),
  TOTL("S2_S1_C2_C1", "For Company & Store People"),
  TOTL("C2_C1", "For Company People"),
  TOTL("S2_S1", "For Store People"),
  TOTL("SB_GN_S2_S1", "Subscribers, Customers & Store People"),
  TOTL("SB_GN", "Subscribers & Customers"),
];

export default Template;
