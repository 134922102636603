import { combineReducers } from "redux";

import item from "./item";
import store_types from "./item/types";

import order from "./order";
import order_types from "./order/types";

import trolly from "./trolly";
import trolly_types from "./trolly/types";

import track from "./track";
import track_types from "./track/types";

import inventories from "./inventories";
import inventories_types from "./inventories/types";

const Expo = combineReducers({ track, inventories, trolly, item, order });

export const store_type = {
  inventories: inventories_types,
  track: track_types,
  trolly: trolly_types,
  store: store_types,
  order: order_types,
};

export default Expo;
