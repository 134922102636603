const types = {
  //
  CATEGORY_02_VIEW_ALL_REQUEST: "CATEGORY_02_VIEW_ALL_REQUEST",
  CATEGORY_02_VIEW_ALL_SUCCESS: "CATEGORY_02_VIEW_ALL_SUCCESS",
  CATEGORY_02_VIEW_ALL_FAIL: "CATEGORY_02_VIEW_ALL_FAIL",
  //
  CATEGORY_02_VIEW_REQUEST: "CATEGORY_02_VIEW_REQUEST",
  CATEGORY_02_VIEW_SUCCESS: "CATEGORY_02_VIEW_SUCCESS",
  CATEGORY_02_VIEW_FAIL: "CATEGORY_02_VIEW_FAIL",
  //
  CATEGORY_02_VIEW_SLUG_REQUEST: "CATEGORY_02_VIEW_SLUG_REQUEST",
  CATEGORY_02_VIEW_SLUG_SUCCESS: "CATEGORY_02_VIEW_SLUG_SUCCESS",
  CATEGORY_02_VIEW_SLUG_FAIL: "CATEGORY_02_VIEW_SLUG_FAIL",
  //
  CATEGORY_02_EDIT_REQUEST: "CATEGORY_02_EDIT_REQUEST",
  CATEGORY_02_EDIT_SUCCESS: "CATEGORY_02_EDIT_SUCCESS",
  CATEGORY_02_EDIT_FAIL: "CATEGORY_02_EDIT_FAIL",
  //
  CATEGORY_02_ADD_REQUEST: "CATEGORY_02_ADD_REQUEST",
  CATEGORY_02_ADD_SUCCESS: "CATEGORY_02_ADD_SUCCESS",
  CATEGORY_02_ADD_FAIL: "CATEGORY_02_ADD_FAIL",
  //
  CATEGORY_02_DELETE_REQUEST: "CATEGORY_02_DELETE_REQUEST",
  CATEGORY_02_DELETE_SUCCESS: "CATEGORY_02_DELETE_SUCCESS",
  CATEGORY_02_DELETE_FAIL: "CATEGORY_02_DELETE_FAIL",
};

export default types;
