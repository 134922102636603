import * as C1 from "./cmp_manager";
import * as C2 from "./cmp_admin";
import * as S1 from "./store_manager";
import * as S2 from "./store_employee";
import * as Customer from "./customer";
import * as root from "./root";

const Expo = {
  C1,
  C2,
  S1,
  S2,
  Customer,
  root,
};

export default Expo;
