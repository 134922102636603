import React, { useEffect, useState } from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../Controls";
import Hoc from "../../Hoc";
import Custom from "../../Custom";
import { Confirm } from "semantic-ui-react";

const Root = (props) => {
  const { Act, view_all, to, title, queryString } = props;
  const dispatch = useDispatch();
  const root = useSelector(({ people: v }) => v.root.data);
  const { data } = view_all;
  const [ConfirmMsg, setConfirmMsg] = useState(null);

  useEffect(() => {
    dispatch(Act.view_all(queryString || null));
  }, [dispatch, Act, queryString]);

  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const tbl = Custom.useTable(data, headCells, filterFn);
  const { TblContainer, finalRecords } = tbl;

  const onSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
      },
    });
  };
  const userLength = Array.isArray(finalRecords()) ? finalRecords().length : 0;
  const TOT = (name, to) => ({ name, to });
  const TOC = (name, onClick) => ({ name, onClick });
  const CMP = root?.role === "Company_Manager";

  const secNav = () => {
    let items = [
      TOC("Refresh", () => dispatch(Act.view_all(queryString || null))),
    ];

    if (CMP) {
      items = [TOT("Create New", "/store-add"), ...items];
    }
    return items;
  };

  const menuActions = (v) => {
    let items = [TOT("View", `/store-view/${v.id}`)];

    const STR =
      root?.role === "Store_Manager" &&
      v?.StoreManagerId === root?.id &&
      `${root?.privilege}`.includes("Edit");

    if (CMP || !!STR) {
      items = [
        TOT("Edit", `/store-edit/${v.id}`),
        TOT("Currencies", `/store-currency-add/${v.id}`),
        ...items,
      ];

      const TOL = (v) => `/address-edit/${v}`;
      const ST01 = `StoreAddressId=${v?.id}`;
      const ST02 = `CollectionAddressId=${v?.id}`;

      const TOADD01 = (n) => {
        if (["01", "02"].includes(n)) {
          return !!v?.Store_Address?.id
            ? TOL(v?.Store_Address?.id)
            : `/address-add?${ST01}${n === "01" ? "&" : ""}${
                n === "01" ? ST02 : ""
              }`;
        } else {
          return !!v?.Collection_Address?.id
            ? TOL(v?.Collection_Address?.id)
            : `/address-add?${ST02}`;
        }
      };

      if (!!v?.one_address) {
        items = [TOT("Address", TOADD01("01")), ...items];
      } else {
        items = [
          TOT("Store Address", TOADD01("02")),
          TOT("Collection Address", TOADD01("03")),
          ...items,
        ];
      }
    }

    if (root?.role === "Company_Manager") {
      items = [
        ...items,
        TOC("Delete", () => setConfirmMsg(v.id)),
        TOT("Pay to the Store", `/transaction-add?StoreId=${v.id}`),
      ];
    }

    return items;
  };
  const onConfirmDelete = () => {
    dispatch(Act.view_delete(ConfirmMsg));
    setConfirmMsg(null);
    dispatch(Act.view_all(queryString || null));
  };
  return (
    <React.Fragment>
      <Hoc.Page title={title}>
        <Hoc.PageBox
          title={title}
          actions={[...list_people.filter((v) => !`${v.to}`.includes(to))]}
        >
          {Hoc.PageTabs(
            secNav(),
            userLength ? `Store Count : ${userLength}` : "Empty Records"
          )}
          <Confirm
            header="Confirm"
            open={!!ConfirmMsg}
            content="Yes, I want to delete the Store."
            onCancel={() => setConfirmMsg(null)}
            onConfirm={onConfirmDelete}
            size="tiny"
          />

          <Box style={{ maxWidth: 200, marginBottom: 10 }}>
            <Controls.Input
              label="Search"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={onSearch}
            />
          </Box>

          {!!userLength ? (
            <TblContainer paginate>
              {finalRecords().map((v, idx) => (
                <TableRow key={idx}>
                  <TableCell>{v.id}</TableCell>
                  <TableCell>
                    <Controls.Switch
                      checked={!!v.Collection_Address && !!v.Store_Address}
                    />
                  </TableCell>
                  <TableCell>{v.StoreManagerId || "NA"}</TableCell>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>{v.branch}</TableCell>
                  <TableCell>{v.vat_price}</TableCell>
                  <TableCell>
                    <Hoc.Product.MenuButton
                      text="Action"
                      actions={menuActions(v)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TblContainer>
          ) : (
            <Hoc.Message text="Empty people in this Section." color="yellow" />
          )}
        </Hoc.PageBox>
      </Hoc.Page>
    </React.Fragment>
  );
};

const list_people = [
  { name: "Product", to: "/product-view-all-trolly" },
  { name: "Stores", to: "/stores" },
  { name: "Orders", to: "/orders" },
];

const headCells = [
  { id: "ID", label: "ID" },
  { id: "Address", label: "Address Status" },
  { id: "ManagerId", label: "Manager Id" },
  { id: "Name", label: "Name" },
  { id: "Branch", label: "Branch" },
  { id: "vat_gst", label: "VAT / GST" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default Root;
