import React from "react";
import { useParams } from "react-router";
import Custom from "../../../comps/Custom";

const AddAbout = () => {
  const P = useParams();
  const formData = Custom.useForm.useForm(initData());

  const cat = formData?.values?.category;

  return (
    <Custom.Base.Notices.NoticeForm
      title={`Updating ${cat !== "Add" ? cat : "Advertisement"}`}
      id={P?.id}
      formData={formData}
    />
  );
};

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  description: v?.description ? v?.description : "",
  photo: v?.photo ? v?.photo : "",
  category: v?.category ? v?.category : "",
  roles: v?.roles ? v?.roles : "",
  add_for: v?.add_for ? v?.add_for : "",
  enabled: v?.enabled ? v?.enabled : false,
  Category01Id: v?.Category01Id ? v?.Category01Id : null,
  Category02Id: v?.Category02Id ? v?.Category02Id : null,
  Category03Id: v?.Category03Id ? v?.Category03Id : null,
  ProductBoxId: v?.ProductBoxId ? v?.ProductBoxId : null,
  StoreId: v?.StoreId ? v?.StoreId : null,
});

export default AddAbout;
