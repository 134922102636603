import React, { useEffect } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Header } from "semantic-ui-react";

const CouponAdd = () => {
  const Act_coupon = Action.product.coupon;
  const dispatch = useDispatch();

  const AllCP = useSelector(({ product: P }) => P.coupon.view_all);
  const { data: D01, error: E01, loading: L01 } = AllCP;
  const AllST = useSelector(({ product: P }) => P.coupon.view_all_store);
  const { data: D02, error: E02, loading: L02 } = AllST;

  const root = useSelector(({ people: P }) => P.root.data);
  const personRole = `${root?.role}`.includes("Store")
    ? "S"
    : `${root?.role}`.includes("Company")
    ? "C"
    : "GN";
  const error = personRole === "S" ? E02 : E01;
  const loading = personRole === "S" ? L02 : L01;
  const data = personRole === "S" ? D02 : D01;
  const { finalRecords, TblContainer } = Custom.useTable(data, cells);

  const refresh = () => {
    if (personRole === "C") {
      dispatch(Act_coupon.view_all());
    } else if (personRole === "S" && root?.Store?.id) {
      dispatch(Act_coupon.view_all_store(root?.Store?.id));
    }
  };

  useEffect(() => {
    if (personRole === "C") {
      dispatch(Act_coupon.view_all());
    } else if (personRole === "S" && root?.Store?.id) {
      dispatch(Act_coupon.view_all_store(root?.Store?.id));
    }
  }, [dispatch, Act_coupon, root, personRole]);

  const actions = [TOT("Create New", "/add-coupon"), TOC("Refresh", refresh)];

  const CPActions = (v) => {
    let items = [];

    const onDelete = () => {
      dispatch(Act_coupon.view_delete(v));
      refresh();
    };

    const view = TOT("View", `/view-coupon/${v}`);
    const edit = TOT("edit", `/edit-coupon/${v}`);
    const delete_item = TOC("Delete", onDelete);

    items = [view, edit, delete_item];

    return items;
  };

  const GetRef = (v) =>
    v?.StoreId
      ? v?.Store?.name
      : v?.Category03Id
      ? `CAT${v?.Category03Id}`
      : v?.ProductBoxId
      ? v?.Product_Box?.name
      : "NA";

  return (
    <Hoc.Dashboard.Container title="Coupons" actions={actions}>
      <Hoc.UseData error={error} loading={loading}>
        {!!finalRecords().length ? (
          <TblContainer paginate>
            {finalRecords().map((v) => (
              <TableRow key={v.id}>
                <TableCell>{v.name}</TableCell>
                <TableCell>{v.category}</TableCell>
                <TableCell>{GetRef(v)}</TableCell>
                <TableCell>{v.ratio}</TableCell>
                <TableCell>{toDate(v.start_date)}</TableCell>
                <TableCell>{toDate(v.end_date)}</TableCell>
                <TableCell>
                  <Header
                    content={CKDD(v.end_date) ? "Not Expired" : "Expired"}
                    color={CKDD(v.end_date) ? "green" : "red"}
                    size="tiny"
                  />
                </TableCell>
                <TableCell>
                  <Hoc.Product.MenuButton actions={CPActions(v?.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TblContainer>
        ) : (
          <Hoc.EmptyMessage />
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const CKDD = (v) => new Date(v).getTime() > new Date(new Date()).getTime();

const toDate = (v) => moment(v).calendar();

const cells = [
  { id: "name", label: "Name" },
  { id: "category", label: "Category" },
  { id: "reference", label: "Reference" },
  { id: "ratio", label: "Percentage" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "Status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const TOC = (name, onClick) => ({ name, onClick });

const TOT = (name, to) => ({ name, to });

export default CouponAdd;
