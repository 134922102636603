import Helper from "../helper";
import { Type } from "../../redux";
import { v4 as uuid } from "uuid";
import axios from "axios";

const types = Type.store_type.trolly;
const notif_type = Type.base02_type.notif;
const CREATE_OOP = Type.base02_type.oop_msg.CREATE_OOP;

const URL = `${Helper.URL}/store-trolly`;

export const count = () => async (dispatch, state) => {
  try {
    const role = state()?.people?.root?.data?.role;
    if (!role || role !== "Customer") return;
    dispatch({ type: types.TROLLY_COUNT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/count`);
    dispatch({ type: types.TROLLY_COUNT_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_COUNT_FAIL);
  }
};

export const view_add = (qty, v) => async (dispatch, state) => {
  try {
    const isArr = (v) => (Array.isArray(v) ? v : []);
    dispatch({ type: types.TROLLY_ADD_REQUEST });
    Helper.set_jwt(state());
    const CustomerId = state()?.people?.root?.data?.id;
    const role = state()?.people?.root?.data?.role;
    const CurrencyId = state()?.people?.root?.data?.Currency?.id;
    const CurrencyRate = state()?.people?.root?.data?.Currency?.value;

    if (!CurrencyId || !CurrencyRate) {
      const text = "Please select a currency on you Profile.";
      return dispatch({ type: CREATE_OOP, payload: { text, status: true } });
    }

    if (v?.quantity === 1) {
      dispatch({ type: notif_type.NOTIF_ADD_REQUEST });

      const text = `"${v?.name} (Product Id - ${v.id})" is now Out of Stock.`;
      const notif = {
        role: "Company_Store",
        StoreId: v?.StoreId,
        text,
      };

      const { data: DN } = await axios.post(
        `${Helper.URL}/notification`,
        notif
      );
      dispatch({ type: notif_type.NOTIF_ADD_SUCCESS, payload: DN?.result });
    }

    let trollyItemList = state()?.store?.trolly?.view_all?.data;
    const trollyExist = isArr(trollyItemList).find(
      (T) => T.ProductBoxId === v?.id
    );

    let S_price = v?.S_price / v?.Currency?.value;
    S_price = S_price * CurrencyRate;

    const trollyItem = {
      CurrencyId,
      S_price,
      sold_units: qty,
      status: "Trolly",
      StoreId: v?.StoreId ? v?.StoreId : null,
      discount: v?.discount ? v?.discount : null,
      ProductBoxId: v?.id ? v?.id : null,
      ...(!CustomerId ? { id: uuid() } : { CustomerId }),
    };

    if (role === "Customer") {
      let response;

      if (!trollyExist && !!trollyItem?.id) {
        response = await axios.put(`${URL}/${trollyItem?.id}`, trollyItem);
      } else {
        const dt = (({ sold_units, ...T }) => ({ ...T, sold_units: qty }))(
          trollyItem
        );
        response = await axios.post(`${URL}`, dt);
      }
      const result = response?.data?.result
        ? response?.data?.result
        : trollyItem;
      trollyItemList = isArr(trollyItemList).map((T) =>
        T.ProductBoxId === result?.ProductBoxId ? result : T
      );
      dispatch({ type: types.TROLLY_ADD_SUCCESS, payload: result });
      dispatch({
        type: types.TROLLY_VIEW_ALL_SUCCESS,
        payload: trollyItemList,
      });
    } else {
      trollyItemList = !!trollyExist
        ? isArr(trollyItemList).map((T) =>
            T.ProductBoxId === v.id ? trollyItem : T
          )
        : [trollyItem, ...trollyItemList];
      dispatch({ type: types.TROLLY_ADD_SUCCESS, payload: trollyItem });
      dispatch({
        type: types.TROLLY_VIEW_ALL_SUCCESS,
        payload: trollyItemList,
      });
    }
    const { data: TC } = await axios.get(`${URL}/count`);
    dispatch({ type: types.TROLLY_COUNT_SUCCESS, payload: TC?.result });

    sessionStorage.setItem(
      "trolly",
      JSON.stringify(isArr(state()?.store?.trolly?.view_all?.data))
    );
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_ADD_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TROLLY_VIEW_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.get(`${URL}/${id}`);
    dispatch({ type: types.TROLLY_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_VIEW_FAIL);
  }
};

export const view_slug = (slug) => async (dispatch) => {
  try {
    dispatch({ type: types.TROLLY_VIEW_SLUG_REQUEST });
    const { data } = await axios.get(`${URL}/slug?${slug}`);
    dispatch({
      type: types.TROLLY_VIEW_SLUG_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_VIEW_SLUG_FAIL);
  }
};

export const empty_len = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TROLLY_EMPTY_LEN_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/empty-len?${queryString || ""}`);
    dispatch({
      type: types.TROLLY_EMPTY_LEN_SUCCESS,
      payload: data?.result,
    });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_EMPTY_LEN_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TROLLY_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${queryString || ""}`);
    dispatch({
      type: types.TROLLY_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_VIEW_ALL_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TROLLY_EDIT_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(`${URL}/${id}`, initData);

    const { data: TC } = await axios.get(`${URL}/count`);
    dispatch({ type: types.TROLLY_COUNT_SUCCESS, payload: TC?.result });

    dispatch({ type: types.TROLLY_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_EDIT_FAIL);
  }
};

export const edit_all = (queryStr, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TROLLY_EDIT_ALL_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(`${URL}/all?${queryStr}`, initData);

    dispatch({ type: types.TROLLY_EDIT_ALL_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_EDIT_ALL_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TROLLY_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.TROLLY_DELETE_SUCCESS, payload: data?.result });
    dispatch({
      type: types.TROLLY_VIEW_ALL_SUCCESS,
      payload: state()?.store?.trolly?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
    const { data: TC } = await axios.get(`${URL}/count`);
    dispatch({ type: types.TROLLY_COUNT_SUCCESS, payload: TC?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TROLLY_DELETE_FAIL);
  }
};
