import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.template;

//   ---------------------------------------   Email

export const email_get = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_GET_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/template/email`);

    dispatch({ type: types.EMAIL_GET_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_GET_FAIL);
  }
};

export const email_set = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EMAIL_SET_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(`${Helper.URL}/template/email`, initData);

    dispatch({ type: types.EMAIL_SET_SUCCESS, payload: data });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EMAIL_SET_FAIL);
  }
};

//   ---------------------------------------   Template

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TEMPLATE_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      `${Helper.URL}/template?${queryString || ""}`
    );

    dispatch({ type: types.TEMPLATE_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TEMPLATE_VIEW_ALL_FAIL);
  }
};

export const action_name_view_all = async () => {
  try {
    const { data } = await axios.get(`${Helper.URL}/template/names`);
    return data?.result;
  } catch (err) {
    Helper.getErr(err);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TEMPLATE_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${Helper.URL}/template`, initData);

    dispatch({ type: types.TEMPLATE_ADD_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TEMPLATE_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TEMPLATE_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${Helper.URL}/template/${id}`, initData);
    dispatch({ type: types.TEMPLATE_EDIT_SUCCESS, payload: data?.status });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TEMPLATE_EDIT_FAIL);
  }
};

export const view = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TEMPLATE_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${Helper.URL}/template/${id}`, initData);

    dispatch({ type: types.TEMPLATE_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TEMPLATE_VIEW_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TEMPLATE_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${Helper.URL}/template/${id}`);
    dispatch({
      type: types.TEMPLATE_VIEW_ALL_SUCCESS,
      payload: state().base.template.view_all.data.filter((v) => v.id !== id),
    });
    dispatch({ type: types.TEMPLATE_DELETE_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TEMPLATE_DELETE_FAIL);
  }
};
