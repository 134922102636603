import * as template from "./template";
import * as settings from "./settings";
import * as payment from "./payment";
import * as address from "./address";
import * as transaction from "./transaction";
import * as terms from "./terms";
import * as mobile_pay from "./mobile_pay";
import * as subscribes from "./subscribes";

const Expo = {
  template,
  mobile_pay,
  settings,
  payment,
  address,
  transaction,
  terms,
  subscribes,
};

export default Expo;
