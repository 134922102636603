import React, { useEffect } from "react";
import { Box, TableRow, TableCell } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import Mail from "@material-ui/icons/Mail";
import Search from "@material-ui/icons/Search";
import Hoc from "../../../../comps/Hoc";
import Controls from "../../../../comps/Controls";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";

const Email = ({ old_mail }) => {
  const dispatch = useDispatch();
  const Act = Action.base02.sent_mail;
  const res = useSelector(({ base02: B }) => B.sent_mail.view_all);
  const { error, loading, data } = res;

  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const tbl = Custom.useTable(data, cells, filterFn);
  const { TblContainer, finalRecords } = tbl;
  const old = `old_mail=${!old_mail ? 0 : 1}`;

  useEffect(() => {
    dispatch(Act.view_all(old));
  }, [dispatch, Act, old_mail, old]);

  const onSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target?.value === "") return items;
        else
          return items.filter((x) =>
            x?.name?.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const refresh = () => dispatch(Act.view_all(old));

  const TOT = (name, to) => ({ name, to });
  const TOC = (name, onClick) => ({ name, onClick });
  const nav01 = { icon: <Mail color="primary" />, title: "Email Templates" };

  const act01 = TOT("Create Template", "/mail-template-add");
  const act02 = [TOC("Refresh", refresh), { name: "Back", goBack: true }];
  const nav02 = {
    title: "Email Information",
    actions: !!old_mail ? act02 : [act01, ...act02],
  };

  const onEdit = (ID, VL) => {
    dispatch(Act.view_edit(ID, VL));
    refresh();
  };

  const t_actions = (v) => {
    let items = [];

    const EnbData = (({ id, ...rest }) => ({ ...rest, old_mail: 0 }))(v);

    if (!v?.old_mail) {
      items = [
        ...items,
        TOC("Disable Template", () => onEdit(v.id, { old_mail: 1 })),
        TOC("Send Mail Now", () => dispatch(Act.view_send(v.id))),
        TOT("Edit", `/mail-template-edit/${v.id}`),
        TOC("Delete", () => dispatch(Act.view_delete(v.id))),
      ];
    } else {
      items = [
        ...items,
        TOC(`Enable Template`, () => dispatch(Act.view_add(EnbData))),
      ];
    }

    return items;
  };

  return (
    <Hoc.Page {...nav01}>
      <Hoc.PageBox {...nav02}>
        <Hoc.UseData error={error} loading={loading}>
          <Box style={{ maxWidth: 200, marginBottom: 5 }}>
            <Controls.Input
              label="Search"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={onSearch}
            />
          </Box>
          {!!data.length ? (
            <TblContainer paginate>
              {finalRecords().map((v) => (
                <TableRow key={v.id || "NA"}>
                  <TabCell content={v.name} />
                  <TabCell content={ToRole(v.role)} />
                  <TabCell content={v.duration} />
                  <TabCell content={v.date} />
                  <TabCell content={v.time} />
                  <TableCell>
                    <Hoc.Product.MenuButton actions={t_actions(v)} />
                  </TableCell>
                </TableRow>
              ))}
            </TblContainer>
          ) : (
            <Hoc.Message text="Empty templates" color="yellow" />
          )}
        </Hoc.UseData>
      </Hoc.PageBox>
    </Hoc.Page>
  );
};

const ToRole = (v1) =>
  `${v1}`
    .split("_")
    .map((v2) =>
      v2 === "SB"
        ? "Subscribers"
        : v2 === "GN"
        ? "Customer"
        : v2 === "S2"
        ? "Store Employees"
        : v2 === "S1"
        ? "Store Managers"
        : v2 === "C2"
        ? "Company Admins"
        : v2 === "C1" && "Company Managers"
    )
    .join(", ");

const TabCell = ({ content }) => (
  <TableCell>{content ? content : "NA"}</TableCell>
);

const cells = [
  { id: "name", label: "Name" },
  { id: "role", label: "Role" },
  { id: "duration", label: "Duration (1Sec = 1000)" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
  { id: "06", label: "actions", disableSorting: true },
];

export default Email;
