import ProductForm from "./Item/ProductForm";
import ProductTrolly from "./Item/ProductTrolly";
import StoreProductTrolly from "./Item/StoreProductTrolly";
import CategoryTrolly from "./Category/CategoryTrolly";
import CategoryViewAll from "./Category/CategoryViewAll";
import ViewVariant from "./Item/ViewVariant/index";
import EditProductVariant from "./Item/EditProductVariant";

const Expo = {
  ProductForm,
  ProductTrolly,
  CategoryTrolly,
  CategoryViewAll,
  ViewVariant,
  EditProductVariant,
  StoreProductTrolly,
};

export default Expo;
