import React, { useEffect } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import { useDispatch } from "react-redux";
import Action from "../../../actions";

const SignIn = (props) => {
  const { title, onSubmit, formData, id } = props;
  const dispatch = useDispatch();
  const Act_address = Action.base.address;

  const { open, onClose } = props;

  const { Form } = Custom.useForm;
  const { values, setValues } = formData;
  const { onChange, resetForm } = formData;

  useEffect(() => {
    !!id &&
      (async () =>
        setValues(initAddress(await dispatch(Act_address.view(id)))))();
  }, [dispatch, setValues, Act_address, id]);

  const finalForm = (
    <Form
      onSubmit={onSubmit}
      resetForm={resetForm}
      submitButton={{ disabled: setup_status(values) }}
    >
      <Controls.Input {...setup.house_name(values.house_name, onChange)} />
      <Controls.Input {...setup.street_name(values.street_name, onChange)} />
      <Controls.Input {...setup.address_3(values.address_3, onChange)} />
      <Controls.Input {...setup.city(values.city, onChange)} />
      <Controls.Input {...setup.region(values.region, onChange)} />
      <Controls.Input {...setup.postcode(values.postcode, onChange)} />
      <Controls.OldSearchSelect {...setup.country(values.country, onChange)} />
    </Form>
  );

  return !onClose ? (
    <Hoc.Page title={title}>
      <Hoc.FormContainer title={title}>{finalForm}</Hoc.FormContainer>
    </Hoc.Page>
  ) : (
    <Hoc.Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onClose}
        />
      }
    >
      {finalForm}
    </Hoc.Dialog>
  );
};

const setup_status = (v) =>
  !v.house_name ||
  !v.street_name ||
  !v.city ||
  !v.region ||
  !v.postcode ||
  !v.country;

const setup = {
  default: (checked, onChange) =>
    Hoc.setupSwitch(checked, onChange, "default", "Default Address"),
  house_name: (v, onChange) =>
    Hoc.setupText(v, onChange, "house_name", "House Name / Number"),
  street_name: (v, onChange) =>
    Hoc.setupText(v, onChange, "street_name", "Street Name"),
  address_3: (v, onChange) =>
    Hoc.setupText(v, onChange, "address_3", "Address line 3"),
  city: (v, onChange) =>
    Hoc.setupText(v, onChange, "city", "Village / City / Town"),
  region: (v, onChange) =>
    Hoc.setupText(v, onChange, "region", "County / Region"),
  postcode: (v, onChange) =>
    Hoc.setupText(v, onChange, "postcode", "Postcode / Zipcode"),
  country: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "country", "Select Country"),
};

const initAddress = (v) => ({
  house_name: v?.house_name ? v?.house_name : "",
  street_name: v?.street_name ? v?.street_name : "",
  address_3: v?.address_3 ? v?.address_3 : "",
  city: v?.city ? v?.city : "",
  region: v?.region ? v?.region : "",
  postcode: v?.postcode ? v?.postcode : "",
  country: v?.country ? v?.country : "",
  default: v?.default ? v?.default : false,
});

export default SignIn;
