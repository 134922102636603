const tp = {
  //
  S1_SIGN_UP_REQUEST: "S1_SIGN_UP_REQUEST",
  S1_SIGN_UP_SUCCESS: "S1_SIGN_UP_SUCCESS",
  S1_SIGN_UP_FAIL: "S1_SIGN_UP_FAIL",
  //
  S1_SIGN_IN_REQUEST: "S1_SIGN_IN_REQUEST",
  S1_SIGN_IN_SUCCESS: "S1_SIGN_IN_SUCCESS",
  S1_SIGN_IN_FAIL: "S1_SIGN_IN_FAIL",
  //
  S1_FORGOT_PSW_REQUEST: "S1_FORGOT_PSW_REQUEST",
  S1_FORGOT_PSW_SUCCESS: "S1_FORGOT_PSW_SUCCESS",
  S1_FORGOT_PSW_FAIL: "S1_FORGOT_PSW_FAIL",
  //
  S1_RESET_PSW_REQUEST: "S1_RESET_PSW_REQUEST",
  S1_RESET_PSW_SUCCESS: "S1_RESET_PSW_SUCCESS",
  S1_RESET_PSW_FAIL: "S1_RESET_PSW_FAIL",
  //
  S1_EDIT_PSW_REQUEST: "S1_EDIT_PSW_REQUEST",
  S1_EDIT_PSW_SUCCESS: "S1_EDIT_PSW_SUCCESS",
  S1_EDIT_PSW_FAIL: "S1_EDIT_PSW_FAIL",
  //
  S1_VERIFY_EMAIL_REQUEST: "S1_VERIFY_EMAIL_REQUEST",
  S1_VERIFY_EMAIL_SUCCESS: "S1_VERIFY_EMAIL_SUCCESS",
  S1_VERIFY_EMAIL_FAIL: "S1_VERIFY_EMAIL_FAIL",
  //
  S1_PROFILE_VIEW_REQUEST: "S1_PROFILE_VIEW_REQUEST",
  S1_PROFILE_VIEW_SUCCESS: "S1_PROFILE_VIEW_SUCCESS",
  S1_PROFILE_VIEW_FAIL: "S1_PROFILE_VIEW_FAIL",
  //
  S1_PROFILE_EDIT_REQUEST: "S1_PROFILE_EDIT_REQUEST",
  S1_PROFILE_EDIT_SUCCESS: "S1_PROFILE_EDIT_SUCCESS",
  S1_PROFILE_EDIT_FAIL: "S1_PROFILE_EDIT_FAIL",
  //
  //
  //
  //
  S1_VIEW_ALL_REQUEST: "S1_VIEW_ALL_REQUEST",
  S1_VIEW_ALL_SUCCESS: "S1_VIEW_ALL_SUCCESS",
  S1_VIEW_ALL_FAIL: "S1_VIEW_ALL_FAIL",
  //
  S1_EDIT_REQUEST: "S1_EDIT_REQUEST",
  S1_EDIT_SUCCESS: "S1_EDIT_SUCCESS",
  S1_EDIT_FAIL: "S1_EDIT_FAIL",
  //
  S1_ADD_REQUEST: "S1_ADD_REQUEST",
  S1_ADD_SUCCESS: "S1_ADD_SUCCESS",
  S1_ADD_FAIL: "S1_ADD_FAIL",
  //
  S1_DELETE_REQUEST: "S1_DELETE_REQUEST",
  S1_DELETE_SUCCESS: "S1_DELETE_SUCCESS",
  S1_DELETE_FAIL: "S1_DELETE_FAIL",
};

export default tp;
