import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";

const SignIn = () => {
  const Act = Action.people.C1.sign_in;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.SignIn
      title="Company Manager Login"
      endLink="/supreme-sign-up"
      forgotPsw="/supreme-forgot-password"
      formData={formData}
      submitAction={Act}
      to={{ url: "/company-dashboard", role: "Company_Manager" }}
    />
  );
};

const initValues = {
  email: "",
  password: "",
};

export default SignIn;
