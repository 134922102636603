import { Route } from "react-router-dom";
import sign_in from "./sign-in";
import sign_up from "./sign-up";
import forgot_password from "./forgot-password";

const Expo = {
  sign_in: <Route exact path="/supreme-sign-in" component={sign_in} />,
  sign_up: <Route exact path="/supreme-sign-up" component={sign_up} />,
  forgot_password: (
    <Route exact path="/supreme-forgot-password" component={forgot_password} />
  ),
};

export default Expo;
