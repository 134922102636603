const types = {
  //
  CURRENCY_LIST_REQUEST: "CURRENCY_LIST_REQUEST",
  CURRENCY_LIST_SUCCESS: "CURRENCY_LIST_SUCCESS",
  CURRENCY_LIST_FAIL: "CURRENCY_LIST_FAIL",
  //
  CURRENCY_VIEW_REQUEST: "CURRENCY_VIEW_REQUEST",
  CURRENCY_VIEW_SUCCESS: "CURRENCY_VIEW_SUCCESS",
  CURRENCY_VIEW_FAIL: "CURRENCY_VIEW_FAIL",
  //
  CURRENCY_VIEW_GBP_REQUEST: "CURRENCY_VIEW_GBP_REQUEST",
  CURRENCY_VIEW_GBP_SUCCESS: "CURRENCY_VIEW_GBP_SUCCESS",
  CURRENCY_VIEW_GBP_FAIL: "CURRENCY_VIEW_GBP_FAIL",
  //
  CURRENCY_ADD_REQUEST: "CURRENCY_ADD_REQUEST",
  CURRENCY_ADD_SUCCESS: "CURRENCY_ADD_SUCCESS",
  CURRENCY_ADD_FAIL: "CURRENCY_ADD_FAIL",
  //
  CURRENCY_EDIT_REQUEST: "CURRENCY_EDIT_REQUEST",
  CURRENCY_EDIT_SUCCESS: "CURRENCY_EDIT_SUCCESS",
  CURRENCY_EDIT_FAIL: "CURRENCY_EDIT_FAIL",
  //
  CURRENCY_DELETE_REQUEST: "CURRENCY_DELETE_REQUEST",
  CURRENCY_DELETE_SUCCESS: "CURRENCY_DELETE_SUCCESS",
  CURRENCY_DELETE_FAIL: "CURRENCY_DELETE_FAIL",
};

export default types;
