import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.TEMPLATE_VIEW_ALL_REQUEST,
  success: types.TEMPLATE_VIEW_ALL_SUCCESS,
  fail: types.TEMPLATE_VIEW_ALL_FAIL,
};

export const view = {
  name: "view",
  state: defListState,
  request: types.TEMPLATE_VIEW_REQUEST,
  success: types.TEMPLATE_VIEW_SUCCESS,
  fail: types.TEMPLATE_VIEW_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.TEMPLATE_EDIT_REQUEST,
  success: types.TEMPLATE_EDIT_SUCCESS,
  fail: types.TEMPLATE_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.TEMPLATE_ADD_REQUEST,
  success: types.TEMPLATE_ADD_SUCCESS,
  fail: types.TEMPLATE_ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.TEMPLATE_DELETE_REQUEST,
  success: types.TEMPLATE_DELETE_SUCCESS,
  fail: types.TEMPLATE_DELETE_FAIL,
};

export const email_get = {
  name: "email_get",
  state: defState,
  request: types.EMAIL_GET_REQUEST,
  success: types.EMAIL_GET_SUCCESS,
  fail: types.EMAIL_GET_FAIL,
};

export const email_set = {
  name: "email_set",
  state: defState,
  request: types.EMAIL_SET_REQUEST,
  success: types.EMAIL_SET_SUCCESS,
  fail: types.EMAIL_SET_FAIL,
};
