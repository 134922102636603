const types = {
  //
  PRODUCT_VIEW_ALL_REQUEST: "PRODUCT_VIEW_ALL_REQUEST",
  PRODUCT_VIEW_ALL_SUCCESS: "PRODUCT_VIEW_ALL_SUCCESS",
  PRODUCT_VIEW_ALL_FAIL: "PRODUCT_VIEW_ALL_FAIL",
  //
  PRODUCT_VIEW_REQUEST: "PRODUCT_VIEW_REQUEST",
  PRODUCT_VIEW_SUCCESS: "PRODUCT_VIEW_SUCCESS",
  PRODUCT_VIEW_FAIL: "PRODUCT_VIEW_FAIL",
  //
  PRODUCT_VIEW_SLUG_REQUEST: "PRODUCT_VIEW_SLUG_REQUEST",
  PRODUCT_VIEW_SLUG_SUCCESS: "PRODUCT_VIEW_SLUG_SUCCESS",
  PRODUCT_VIEW_SLUG_FAIL: "PRODUCT_VIEW_SLUG_FAIL",
  //
  PRODUCT_EDIT_REQUEST: "PRODUCT_EDIT_REQUEST",
  PRODUCT_EDIT_SUCCESS: "PRODUCT_EDIT_SUCCESS",
  PRODUCT_EDIT_FAIL: "PRODUCT_EDIT_FAIL",
  //
  PRODUCT_ADD_REQUEST: "PRODUCT_ADD_REQUEST",
  PRODUCT_ADD_SUCCESS: "PRODUCT_ADD_SUCCESS",
  PRODUCT_ADD_FAIL: "PRODUCT_ADD_FAIL",
  //
  PRODUCT_DELETE_REQUEST: "PRODUCT_DELETE_REQUEST",
  PRODUCT_DELETE_SUCCESS: "PRODUCT_DELETE_SUCCESS",
  PRODUCT_DELETE_FAIL: "PRODUCT_DELETE_FAIL",
  //
  PRODUCT_BEST_SELLER_REQUEST: "PRODUCT_BEST_SELLER_REQUEST",
  PRODUCT_BEST_SELLER_SUCCESS: "PRODUCT_BEST_SELLER_SUCCESS",
  PRODUCT_BEST_SELLER_FAIL: "PRODUCT_BEST_SELLER_FAIL",
  //
  PRODUCT_LATEST_REQUEST: "PRODUCT_LATEST_REQUEST",
  PRODUCT_LATEST_SUCCESS: "PRODUCT_LATEST_SUCCESS",
  PRODUCT_LATEST_FAIL: "PRODUCT_LATEST_FAIL",
  //
  PRODUCT_SEARCH_REQUEST: "PRODUCT_SEARCH_REQUEST",
  PRODUCT_SEARCH_SUCCESS: "PRODUCT_SEARCH_SUCCESS",
  PRODUCT_SEARCH_FAIL: "PRODUCT_SEARCH_FAIL",
};

export default types;
