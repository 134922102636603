import React, { useState } from "react";
import Action from "../../../../../actions";
import Custom from "../../../../Custom";
import Hoc from "../../../../Hoc";
import Controls from "../../../../Controls";
import { useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { Box, Typography } from "@material-ui/core";
import { setup, NoRecords } from "./utils";

const Info = (props) => {
  const { ProductBoxId, refresh } = props;
  const { editable, setEditable, closeEditable } = props;
  let { items } = props;
  items = !!ProductBoxId
    ? Hoc.isArr(items).filter((v) => v.ProductBoxId === ProductBoxId)
    : items;
  const Act = Action.product.info;
  const dispatch = useDispatch();
  const { Form, useForm } = Custom.useForm;
  const { values, setValues, onChange, resetForm } = useForm({
    title: "",
    info: "",
  });

  const [infoID, setInfoID] = useState(null);
  const onSubmit = () => {
    if (!!infoID) {
      dispatch(Act.view_edit(infoID, { ...values }));
      setInfoID(null);
    } else {
      dispatch(Act.view_add({ ...values, ProductBoxId }));
    }

    resetForm();
    refresh();
    closeEditable();
  };

  const onDelete = (id) => {
    dispatch(Act.view_delete(id));
    refresh();
  };

  const onEdit = (id) => {
    const item = Hoc.isArr(items).find((v) => v.id === id);
    setEditable(true);
    setInfoID(id);
    setValues({ title: item?.title, info: item?.info });
  };

  return (
    <Box>
      {!editable && (
        <Box style={{ marginTop: 20 }}>
          {!Hoc.isArr(items).length ? (
            <NoRecords />
          ) : (
            Hoc.isArr(items).map(
              (v, idx) =>
                !!v.info && (
                  <Box key={idx} style={{ marginTop: 5 }}>
                    <Message
                      success
                      header={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>{<h5>{v.title}</h5>}</Typography>
                          <Box>
                            <Icon
                              color="yellow"
                              name="edit outline"
                              style={{ cursor: "pointer", marginRight: 10 }}
                              onClick={() => onEdit(v.id)}
                            />
                            <Icon
                              color="red"
                              name="trash alternate"
                              style={{ cursor: "pointer" }}
                              onClick={() => onDelete(v.id)}
                            />
                          </Box>
                        </Box>
                      }
                      list={`${v.info}`.split(",")}
                    />
                  </Box>
                )
            )
          )}
        </Box>
      )}

      {!!editable && (
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          style={{ marginTop: 10 }}
          submitButton={{
            disabled: !values?.title || !values?.info || ProductBoxId === "NA",
          }}
        >
          <Controls.Input {...setup.title(values.title, onChange)} />
          <Controls.Input {...setup.info(values.info, onChange)} />
        </Form>
      )}
    </Box>
  );
};

export default Info;
