import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const [openShop, setOpenShop] = React.useState(false);
  const onShopClose = () => setOpenShop(false);
  return (
    <>
      <Header openShop={openShop} setOpenShop={setOpenShop} />
      <main onClick={onShopClose}>{children}</main>
      <div onClick={onShopClose}>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
