import { combineReducers } from "redux";
import { toReducer } from "../utils";

import cmp_manager from "./cmp_manager";
import cmp_manager_types from "./cmp_manager/types";

import cmp_admin from "./cmp_admin";
import cmp_admin_types from "./cmp_admin/types";

import store_manager from "./store_manager";
import store_manager_types from "./store_manager/types";

import store_employee from "./store_employee";
import store_employee_types from "./store_employee/types";

import customer from "./customer";
import customer_types from "./customer/types";

import { token_expire, root_types } from "./root";
import { root } from "./root";

const people = combineReducers({
  ...toReducer(root),
  ...toReducer(token_expire),
  //
  cmp_manager,
  cmp_admin,
  store_manager,
  store_employee,
  customer,
});

export const people_type = {
  cmp_manager: cmp_manager_types,
  cmp_admin: cmp_admin_types,
  store_manager: store_manager_types,
  store_employee: store_employee_types,
  customer: customer_types,
  root: root_types,
};

export default people;
