import React from "react";
import { Dropdown } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";

const SearchSelect = (props) => {
  const { name, label, value } = props;
  const { onChange = (f) => f, options } = props;
  const { disabled } = props;

  const toDefEventPara = (name, value) => ({ target: { name, value } });

  const onChangeHandler = (e, { name, value }) => {
    e.preventDefault();
    onChange(toDefEventPara(name, value));
  };
  const CSS = useCSS();
  return (
    <Dropdown
      className={CSS.root}
      disabled={disabled || false}
      search
      name={name}
      value={value}
      placeholder={label}
      onChange={onChangeHandler}
      options={options?.map((v, idx) => ({
        key: `${idx}_${v?.id ? v?.id : v}`,
        value: v?.id ? v?.id : v,
        text: v?.title ? v?.title : v,
      }))}
    />
  );
};

const useCSS = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: theme.spacing(1),
    width: "100%",
  },
}));

export default SearchSelect;
