import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../../../Controls";
import Custom from "../../../../../Custom";
import Hoc from "../../../../../Hoc";
import action from "../../../../../../actions";
import { variant_status, setup } from "../utils";
import { xs_02, xs_05 } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "semantic-ui-react";
import moment from "moment";

const VariantForm = (props) => {
  const act_curr = action.base02.currency;
  const act_str_curr = action.base02.store_currency;
  const { Form } = Custom.useForm;
  const { formData, onSubmit } = props;
  const { root, StoreId } = props;
  const { values, onChange, resetForm } = formData;
  const stores = useSelector(({ store: s }) => s.item.view_all.data);
  const dispatch = useDispatch();
  let currencies = useSelector(({ base02: B }) => B.currency.list.data);
  let store_currency = useSelector(
    ({ base02: B }) => B.store_currency.list.data
  );

  const isCmp = !!`${root?.role}`.includes("Company");
  const ids = Hoc.isArr(store_currency)
    .filter((v) => !isCmp && v?.StoreId === root?.Store?.id)
    .map((v) => v?.CurrencyId);

  currencies = Hoc.isArr(currencies).filter((v) =>
    !isCmp ? ids.includes(v.id) : true
  );

  useEffect(() => {
    dispatch(act_str_curr.list(`StoreId=${root?.Store?.id}`));
    dispatch(act_curr.list());
  }, [dispatch, act_curr, act_str_curr, root]);

  const InpNumber = (VL, NM, LB) => (
    <Grid {...xs_05} {...setup.InpNumber(VL, NM, LB, onChange)} />
  );
  const InpFloat = (VL, NM, LB) => (
    <Grid {...xs_05} {...setup.InpFloat(VL, NM, LB, onChange)} />
  );
  const InpRate = (VL, NM, LB) => (
    <Grid {...xs_05} {...setup.InpRate(VL, NM, LB, onChange)} />
  );

  const GridTitle = ({ content }) => (
    <Grid item xs={12} style={{ margin: "10px 0" }}>
      <Header textAlign="center" color="green" content={content} />
    </Grid>
  );

  const InpFloat02 = (VL, NM, LB) => (
    <Grid {...xs_02} {...setup.InpFloat(VL, NM, LB, onChange)} />
  );
  return (
    <Form
      onSubmit={onSubmit}
      resetForm={resetForm}
      style={{ marginTop: 10 }}
      submitButton={{
        disabled: variant_status(values) || !StoreId || StoreId === "NA",
      }}
    >
      <Grid container justifyContent="center">
        <Grid {...xs_05} {...setup.name(values.name, onChange)} />
        <Grid item xs={5}>
          <Box style={{ marginRight: "auto" }} />{" "}
          <Controls.DatePicker
            {...setup.expires(
              moment(values.expires).format("YYYY-MM-DD"),
              onChange
            )}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={5}>
          <Controls.SearchSelect
            {...setup.StoreId(values.StoreId, onChange, stores, root)}
          />
        </Grid>
        <Grid item xs={5}>
          <Controls.SearchSelect
            {...setup.CurrencyId(values.CurrencyId, onChange, currencies)}
          />
        </Grid>
        <Grid item xs={5}>
          <Controls.SearchSelect
            {...setup.destination(values.destination, onChange)}
          />
        </Grid>

        <GridTitle content="Files" />

        <Grid item xs={12} style={Hoc.flex("space-around", "center")}>
          <Controls.FileUpload
            xs={5}
            {...setup.photo02(values?.photo, onChange)}
          />
          <Box>
            {!!values?.photo && (
              <Box component="img" src={values?.photo} height={50} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} style={Hoc.flex("space-around", "center")}>
          <GridSelect {...setup.file_type(values?.file_type, onChange)} />

          <Controls.FileUpload
            xs={5}
            {...setup.preview(values?.preview, onChange)}
          />
          <Controls.FileUpload xs={5} {...setup.file(values?.file, onChange)} />
        </Grid>

        <GridTitle content="Product Dimentions" />

        {InpFloat02(values.height, "height", "Height (cm)")}
        {InpFloat02(values.width, "width", "Width (cm)")}
        {InpFloat02(values.length, "length", "Length (cm)")}
        {InpFloat02(values.weight, "weight", "Weight (cm)")}

        <GridTitle content="Buying Details" />

        {InpFloat(values.B_price, "B_price", "Unit Price")}
        {InpNumber(values.total_units, "total_units", "Total Units")}
        {InpFloat(
          values.BT_shipping_price,
          "BT_shipping_price",
          "Total Shipping Price"
        )}
        {InpFloat(values.BT_vat_price, "BT_vat_price", "Total VAT / GST Paid")}
        <Grid {...xs_05} {...setup.Inp_total_price(values, "TP")} />
        <Grid {...xs_05} {...setup.Inp_total_price(values, "UP")} />

        <GridTitle content="Selling Details" />

        {InpFloat(values.S_price, "S_price", "Unit Price")}
        {InpFloat(
          values.NT_shipping_price,
          "NT_shipping_price",
          "Shipping Price (National)"
        )}
        {InpRate(values.ST_vat_rate, "ST_vat_rate", "VAT / GST Percentage")}
        {InpNumber(values.quantity, "quantity", "Quantity")}
        <Grid {...xs_05} {...setup.Inp_total_price(values, "EP")} />

        <Grid item xs={12} container justifyContent="space-around">
          <Controls.Switch
            {...setup.collectable(values.collectable, onChange)}
          />
          <Controls.Switch
            {...setup.subscription(values.subscription, onChange)}
          />
          <Controls.Switch {...setup.publish(values.publish, onChange)} />
        </Grid>
      </Grid>
    </Form>
  );
};

const GridSelect = ({ ...rest }) => (
  <Box style={{ width: 150 }}>
    <Controls.SearchSelect {...rest} />
  </Box>
);

export default VariantForm;
