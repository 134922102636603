import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import Action from "../../../actions";
import { useHistory } from "react-router";

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const Act_Opp = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const H = useHistory();
  const { title, submitAction = (f) => f, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;

  const onSubmit = async () => {
    setLoading(true);
    const status = await dispatch(submitAction(values));
    setLoading(false);
    resetForm();
    H.goBack();
    !!status && dispatch(Act_Opp("Please verify your email."));
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: !values?.email || loading }}
      >
        <Controls.Input {...setup.email(values.email, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const setup = {
  email: (v, onChange) =>
    Hoc.setupText(v, onChange, "email", "Email Address", "email"),
};

export default SignIn;
