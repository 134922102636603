import Helper from "../../helper";
import { Type } from "../../../redux";
import axios from "axios";

const types = Type.product_type.box;
const URL = `${Helper.URL}/product-box`;

export const variants = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_VARIANT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/variants`);

    dispatch({
      type: types.PRODUCT_BOX_VARIANT_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_VARIANT_FAIL);
  }
};

export const search_items = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_SEARCH_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/search?${queryString || ""}`);

    dispatch({ type: types.PRODUCT_BOX_SEARCH_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_SEARCH_FAIL);
  }
};

export const random_items = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_RAND_SEARCH_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/random?${queryString || ""}`);
    dispatch({
      type: types.PRODUCT_RAND_SEARCH_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_RAND_SEARCH_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_VIEW_REQUEST });
    const { data } = await axios.get(`${URL}/${id}`);
    dispatch({ type: types.PRODUCT_BOX_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_VIEW_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(
      !queryString ? URL : `${URL}?${queryString || ""}`
    );
    dispatch({
      type: types.PRODUCT_BOX_VIEW_ALL_SUCCESS,
      payload: data?.result,
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_VIEW_ALL_FAIL);
  }
};

export const best_seller = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_BEST_SELLER_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/best-seller`);

    dispatch({
      type: types.PRODUCT_BOX_BEST_SELLER_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_BEST_SELLER_FAIL);
  }
};

export const latest = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_LATEST_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${queryString || ""}`);

    dispatch({
      type: types.PRODUCT_BOX_LATEST_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_LATEST_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_ADD_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(`${URL}`, initData, Helper.multiForm);

    dispatch(
      { type: types.PRODUCT_BOX_ADD_SUCCESS, payload: data?.result },
      Helper.multiForm
    );
    dispatch({
      type: types.PRODUCT_BOX_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.product?.product?.view_all?.data],
    });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_EDIT_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.put(`${URL}/${id}`, initData);

    dispatch({ type: types.PRODUCT_BOX_EDIT_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PRODUCT_BOX_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.PRODUCT_BOX_DELETE_SUCCESS, payload: data?.result });
    dispatch({
      type: types.PRODUCT_BOX_VIEW_ALL_SUCCESS,
      payload: state()?.product?.product?.view_all?.data?.filter(
        (v) => v.id !== id
      ),
    });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PRODUCT_BOX_DELETE_FAIL);
  }
};
