import React, { useEffect } from "react";
import HomeIcon from "@material-ui/icons/Home";
import Hoc from "../comps/Hoc";
import Action from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@material-ui/core";
import { Header } from "semantic-ui-react";

const Home = () => {
  const dispatch = useDispatch();
  const Act_notice = Action.base02.notice;

  const notices = useSelector(({ base02: B }) => B.notice.view_all.data);

  const root = useSelector(({ people: P }) => P.root.data);
  const CKR = (v) => `${root?.role}`.includes(v);

  const role = CKR("Company") ? "CMP" : CKR("Store") ? "STR" : "CUS";

  const NoticeFilter = (v) => notices?.filter((T) => T.category === v);

  useEffect(() => {
    dispatch(Act_notice.view_all(`enabled=1&role=${role}`));
  }, [dispatch, Act_notice, role]);

  const CKL = (v) => !!NoticeFilter(v)?.length;

  return (
    <Hoc.Page noStyle title="Home Page" icon={<HomeIcon color="primary" />}>
      <Container>
        <Box style={{ width: "100%", marginBottom: 10 }} />
        {!!CKL("News") && <Hoc.Home.B01News items={NoticeFilter("News")} />}
        <Hoc.Home.B02Latest latest />
        {!!CKL("Add") && (
          <React.Fragment>
            <Header {...hd01(true)} />
            <Hoc.Home.B03Advertisement items={NoticeFilter("Add")} />
          </React.Fragment>
        )}
        <br />
        <br />
        <Hoc.Home.B02Latest />
        <Hoc.Home.B04Subscribe />
        {!!CKL("Notice") && (
          <React.Fragment>
            <Header {...hd01(false)} />
            <Hoc.Home.B05Notice items={NoticeFilter("Notice")} />
          </React.Fragment>
        )}
      </Container>
    </Hoc.Page>
  );
};

const hd01 = (LT) => ({
  content: LT ? "Advertisement" : "Notices",
  color: "blue",
  textAlign: "center",
  as: "h2",
});

export default Home;
