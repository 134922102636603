import { combineReducers } from "redux";
import { toReducer } from "../../utils";
import * as reducer from "./reducer";

const cmp_admin = combineReducers({
  ...toReducer(reducer.list),
  ...toReducer(reducer.read_all),
  ...toReducer(reducer.count),
  ...toReducer(reducer.view_add),
  ...toReducer(reducer.view_edit),
  ...toReducer(reducer.delete_all),
  ...toReducer(reducer.view_delete),
});

export default cmp_admin;
