import SecureRoute from "../../../../comps/layout/SecureRoute";
import { Route } from "react-router-dom";
import add from "./add";
import edit from "./edit";
import view from "./view";
import view_all from "./view_all";
import public_view from "./public_view";

const Expo = {
  add: <SecureRoute grant="C1" path="/add-privacy" component={add} />,
  edit: <SecureRoute grant="C1" path="/edit-privacy/:id" component={edit} />,
  view: <SecureRoute grant="C1" path="/view-privacy/:id" component={view} />,
  view_all: (
    <SecureRoute grant="C1" path="/view-all-privacy" component={view_all} />
  ),
  public_view: <Route exact path="/privacy-policy" component={public_view} />,
};

export default Expo;
