import React from "react";
import Hoc from "../../comps/Hoc";
import Comp from "../../comps/Comp";
import { useSelector } from "react-redux";

const C1Dashboard = () => {
  const root = useSelector(({ people: v }) => v?.root?.data);
  const toAct = (name, to) => ({ name, to });

  return (
    <Hoc.Page title="Dashboard">
      <Hoc.Dashboard.Container
        title={`${root?.first_name} ${root?.last_name}` || "Customer"}
        actions={[toAct("Shop Now", "/category-00"), toAct("Home", "/")]}
      >
        {/* Start */}
        <Comp.Showcase.Token role={root?.role} GN />

        <Comp.Showcase.OrderHistory role={root?.role} gn />
        <Comp.Showcase.Profile role={root?.role} />

        {/* End */}
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

export default C1Dashboard;
