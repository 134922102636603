import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { Header, Message } from "semantic-ui-react";
import Custom from "../../../../comps/Custom";
import Hoc from "../../../../comps/Hoc";

const PayInfo = ({ data, trollies }) => {
  const { TblContainer } = Custom.useTable([], cells);
  const PF = (v = 0) => parseFloat(v);
  const PFP = (v = 0) => parseFloat(v).toFixed(3);

  const TT_paid = data?.Transactions?.reduce(
    (a, b) => a + PF(b.status === "Paid" ? b.amount : 0),
    0
  );
  const TT_price = trollies?.reduce((a, v) => a + PF(v.ST_price), 0);
  const base = {
    curr:
      new Set(Hoc.isArr(trollies).map((v) => v?.Currency?.id)).size === 1 &&
      trollies[0]?.Currency,
    S_price: trollies?.reduce((a, v) => a + PF(v.S_price * v.sold_units), 0),
    ST_vat_price: trollies?.reduce(
      (a, v) => a + PF(v.ST_vat_price * v.sold_units),
      0
    ),
    ST_shipping_price: trollies?.reduce(
      (a, v) => a + PF(v.ST_shipping_price * v.sold_units),
      0
    ),
    ST_price: TT_price,
    paid: TT_paid,
    need_paid: PF(TT_price - TT_paid),
  };

  const TBCL01 = ({ price }) => (
    <TableCell>
      <Hoc.ToCurrency currency={base.curr} price={price} />
    </TableCell>
  );

  return (
    <Message>
      <Header content="Payment Detail" color="green" as="h3" />
      <TblContainer>
        <TableRow>
          <TBCL01 price={PFP(base.S_price)} />
          <TBCL01 np price={PFP(base.ST_vat_price)} />
          <TBCL01 price={PFP(base.ST_shipping_price)} />
          <TBCL01 price={PFP(base.ST_price)} />
          <TBCL01 price={PFP(base.paid)} />
          <TBCL01 price={PFP(base.need_paid)} />
        </TableRow>
      </TblContainer>
    </Message>
  );
};

const cells = [
  { id: "01", label: "Total Cost (Exc All Taxes)" },
  { id: "02", label: "Total VAT / GST" },
  { id: "03", label: "Shipping Price" },
  { id: "04", label: "Total Amount" },
  { id: "05", label: "Total Paid" },
  { id: "06", label: "Need to Pay" },
];

export default PayInfo;
