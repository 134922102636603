import React from "react";
import { useParams } from "react-router";
import Custom from "../../../../comps/Custom";

const AddAbout = () => {
  const P = useParams();

  return <Custom.Base.TermView id={P?.id} />;
};

export default AddAbout;
