import React, { useEffect } from "react";
import Hoc from "../../comps/Hoc";
import action from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Message, Header } from "semantic-ui-react";
import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";

const NotificationList = () => {
  const act_notif = action.base02.notif;
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(({ base02: B }) => B.notif.list);
  const root = useSelector(({ people: B }) => B.root.data);

  useEffect(() => {
    dispatch(act_notif.list());
    dispatch(act_notif.count());
  }, [dispatch, act_notif]);

  const refresh = () => {
    dispatch(act_notif.list());
    dispatch(act_notif.count());
  };

  const markAsRead = () => {
    dispatch(act_notif.read_all());
    refresh();
  };

  const onDeleteAll = () => {
    dispatch(act_notif.delete_all());
    refresh();
  };

  const nav_01 = {
    title: "Notifications",
    actions: [
      { name: "Delete all", onClick: onDeleteAll },
      {
        name: "Mark Read for all",
        onClick: markAsRead,
      },
      { name: "Refresh", onClick: refresh },
    ],
  };
  const t_acts = (id, is_read) => {
    let items = [];
    const onEdit = () => {
      dispatch(act_notif.view_edit(id, { is_read: !is_read }));
      refresh();
    };

    const onDelete = () => {
      dispatch(act_notif.view_delete(id));
      refresh();
    };

    items = [
      { name: `Mark as ${!is_read ? "Read" : "Unread"}`, onClick: onEdit },
      { name: `Delete`, onClick: onDelete },
    ];

    return items;
  };

  const DT = Hoc.isArr(data);
  let CKD = (M) => moment(M?.createdAt).format("LL");
  let finalData = DT.map((M) => CKD(M));
  finalData = [...new Set(finalData)];
  finalData = finalData.map((M1) => ({
    date: M1,
    items: DT.filter((M2) => CKD(M2) === M1),
  }));

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData loading={loading} error={error}>
        <Box style={{ width: "100%", maxHeight: "100vh", overflow: "auto" }}>
          {finalData.map((D) => (
            <React.Fragment key={D.date}>
              <Header content={D.date} />
              {!!D?.items.length ? (
                D?.items?.map((M) => (
                  <Message
                    style={{ width: "100%" }}
                    key={M.id}
                    {...(!M?.is_read && { color: "teal" })}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={10}>
                        <Typography>{M?.text}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Hoc.Product.MenuButton
                          actions={
                            !!(!!M?.StoreId && root?.role !== "Store_Manager")
                              ? []
                              : t_acts(M.id, M?.is_read)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Message>
                ))
              ) : (
                <Hoc.EmptyMessage />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

export default NotificationList;
