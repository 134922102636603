import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.people_type.store_employee;
const root_types = Type.people_type.root;
const URL = `${Helper.URL}/store-employee`;

export const sign_up = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.S2_SIGN_UP_REQUEST });

    const { data } = await axios.post(`${URL}`, initData);

    dispatch({ type: types.S2_SIGN_UP_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_SIGN_UP_FAIL);
  }
};

export const sign_in = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.S2_SIGN_IN_REQUEST });

    const { data } = await axios.put(`${URL}`, initData);

    dispatch({ type: types.S2_SIGN_IN_SUCCESS, payload: data?.result });
    dispatch({ type: root_types.ROOT_SUCCESS, payload: data?.result });
    if (data?.status && data?.result?.token) {
      sessionStorage.removeItem("root");
      sessionStorage.setItem("root", JSON.stringify(data?.result));
    }
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_SIGN_IN_FAIL);
  }
};

export const forgot_password = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.S2_FORGOT_PSW_REQUEST });

    const { data } = await axios.post(`${URL}/forgot-password`, initData);

    dispatch({ type: types.S2_FORGOT_PSW_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_FORGOT_PSW_FAIL);
  }
};

export const reset_password = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.S2_RESET_PSW_REQUEST });

    const { data } = await axios.post(`${URL}/reset-password`, initData);

    dispatch({ type: types.S2_RESET_PSW_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_RESET_PSW_FAIL);
  }
};

export const verify_email = (token) => async (dispatch) => {
  try {
    dispatch({ type: types.S2_VERIFY_EMAIL_REQUEST });

    const { data } = await axios.get(`${URL}?token=${token}`);

    dispatch({ type: types.S2_VERIFY_EMAIL_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_VERIFY_EMAIL_FAIL);
  }
};

export const view_profile = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_PROFILE_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/profile`);

    dispatch({ type: types.S2_PROFILE_VIEW_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_PROFILE_VIEW_FAIL);
  }
};

export const edit_profile = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_PROFILE_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/profile`);

    dispatch({ type: types.S2_PROFILE_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_PROFILE_EDIT_FAIL);
  }
};

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/root?${queryString || ""}`);

    dispatch({ type: types.S2_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${URL}/root`, initData);

    dispatch({ type: types.S2_ADD_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/root/${id}`, initData);

    dispatch({ type: types.S2_EDIT_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_EDIT_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/root/${id}`);

    dispatch({ type: types.S2_ADD_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_ADD_FAIL);
  }
};

export const edit_password = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.S2_EDIT_PSW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${URL}/edit-password`, initData);

    dispatch({ type: types.S2_EDIT_PSW_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.S2_EDIT_PSW_FAIL);
  }
};
