import React from "react";
import Custom from "../../../../comps/Custom";

const AddAbout = () => {
  const formData = Custom.useForm.useForm(initData());

  return (
    <Custom.Base.TermForm title="Add Privacy Policy" formData={formData} />
  );
};

const initData = (v) => ({
  name: v?.name ? v?.name : "",
  description: v?.description ? v?.description : "",
  level: v?.level ? v?.level : "",
  category: "Privacy",
  CompanyManagerId: null,
});

export default AddAbout;
