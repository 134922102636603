const types = {
  //
  EMAIL_TMP_VIEW_ALL_REQUEST: "EMAIL_TMP_VIEW_ALL_REQUEST",
  EMAIL_TMP_VIEW_ALL_SUCCESS: "EMAIL_TMP_VIEW_ALL_SUCCESS",
  EMAIL_TMP_VIEW_ALL_FAIL: "EMAIL_TMP_VIEW_ALL_FAIL",
  //
  EMAIL_TMP_VIEW_REQUEST: "EMAIL_TMP_VIEW_REQUEST",
  EMAIL_TMP_VIEW_SUCCESS: "EMAIL_TMP_VIEW_SUCCESS",
  EMAIL_TMP_VIEW_FAIL: "EMAIL_TMP_VIEW_FAIL",
  //
  EMAIL_SND_VIEW_REQUEST: "EMAIL_SND_VIEW_REQUEST",
  EMAIL_SND_VIEW_SUCCESS: "EMAIL_SND_VIEW_SUCCESS",
  EMAIL_SND_VIEW_FAIL: "EMAIL_SND_VIEW_FAIL",
  //
  EMAIL_TMP_ADD_REQUEST: "EMAIL_TMP_ADD_REQUEST",
  EMAIL_TMP_ADD_SUCCESS: "EMAIL_TMP_ADD_SUCCESS",
  EMAIL_TMP_ADD_FAIL: "EMAIL_TMP_ADD_FAIL",
  //
  EMAIL_TMP_EDIT_REQUEST: "EMAIL_TMP_EDIT_REQUEST",
  EMAIL_TMP_EDIT_SUCCESS: "EMAIL_TMP_EDIT_SUCCESS",
  EMAIL_TMP_EDIT_FAIL: "EMAIL_TMP_EDIT_FAIL",
  //
  EMAIL_TMP_DELETE_REQUEST: "EMAIL_TMP_DELETE_REQUEST",
  EMAIL_TMP_DELETE_SUCCESS: "EMAIL_TMP_DELETE_SUCCESS",
  EMAIL_TMP_DELETE_FAIL: "EMAIL_TMP_DELETE_FAIL",
};

export default types;
