import { combineReducers } from "redux";
import { toReducer } from "../../utils";
import * as reducer from "./reducer";

const cmp_admin = combineReducers({
  ...toReducer(reducer.view_all),
  ...toReducer(reducer.stock_view_all),
  ...toReducer(reducer.my_store),
  ...toReducer(reducer.view),
  ...toReducer(reducer.view_add),
  ...toReducer(reducer.product_add),
  ...toReducer(reducer.product_view),
  ...toReducer(reducer.view_edit),
  ...toReducer(reducer.view_delete),
});

export default cmp_admin;
