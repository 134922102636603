import SecureRoute from "../../../comps/layout/SecureRoute";
import add from "./add";
import edit from "./edit";
import view from "./view";
import view_all from "./view_all";

const Expo = {
  add: <SecureRoute grant="GN" path="/address-add" component={add} />,
  edit: <SecureRoute grant="GN" path="/address-edit/:id" component={edit} />,
  view: <SecureRoute grant="GN" path="/address-view/:id" component={view} />,
  view_all: (
    <SecureRoute grant="GN" path="/view-all-address" component={view_all} />
  ),
};

export default Expo;
