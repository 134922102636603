import React, { useEffect, useState } from "react";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import Controls from "../../../../comps/Controls";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Card, Header } from "semantic-ui-react";
import { TableRow, TableCell, Box, Typography } from "@material-ui/core";
import moment from "moment";

const ViewAll = (props) => {
  const Act = Action.store.order.ordered;
  const act_del = Action.store.order.view_delete;
  const dispatch = useDispatch();
  const { title, to } = props;
  const { error, loading, data } = useSelector(
    ({ store: S }) => S.order.ordered
  );

  const root = useSelector(({ people: P }) => P.root.data);
  const isUser = root?.role === "Customer";

  useEffect(() => {
    dispatch(Act(`status=${to}`));
  }, [dispatch, Act, to]);

  const refresh = () => dispatch(Act(`status=${to}`));

  const TOT = (name, to, id = "00") => ({ id, name, to });

  const MapActions = [
    TOT("Open Orders", "/orders-01", "01"),
    TOT("Dispatched Orders", "/orders-02", "02"),
    TOT("Completed Orders", "/orders-03", "03"),
  ];

  let nav_02 = [
    TOT("Support Ticket", `/${isUser ? "customer" : "root"}-general-tickets`),
    { name: "Refresh", onClick: refresh },
  ];

  const { TblContainer, finalRecords } = Custom.useTable(data, cells);

  const menuActions = (v) => {
    const CMP = `${root?.role}`.includes("Company");
    const onDelete = () => {
      dispatch(act_del(v?.id, `status=${to}`));
    };

    let items = [
      TOT("View", `/view-order/${v.id}`),
      TOT("Merge with another Order", `/merge-order/${v.id}`),
    ];
    if (!v?.Transactions?.length && !v?.Trollies?.length) {
      items = [...items, { name: "Delete", onClick: onDelete }];
    }
    if (!!CMP || !!isUser) {
      const PayPara01 = `/transaction-add?OrderId=${v.id}&CustomerId=${v?.CustomerId}`;
      items = [...items, TOT("Confirm Payment", PayPara01)];
    }

    return items;
  };

  const [OPN, setOPN] = useState(false);
  const CKOPN = (v) => !!v?.is_open && v?.status === "None";
  const finalList = finalRecords().filter((v1) =>
    !!OPN ? !!CKOPN(v1) : !CKOPN(v1)
  );

  const ToML = (v1) => ({
    curr: v1?.Currency,
    price: parseFloat(v1?.S_price) * parseInt(v1?.sold_units),
  });

  const ToTS = (v1) => ({
    curr: v1?.Currency,
    price: parseFloat(v1?.amount),
  });
  const AmountPay = ({ data, status = "Paid", ...rest }) => (
    <TableCell>
      <Typography color="primary">
        <Hoc.MultiCurrency
          items={Hoc.isArr(data?.filter((v) => v.status === status)).map((v1) =>
            ToTS(v1)
          )}
          {...rest}
        />
      </Typography>
    </TableCell>
  );

  return (
    <Hoc.Dashboard.Container
      title={title}
      actions={MapActions.filter((v) => v.id !== to)}
    >
      <Box style={{ width: "100%" }}>{Hoc.PageTabs(nav_02, "TICKETS")}</Box>
      {to === "01" && (
        <Box style={{ width: "100%" }} {...Hoc.flex("flex-end")}>
          <Controls.Switch
            name="OPN"
            label="Incomplete Orders"
            checked={OPN}
            onChange={({ target: T }) => setOPN(T.value)}
          />
        </Box>
      )}
      {!!OPN && (
        <Box style={{ width: "100%" }}>
          <Hoc.EmptyMessage content="These orders might not have any products and you need to place the order to make this complete" />
        </Box>
      )}

      <Hoc.UseData error={error} loading={loading}>
        <Card.Group style={{ width: "100%" }}>
          {!!finalList.length ? (
            <TblContainer paginate>
              {finalList.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.id}</TableCell>
                  <TableCell>
                    <Header
                      content={!!v.is_open ? "Yes" : "No"}
                      color={!!v.is_open ? "green" : "orange"}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography color="primary">
                      <Hoc.MultiCurrency
                        items={Hoc.isArr(v?.Trollies).map((v1) => ToML(v1))}
                      />
                    </Typography>
                  </TableCell>
                  <AmountPay data={v?.Transactions} empty="NA" />
                  <AmountPay
                    data={v?.Transactions}
                    status="Refund"
                    empty="NA"
                  />
                  <TableCell>{v.status || "NA"}</TableCell>
                  <TableCell>{v?.Trollies?.length || "NA"}</TableCell>
                  <TableCell>
                    <Typography
                      color={
                        !!moment(v.createdAt).calendar()
                          ? "primary"
                          : "secondary"
                      }
                    >
                      {moment(v.createdAt).calendar() || "NA"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Hoc.Product.MenuButton
                      text="Action"
                      actions={menuActions(v)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TblContainer>
          ) : (
            <Hoc.EmptyMessage />
          )}
        </Card.Group>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { id: "id", label: "ID" },
  { id: "is_open", label: "Opened" },
  { id: "ST_price", label: "Total Price" },
  { id: "01", label: "Total Paid" },
  { id: "02", label: "Total Refund" },
  { id: "status", label: "Status" },
  { id: "08", label: "Trollies Count" },
  { id: "createdAt", label: "Created At" },
  { id: "10", label: "Actions", disableSorting: true },
];

export default ViewAll;
