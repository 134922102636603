import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../Hoc";
import Controls from "../../Controls";
import Action from "../../../actions";
import Custom from "..";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Root = (props) => {
  const dispatch = useDispatch();
  const Act_review = Action.product.review;
  const Act_order = Action.store.order;
  const Act_report = Action.product.report;
  const [review, setReview] = useState(initReview);
  const [openReview, setOpenReview] = useState(false);
  const [reviewInfo, setReviewInfo] = useState(null);
  const [currentReview, setCurrentReview] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const { Act, view_all, title, current } = props;
  const H = useHistory();
  const reviews = useSelector(({ product: p }) => p.review.view_all.data);
  const root = useSelector(({ people: p }) => p.root.data);
  let { data } = view_all;
  const ARR = (v) => (Array.isArray(v) ? v : []);

  data = ARR(data).filter((v) => (current ? !!v?.is_open : !v?.is_open));
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = Custom.useForm.useForm(initToken);
  // current
  useEffect(() => {
    dispatch(Act.my_view_all());
    dispatch(Act_review.view_all());
  }, [dispatch, Act, Act_review]);

  const CKArr = (v) => (Array.isArray(v) && !!v.length ? v : []);

  const actionItems = (v) => {
    let items = [
      { name: "View Order", onClick: () => H.push(`/orders-01/${v}`) },
      { name: "Cancel Order" },
      { name: "Request Refund" },
    ];

    return items;
  };

  const onReviewInfo = (v) => {
    setOpenReview(true);
    const CK = (v1) =>
      v1?.CustomerId === root?.id &&
      v1?.item_id === v?.item_id &&
      v1?.item_type === v?.item_type;

    if (ARR(reviews).some((v) => CK(v))) {
      const rv = ARR(reviews).find((v) => CK(v));
      setCurrentReview(rv);
      setReview({ text: rv?.text, rating: rv?.rating });
    } else {
      setReviewInfo(
        ((v) => ({
          item_id: v?.item_id,
          item_type: v?.item_type,
          CustomerId: root.id,
        }))(v)
      );
    }
  };
  const onReport = (v) => {
    setOpenReport(true);
    setReviewInfo(v);
  };

  const cartActions = (v) => {
    let items = [
      { name: "Review", onClick: () => onReviewInfo(v) },
      { name: "Support Ticket", onClick: () => onReport(v) },
    ];
    // status
    return items;
  };

  const tblRecords = CKArr(data).map(({ Carts, ...v }) => ({
    main: [
      v?.id,
      v?.payment_status,
      v?.status,
      v?.paid_amount,
      v?.total_price,
      v?.paid_total,
      <Hoc.Product.MenuButton text="Action" actions={actionItems(v.id)} />,
    ],
    child: CKArr(Carts).map((v) => [
      v?.item_id,
      v?.item_type,
      v?.item_name,
      v?.status,
      v?.quantity,
      v?.total_price,
      <Hoc.Product.MenuButton text="Action" actions={cartActions(v)} />,
    ]),
  }));

  const onRvChange = ({ target: v }) =>
    setReview({ ...review, [v.name]: v.value });

  const onOpenReviewClose = () => {
    setOpenReview(false);
    setReview(initReview);
    setReviewInfo(null);
    setCurrentReview(null);
  };

  const onOpenReportClose = () => {
    setOpenReport(false);
    setReviewInfo(null);
  };

  const onSubmit = () => {
    const sendData = {
      ...values,
      cart_id: reviewInfo?.id,
      CartId: reviewInfo?.id,
      CustomerId: reviewInfo?.CustomerId,
    };
    dispatch(Act_report.view_add(sendData));
    setOpenReport(false);
    setReviewInfo(null);
    resetForm();
  };

  return (
    <React.Fragment>
      <Hoc.Dialog
        open={openReport}
        onClose={onOpenReportClose}
        title="Support Ticket"
        fullWidth
        maxWidth="sm"
        actions={
          <Controls.Button
            text="Close"
            color="secondary"
            size="small"
            onClick={onOpenReportClose}
          />
        }
      >
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          maxWidth="xs"
          submitButton={{
            disabled: setup_status(values),
          }}
        >
          <Controls.SearchSelect {...token_setup.type(values.type, onChange)} />
          <Controls.Input {...token_setup.subject(values.subject, onChange)} />
          <Controls.Input
            {...token_setup.description(values.description, onChange)}
          />
          <Controls.Input {...token_setup.advice(values.advice, onChange)} />
          <Controls.SearchSelect
            {...token_setup.urgency(values.urgency, onChange)}
          />
        </Form>
      </Hoc.Dialog>

      <Hoc.Dialog
        open={openReview}
        onClose={onOpenReviewClose}
        title="Review"
        fullWidth
        maxWidth="xs"
        actions={
          <Box>
            <Controls.Button
              text="Submit"
              size="small"
              onClick={() => {
                if (currentReview?.id) {
                  dispatch(Act_review.view_edit(currentReview?.id, review));
                } else {
                  dispatch(Act_review.view_add({ ...review, ...reviewInfo }));
                }

                onOpenReviewClose();
                dispatch(Act_order.my_view_all());
              }}
              disabled={!review?.description || !review?.rating}
            />
            <Controls.Button
              text="Close"
              color="secondary"
              size="small"
              onClick={onOpenReviewClose}
            />
          </Box>
        }
      >
        <Controls.Input
          {...setup.description(review.description, onRvChange)}
        />
        <Controls.SearchSelect {...setup.rating(review.rating, onRvChange)} />
      </Hoc.Dialog>

      <Hoc.Page title={title}>
        <Hoc.PageBox title={title} actions={[...list_people(current)]}>
          {!!ARR(data).length ? (
            <Custom.CollapsibleTable
              childLabel="Trollies"
              cells={cells}
              records={tblRecords}
              childCells={headCells}
            />
          ) : (
            <Hoc.Message text="Empty Resource found." color="yellow" />
          )}
        </Hoc.PageBox>
      </Hoc.Page>
    </React.Fragment>
  );
};

const setup_status = (v) =>
  !v?.subject || !v?.advice || !v?.description || !v?.type;

const initToken = {
  subject: "",
  advice: "",
  description: "",
  type: "",
  urgency: "Not Urgent",
};

const token_setup = {
  subject: (v, onChange) => Hoc.setupText(v, onChange, "subject", "Subject"),
  advice: (v, onChange) => Hoc.setupText(v, onChange, "advice", "Advice"),
  description: (v, onChange) =>
    Hoc.setupLongText(v, onChange, "description", "Description"),
  type: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "type", "Service Type", tp01),
  urgency: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "urgency", "Importance of token", tp02),
};

const tp01 = [
  { id: "General", title: "General Information" },
  { id: "Return", title: "Return An Item" },
  { id: "Refund", title: "Request Refund" },
  { id: "Support", title: "Product Support" },
  { id: "Complain", title: "Raise Complaint" },
];
const tp02 = [
  { id: "Not Urgent", title: "Not Urgent" },
  { id: "General", title: "Normal" },
  { id: "Important", title: "Important" },
  { id: "Urgent", title: "Urgent" },
];

const setup = {
  description: (v, onChange) => ({
    ...Hoc.setupLongText(v, onChange, "description", "Review"),
    style: { marginBottom: 20 },
  }),
  rating: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "rating", "Rating", tp03),
};

const tp03 = [
  { id: 1, title: "Worst" },
  { id: 2, title: "Not Good" },
  { id: 3, title: "Average" },
  { id: 4, title: "Good" },
  { id: 5, title: "Excellent" },
];

const initReview = { description: "", rating: "" };

const list_people = (v) => {
  let items = [{ name: "Support Ticket", to: "/customer-general-tickets" }];

  if (!!v) {
    items = [{ name: "Order History", to: "/orders-01" }, ...items];
  } else {
    items = [
      { name: "Current Order", to: "/customer-current-order" },
      ...items,
    ];
  }

  return items;
};

const cells = [
  "ID",
  "Payment Status",
  "Status",
  "Last Paid",
  "Total Price",
  "Paid Total",
  "Actions",
];

const headCells = [
  "Item ID",
  "Category",
  "Item Name",
  "Status",
  "Quantity",
  "Total Price",
  "Actions",
];

export default Root;
