// Base
import * as country_ship_tax from "./base/country-ship-charge";
import * as product from "./base/product";
import * as review from "./base/review";
import * as report from "./base/report";
import * as note from "./base/note";
import * as coupon from "./base/coupon";

// Category
import * as category_01 from "./category/category-01";
import * as category_02 from "./category/category-02";
import * as category_03 from "./category/category-03";

// Variant
import * as box from "./variant/box";
import * as description from "./variant/description";
import * as info from "./variant/info";
import * as photo from "./variant/photo";

const Expo = {
  // Variant
  box,
  description,
  info,
  photo,
  category_01,
  category_02,
  category_03,
  // Base
  coupon,
  note,
  product,
  review,
  report,
  country_ship_tax,
};

export default Expo;
