import React, { useEffect } from "react";
import Hoc from "../../comps/Hoc";
import action from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import Custom from "../../comps/Custom";

const List = () => {
  const act_currency = action.base02.currency;
  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(
    ({ base02: B }) => B.currency.list
  );

  useEffect(() => {
    dispatch(act_currency.list());
  }, [dispatch, act_currency]);
  const refresh = () => dispatch(act_currency.list());
  const nav_01 = {
    title: "List Of Currencies",
    actions: [
      { name: "Create New", to: "/currency-add" },
      { name: "Refresh", onClick: refresh },
      { name: "Back", goBack: true },
    ],
  };
  const TBL = Custom.useTable(data, cells);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  const t_acts = (v) => {
    let items = [];
    const onDelete = () => {
      dispatch(act_currency.view_delete(v?.id));
      refresh();
    };
    items = [
      { name: "Edit", to: `/currency-edit/${v?.id}` },
      { name: "Delete", onClick: () => onDelete() },
    ];

    return items;
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData error={error} loading={loading}>
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell content={v.id} />
              <TableCell content={v.name} />
              <TableCell content={v.currency} />
              <TableCell content={v.value} />
              <TableCell
                content={<Hoc.Product.MenuButton actions={t_acts(v)} />}
              />
            </TableRow>
          ))}
        </TblContainer>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { name: "id", label: "ID" },
  { name: "name", label: "Name" },
  { name: "currency", label: "Currency" },
  { name: "value", label: "Currency Value / GBP" },
  { name: "04", label: "Action", disableSorting: true },
];

export default List;
