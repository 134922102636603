import React, { useEffect } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Header } from "semantic-ui-react";

const CouponAdd = () => {
  const Act_coupon = Action.product.coupon;
  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(
    ({ product: P }) => P.coupon.view_all
  );
  const { finalRecords, TblContainer } = Custom.useTable(data, cells);

  useEffect(() => {
    dispatch(Act_coupon.view_all());
  }, [dispatch, Act_coupon]);

  const refresh = () => dispatch(Act_coupon.view_all());
  const actions = [
    TOA("Create New", "/add-advertise"),
    TOA("Refresh", false, refresh),
  ];

  const CPActions = (v) => {
    let items = [];

    const onDelete = () => {
      dispatch(Act_coupon.view_delete(v));
      refresh();
    };

    const view = { name: "View", to: `/view-advertise/${v}` };
    const edit = { name: "edit", to: `/edit-advertise/${v}` };
    const delete_item = { name: "Delete", onClick: onDelete };

    items = [view, edit, delete_item];

    return items;
  };

  return (
    <Hoc.Dashboard.Container title="Advertisements" actions={actions}>
      <Hoc.UseData error={error} loading={loading}>
        {!!finalRecords().length ? (
          <TblContainer paginate>
            {finalRecords().map((v) => (
              <TableRow key={v.id}>
                <TableCell>{v.name}</TableCell>
                <TableCell>{v.category}</TableCell>
                <TableCell>
                  {`${v.description}`.substring(0, 10) + ".."}
                </TableCell>
                <TableCell>{v.ratio}</TableCell>
                <TableCell>{toDate(v.start_date)}</TableCell>
                <TableCell>{toDate(v.end_date)}</TableCell>
                <TableCell>
                  <Header
                    content={CKDD(v.end_date) ? "Not Expired" : "Expired"}
                    color={CKDD(v.end_date) ? "green" : "red"}
                    size="tiny"
                  />
                </TableCell>
                <TableCell>
                  <Hoc.Product.MenuButton actions={CPActions(v?.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TblContainer>
        ) : (
          <Hoc.EmptyMessage />
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const CKDD = (v) => new Date(v).getTime() > new Date(new Date()).getTime();

const toDate = (v) => moment(v).calendar();

const cells = [
  { id: "name", label: "Name" },
  { id: "category", label: "Category" },
  { id: "description", label: "Description" },
  { id: "ratio", label: "Percentage" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "Status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const TOA = (name, to, onClick) => ({
  name,
  ...(to && { to }),
  ...(onClick && { onClick }),
});

export default CouponAdd;
