const types = {
  //
  NOTE_VIEW_ALL_REQUEST: "NOTE_VIEW_ALL_REQUEST",
  NOTE_VIEW_ALL_SUCCESS: "NOTE_VIEW_ALL_SUCCESS",
  NOTE_VIEW_ALL_FAIL: "NOTE_VIEW_ALL_FAIL",
  //
  NOTE_VIEW_REQUEST: "NOTE_VIEW_REQUEST",
  NOTE_VIEW_SUCCESS: "NOTE_VIEW_SUCCESS",
  NOTE_VIEW_FAIL: "NOTE_VIEW_FAIL",
  //
  NOTE_EDIT_REQUEST: "NOTE_EDIT_REQUEST",
  NOTE_EDIT_SUCCESS: "NOTE_EDIT_SUCCESS",
  NOTE_EDIT_FAIL: "NOTE_EDIT_FAIL",
  //
  NOTE_ADD_REQUEST: "NOTE_ADD_REQUEST",
  NOTE_ADD_SUCCESS: "NOTE_ADD_SUCCESS",
  NOTE_ADD_FAIL: "NOTE_ADD_FAIL",
  //
  NOTE_DELETE_REQUEST: "NOTE_DELETE_REQUEST",
  NOTE_DELETE_SUCCESS: "NOTE_DELETE_SUCCESS",
  NOTE_DELETE_FAIL: "NOTE_DELETE_FAIL",
};

export default types;
