import React, { useEffect } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid } from "@material-ui/core";
import Action from "../../../actions";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const Act_ship_tax = Action.product.country_ship_tax;
  const { title, formData } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const { data, error, loading } = useSelector(
    ({ product: p }) => p.country_ship_tax.view_all
  );

  const ProductBoxId = values?.ProductBoxId;
  const onSubmit = async () => {
    const edited = await dispatch(Act_ship_tax.view_add(values));
    !!edited && dispatch(Act_ship_tax.view_all(`ProductBoxId=${ProductBoxId}`));
    resetForm();
  };

  useEffect(() => {
    dispatch(Act_ship_tax.view_all(`ProductBoxId=${ProductBoxId}`));
  }, [dispatch, Act_ship_tax, ProductBoxId]);

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: !values?.country || !values?.charge,
        }}
      >
        <Grid container justifyContent="space-around">
          <Controls.OldSearchSelect
            {...setup.country(values.country, onChange)}
          />
          <Controls.Input {...setup.charge(values.charge, onChange)} />
        </Grid>
      </Form>
      <Box>
        <Hoc.UseData error={error} loading={loading}>
          {Array.isArray(data) &&
            !!data.length &&
            data.map((v, idx) => (
              <Chip
                key={idx}
                label={`${v?.country} - ${v?.charge}`}
                color="primary"
                onDelete={() => dispatch(Act_ship_tax.view_delete(v.id))}
                style={{ margin: 3 }}
              />
            ))}
        </Hoc.UseData>
      </Box>
    </Hoc.FormContainer>
  );
};

const setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "name0"),
  country: (v, onChange) => Hoc.setupSelect(v, onChange, "country"),
  charge: (v, onChange) => ({
    ...Hoc.setupText(v, onChange, "charge", "Shipping Charge", "number"),
    inputProps: {
      min: 0,
      step: 0.0001,
    },
  }),
};

export default SignIn;
