import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base02_type.notif;

const URL = `${Helper.URL}/notification`;

//   ---------------------------------------   Payments

export const list = (qryStr) => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_LIST_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${qryStr || ""}`);

    dispatch({ type: types.NOTIF_LIST_SUCCESS, payload: data?.result });
    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_LIST_FAIL);
  }
};

const roleName = (role) =>
  role === "Company_Manager"
    ? "CompanyManagerId"
    : role === "Company_Admin"
    ? "CompanyAdminId"
    : role === "Store_Manager"
    ? "StoreManagerId"
    : role === "Store_Employee"
    ? "StoreEmployeeId"
    : role === "Customer"
    ? "CustomerId"
    : "CustomerId";

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_ADD_REQUEST });
    Helper.set_jwt(state());
    let finalData = initData;
    if (!initData?.role) {
      const root = state()?.people?.root?.data;
      finalData = {
        ...finalData,
        role: root?.role,
        [roleName(root?.role)]: root?.id,
      };
    }
    const { data } = await axios.post(URL, finalData);
    dispatch({ type: types.NOTIF_ADD_SUCCESS, payload: data?.result });
    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });

    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);

    dispatch({ type: types.NOTIF_EDIT_SUCCESS, payload: data?.result });
    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_EDIT_FAIL);
  }
};

export const count = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_LEN_REQUEST });
    Helper.set_jwt(state());

    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });
    return DT?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_LEN_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.NOTIF_DELETE_SUCCESS, payload: data?.result });
    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_DELETE_FAIL);
  }
};

export const read_all = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_RD_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/all`);

    dispatch({ type: types.NOTIF_RD_ALL_SUCCESS, payload: data?.result });
    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_RD_ALL_FAIL);
  }
};

export const delete_all = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.NOTIF_DEL_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/all`);

    dispatch({ type: types.NOTIF_DEL_ALL_SUCCESS, payload: data?.result });
    const { data: DT } = await axios.get(`${URL}/count`);
    dispatch({ type: types.NOTIF_LEN_SUCCESS, payload: DT?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.NOTIF_DEL_ALL_FAIL);
  }
};
