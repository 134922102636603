const types = {
  //
  PAY_STRIPE_REQUEST: "PAY_STRIPE_REQUEST",
  PAY_STRIPE_SUCCESS: "PAY_STRIPE_SUCCESS",
  PAY_STRIPE_FAIL: "PAY_STRIPE_FAIL",
  //
  ADD_TRANSACTION_REQUEST: "ADD_TRANSACTION_REQUEST",
  ADD_TRANSACTION_SUCCESS: "ADD_TRANSACTION_SUCCESS",
  ADD_TRANSACTION_FAIL: "ADD_TRANSACTION_FAIL",
  //
  EDIT_TRANSACTION_REQUEST: "EDIT_TRANSACTION_REQUEST",
  EDIT_TRANSACTION_SUCCESS: "EDIT_TRANSACTION_SUCCESS",
  EDIT_TRANSACTION_FAIL: "EDIT_TRANSACTION_FAIL",
  //
  GET_TRANSACTION_REQUEST: "GET_TRANSACTION_REQUEST",
  GET_TRANSACTION_SUCCESS: "GET_TRANSACTION_SUCCESS",
  GET_TRANSACTION_FAIL: "GET_TRANSACTION_FAIL",
  //
  GET_ALL_TRANSACTION_REQUEST: "GET_ALL_TRANSACTION_REQUEST",
  GET_ALL_TRANSACTION_SUCCESS: "GET_ALL_TRANSACTION_SUCCESS",
  GET_ALL_TRANSACTION_FAIL: "GET_ALL_TRANSACTION_FAIL",
  //
  TRANSACTION_DELETE_REQUEST: "TRANSACTION_DELETE_REQUEST",
  TRANSACTION_DELETE_SUCCESS: "TRANSACTION_DELETE_SUCCESS",
  TRANSACTION_DELETE_FAIL: "TRANSACTION_DELETE_FAIL",
};

export default types;
