import React from "react";
import Controls from "../../../../comps/Controls";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import Action from "../../../../actions";
import { useDispatch } from "react-redux";

const EditTrollyStatusDialog = (props) => {
  const { open, setOpen } = props;
  const Act_trolly = Action.store.trolly.view_edit;
  const dispatch = useDispatch();

  const onClose = () => setOpen({ status: false, Trolly: null });

  const { Form, useForm } = Custom.useForm;

  const status = open?.Trolly?.status;

  const formData = useForm({ status: status ? status : null });

  const { values, onChange, resetForm } = formData;

  const onSubmit = () => {
    dispatch(Act_trolly(open?.Trolly?.id, values));
    resetForm();
    onClose();
  };

  return (
    <Hoc.Dialog
      open={open?.status}
      onClose={onClose}
      title={`${open?.Trolly?.Product_Box?.name} (Edit Status)`}
      fullWidth
      maxWidth="sm"
      actions={
        <Controls.Button
          text="Close"
          color="secondary"
          size="small"
          onClick={onClose}
        />
      }
    >
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        maxWidth="xs"
        submitButton={{
          disabled: !values?.status,
        }}
      >
        <Controls.SearchSelect {...setup.status(values.status, onChange)} />
      </Form>
    </Hoc.Dialog>
  );
};

const setup = {
  status: (value, onChange) => ({
    name: "status",
    label: "Select Category",
    value: value ? value : "",
    onChange,
    options: tr01,
  }),
};

const tr01 = [
  "Trolly",
  "Open",
  "Dispatched",
  "Shipped",
  "Out for delivery",
  "Delivered",
  "Cancelled",
];

export default EditTrollyStatusDialog;
