import React from "react";
import Hoc from "../../../comps/Hoc";

const View = () => {
  const nav01 = {
    title: "View Page",
    //
  };

  return (
    <Hoc.Dashboard.Container {...nav01}>
      <h1>View All Page</h1>
    </Hoc.Dashboard.Container>
  );
};

export default View;
