const types = {
  //
  DESCRIPTION_VIEW_ALL_REQUEST: "DESCRIPTION_VIEW_ALL_REQUEST",
  DESCRIPTION_VIEW_ALL_SUCCESS: "DESCRIPTION_VIEW_ALL_SUCCESS",
  DESCRIPTION_VIEW_ALL_FAIL: "DESCRIPTION_VIEW_ALL_FAIL",
  //
  DESCRIPTION_VIEW_REQUEST: "DESCRIPTION_VIEW_REQUEST",
  DESCRIPTION_VIEW_SUCCESS: "DESCRIPTION_VIEW_SUCCESS",
  DESCRIPTION_VIEW_FAIL: "DESCRIPTION_VIEW_FAIL",
  //
  DESCRIPTION_EDIT_REQUEST: "DESCRIPTION_EDIT_REQUEST",
  DESCRIPTION_EDIT_SUCCESS: "DESCRIPTION_EDIT_SUCCESS",
  DESCRIPTION_EDIT_FAIL: "DESCRIPTION_EDIT_FAIL",
  //
  DESCRIPTION_ADD_REQUEST: "DESCRIPTION_ADD_REQUEST",
  DESCRIPTION_ADD_SUCCESS: "DESCRIPTION_ADD_SUCCESS",
  DESCRIPTION_ADD_FAIL: "DESCRIPTION_ADD_FAIL",
  //
  DESCRIPTION_DELETE_REQUEST: "DESCRIPTION_DELETE_REQUEST",
  DESCRIPTION_DELETE_SUCCESS: "DESCRIPTION_DELETE_SUCCESS",
  DESCRIPTION_DELETE_FAIL: "DESCRIPTION_DELETE_FAIL",
};

export default types;
