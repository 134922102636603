import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MultiCarousel from "react-multi-carousel";
import Magnifier from "react-magnifier";
import Hoc from "../../../../Hoc";
import clsx from "clsx";

const LeftSide = (props) => {
  const CSS = useCSS();
  const Arr = Hoc.isArr;
  const { data } = props;
  const [moreDesc, setMoreDesc] = React.useState(false);
  const [moreText, setMoreText] = React.useState(false);
  const [photoID, setPhotoID] = useState(null);

  let photos = Arr(data?.Photos);
  photos = [
    { id: parseInt(photos?.length + 1), photo: data?.photo },
    ...photos,
  ];

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h4">{data?.name}</Typography>
      <br />
      {!!photoID && Arr(photos).some((v) => v.id === photoID) ? (
        <Magnifier
          src={Arr(photos).find((v) => v.id === photoID)?.photo}
          height={250}
          width="auto"
        />
      ) : (
        <Magnifier src={data?.photo} height={250} width="auto" />
      )}
      <MultiCarousel responsive={responsive} className={CSS.sm_photo_root}>
        {Arr(photos).map(({ id, photo }) => (
          <Box
            key={id}
            component="img"
            src={photo}
            className={clsx(
              CSS.sm_photo_src,
              id === photoID && CSS.sm_photo_act
            )}
            onClick={() => setPhotoID(id)}
          />
        ))}
      </MultiCarousel>
      {!!data?.Descriptions.length && (
        <Box style={{ margin: "2rem 0" }}>
          <Typography variant="h4">About this product</Typography>
          {!moreDesc ? (
            <Typography variant="body2" className={CSS.text}>
              &emsp;{data?.Descriptions[0]?.text}
            </Typography>
          ) : (
            data?.Descriptions.map(({ id, text }) => (
              <Typography key={id} variant="body2" className={CSS.text}>
                &emsp;{text}
              </Typography>
            ))
          )}

          <Typography
            variant="body2"
            className={CSS.moreText}
            onClick={() => setMoreDesc(!moreDesc)}
          >
            {!moreDesc ? "Read More" : "Read Less"}
          </Typography>
        </Box>
      )}
      {!!data?.Product_Infos?.length && (
        <>
          {!moreText ? (
            <ShowText infos={[data?.Product_Infos[0]]} />
          ) : (
            <ShowText infos={data?.Product_Infos} />
          )}

          <Typography
            variant="body2"
            className={CSS.moreText}
            onClick={() => setMoreText(!moreText)}
          >
            {!moreText ? "Read More" : "Read Less"}
          </Typography>
        </>
      )}
    </Grid>
  );
};

const useCSS = makeStyles((theme) => ({
  sm_photo_root: {
    width: 400,
    display: "flex",
    alignItems: "center",
  },
  sm_photo_act: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  sm_photo_src: {
    height: theme.spacing(6),
    padding: theme.spacing(0.2),
    borderRadius: theme.spacing(0.5),

    cursor: "pointer",
  },
  text: {
    fontSize: 16,
    fontWeight: 100,
    marginBottom: 15,
  },
  text_14: {
    fontSize: 14,
  },
  moreText: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "right",
  },
}));

const ShowText = ({ infos }) => {
  const Item = ({ title, info }) => (
    <Box style={{ margin: "1rem 0" }}>
      <Typography variant="h6" style={{ fontWeight: 100 }}>
        {title}
      </Typography>
      <ul>
        {`${info}`.split(",,").map((v, idx) => (
          <Typography
            key={idx}
            component="li"
            className={clsx(CSS.text, CSS.text_14)}
            style={{ fontWeight: 100 }}
          >
            {v}
          </Typography>
        ))}
      </ul>
    </Box>
  );

  return infos.map(({ title, info }, idx) => (
    <Item key={idx} title={title} info={info} />
  ));
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export default LeftSide;
