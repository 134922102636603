import { Route } from "react-router-dom";
import cmp_manager from "./cmp-manager";
import cmp_admin from "./cmp-admin";
import store_manager from "./store-manager";
import store_employee from "./store-employee";
import customer from "./customer";
import email_confirm from "./email-confirmation";
import reset_password from "./reset-password";

const Expo = {
  cmp_manager,
  cmp_admin,
  store_manager,
  store_employee,
  customer,
  email_confirm: (
    <Route exact path="/email-confirmation/:token" component={email_confirm} />
  ),
  reset_password: (
    <Route exact path="/reset-password/:token" component={reset_password} />
  ),
};

export default Expo;
