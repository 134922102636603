import SecureRoute from "../../../comps/layout/SecureRoute";
import view_all from "./view_all";
import view from "./view";
import add from "./add";
import edit from "./edit";
import my_store from "./my_store";

const Expo = {
  view_all: <SecureRoute grant="C2" path="/stores" component={view_all} />,
  view: <SecureRoute grant="C2" path="/store-view/:id" component={view} />,
  add: <SecureRoute grant="C1" path="/store-add" component={add} />,
  edit: <SecureRoute grant="S2" path="/store-edit/:id" component={edit} />,
  my_store: <SecureRoute grant="S2" path="/my-store" component={my_store} />,
};

export default Expo;
