import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Action from "../../../actions";
import { TableRow, TableCell } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import Custom from "../../../comps/Custom";

const View = () => {
  const Act_subscribe = Action.base.subscribes;
  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(
    ({ base: B }) => B.subscribes.view_all
  );

  useEffect(() => {
    dispatch(Act_subscribe.view_all());
  }, [dispatch, Act_subscribe]);

  const nav01 = {
    title: "Subscribers",
    actions: [
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: () => dispatch(Act_subscribe.view_all()) },
    ],
  };

  const { TblContainer, finalRecords } = Custom.useTable(data, cells);

  return (
    <Hoc.Dashboard.Container {...nav01}>
      <Hoc.UseData error={error} loading={loading}>
        {!!finalRecords().length ? (
          <TblContainer paginate>
            {finalRecords().map((v, idx) => (
              <TableRow key={idx}>
                <TableCell>{v?.id}</TableCell>
                <TableCell>{v?.name}</TableCell>
                <TableCell>{v?.email}</TableCell>
                <TableCell>
                  <Hoc.Product.MenuButton />{" "}
                </TableCell>
              </TableRow>
            ))}
          </TblContainer>
        ) : (
          <Hoc.EmptyMessage />
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { name: "id", label: "ID" },
  { name: "name", label: "Name" },
  { name: "email", label: "Email" },
  { name: "04", label: "Action", disableSorting: true },
];

export default View;
