import SecureRoute from "../../../comps/layout/SecureRoute";
import order_address from "./order_address";
import view from "./view";
import transaction from "./transaction";

const Expo = {
  transaction,
  view: <SecureRoute grant="GN" path="/view-order/:id" component={view} />,
  order_address: (
    <SecureRoute grant="GN" path="/order-address" component={order_address} />
  ),
};

export default Expo;
