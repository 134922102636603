import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";

const SignIn = () => {
  const Act = Action.people.S1.sign_in;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.SignIn
      title="Store Manager Login"
      endLink="/s1-sign-up"
      forgotPsw="/s1-forgot-password"
      submitAction={Act}
      formData={formData}
      to={{ url: "/store-dashboard", role: "Store_Manager" }}
    />
  );
};

const initValues = {
  email: "",
  password: "",
};

export default SignIn;
