import React from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { DialogTitle, DialogContentText, Slide } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog(props) {
  const { open, onClose, noContent, title, text, actions, children, ...rest } =
    props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...rest}
    >
      {!!title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      {!!noContent ? (
        children
      ) : (
        <DialogContent>
          {!!text && <DialogContentText>{text}</DialogContentText>}
          {children}
        </DialogContent>
      )}

      {!!actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
