import { Box, Grid } from "@material-ui/core";
import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import Hoc from "../Hoc";
import moment from "moment";
import Action from "../../actions";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const CSS = useCSS();
  const Act_footer = Action.base02.footer.view_all;
  const Act_Oop = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const H = useHistory();
  const data = useSelector(({ base02: B }) => B.footer.list.data);
  const root = useSelector(({ people: P }) => P.root.data);

  const sclinks = data?.filter((v) => v?.category === "Social Link");
  const nmlinks = data?.filter((v) => v?.category === "Normal Link");

  useEffect(() => {
    dispatch(Act_footer());
  }, [dispatch, Act_footer]);

  const onPushDashboard = () => {
    if (!!root?.role) {
      const CKR = (v) => `${root?.role}`.includes(v);
      H.push(
        `${
          CKR("Company")
            ? "company"
            : CKR("Store")
            ? "store"
            : CKR("Customer") && "customer"
        }-dashboard`
      );
    } else {
      dispatch(Act_Oop("Please login to go to Dashboard", true));
    }
  };

  return (
    <React.Fragment>
      <Box className={clsx(CSS.footer, "primary-gradient")}>
        <Hoc.Home.ThreeCards data={nmlinks} />

        <Grid className={CSS.grid_root} container spacing={3}>
          <Grid {...gridItem}>
            <Box style={{ cursor: "pointer" }} onClick={() => H.push("/")}>
              <Box component="img" src="/logo.png" height={70} />
            </Box>

            <Typography variant="h6" color="primary">
              Language Shop
            </Typography>
          </Grid>
          {!!sclinks?.length && (
            <Grid {...gridItem}>
              <FooterHead text="Social Media">
                {sclinks?.map((v) => (
                  <LinkTarget key={v.id} href={v.url} content={v.name} />
                ))}
              </FooterHead>
            </Grid>
          )}

          <Grid {...gridItem}>
            <FooterHead text="Page Links">
              <LinkTo text="Home" to="/" />
              <LinkTo text="Dashboard" onClick={onPushDashboard} />
              <LinkTo text="Store" to="/s2-sign-in" />
            </FooterHead>
          </Grid>
          <Grid {...gridItem}>
            <FooterHead text="Userful Links">
              <LinkTo text="About US" to="/about-us" />
              <LinkTarget
                content="Contact US"
                href="https://linguistscollective.com/landing-page/contact"
              />

              <LinkTo text="Terms and Conditions" to="/terms-and-conditions" />
            </FooterHead>
          </Grid>
        </Grid>
      </Box>
      <Box className={CSS.signature}>
        <Typography variant="body2" color="textSecondary">
          &copy; Linguists Collective (LC) {moment().format("YYYY")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Builder : {"{"}
          <LinkTarget
            content="CITC"
            href="https://cambridgeitconsultancy.co.uk"
          />
          {"}"}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

const LinkTarget = ({ href, content }) => (
  <Link target="_blank" href={href}>
    {content}
  </Link>
);

const gridItem = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
  component: Box,
  display: "flex",
  flexDirection: "column",
  align: "center",
};

const FooterHead = ({ text, children, Mtop }) => (
  <Box {...(Mtop && { style: { marginTop: 10 } })}>
    <Typography variant="h6" color="secondary" style={{ fontSize: 16 }}>
      {text}
    </Typography>
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  </Box>
);

const LinkTo = ({ text, to, onClick }) => (
  <Link
    {...(!onClick
      ? { component: RouterLink, to: to }
      : { onClick, style: { cursor: "pointer" } })}
    className={CSS.to_link}
  >
    {text}
  </Link>
);

const useCSS = makeStyles((theme) => ({
  gapOnMd: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0),
    },
  },
  grid_root: {
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(5, 0),
  },
  to_link: {
    fontSize: 14,
    marginTop: theme.spacing(0.3),
  },
  footer: {
    padding: theme.spacing(2, 1),
  },
  footer_main: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  footer_links: {
    "& .MuiLink-root": {
      fontSize: 10,
      marginLeft: 5,
      marginRight: 5,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  signature: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontWeight: 400,
    padding: theme.spacing(1.5),
  },
}));

export default Footer;
