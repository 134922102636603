import people from "./people";
import base from "./base";
import base02 from "./base02";
import product from "./product";
import store from "./store";

const Expo = {
  people,
  base,
  base02,
  product,
  store,
};

export default Expo;
