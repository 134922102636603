import React from "react";
import { Box, Stepper } from "@material-ui/core";
import { Step, StepLabel } from "@material-ui/core";

const steps = [
  "Select the Trollies",
  "Delivery / Shipping Address",
  "Payments / Transactions",
  "Place The Order",
];

const CompleteSteps = ({ step }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CompleteSteps;
