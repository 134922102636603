import React from "react";
import Custom from "../../../comps/Custom";
import { useSelector } from "react-redux";

const SignIn = () => {
  const { useForm } = Custom.useForm;
  const root = useSelector(({ people: p }) => p.root.data);
  const formData = useForm(initValues);

  return (
    <Custom.Profile.EditPassword
      title="Editing My Password"
      formData={formData}
      root={root}
    />
  );
};

const initValues = {
  old_password: "",
  password: "",
  password2: "",
};

export default SignIn;
