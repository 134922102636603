import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../Hoc";
import Action from "../../../actions";
import Custom from "..";
import { Header, Message } from "semantic-ui-react";

const Root = (props) => {
  const dispatch = useDispatch();
  const Act_review = Action.product.review;
  const Act_report = Action.product.report;
  const Act_note = Action.product.note;
  const Act_S1 = Action.people.S1.view_all;
  const Act_S2 = Action.people.S2.view_all;
  const { Act, view_all, title, to } = props;
  let { data, error, loading } = view_all;
  const root = useSelector(({ people: p }) => p.root.data);
  const { TblContainer, finalRecords } = Custom.useTable(data, cells);
  const [openToken, setOpenToken] = useState(false);
  const [token, setToken] = useState(null);

  const toQry =
    to === "general"
      ? "General"
      : to === "complain"
      ? "Complain"
      : to === "product"
      ? "Product"
      : "All";

  const queryStr =
    root?.role === "Customer"
      ? `type=${toQry}&CustomerId=${root?.id}`
      : `type=${toQry}`;

  useEffect(() => {
    dispatch(Act.view_all(queryStr));
  }, [dispatch, Act, Act_review, queryStr]);

  const onOpenToken = (v) => {
    const S1 = ["Company_Manager", "Company_Admin"];
    const S2 = [...S1, "Store_Manager", "Store_Employee"];

    if (S2.includes(root?.role)) {
      dispatch(Act_S1());
      dispatch(Act_S2());
    }

    setToken(v);
    setOpenToken(true);
    dispatch(Act_note.view_all());
  };

  const menuActions = (v) => {
    let items = [{ name: "View", onClick: () => onOpenToken(v) }];

    const CLTK = () => {
      const dt = { status: "Closed", closed_by: root?.role };
      dispatch(Act_report.view_edit(v?.id, dt));
      dispatch(Act.view_all(queryStr));
    };

    const CKS = v?.status !== "Closed" && root?.role === "Customer";

    if (CKS) items = [...items, { name: "Close", onClick: CLTK }];

    return items;
  };

  const refresh = {
    name: "Refresh",
    onClick: () => dispatch(Act.view_all(queryStr)),
  };

  const setColor = (v) =>
    v === "Open"
      ? "green"
      : v === "In Progress"
      ? "blue"
      : v === "Closed"
      ? "red"
      : "purple";

  return (
    <React.Fragment>
      <Hoc.Product.OpenToken
        token={token}
        open={openToken}
        setOpen={setOpenToken}
        is_product={to === "product"}
        root={root}
      />

      <Hoc.Page title={title}>
        <Hoc.PageBox
          title={title}
          actions={
            root?.role === "Customer"
              ? [...top_nav_01, refresh]
              : [{ name: "Products", to: "/product-view-all-trolly" }, refresh]
          }
        >
          {Hoc.PageTabs(
            show_nav(root?.role === "Customer").filter(
              (v) => !`${v.to}`.includes(to)
            ),
            `${to} `.toUpperCase() + " TICKETS"
          )}
          <Hoc.UseData error={error} loading={loading}>
            {!!finalRecords().length ? (
              <TblContainer paginate>
                {finalRecords().map((v, idx) => (
                  <TableRow
                    key={idx}
                    component={Message}
                    color={SetClr(v?.urgency)}
                  >
                    <TableCell>{v?.urgency}</TableCell>
                    <TableCell>{v?.subject}</TableCell>
                    <TableCell>
                      {`${v?.description}`.substring(0, 15) + "..."}
                    </TableCell>
                    <TableCell>{v?.type}</TableCell>
                    <TableCell>
                      <Header as="h5" color={setColor(v?.status)}>
                        {v?.status}
                      </Header>
                    </TableCell>
                    <TableCell>
                      {v?.owner_id || (
                        <Typography style={{ fontSize: 13 }} color="secondary">
                          NA
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell>
                      <Hoc.Product.MenuButton
                        text="Action"
                        actions={menuActions(v)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TblContainer>
            ) : (
              <Hoc.Message text="Empty Tickets found." color="yellow" />
            )}
          </Hoc.UseData>
        </Hoc.PageBox>
      </Hoc.Page>
    </React.Fragment>
  );
};

const show_nav = (customer) => {
  const preUrl = customer ? "/customer" : "/root";

  let items = [
    { name: "General", to: `${preUrl}-general-tickets` },
    { name: "Complaint", to: `${preUrl}-complain-tickets` },
    { name: "Product Related", to: `${preUrl}-product-tickets` },
  ];

  return items;
};

const cells = [
  { id: "urgency", label: "Urgency" },
  { id: "subject", label: "Subject" },
  { id: "description", label: "Description" },
  { id: "type", label: "Ticket Type" },
  { id: "status", label: "Status" },
  { id: "Owner_ID", label: "Owner ID" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const top_nav_01 = [
  { name: "Raise Complaint", to: "/add-token" },
  { name: "Orders", to: `/orders-01` },
];
const SetClr = (v) =>
  v === "Not Urgent"
    ? "green"
    : v === "General"
    ? "blue"
    : v === "Important"
    ? "pink"
    : v === "Urgent"
    ? "red"
    : "teal";

export default Root;
