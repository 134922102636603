import Button from "./Button";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import FileUpload from "./FileUpload";
import Input from "./Input";
import InputAdornment from "./InputAdornment";
import PhoneInput from "./PhoneInput";
import RadioGroup from "./RadioGroup";
import SearchSelect from "./SearchSelect";
import OldSearchSelect from "./OldSearchSelect";
import AutoSelect from "./AutoSelect";
import Switch from "./Switch";

const controls = {
  Button,
  Checkbox,
  DatePicker,
  FileUpload,
  Input,
  InputAdornment,
  PhoneInput,
  RadioGroup,
  SearchSelect,
  OldSearchSelect,
  Switch,
  AutoSelect,
};

export default controls;
