import React from "react";
import { Paper, Box, ButtonBase, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const Page = (actions, title) => {
  const CSS = useCSS();
  const h = useHistory();
  const ARR = (v) => (Array.isArray(v) ? v : []);
  return (
    <Paper className={CSS.paper}>
      {!!title && <Typography style={{ marginRight: 10 }}>{title}</Typography>}
      <Box>
        {ARR(actions)?.map(({ name, onClick, to, goBack }, idx) => (
          <ButtonBase
            key={idx}
            component={Button}
            variant="outlined"
            color="inherit"
            size="small"
            onClick={
              !!to
                ? () => h.push(`${to}`)
                : !!goBack
                ? () => h.goBack()
                : !!onClick
                ? onClick
                : (f) => f
            }
            className={CSS.link}
          >
            <span className={CSS.link_name}>{name}</span>
          </ButtonBase>
        ))}
      </Box>
    </Paper>
  );
};

const useCSS = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(0.5),
  },
  link_name: {
    textTransform: "None",
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: theme.spacing(2, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(2),
    },
    "& .MuiButton-root": {
      marginRight: theme.spacing(1),
      fontWeight: 300,
    },
    padding: theme.spacing(1.5),
  },
}));

export default Page;
