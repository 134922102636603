import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const Act = Action.people.C2;
  const view_all = useSelector(({ people: v }) => v.cmp_admin.view_all);

  return (
    <Custom.People.ViewAll
      Act={Act}
      view_all={view_all}
      to="c2"
      title="Company Admin"
      queryString="inactive=true"
    />
  );
};

export default ViewAll;
