import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const SignIn = () => {
  const H = useHistory();
  const Act_Opp = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const { useForm } = Custom.useForm;
  const root = useSelector(({ people: p }) => p.root.data);
  const formData = useForm(initValues(root));
  const { values, resetForm } = formData;

  const CKR = (v) => root?.role === v;
  const Act = CKR("Company_Manager")
    ? Action.people.C1
    : CKR("Company_Admin")
    ? Action.people.C2
    : CKR("Store_Manager")
    ? Action.people.S1
    : CKR("Store_Employee")
    ? Action.people.S2
    : CKR("Customer")
    ? Action.people.Customer
    : null;

  const onSubmit = async () => {
    dispatch(Act.view_edit(root?.id, values));
    resetForm();
    H.goBack();
    dispatch(Act_Opp("Profile Address updated."));
  };

  return (
    <Custom.Form.Address
      title="Edit Profile Address"
      formData={formData}
      onSubmit={onSubmit}
      profile
    />
  );
};

const initValues = (v) => ({
  house_name: v?.house_name ? v?.house_name : "",
  street_name: v?.street_name ? v?.street_name : "",
  address_3: v?.address_3 ? v?.address_3 : "",
  city: v?.city ? v?.city : "",
  region: v?.region ? v?.region : "",
  postcode: v?.postcode ? v?.postcode : "",
  country: v?.country ? v?.country : "",
  default: v?.default ? v?.default : false,
});

export default SignIn;
