const types = {
  //
  BANK_ITEM_VIEW_REQUEST: "BANK_ITEM_VIEW_REQUEST",
  BANK_ITEM_VIEW_SUCCESS: "BANK_ITEM_VIEW_SUCCESS",
  BANK_ITEM_VIEW_FAIL: "BANK_ITEM_VIEW_FAIL",
  //
  BANK_ITEM_ADD_REQUEST: "BANK_ITEM_ADD_REQUEST",
  BANK_ITEM_ADD_SUCCESS: "BANK_ITEM_ADD_SUCCESS",
  BANK_ITEM_ADD_FAIL: "BANK_ITEM_ADD_FAIL",
  //
  BANK_ITEM_EDIT_REQUEST: "BANK_ITEM_EDIT_REQUEST",
  BANK_ITEM_EDIT_SUCCESS: "BANK_ITEM_EDIT_SUCCESS",
  BANK_ITEM_EDIT_FAIL: "BANK_ITEM_EDIT_FAIL",
  //
  BANK_ITEM_DELETE_REQUEST: "BANK_ITEM_DELETE_REQUEST",
  BANK_ITEM_DELETE_SUCCESS: "BANK_ITEM_DELETE_SUCCESS",
  BANK_ITEM_DELETE_FAIL: "BANK_ITEM_DELETE_FAIL",
};

export default types;
