import React, { useEffect, useState } from "react";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import ShowTrackDialog from "../order/Hoc/ShowTrackDialog";
import AddTrackDialog from "../trolly/Hoc02/AddTrackDialog";
import EditTrollyStatusDialog from "../trolly/Hoc02/EditTrollyStatusDialog";
import { Header } from "semantic-ui-react";

const Trollies = () => {
  const dispatch = useDispatch();
  const Act_trolly = Action.store.trolly;

  useEffect(() => {
    dispatch(Act_trolly.view_all());
  }, [dispatch, Act_trolly]);

  const refresh = () => dispatch(Act_trolly.view_all());

  const top_nav = [{ name: "Refresh", onClick: refresh }];

  const { data, error, loading } = useSelector(
    ({ store: S }) => S.trolly.view_all
  );
  const root = useSelector(({ people: P }) => P.root.data);
  const { finalRecords, TblContainer } = Custom.useTable(data, cells);

  const [openAddTrack, setOpenAddTrack] = useState({
    status: false,
    TrollyId: null,
  });

  const [trollyStatus, setTrollyStatus] = useState({
    status: false,
    Trolly: null,
  });

  const [viewTrack, setViewTrack] = useState({
    status: false,
    name: null,
    tracks: [],
  });

  const onAddTrack = (TrollyId) => setOpenAddTrack({ status: true, TrollyId });
  const onTrollyStatus = (Trolly) => setTrollyStatus({ status: true, Trolly });

  const onViewTrack = (v) =>
    setViewTrack({
      status: true,
      name: v?.Product_Box?.name,
      tracks: v?.Tracks,
    });

  const t_actions = (v) => {
    const TOT = (name, to) => ({ name, to });
    const TOC = (name, onClick) => ({ name, onClick });
    let items = [
      TOC("View Tracks", () => onViewTrack(v)),
      TOT("View Order", `/view-order/${v?.OrderId}`),
    ];

    const CKR = (v) => `${root?.role}`.includes(v);

    if (!CKR("Customer")) {
      items = [...items, TOC("Create Track", () => onAddTrack(v))];
    }

    if (CKR("Company_Manager")) {
      items = [...items, TOT("Refund", `/transaction-add?TrollyId=${v.id}`)];
    } else if (CKR("Store")) {
      items = [...items, TOC("Change Status", () => onTrollyStatus(v))];
    }

    return items;
  };

  return (
    <Hoc.Dashboard.Container title="Trollies" actions={top_nav}>
      <EditTrollyStatusDialog open={trollyStatus} setOpen={setTrollyStatus} />
      <ShowTrackDialog open={viewTrack} setOpen={setViewTrack} />
      <AddTrackDialog open={openAddTrack} setOpen={setOpenAddTrack} />

      <Hoc.UseData error={error} loading={loading}>
        {!!finalRecords().length ? (
          <TblContainer paginate>
            {finalRecords().map((v, idx) => (
              <TableRow key={idx}>
                <TabCell content={v.country} />
                <TableCell>
                  <Header
                    content={v?.collectable !== "None" ? "Yes" : "No"}
                    color={v?.collectable !== "None" ? "green" : "orange"}
                  />
                </TableCell>
                <TabCell content={v.destination} />
                <TabCell content={v.status} />
                <TabCell content={v.sold_units} />
                <TabCell content={v.ST_price} />
                <TabCell content={v.paid_amount} />
                <TabCell content={v.payment_status} />
                <TabCell content={ToDate(v.createdAt)} />
                <TabCell
                  content={<Hoc.Product.MenuButton actions={t_actions(v)} />}
                />
              </TableRow>
            ))}
          </TblContainer>
        ) : (
          <Hoc.EmptyMessage />
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const TabCell = ({ content }) => (
  <TableCell>{content ? content : "NA"}</TableCell>
);

const ToDate = (v) => moment(v).calendar();

const TOC01 = (id, label) => ({ id, label });
const cells = [
  TOC01("country", "Country"),
  TOC01("collectable", "Collecting fron Store"),
  TOC01("destination", "Destination"),
  TOC01("status", "Status"),
  TOC01("sold_units", "Quantity"),
  TOC01("ST_price", "Total Price"),
  TOC01("paid_amount", "Paid Amount"),
  TOC01("payment_status", "Payment Status"),
  TOC01("createdAt", "Created At"),
  TOC01("09", "Action"),
];

export default Trollies;
