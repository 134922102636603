import React, { useState, useEffect } from "react";
import { Box, Typography, CardMedia } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

const ImageCarousel = ({ photos, to, size }) => {
  const CSS = useCSS();
  const h = useHistory();
  photos = Array.isArray(photos) && photos?.filter((v) => !!v);
  const [count, setCount] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((v) => (v === photos.length ? 1 : v + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, [photos.length]);

  return (
    <Box className={CSS.photo_root}>
      <Box className={CSS.photo_root}>
        <CardMedia
          component="img"
          image={photos[count - 1]}
          height={size || 250}
          {...(!!to && { onClick: () => h.push(to) })}
        />
      </Box>
      <Box className={CSS.photo_action}>
        <NavigateBeforeIcon
          color="inherit"
          onClick={() => setCount((v) => (v === 1 ? photos.length : v - 1))}
        />
        <Typography variant="subtitle2" color="inherit">
          {count}/{photos.length}
        </Typography>
        <NavigateNextIcon
          color="inherit"
          onClick={() => setCount((v) => (v === photos.length ? 1 : v + 1))}
        />
      </Box>
    </Box>
  );
};

const useCSS = makeStyles((theme) => ({
  photo_root: {
    position: "relative",
  },
  photo_action: {
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",
    backgroundColor: "rgba(0,0,0,.5)",
    margin: theme.spacing(2),
    padding: theme.spacing(0.2, 1),
    borderRadius: 5,
    zIndex: 1,
    "& *": {
      color: "rgba(255,255,255,.6)",
    },
    "& *:hover": {
      color: "rgba(255,255,255,1)",
    },
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      zIndex: 2,
    },
  },
}));

export default ImageCarousel;
