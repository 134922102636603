import { Box, TableRow, TableCell } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { NoRecords } from "../utils";
import Hoc from "../../../../../Hoc";
import Controls from "../../../../../Controls";
import Action from "../../../../../../actions";
import { Header } from "semantic-ui-react";

const VariantTable = (props) => {
  const Act_box = Action.product.box;
  const dispatch = useDispatch();

  const { TBL, refresh, onEdit } = props;
  const { TblContainer, finalRecords } = TBL;

  const menuActions = (v) => {
    const TOT = (name, to) => ({ name, to });
    const TOC = (name, onClick) => ({ name, onClick });

    let items = [
      TOT("View Details", `/store-product-view/${v.id}`),
      TOT("Global Charges", `/product-ship-charge/${v.id}`),
      TOC("Edit", () => onEdit(v)),
      TOC("Delete", () => {
        dispatch(Act_box.view_delete(v.id));
        refresh();
      }),
    ];

    return items;
  };

  const onPublish = (id, publish) => {
    dispatch(Act_box.view_edit(id, { publish }));
    refresh();
  };
  const onSubscription = (id, subscription) => {
    dispatch(Act_box.view_edit(id, { subscription }));
    refresh();
  };

  return (
    <Box style={{ marginTop: 10 }}>
      {!Hoc.isArr(finalRecords()).length ? (
        <NoRecords />
      ) : (
        <TblContainer paginate>
          {finalRecords().map((v, idx) => (
            <TableRow key={idx}>
              <TabCell content={v.StoreId} />
              <TabCell content={v.name} />
              <TabCell content={v.S_price} />
              <TabCell content={v.quantity} />
              <Typ01>
                <Controls.Switch
                  name="publish"
                  checked={!!v?.subscription}
                  onChange={({ target: T }) => onSubscription(v?.id, T?.value)}
                />
              </Typ01>
              <Typ01>
                <Controls.Switch
                  name="publish"
                  checked={!!v?.publish}
                  onChange={({ target: T }) => onPublish(v?.id, T?.value)}
                />
              </Typ01>
              <TableCell>
                <Hoc.Product.MenuButton
                  text="Action"
                  actions={menuActions(v)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TblContainer>
      )}
    </Box>
  );
};

const TabCell = ({ content }) => (
  <TableCell>
    <span {...(!content && { style: { color: "orangered" } })}>{content}</span>
  </TableCell>
);

const Typ01 = ({ children, ...rest }) => (
  <TableCell>
    <Header {...rest}>{children}</Header>
  </TableCell>
);

export default VariantTable;
