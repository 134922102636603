import React, { useEffect } from "react";
import Hoc from "../../../comps/Hoc";
import Custom from "../../../comps/Custom";
import action from "../../../actions";
import { Box, TableCell as TabCell } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const FooterList = () => {
  const act_footer = action.base02.footer;
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    ({ base02: B }) => B.footer.list
  );

  useEffect(() => {
    dispatch(act_footer.view_all());
  }, [dispatch, act_footer]);

  const refresh = () => dispatch(act_footer.view_all());

  const nav_01 = {
    title: "All Footer Links",
    actions: [
      { name: "Create New", to: "/footer-add" },
      { name: "Refresh", onClick: refresh },
      { name: "Back", goBack: true },
    ],
  };
  const Tbl = Custom.useTable(data, cells);
  const { TblContainer, finalRecords, TableRow, TableCell } = Tbl;

  const t_acts = (v) => {
    let items = [];

    const onDelete = () => {
      dispatch(act_footer.view_delete(v?.id));
      refresh();
    };

    items = [
      { name: "Edit", to: `/footer-edit/${v?.id}` },
      { name: "Delete", onClick: onDelete },
    ];

    return items;
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData loading={loading} error={error}>
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TabCell>
                {!!v?.photo ? (
                  <Box component="img" src={v?.photo} height={30} />
                ) : (
                  v.id
                )}
              </TabCell>
              <TableCell content={v.category} />
              <TableCell content={v.name} />
              <TableCell content={v.url} />
              <TableCell
                content={<Hoc.Product.MenuButton actions={t_acts(v)} />}
              />
            </TableRow>
          ))}
        </TblContainer>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { id: "id", label: "ID / Photo" },
  { id: "category", label: "Category" },
  { id: "name", label: "Name" },
  { id: "url", label: "URL" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default FooterList;
