const types = {
  //
  TROLLY_EMPTY_LEN_REQUEST: "TROLLY_EMPTY_LEN_REQUEST",
  TROLLY_EMPTY_LEN_SUCCESS: "TROLLY_EMPTY_LEN_SUCCESS",
  TROLLY_EMPTY_LEN_FAIL: "TROLLY_EMPTY_LEN_FAIL",
  //
  TROLLY_VIEW_ALL_REQUEST: "TROLLY_VIEW_ALL_REQUEST",
  TROLLY_VIEW_ALL_SUCCESS: "TROLLY_VIEW_ALL_SUCCESS",
  TROLLY_VIEW_ALL_FAIL: "TROLLY_VIEW_ALL_FAIL",
  //
  TROLLY_VIEW_REQUEST: "TROLLY_VIEW_REQUEST",
  TROLLY_VIEW_SUCCESS: "TROLLY_VIEW_SUCCESS",
  TROLLY_VIEW_FAIL: "TROLLY_VIEW_FAIL",
  //
  TROLLY_EDIT_REQUEST: "TROLLY_EDIT_REQUEST",
  TROLLY_EDIT_SUCCESS: "TROLLY_EDIT_SUCCESS",
  TROLLY_EDIT_FAIL: "TROLLY_EDIT_FAIL",
  //
  TROLLY_EDIT_ALL_REQUEST: "TROLLY_EDIT_ALL_REQUEST",
  TROLLY_EDIT_ALL_SUCCESS: "TROLLY_EDIT_ALL_SUCCESS",
  TROLLY_EDIT_ALL_FAIL: "TROLLY_EDIT_ALL_FAIL",
  //
  TROLLY_ADD_REQUEST: "TROLLY_ADD_REQUEST",
  TROLLY_ADD_SUCCESS: "TROLLY_ADD_SUCCESS",
  TROLLY_ADD_FAIL: "TROLLY_ADD_FAIL",
  //
  TROLLY_DELETE_REQUEST: "TROLLY_DELETE_REQUEST",
  TROLLY_DELETE_SUCCESS: "TROLLY_DELETE_SUCCESS",
  TROLLY_DELETE_FAIL: "TROLLY_DELETE_FAIL",
  //
  TROLLY_COUNT_REQUEST: "TROLLY_COUNT_REQUEST",
  TROLLY_COUNT_SUCCESS: "TROLLY_COUNT_SUCCESS",
  TROLLY_COUNT_FAIL: "TROLLY_COUNT_FAIL",
};

export default types;
