import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../../comps/Hoc";
import Controls from "../../../comps/Controls";
import Action from "../../../actions";
import { useHistory, useParams } from "react-router-dom";
import ShowOrderDetail01 from "./Hoc/ShowOrderDetail01";
import ShowTrolly01 from "./Hoc/ShowTrolly01";
import ShowTransactions from "./Hoc/ShowTransactions";
import { Box } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import PayInfo from "../trolly/Hoc02/PayInfo";

const MakePayment = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const H = useHistory();
  const Act_order = Action.store.order;
  const act_trolly = Action.store.trolly.view_edit;
  const act_notif = Action.base02.notif.view_add;

  const order_view = useSelector(({ store: v }) => v.order.view);
  const { data } = order_view;
  const root = useSelector(({ people: P }) => P.root.data);

  const otherUser =
    root?.role === "Customer" &&
    !!data?.CustomerId &&
    root?.id !== data?.CustomerId;

  useEffect(() => {
    otherUser && H.goBack();
  }, [H, otherUser]);

  useEffect(() => {
    dispatch(Act_order.view(params?.id));
  }, [dispatch, Act_order, otherUser, H, params]);

  const refresh = () => dispatch(Act_order.view(params?.id));

  const topNav = [
    { name: "Refresh", onClick: refresh },
    { name: "Back", goBack: true },
  ];

  const status = order_view?.data?.status;

  const onStatusChange = ({ target: T }) => {
    dispatch(Act_order.view_edit(order_view?.data?.id, { status: T.value }));
    dispatch(Act_order.view(params?.id));

    let text = `Order Number ${data?.id} : Your order status has been changed as ${T.value}.`;
    const ntifPara = {
      text,
      role: "Customer",
      CustomerId: data?.CustomerId,
    };
    dispatch(act_notif(ntifPara));
  };

  const onConfirmOdr = () => {
    const toUrl = `/transaction-add?OrderId=${data?.id}&CustomerId=${data?.CustomerId}`;
    H.push(toUrl);
  };

  const PF = (v) => parseFloat(v);
  const due =
    PF(data?.Trollies?.reduce((a, b) => a + PF(b?.ST_price), 0)) -
      PF(
        data?.Transactions?.filter((v) => v.status === "Paid")?.reduce(
          (a, b) => a + PF(b?.amount),
          0
        )
      ) || 0;

  useEffect(() => {
    if (
      due <= 0 &&
      !!data?.Trollies?.filter((v) => v?.status === "Trolly")?.length
    ) {
      Hoc.isArr(data?.Trollies)
        ?.filter((v) => v.status === "Trolly")
        .forEach((TL) => {
          dispatch(act_trolly(TL.id, { status: "Open" }));
          const curr = TL?.Currency;
          let text = `Product Ordered : The product "${TL?.Product_Box?.name}"`;
          text = `${text} is waiting for delivery on `;
          text = `${text} "${Hoc.showAddress(
            data?.Delivery_Address
          )}" which costs`;
          text = `${text} ${TL?.ST_price} ${curr?.name} (${curr?.currency}) currency.`;

          let para01 = { role: "Store", StoreId: TL?.Product_Box?.Store?.id };
          para01 = { ...para01, text };
          dispatch(act_notif(para01));
        });
    }
  }, [dispatch, act_notif, act_trolly, data, due]);

  return (
    <Hoc.Page title="Payment for Order">
      <Hoc.Dashboard.Container title="My Order" actions={topNav}>
        <Box style={{ width: "100%" }} {...Hoc.flex("flex-end")}>
          {!!root?.token && root?.role !== "Customer" && status !== "None" && (
            <Box style={{ width: 250 }}>
              <Controls.SearchSelect
                {...setup.status(status, onStatusChange)}
              />
            </Box>
          )}
          {due >= 0 && (
            <Button
              content="Confirm Payment for This Order"
              onClick={onConfirmOdr}
            />
          )}
        </Box>

        {!!order_view?.data ? (
          <React.Fragment>
            <ShowOrderDetail01 order_view={order_view} />
            <ShowTrolly01 trollies={order_view?.data?.Trollies} />
            {!!order_view?.data?.Transactions?.length ? (
              <ShowTransactions data={order_view?.data?.Transactions} />
            ) : (
              <Hoc.EmptyMessage content="No Transactions Found" />
            )}
          </React.Fragment>
        ) : (
          <Hoc.EmptyMessage content="No data found" />
        )}
        <PayInfo data={data} trollies={data?.Trollies} />
      </Hoc.Dashboard.Container>
    </Hoc.Page>
  );
};

const setup = {
  status: (v, onChange) =>
    Hoc.setupSelect(v, onChange, "status", "Select Status", sel01),
};

const sel01 = [
  { id: "Ordered", title: "Ordered" },
  { id: "Shipped", title: "Shipped" },
  { id: "Out for delivery", title: "Out for delivery" },
  { id: "Delivered", title: "Delivered" },
  { id: "Cancelled", title: "Cancelled" },
];

export default MakePayment;
