import React from "react";
import Custom from "../../../comps/Custom";
import Hoc from "../../../comps/Hoc";

const AddProduct = () => {
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Hoc.Page title="Create a New Store">
      <Custom.Form.Store title="Create a New Store" formData={formData} />
    </Hoc.Page>
  );
};

const initValues = {
  name: "",
  branch: "",
  vat_price: "",
  contact: "",
  email: "",
  one_address: true,
  // PD
  PD_email: null,
  PD_mobile: null,
  PD_account_number: null,
  PD_account_name: null,
  PD_bank_code: null,
  PD_branch_name: null,
  PD_country_name: null,
  PD_transaction_id: null,
  PD_reference: null,
  PD_iban: null,
  PD_bic_code: null,
  PD_sort_code: null,
};

export default AddProduct;
