import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Button, Message } from "semantic-ui-react";

import Controls from "../../../../Controls";
import Hoc from "../../../../Hoc";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../../../../actions";
import clsx from "clsx";

const RightSide = (props) => {
  const CSS = useCSS();
  const { data, coupons, root } = props;
  const { refresh = (f) => f } = props;
  const Act_trolly = Action.store.trolly;
  const Act_coupon = Action.product.coupon;
  const dispatch = useDispatch();
  const Arr = Hoc.isArr;
  const H = useHistory();
  const Boxes = Arr(data?.Product?.Product_Boxes).filter(
    (v) => v.StoreId === data.StoreId
  );
  const [qty, setQty] = useState(1);
  const [coupon, setCoupon] = useState("");
  const [CP, setCP] = useState("");
  const getDSC = (cp, amt) => {
    const ratio = coupons?.find((v) => v.id === cp)?.ratio;
    let discount = (amt / 100) * ratio;
    discount = discount ? discount : data?.discount;
    let S_price = !((amt / 100) * ratio) ? amt : amt - discount;
    return { discount, S_price };
  };

  const addToTrolly = () => {
    if (!qty) return;
    const { discount, S_price } = getDSC(coupon, data?.S_price);
    const finalData = { ...data, discount, S_price };
    dispatch(Act_trolly.view_add(qty, finalData));
    refresh();
    setQty(1);
  };
  const selCP = useSelector(({ product: P }) => P.coupon.check_coupon.data);

  const onCpApply = async () => {
    if (!CP) return;
    const act = Act_coupon.check_coupon({ name: CP, id: data?.id });
    const cp = await dispatch(act);
    setCoupon(cp?.id);
  };

  return (
    <Grid item xs={12} md={6}>
      {!!Arr(Boxes).length && (
        <Box>
          <Typography variant="subtitle1">
            Choose your favourite variant.
          </Typography>
          {Arr(Boxes).map((v) => (
            <Controls.Button
              key={v.id}
              size="small"
              variant={data.id === v.id ? "contained" : "outlined"}
              color={v.quantity > 0 ? "primary" : "secondary"}
              text={v.name}
              onClick={() => H.push(`/product-variant/${v.id}`)}
            />
          ))}
        </Box>
      )}

      <Box style={{ width: "100%", margin: "20px 0" }} {...Hoc.flex()}>
        <Controls.Input {...setup.coupon(CP, setCP)} />
        <Button onClick={onCpApply} {...stlapl} />
      </Box>
      {!!selCP?.description && !!coupon && (
        <Box style={{ width: "100%", margin: "20px 0" }} {...Hoc.flex()}>
          <Message color="teal" content={selCP?.description} />
        </Box>
      )}

      <Box {...Hoc.flex()} style={{ margin: "10px 0" }}>
        <Typography variant="h3">
          <Hoc.ToCurrency
            currency={data?.Currency}
            price={getDSC(coupon, data?.S_price).S_price}
          />
        </Typography>
      </Box>

      {root?.role === "Customer" && (
        <Box display="flex" alignItems="center" justifyContent="center">
          {!!data?.quantity && (
            <Box style={{ width: 100, marginRight: 10 }}>
              <BtnQty qty={qty} setQty={setQty} quantity={!data?.quantity} />
            </Box>
          )}

          <Controls.Button
            disabled={!qty}
            text={!!data?.quantity ? "Add To Trolly" : "Out of stock"}
            color={!!data?.quantity ? "primary" : "secondary"}
            onClick={addToTrolly}
          />
        </Box>
      )}

      {!!data?.Store?.Collection_Address && (
        <React.Fragment>
          <Divider01 />
          <Typography variant="h6">Store Address</Typography>
          <Typography
            className={clsx(CSS.text, CSS.text_14)}
            style={{ margin: "1rem 0" }}
          >
            {Hoc.showAddress(data?.Store?.Collection_Address)}
          </Typography>
        </React.Fragment>
      )}

      <Divider01 />
    </Grid>
  );
};
const setup = {
  coupon00: (v, setV, op) => ({
    name: "coupon",
    label: "Select Coupon",
    value: v ? v : "",
    onChange: ({ target: T }) => setV(T.value),
    options: op.map((v) => ({ id: v?.id, title: `${v?.name} (${v?.ratio}%)` })),
  }),
  coupon: (V, SetV) => ({
    ...Hoc.setupText(
      V,
      ({ target: T }) => SetV(T.value),
      "coupon",
      "Enter Coupon Code"
    ),
    size: "small",
    style: { width: 200 },
  }),
};
const stlapl = {
  content: "Apply",
  color: "purple",
  style: { marginLeft: 5 },
};
const Btn = ({ icon, content, ...rest }) => (
  <Button {...rest}>{!!icon ? <Icon name={icon} /> : content || ""}</Button>
);

const BtnQty = (props) => {
  const { qty, setQty } = props;
  const { quantity } = props;

  return (
    <Button.Group icon>
      <Btn icon="minus" onClick={() => setQty((v) => (v === 0 ? 0 : v - 1))} />
      <Btn content={qty || "0"} />
      <Btn
        disabled={qty === quantity}
        icon="plus"
        onClick={() => setQty((v) => v + 1)}
      />
    </Button.Group>
  );
};
const Divider01 = ({ height }) => (
  <div style={{ margin: `${height || 1}rem 0` }}>
    <Divider />
  </div>
);

const useCSS = makeStyles((theme) => ({
  sm_photo_root: {
    width: 400,
    display: "flex",
    alignItems: "center",
  },
  sm_photo_act: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  sm_photo_src: {
    height: theme.spacing(6),
    padding: theme.spacing(0.2),
    borderRadius: theme.spacing(0.5),

    cursor: "pointer",
  },
  text: {
    fontSize: 16,
    fontWeight: 100,
    marginBottom: 15,
  },
  text_14: {
    fontSize: 14,
  },
  moreText: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "right",
  },
}));

export default RightSide;
