import React from "react";
import { useParams } from "react-router";
import Custom from "../../../comps/Custom";

const AddAbout = () => {
  const formData = Custom.useForm.useForm(initData);
  const P = useParams();
  return (
    <Custom.Base.CouponForm
      title="Edit the Coupon"
      id={P?.id}
      formData={formData}
    />
  );
};

const initData = {
  name: "",
  ratio: "",
  description: "",
  category: "For All",
  start_date: null,
  end_date: null,
  Category03Id: null,
  StoreId: null,
  ProductBoxId: null,
};

export default AddAbout;
