import { Route } from "react-router-dom";
import SecureRoute from "../../../comps/layout/SecureRoute";
import sign_in from "./sign-in";
import sign_up from "./sign-up";
import view_all from "./view_all";
import forgot_password from "./forgot-password";
import archive_view_all from "./archive_view_all";
import inactive_view_all from "./inactive_view_all";

const Expo = {
  sign_in: <Route exact path="/s2-sign-in" component={sign_in} />,
  sign_up: <Route exact path="/s2-sign-up" component={sign_up} />,
  view_all: (
    <SecureRoute grant="S1" path="/s2-active-view-all" component={view_all} />
  ),
  forgot_password: (
    <Route exact path="/s2-forgot-password" component={forgot_password} />
  ),
  archive_view_all: (
    <SecureRoute
      grant="S1"
      path="/s2-archive-view-all"
      component={archive_view_all}
    />
  ),
  inactive_view_all: (
    <SecureRoute
      grant="S1"
      path="/s2-inactive-view-all"
      component={inactive_view_all}
    />
  ),
};

export default Expo;
