import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.store_type.store;
const URL = `${Helper.URL}/store-item`;

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${queryString || ""}`);
    dispatch({ type: types.STORE_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_VIEW_ALL_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.STORE_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_VIEW_FAIL);
  }
};

export const my_store = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.MY_STORE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/my-sore`);

    dispatch({ type: types.MY_STORE_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.MY_STORE_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_ADD_REQUEST });
    Helper.set_jwt(state());
    const { data } = await axios.post(`${URL}`, initData, Helper.multiForm);
    dispatch({ type: types.STORE_ADD_SUCCESS, payload: data?.result });
    dispatch({
      type: types.STORE_VIEW_ALL_SUCCESS,
      payload: [data?.result, ...state()?.store?.item?.view_all?.data],
    });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_ADD_FAIL);
  }
};

export const product_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_PRODUCT_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${URL}/item`, initData);

    dispatch({ type: types.STORE_PRODUCT_ADD_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_PRODUCT_ADD_FAIL);
  }
};

export const product_view = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_PRODUCT_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/item?${queryString || ""}`);

    dispatch({ type: types.STORE_PRODUCT_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_PRODUCT_VIEW_FAIL);
  }
};

export const stock_view = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STOCK_VIEW_ALL_REQUEST });
    const { data } = await axios.get(`${URL}/stock?${queryString || ""}`);
    dispatch({ type: types.STOCK_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STOCK_VIEW_ALL_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);

    dispatch({ type: types.STORE_VIEW_ALL_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_VIEW_ALL_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.STORE_ADD_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({ type: types.STORE_ADD_SUCCESS, payload: data?.result });
    dispatch({
      type: types.STORE_VIEW_ALL_SUCCESS,
      payload: state()?.store?.item?.view_all?.data?.filter((v) => v.id !== id),
    });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.STORE_ADD_FAIL);
  }
};
