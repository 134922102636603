import React, { useEffect } from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const SignIn = () => {
  const H = useHistory();
  const Act_order = Action.store.order;
  const Act_Opp = Action.base02.oop_msg.view_add;
  const dispatch = useDispatch();
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues());
  const { values, setValues, resetForm } = formData;

  useEffect(() => {
    (async () => setValues(initValues(await dispatch(Act_order.my_view()))))();
  }, [dispatch, setValues, Act_order]);

  const onSubmit = async () => {
    const status = await dispatch(Act_order.my_view_edit(values));
    resetForm();
    if (status) {
      H.goBack();
      dispatch(Act_Opp("Shipping Address updated."));
    }
  };

  return (
    <Custom.Form.Address
      title="Shipping Address"
      formData={formData}
      onSubmit={onSubmit}
      to={{ url: "/company-dashboard", role: "Company_Manager" }}
    />
  );
};

const initValues = (v) => ({
  house_name: v?.house_name ? v?.house_name : "",
  street_name: v?.street_name ? v?.street_name : "",
  address_3: v?.address_3 ? v?.address_3 : "",
  city: v?.city ? v?.city : "",
  region: v?.region ? v?.region : "",
  postcode: v?.postcode ? v?.postcode : "",
  country: v?.country ? v?.country : "",
  default: v?.default ? v?.default : false,
});

export default SignIn;
