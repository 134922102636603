// // Europe
// const items01 = [
//   "BGN",
//   "CHF",
//   "CZK",
//   "DKK",
//   "EUR",
//   "GBP",
//   "HRK",
//   "GEL",
//   "HUF",
//   "NOK",
//   "PLN",
//   "RUB",
//   "RON",
//   "SEK",
//   "TRY",
//   "UAH",
// ];

// // Middle East and Africa
// const items02 = ["AED", "ILS", "KES", "MAD", "NGN", "ZAR"];

// // The Americas
// const items03 = ["BRL", "CAD", "CLP", "COP", "MXN", "PEN", "USD"];

// // Asia &* The Pacific region
// const items04 = [
//   "AUD",
//   "BDT",
//   "CNY",
//   "HKD",
//   "IDR",
//   "INR",
//   "JPY",
//   "MYR",
//   "NZD",
//   "PHP",
//   "PKR",
//   "SGD",
//   "KRW",
//   "LKR",
//   "THB",
//   "VND",
// ];

// const Expo = [items01, items02, items03, items04].flat(1);

const Expo = [
  "GBP",
  "EUR",
  "CAD",
  "AUD",
  "USD",
  "NZD",
  "TRY",
  "SGD",
  "HUF",
  "RON",
  "BDT",
  "IDR",
];

export default Expo;
