import React, { useEffect } from "react";
import Hoc from "../../../Hoc";
import Controls from "../../../Controls";
import Action from "../../../../actions";
import Custom from "../..";
import { useDispatch, useSelector } from "react-redux";
import { Box, TableCell, TableRow } from "@material-ui/core";

const TermViewAll = (props) => {
  const { to } = props;
  const dispatch = useDispatch();
  const Act_notice = Action.base02.notice;

  const { data } = useSelector(({ base02: B }) => B.notice.view_all);

  const { TblContainer, finalRecords } = Custom.useTable(data, cells);

  useEffect(() => {
    dispatch(Act_notice.view_all(`category=${to}`));
  }, [dispatch, Act_notice, to]);

  const refresh = () => dispatch(Act_notice.view_all(`category=${to}`));

  const nav_01 = [
    { name: "Create New", to: `/add-notices?category=${to}` },
    { name: "Refresh", onClick: refresh },
    { name: "Back", goBack: true },
  ];
  const TOT = (name, to, id = "01") => ({ name, to, id });

  const menuActions = (id) => {
    const onDelete = () => {
      dispatch(Act_notice.view_delete(id));
      refresh();
    };
    let items = [
      TOT("View", `/view-notices/${id}`),
      TOT("Edit", `/edit-notices/${id}`),
      { name: "Delete", onClick: onDelete },
    ];

    return items;
  };

  const nav_02 = [
    TOT("Notices", "/view-all-notices", "Notice"),
    TOT("News", "/view-all-news", "News"),
    TOT("Adveretisement", "/view-all-adds", "Add"),
  ];

  const onEnabled = (id, enabled) => {
    dispatch(Act_notice.view_edit(id, { enabled }));
    refresh();
  };

  return (
    <Hoc.Dashboard.Container
      title="Notices / News / Adveretisement"
      actions={nav_01}
    >
      <Box style={{ width: "100%" }}>
        {Hoc.PageTabs(
          nav_02.filter((v) => v.id !== to),
          to === "Add" ? "Advertisement" : to
        )}
      </Box>

      {!!finalRecords().length ? (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell>
                {v?.photo ? (
                  <Box component="img" src={v?.photo} height={25} />
                ) : (
                  v?.id
                )}
              </TableCell>
              <TableCell>{v?.name}</TableCell>

              <TableCell>
                {`${v?.description}`.substring(0, 15) + ".."}
              </TableCell>
              <TableCell>
                {`${v?.roles}`
                  .split("_")
                  .map((v) =>
                    v === "CMP"
                      ? "Company, "
                      : v === "STR"
                      ? "Store, "
                      : v === "CUS"
                      ? "Customer, "
                      : null
                  )}
              </TableCell>
              <TableCell>{v?.add_for}</TableCell>
              <TableCell>
                {v?.ProductBoxId ||
                  v?.StoreId ||
                  v?.Category01Id ||
                  v?.Category02Id ||
                  v?.Category03Id ||
                  "NA"}
              </TableCell>
              <TableCell>
                <Controls.Switch
                  name="enabled"
                  checked={!!v.enabled}
                  onChange={({ target: T }) => onEnabled(v?.id, T?.value)}
                />
              </TableCell>

              <TableCell>
                <Hoc.Product.MenuButton actions={menuActions(v?.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TblContainer>
      ) : (
        <Hoc.EmptyMessage />
      )}
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { id: "id", label: "ID / Photo" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  { id: "roles", label: "Roles" },
  { id: "add_for", label: "Advertisement For" },
  { id: "ProductBoxId", label: "Reference ID" },
  { id: "enabled", label: "Enabled" },
  { id: "action", label: "Actions", disableSorting: true },
];

export default TermViewAll;
