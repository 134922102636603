import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const sign_up = {
  name: "sign_up",
  state: defState,
  request: types.SIGN_UP_REQUEST,
  success: types.SIGN_UP_SUCCESS,
  fail: types.SIGN_UP_FAIL,
};

export const sign_in = {
  name: "sign_in",
  state: defState,
  request: types.SIGN_IN_REQUEST,
  success: types.SIGN_IN_SUCCESS,
  fail: types.SIGN_IN_FAIL,
};

export const forgot_password = {
  name: "forgot_password",
  state: defState,
  request: types.FORGOT_PSW_REQUEST,
  success: types.FORGOT_PSW_SUCCESS,
  fail: types.FORGOT_PSW_FAIL,
};

export const reset_password = {
  name: "reset_password",
  state: defState,
  request: types.RESET_PSW_REQUEST,
  success: types.RESET_PSW_SUCCESS,
  fail: types.RESET_PSW_FAIL,
};

export const verify_email = {
  name: "verify_email",
  state: defState,
  request: types.VERIFY_EMAIL_REQUEST,
  success: types.VERIFY_EMAIL_SUCCESS,
  fail: types.VERIFY_EMAIL_FAIL,
};

export const view_profile = {
  name: "view_profile",
  state: defState,
  request: types.PROFILE_VIEW_REQUEST,
  success: types.PROFILE_VIEW_SUCCESS,
  fail: types.PROFILE_VIEW_FAIL,
};

export const edit_profile = {
  name: "edit_profile",
  state: defState,
  request: types.PROFILE_EDIT_REQUEST,
  success: types.PROFILE_EDIT_SUCCESS,
  fail: types.PROFILE_EDIT_FAIL,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.VIEW_ALL_REQUEST,
  success: types.VIEW_ALL_SUCCESS,
  fail: types.VIEW_ALL_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.EDIT_REQUEST,
  success: types.EDIT_SUCCESS,
  fail: types.EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.ADD_REQUEST,
  success: types.ADD_SUCCESS,
  fail: types.ADD_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.DELETE_REQUEST,
  success: types.DELETE_SUCCESS,
  fail: types.DELETE_FAIL,
};

export const edit_password = {
  name: "edit_password",
  state: defState,
  request: types.EDIT_PSW_REQUEST,
  success: types.EDIT_PSW_SUCCESS,
  fail: types.EDIT_PSW_FAIL,
};
