import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Mail from "@material-ui/icons/Mail";
import Hoc from "../../comps/Hoc";
import Action from "../../actions";
import { useDispatch, useSelector } from "react-redux";

const Email = () => {
  const dispatch = useDispatch();
  const act_email = Action.base02.footer.view_email;
  const { data, error, loading } = useSelector(
    ({ base02: v }) => v.footer.email
  );

  useEffect(() => {
    dispatch(act_email());
  }, [dispatch, act_email]);

  const refresh = () => dispatch(act_email());

  const nav_01 = {
    title: "Email Information",
    actions: [
      {
        name: "Update Details",
        to: `/footer-${!data ? "add" : "edit/"}${data?.id || ""}`,
      },
      { name: "Refresh", onClick: refresh },
      { name: "Back", goBack: true },
    ],
  };

  return (
    <Hoc.Page icon={<Mail color="primary" />} title="Email Settings">
      <Hoc.PageBox {...nav_01}>
        <Hoc.UseData error={error} loading={loading}>
          <Grid container spacing={2}>
            <ShowItem name="ID" value={data?.id} />

            <ShowItem name="Protocol" value={data?.protocol} />
            <ShowItem name="Username" value={data?.username} />
            <ShowItem name="From" value={data?.from} />
            <ShowItem name="Password" value={data?.password} />
            <ShowItem name="IMAP" value={data?.imap_port} />
            <ShowItem name="POP03" value={data?.pop3_port} />
            <ShowItem name="SMTP" value={data?.smtp_host} />
          </Grid>
        </Hoc.UseData>
      </Hoc.PageBox>
    </Hoc.Page>
  );
};

const ShowItem = (rest) => (
  <Grid item xs={12} sm={6} lg={4}>
    <Hoc.ShowItem {...rest} />
  </Grid>
);

export default Email;
