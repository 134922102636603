import React from "react";
import Hoc from "../../../../comps/Hoc";
import Controls from "../../../../comps/Controls";
import Action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Message } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { getStoteTrolly } from "../Hoc/utils";

const ViewAll = (props) => {
  const H = useHistory();
  const { refresh, data } = props;
  let { loading, error } = useSelector(({ store: v }) => v.trolly.view_all);

  const Act_trolly = Action.store.trolly;

  const dispatch = useDispatch();

  let store_items = getStoteTrolly(data);
  const CSS = useCSS();
  const PFP = (v) => parseFloat(v).toFixed(2);

  const GridPhoto = ({ data }) => (
    <Grid {...T1} className={CSS?.trolly_photo_root}>
      <Box
        component="img"
        src={data?.Product_Box?.photo}
        className={CSS.trolly_photo}
      />
    </Grid>
  );

  const GridText = ({ data }) => (
    <Grid
      {...T2}
      onClick={() => H.push(`/product-variant/${data?.Product_Box?.id}`)}
    >
      <Typography className={CSS.trolly_remove}>
        {data?.Product_Box?.name}
      </Typography>
    </Grid>
  );

  const onCollectability = (data, T) => {
    dispatch(
      Act_trolly.view_edit(data?.id, {
        collectable: !!T?.value ? "Collecting" : "None",
      })
    );
    refresh();
  };

  const GridFromStore = ({ data }) => {
    const CKCC = (v) => data?.collectable === v;
    return (
      <Grid {...T3}>
        <Controls.Switch
          value={CKCC("Collecting")}
          name="collectable"
          label="Collecting From Store"
          checked={!!CKCC("Collecting")}
          onChange={({ target: T }) => onCollectability(data, T)}
          disabled={!data?.Product_Box?.collectable}
        />
      </Grid>
    );
  };

  const GridPrice = ({ data }) => (
    <Grid {...T4}>
      <Typography color="primary" variant="h6">
        <Hoc.ToCurrency
          currency={data?.Currency}
          price={PFP(data?.S_price * parseInt(data?.sold_units))}
        />
      </Typography>
      {!!data?.S_price && parseInt(data?.sold_units) > 1 && (
        <Typography>
          <Hoc.ToCurrency
            currency={data?.Currency}
            price={PFP(data?.S_price)}
          />{" "}
          / 1 Item
        </Typography>
      )}
    </Grid>
  );

  const GridDivider = () => (
    <Grid item xs={12}>
      <Divider color="primary" />
    </Grid>
  );

  const ToML = (v1) => ({
    curr: v1?.Currency,
    price: PFP(v1?.S_price * parseInt(v1?.sold_units)),
  });
  return (
    <Hoc.UseData loading={loading} error={error}>
      {store_items.map(({ items, Store }, idx) => (
        <Message key={idx} className={CSS.trolly}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={7}>
              {items?.map((v, idx) => (
                <Grid
                  key={idx}
                  className={CSS.trolly_container}
                  container
                  spacing={1}
                >
                  <GridPhoto data={v} />
                  <GridText data={v} />
                  <GridFromStore data={v} />
                  <GridPrice data={v} />
                  <GridDivider />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <Message color="purple">
                <Box className={CSS.trolly_details}>
                  {!!Store && (
                    <Typography variant="subtitle2" color="textPrimary">
                      Address : {Hoc.showAddress(Store)}
                    </Typography>
                  )}
                  <Typography className={CSS.sub_price}>
                    Sub Total :
                    <Hoc.MultiCurrency
                      items={Hoc.isArr(items).map((v1) => ToML(v1))}
                    />
                  </Typography>
                </Box>
              </Message>
            </Grid>
          </Grid>
        </Message>
      ))}

      <Message color="green">
        <Typography align="right" className={CSS.sub_price}>
          Total Price :{" "}
          <Hoc.MultiCurrency
            items={Hoc.isArr(store_items)
              .reduce((a, b) => [...a, b.items], [])
              ?.flat(Infinity)
              ?.map((v1) => ToML(v1))}
          />
        </Typography>
      </Message>
    </Hoc.UseData>
  );
};

const useCSS = makeStyles((theme) => ({
  trolly: {
    display: "flex",
    alignItems: "center",
  },
  trolly_container: {
    margin: theme.spacing(1, 0),
  },
  trolly_details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  trolly_photo: {
    height: "100%",
  },
  trolly_photo_root: {
    maxHeight: theme.spacing(4),
    ...fx,
  },
  trolly_remove: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: theme.spacing(1.6),
    "&:hover": {
      textDecoration: "underline",
      fontSize: theme.spacing(1.8),
    },
  },
  sub_price: {
    fontWeight: 700,
    fontSize: 20,
  },
}));

const T1 = {
  item: true,
  xs: 2,
};
const T2 = {
  item: true,
  xs: 4,
};
const T3 = {
  item: true,
  xs: 3,
};
const T4 = {
  item: true,
  xs: 3,
};

const fx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default ViewAll;
