const types = {
  //
  TRACK_VIEW_ALL_REQUEST: "TRACK_VIEW_ALL_REQUEST",
  TRACK_VIEW_ALL_SUCCESS: "TRACK_VIEW_ALL_SUCCESS",
  TRACK_VIEW_ALL_FAIL: "TRACK_VIEW_ALL_FAIL",
  //
  TRACK_VIEW_REQUEST: "TRACK_VIEW_REQUEST",
  TRACK_VIEW_SUCCESS: "TRACK_VIEW_SUCCESS",
  TRACK_VIEW_FAIL: "TRACK_VIEW_FAIL",
  //
  TRACK_EDIT_REQUEST: "TRACK_EDIT_REQUEST",
  TRACK_EDIT_SUCCESS: "TRACK_EDIT_SUCCESS",
  TRACK_EDIT_FAIL: "TRACK_EDIT_FAIL",
  //
  TRACK_ADD_REQUEST: "TRACK_ADD_REQUEST",
  TRACK_ADD_SUCCESS: "TRACK_ADD_SUCCESS",
  TRACK_ADD_FAIL: "TRACK_ADD_FAIL",
  //
  TRACK_DELETE_REQUEST: "TRACK_DELETE_REQUEST",
  TRACK_DELETE_SUCCESS: "TRACK_DELETE_SUCCESS",
  TRACK_DELETE_FAIL: "TRACK_DELETE_FAIL",
};

export default types;
