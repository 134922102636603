import B01News from "./B01News";
import B02Latest from "./B02Latest";
import B03Advertisement from "./B03Advertisement";
import B04Subscribe from "./B04Subscribe";
import B05Notice from "./B05Notice";

import OfferItem from "./OfferItem";
import OneCard from "./OneCard";
import ProductCard from "./ProductCard";
import ThreeCards from "./ThreeCards";

const Expo = {
  //Banners
  B01News,
  B02Latest,
  B03Advertisement,
  B04Subscribe,
  B05Notice,
  //
  OfferItem,
  ProductCard,
  OneCard,
  ThreeCards,
};

export default Expo;
