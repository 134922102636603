import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../Hoc";

const Root = (props) => {
  const dispatch = useDispatch();
  const root = useSelector(({ people: p }) => p.root.data);
  const { Act, title, children } = props;

  useEffect(() => {
    dispatch(Act());
  }, [dispatch, Act]);

  const refresh = () => dispatch(Act());

  return (
    <Hoc.Page title={title}>
      <Hoc.PageBox
        title={title}
        actions={[
          { name: "Products", to: "/product-view-all-trolly" },
          { name: "Back", goBack: true },
        ]}
      >
        {Hoc.PageTabs(
          [...list_actions(refresh, root)],
          true ? "Empty Records 01" : "Empty Records 02"
        )}

        {children}
      </Hoc.PageBox>
    </Hoc.Page>
  );
};

const list_actions = (onClick, root) => {
  let res = [];

  if (
    !["Customer"].includes(root?.role) &&
    `${root?.privilege}`.includes("Add")
  ) {
    res = [{ name: "Create New", to: "/product-add" }];
  }

  return [...res, { name: "Refresh", onClick }, { name: "Back", goBack: true }];
};

export default Root;
