import React from "react";
import Custom from "../../../comps/Custom";
import actions from "../../../actions";

const SignIn = () => {
  const Act = actions.people.C2.sign_up;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Custom.Form.SignUp
      title="Company Admin Registration"
      endLink="/c2-sign-in"
      submitAction={Act}
      formData={formData}
    />
  );
};

const initValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  contact: "",
};

export default SignIn;
