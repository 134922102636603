import React, { useEffect } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "..";
import Action from "../../../actions";

const PayPal = (props) => {
  const { order_id, curr, refresh = (f) => f } = props;
  const amount = parseFloat(props?.amount).toFixed(2);

  const dispatch = useDispatch();
  const Act_add_transaction = Action.base.transaction.view_add;
  const Act_OOP = Action.base02.oop_msg.view_add;

  const act_paypal = Action.base.payment.method;
  const Act_edit_order = Action.store.order.view_edit;
  const { data, loading, error } = useSelector(
    ({ base: b }) => b.payment.method
  );

  useEffect(() => {
    dispatch(act_paypal("PayPal"));
  }, [dispatch, act_paypal]);

  const onSuccess = async (V, _) => {
    dispatch(
      Act_add_transaction({
        amount: amount,
        CurrencyId: curr?.id,
        status: "Paid",
        method: "PayPal",
        PD_transaction_id: V.orderID,
        PD_reference: V.payerID,
        OrderId: order_id,
      })
    );
    await dispatch(Act_edit_order(order_id, { phase: "04" }));
    refresh();
    dispatch(Act_OOP("Payment Successfull.."));
  };

  return (
    <Hoc.UseData loading={loading} error={error}>
      {!!data?.client_id ? (
        <PayPalButton
          amount={amount}
          currency={curr?.currency}
          createOrder={(_, actions) =>
            actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency: curr?.currency,
                    value: amount,
                  },
                },
              ],
            })
          }
          options={{
            clientId: data?.client_id,
          }}
          onApprove={onSuccess}
          onError={(err) => dispatch(Act_OOP(`${err}`))}
        />
      ) : (
        <Hoc.Message text="Loading Paypal Info!." color="yellow" />
      )}
    </Hoc.UseData>
  );
};

export default PayPal;
