import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import action from "../../../../actions";
import { Button } from "semantic-ui-react";

const MergeOrder = () => {
  const act_order = action.store.order;
  const act_trolly = action.store.trolly.view_edit;
  const act_transaction = action.base.transaction.view_edit;
  const Act_OOP = action.base02.oop_msg.view_add;

  const H = useHistory();
  const dispatch = useDispatch();
  const P = useParams();
  const ODT = useSelector(({ store: S }) => S.order.view_all);
  let { data } = ODT;
  const { loading, error } = ODT;

  const own_order = data.find((v) => v.id === parseInt(P.id));
  const CID = (v) => v === parseInt(P.id);
  data = data.filter(
    (v) => !CID(v?.id) && own_order?.CustomerId === v?.CustomerId
  );

  const TBL = Custom.useTable([own_order, data].flat(1), cells);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  useEffect(() => {
    dispatch(act_order.view_all());
  }, [dispatch, act_order]);

  const onMerge = (new_id) => {
    const old_id = parseInt(P?.id);
    const order = data.find((v) => v?.id === new_id);

    const SetTr = (id) => dispatch(act_trolly(id, { OrderId: old_id }));
    const SetTs = (id) => dispatch(act_transaction(id, { OrderId: old_id }));

    order?.Transactions?.length &&
      order?.Transactions.forEach((v) => SetTs(v?.id));

    order?.Trollies?.length && order?.Trollies?.forEach((v) => SetTr(v?.id));

    dispatch(
      Act_OOP("Both trollies & transactions transfered to the current order.")
    );
    H.goBack();
  };

  const onDelete = (id) => {
    dispatch(act_order.view_delete(id));
    dispatch(Act_OOP("Order deleted successfully."));
    H.goBack();
  };

  const ToML = (v) => ({ curr: v?.Currency, price: v.amount });

  const nav_01 = {
    title: "Merge with another Order",
    actions: [
      { name: "Back", goBack: true },
      { name: "Refresh", onClick: () => dispatch(act_order.view_all()) },
    ],
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData loading={loading} error={error}>
        <TblContainer>
          {finalRecords().map((v, idx) => (
            <TableRow key={`${idx}-${v?.id}`}>
              <TableCell content={v?.id} />
              <TableCell content={v?.Trollies?.length} />
              <TableCell>
                <Hoc.MultiCurrency
                  items={Hoc.isArr(v?.Transactions).map((v1) => ToML(v1))}
                />
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  content={CID(v?.id) ? "Current Order" : "Transfer"}
                  color={CID(v?.id) ? "green" : "purple"}
                  onClick={() => onMerge(v?.id)}
                  disabled={CID(v?.id)}
                />
                {!Hoc.isArr(v?.Transactions)?.length &&
                  !Hoc.isArr(v?.Trollies)?.length && (
                    <Button
                      size="small"
                      content="Delete"
                      color="red"
                      onClick={() => onDelete(v.id)}
                    />
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TblContainer>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { id: "id", label: "ID" },
  { id: "Trolly Count", label: "Trolly Count" },
  { id: "Paid Amount", label: "Approximate Paid Amount" },
  { id: "Merge", label: "Merge", disableSorting: true },
];

export default MergeOrder;
