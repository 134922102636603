import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../../actions";
import Controls from "../../../comps/Controls";
import Hoc from "../../../comps/Hoc";
import { Chip, Box } from "@material-ui/core";

const AddEmp = (props) => {
  const dispatch = useDispatch();
  const Act_S2_edit = Action.people.S2.view_edit;
  const Act_S2_view_all = Action.people.S2.view_all;
  const [emp, setEmp] = useState("");
  const S2 = useSelector(({ people: v }) => v.store_employee.view_all.data);

  useEffect(() => {
    dispatch(Act_S2_view_all());
  }, [dispatch, Act_S2_view_all]);

  const { StoreId, open, setOpen, refresh: RF = (f) => f } = props;

  const refresh = () => {
    RF();
    dispatch(Act_S2_view_all());
  };

  return (
    <Hoc.Dialog
      open={open}
      onClose={() => setOpen(false)}
      title="Select Employees"
      fullWidth
      maxWidth="sm"
      actions={
        <Box>
          <Controls.Button
            text="Add Employee"
            size="small"
            onClick={() => {
              dispatch(Act_S2_edit(emp, { StoreId }));
              setOpen(false);
              refresh();
            }}
          />
          <Controls.Button
            text="Close"
            color="secondary"
            size="small"
            onClick={() => setOpen(false)}
          />
        </Box>
      }
    >
      <Box style={{ marginBottom: 20 }}>
        {S2?.filter((v) => v.StoreId === parseInt(StoreId))?.map((v, i) => (
          <Chip
            key={i}
            label={`${v?.first_name} ${v?.last_name} (${v?.id})`}
            color="primary"
            onDelete={() => {
              dispatch(Act_S2_edit(v?.id, { store: null }));
              refresh();
            }}
            style={{ margin: 3 }}
          />
        ))}
      </Box>
      <Controls.SearchSelect
        name="store"
        label="Select Employee"
        value={emp || ""}
        onChange={({ target: v }) => setEmp(v.value)}
        options={S2?.filter((v) => !v.StoreId)?.map((v) => ({
          id: v.id,
          title: `${v?.first_name} ${v?.last_name} (${v?.id})`,
        }))}
      />
    </Hoc.Dialog>
  );
};

export default AddEmp;
