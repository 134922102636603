import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

export const sign_up = {
  name: "sign_up",
  state: defState,
  request: types.C1_SIGN_UP_REQUEST,
  success: types.C1_SIGN_UP_SUCCESS,
  fail: types.C1_SIGN_UP_FAIL,
};

export const sign_in = {
  name: "sign_in",
  state: defState,
  request: types.C1_SIGN_IN_REQUEST,
  success: types.C1_SIGN_IN_SUCCESS,
  fail: types.C1_SIGN_IN_FAIL,
};

export const forgot_password = {
  name: "forgot_password",
  state: defState,
  request: types.C1_FORGOT_PSW_REQUEST,
  success: types.C1_FORGOT_PSW_SUCCESS,
  fail: types.C1_FORGOT_PSW_FAIL,
};
export const reset_password = {
  name: "reset_password",
  state: defState,
  request: types.C1_RESET_PSW_REQUEST,
  success: types.C1_RESET_PSW_SUCCESS,
  fail: types.C1_RESET_PSW_FAIL,
};

export const verify_email = {
  name: "verify_email",
  state: defState,
  request: types.C1_VERIFY_EMAIL_REQUEST,
  success: types.C1_VERIFY_EMAIL_SUCCESS,
  fail: types.C1_VERIFY_EMAIL_FAIL,
};

export const view_profile = {
  name: "view_profile",
  state: defState,
  request: types.C1_PROFILE_VIEW_REQUEST,
  success: types.C1_PROFILE_VIEW_SUCCESS,
  fail: types.C1_PROFILE_VIEW_FAIL,
};

export const edit_profile = {
  name: "edit_profile",
  state: defState,
  request: types.C1_PROFILE_EDIT_REQUEST,
  success: types.C1_PROFILE_EDIT_SUCCESS,
  fail: types.C1_PROFILE_EDIT_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.C1_EDIT_REQUEST,
  success: types.C1_EDIT_SUCCESS,
  fail: types.C1_EDIT_FAIL,
};

export const edit_password = {
  name: "edit_password",
  state: defState,
  request: types.C1_EDIT_PSW_REQUEST,
  success: types.C1_EDIT_PSW_SUCCESS,
  fail: types.C1_EDIT_PSW_FAIL,
};
