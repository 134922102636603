import React, { useEffect, useState } from "react";
import { IconButton, Toolbar, Box } from "@material-ui/core";
import { AppBar, Grid, Typography } from "@material-ui/core";
import { Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../actions";
import Controls from "../../comps/Controls";
import NotifIcon from "@material-ui/icons/NotificationsActiveOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ShopItem from "./ShopItem";
import Hoc from "../Hoc";

const Header = ({ openShop, setOpenShop }) => {
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const H = useHistory();
  const Act_logout = Action.people.root.sign_out;
  const Act_search_items = Action.product.box.search_items;
  const act_notif_count = Action.base02.notif.count;
  const Act_trolly = Action.store.trolly;
  const Act_order = Action.store.order;
  const CSS = useCSS();
  const dispatch = useDispatch();
  const root = useSelector(({ people: v }) => v.root.data);
  const search_items = useSelector(({ product: p }) => p.box.search.data);
  const toURL = (url) => () => H.push(url);
  const trolly_count = useSelector(({ store: s }) => s.trolly.count.data);
  const notif_count = useSelector(({ base02: s }) => s.notif.count.data);

  useEffect(() => {
    if (!!root) {
      dispatch(Act_trolly.count());
      dispatch(act_notif_count());
    }
  }, [dispatch, root, Act_trolly, act_notif_count]);

  const onLogout = () => {
    const url = dispatch(Act_logout());
    H.push(url);
  };

  const onSearchChange = ({ target: v }) => {
    setSearch(v.value);
    setSearchText(null);
    dispatch(Act_search_items(`search=${v.value}`));
    v?.value?.slug && H.push(`/product-variant/${v?.value?.id}`);
  };

  const onSearchTextChange = ({ target: v }) => {
    setSearchText(v.value);
    dispatch(Act_search_items(`search=${v.value}`));
  };

  const CKR = (v) => (v ? root?.role === v : "/customer");
  const dasboard_link = root?.role
    ? `/${
        CKR("Company_Manager")
          ? "company"
          : CKR("Company_Admin")
          ? "company"
          : CKR("Store_Manager")
          ? "store"
          : CKR("Store_Employee")
          ? "store"
          : "customer"
      }-dashboard`
    : "/customer-dashboard";

  const closeShop = () => setOpenShop(false);

  const onTrolly = async () => {
    const res = await dispatch(Act_order.open_view());
    if (!res?.phase) {
      H.push(`/trolly-01`);
    } else {
      const count = Hoc.isArr(res?.Trollies)?.length;
      if (!!res?.phase && !count) {
        dispatch(Act_order.view_edit(res?.id, { phase: "01" }));
      }
      const phase = !!count && !!res?.phase ? res?.phase : "01";
      H.push(`/trolly-${phase}`);
    }
  };

  return (
    <AppBar position="static" className={CSS.root}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item onClick={closeShop} component={RouterLink} to="/">
            <Box component="img" src="/logo.png" className={CSS.logo} />
          </Grid>
          <Grid item className="flex-center">
            <Grid container alignItems="center" justifyContent="space-between">
              <ShopItem open={openShop} setOpen={setOpenShop} />
            </Grid>
          </Grid>
          <Grid item onClick={closeShop}>
            <Box style={{ minWidth: 250 }}>
              <Controls.AutoSelect
                {...search_setup(
                  search,
                  onSearchChange,
                  searchText,
                  onSearchTextChange,
                  Hoc.isArr(search_items).map(({ name, ...v }) => ({
                    ...v,
                    title: name,
                  }))
                )}
              />
            </Box>
          </Grid>
          <Grid item onClick={closeShop}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {!!root?.token && !!root?.role && (
                <CustomNavLink text="Dashboard" to={dasboard_link} />
              )}

              {!!root?.token && (
                <IconButton onClick={() => H.push("/notification-list")}>
                  <Badge badgeContent={notif_count} color="secondary">
                    <NotifIcon color="primary" />
                  </Badge>
                </IconButton>
              )}
              {root?.role && root?.role === "Customer" && (
                <IconButton onClick={onTrolly}>
                  <Badge badgeContent={trolly_count?.count} color="primary">
                    <ShoppingCartOutlinedIcon color="primary" />
                  </Badge>
                </IconButton>
              )}
              <IconButton onClick={!root?.token ? toURL("/sign-in") : onLogout}>
                {root?.token ? (
                  <ExitToAppIcon color="primary" />
                ) : (
                  <PersonOutlineIcon color="primary" />
                )}
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const search_setup = (v, onChange, text, onTextChange, options) => ({
  name: "search",
  label: "Search",
  size: "small",
  value: v || null,
  options,
  onChange,
  text,
  onTextChange,
});

const CustomNavLink = ({ to, text }) => {
  const H = useHistory();
  const CSS = useCSS();
  return (
    <Grid
      item
      component="div"
      className={CSS.navLink}
      onClick={() => H.push(to)}
    >
      <Typography variant="subtitle2">{text}</Typography>
    </Grid>
  );
};

const useCSS = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
  },
  logo: {
    cursor: "pointer",
    maxHeight: theme.spacing(6),
  },
  navLink: {
    cursor: "pointer",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textTransform: "uppercase",
  },
}));

export default Header;
