import moment from "moment";
import React from "react";
import { TableCell as TabCell } from "@material-ui/core";
import Custom from "../../../../comps/Custom";
import Hoc from "../../../../comps/Hoc";
import { Header, Message } from "semantic-ui-react";

const ShowTransactions = ({ data }) => {
  const Tbl = Custom.useTable(data, cells);
  const { finalRecords, TblContainer } = Tbl;
  const { TableRow, TableCell } = Tbl;
  return (
    <Message>
      <Header content="Transactions" color="green" as="h3" />
      <TblContainer paginate>
        {finalRecords().map((v) => (
          <TableRow key={v.id}>
            <TableCell content={v.id} />
            <TabCell>
              <Hoc.ToCurrency currency={v?.Currency} price={v.amount} />
            </TabCell>
            <TableCell content={v?.Currency?.currency} />
            <TableCell content={v.method} />
            <TableCell content={v.paying_to} />
            <TableCell content={v.status} />
            <TableCell>{ToDate(v.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TblContainer>
    </Message>
  );
};
const ToDate = (v) => moment(v).calendar();

const TOL = (name, label) => ({ name, label });

const cells = [
  TOL("id", "ID"),
  TOL("amount", "Amount"),
  TOL("currency", "Currency"),
  TOL("method", "Method"),
  TOL("paying_to", "Paying To"),
  TOL("status", "Status"),
  TOL("06", "Paid At"),
];

export default ShowTransactions;
