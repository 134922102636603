import React, { useEffect, useState } from "react";
import Action from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "..";
import { Button } from "semantic-ui-react";
import { Box } from "@material-ui/core";

const RazorPay = (props) => {
  const { order_id, refresh = (f) => f } = props;
  const { amount = 0, curr } = props;
  const dispatch = useDispatch();
  const act_pay = Action.base.payment.method;
  const userInfo = useSelector(({ people: p }) => p.root.data);
  const [running, setRunning] = useState(false);
  const { data, loading, error } = useSelector(
    ({ base: b }) => b.payment.method
  );

  useEffect(() => {
    dispatch(act_pay("RazorPay"));
  }, [dispatch, act_pay]);

  const onSubmit = () => {
    setRunning(true);
    displayRazorPay({
      amount: Math.ceil(amount * 100),
      userInfo,
      data,
      dispatch,
      order_id,
      refresh,
      curr,
    });
    setRunning(false);
  };

  return (
    <Hoc.UseData loading={loading} error={error}>
      {!!data?.key ? (
        <Box display="flex" justifyContent="center">
          <Button
            content={`Pay ${Hoc.toCurrency(curr?.currency, amount)}`}
            inverted
            color="purple"
            onClick={onSubmit}
            disabled={!!running}
          />
        </Box>
      ) : (
        <Hoc.Message text="Loading RazorPay Info!." color="yellow" />
      )}
    </Hoc.UseData>
  );
};

const displayRazorPay = async (props) => {
  const { amount, userInfo, order_id, data } = props;
  const { dispatch, refresh, curr } = props;

  const Act_add_transaction = Action.base.transaction.view_add;
  const Act_OOP = Action.base02.oop_msg.view_add;
  const Act_edit_order = Action.store.order.view_edit;
  const res = await loadRazorPay(
    "https://checkout.razorpay.com/v1/checkout.js"
  );
  if (!res) {
    dispatch(Act_OOP("Failed to load RazorPay SDK. Are you online?"));
    return;
  }

  const options = {
    key: data?.key,
    amount,
    currency: curr?.currency,
    name: data?.name,
    image: "/logo.png",

    handler: async (razorData) => {
      dispatch(
        Act_add_transaction({
          amount: amount / 100,
          CurrencyId: curr?.id,
          status: "Paid",
          method: "RazorPay",
          reference: razorData?.razorpay_payment_id,
          OrderId: order_id,
        })
      );
      dispatch(Act_edit_order(order_id, { phase: "04" }));
      refresh();
      dispatch(Act_OOP("Payment Successfull.."));
    },
    prefill: {
      name: `${userInfo?.first_name} ${userInfo?.last_name}`,
      email: userInfo.email,
    },
  };
  const paymentObject = new window.Razorpay(options);

  paymentObject.on("payment.failed", (data) =>
    dispatch(Act_OOP(`${data.error.description}`))
  );

  paymentObject.open();
};

const loadRazorPay = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });

// const __DEV__ = document.domain === 'localhost'

export default RazorPay;
