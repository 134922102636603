import SecureRoute from "../../comps/layout/SecureRoute";
import template from "./template";
import payment_info from "./payment-info";

import email from "./email";
import terms from "./terms";
import address from "./address";
import subscribe from "./subscribe";

const Expo = {
  template,
  subscribe,
  address,
  terms,
  payment_info,
  email: <SecureRoute grant="C1" exact path="/email" component={email} />,
};

export default Expo;
