import React, { useEffect, useState } from "react";
import Hoc from "../../../comps/Hoc";
import Controls from "../../../comps/Controls";
import Actions from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button, Header } from "semantic-ui-react";
import moment from "moment";
import AddEmp from "./AddEmp";

const AddProduct = () => {
  const dispatch = useDispatch();
  const [showable, setShowable] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const Act_store = Actions.store.item;

  const root = useSelector(({ people: v }) => v.root.data);

  const { data, loading, error } = useSelector(
    ({ store: v }) => v.item.my_store
  );

  useEffect(() => {
    dispatch(Act_store.my_store());
  }, [dispatch, Act_store]);

  const refresh = () => dispatch(Act_store.my_store());

  const TOC = (name, onClick) => ({ name, onClick });
  const TOT = (name, to) => ({ name, to });

  const actions = () => {
    let IT = [TOC("Refresh", refresh)];

    const RL = ["Company_Manager", "Company_Admin", "Store_Manager"];

    if (RL.includes(root?.role)) {
      IT = [TOT("Edit Store", `/store-edit/${data?.id}`), ...IT];
    }

    return IT;
  };
  const nav01 = {
    title: `${data?.name} (${data?.id})` || "My Store",
    actions: actions(),
  };

  return (
    <React.Fragment>
      <Hoc.Dashboard.Container {...nav01}>
        <Hoc.UseData error={error} loading={loading}>
          <Box></Box>
          <AddEmp
            StoreId={data?.id}
            open={openEmp}
            setOpen={setOpenEmp}
            refresh={refresh}
          />
          {!data ? (
            <Header
              content="Sorry, You are not associated with any store"
              color="red"
            />
          ) : (
            <Grid container spacing={2}>
              <ShowItem name="Name" value={data?.name} />
              <ShowItem name="Branch" value={data?.branch} />
              <ShowItem name="Email" value={data?.email} />
              <ShowItem name="Contact" value={data?.contact} />

              <ShowItem name="VAT / GST" value={data?.vat} />
              <ShowItem name="VAT Price" value={data?.vat_price} />
              <ShowItem name="Owner" value={data?.owner} />
              <ShowItem
                name="Address"
                value={data?.one_address ? "Single Address" : "Two Addresses"}
              />
              <ShowItem name="Status" value={data?.status} />
              <ShowItem name="Archive" value={data?.archive} />
              <ShowItem
                name="Collection Address"
                value={Hoc.showAddress(data?.Collection_Address)}
              />
              <ShowItem
                name="Store Address"
                value={Hoc.showAddress(data?.Store_Address)}
              />
              <ShowItem name="Delete Request" value={data?.delete_request} />
              <ShowItem name="Created At" value={ToDate(data?.createdAt)} />
              <ShowItem name="Updated At" value={ToDate(data?.updatedAt)} />
              {!!showable && (
                <React.Fragment>
                  <ShowItem name="Manager Id" value={data?.StoreManagerId} />
                  <ShowItem
                    name="Account Number"
                    value={data?.PD_account_number}
                  />
                  <ShowItem name="Account Name" value={data?.PD_account_name} />
                  <ShowItem name="Bank Code" value={data?.PD_bank_code} />
                  <ShowItem name="Branch Name" value={data?.PD_branch_name} />
                  <ShowItem name="Country Name" value={data?.PD_country_name} />
                  <ShowItem
                    name="Transaction Id"
                    value={data?.PD_transaction_id}
                  />
                  <ShowItem name="Reference" value={data?.PD_reference} />
                  <ShowItem name="IBAN" value={data?.PD_iban} />
                  <ShowItem name="BIC Code" value={data?.PD_bic_code} />
                  <ShowItem name="Sort Code" value={data?.PD_sort_code} />
                  <ShowItem
                    name="Employees Count"
                    value={
                      <Box
                        display="flex"
                        alignItems="items"
                        justifyContent="space-around"
                      >
                        <Typography>
                          {data?.Store_Employees?.length || "0"}
                        </Typography>
                        {root?.role === "Store_Manager" && (
                          <Controls.Button
                            text="Add"
                            size="small"
                            onClick={() => setOpenEmp(!openEmp)}
                          />
                        )}
                      </Box>
                    }
                  />
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <Box {...Hoc.flex("flex-end")}>
                  <Button
                    content={!showable ? "Show" : "Hide"}
                    onClick={() => setShowable(!showable)}
                    inverted
                    color="orange"
                    size="tiny"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Hoc.UseData>
      </Hoc.Dashboard.Container>{" "}
    </React.Fragment>
  );
};

const ToDate = (v) => moment(v).calendar();

const ShowItem = ({ value, ...rest }) =>
  !!value && (
    <Grid item xs={12} sm={6} lg={4}>
      <Hoc.ShowItem value={value} {...rest} />
    </Grid>
  );

export default AddProduct;
