import { Route } from "react-router-dom";
import SecureRoute from "../../../comps/layout/SecureRoute";
import sign_in from "./Sign/sign-in";
import sign_up from "./Sign/sign-up";
import forgot_password from "./Sign/forgot-password";

// View All
import view_all from "./view_all/view_all";
import archive_view_all from "./view_all/archive_view_all";
import inactive_view_all from "./view_all/inactive_view_all";

// Orders
import orders_01 from "./order/orders-01";
import orders_02 from "./order/orders-02";
import orders_03 from "./order/orders-03";
import MergeOrder from "./order/MergeOrder";

//
import add_token from "./tickets/add_token";
import current_order from "./current-order";
import general_ticket from "./tickets/general_ticket";
import complain_ticket from "./tickets/complain_ticket";
import product_ticket from "./tickets/product_ticket";

const Expo = {
  sign_in: <Route exact path="/sign-in" component={sign_in} />,
  sign_up: <Route exact path="/customer-sign-up" component={sign_up} />,
  current_order: (
    <SecureRoute
      grant="GN"
      path="/customer-current-order"
      component={current_order}
    />
  ),
  orders_01: <SecureRoute grant="GN" path="/orders-01" component={orders_01} />,
  orders_02: <SecureRoute grant="GN" path="/orders-02" component={orders_02} />,
  orders_03: <SecureRoute grant="GN" path="/orders-03" component={orders_03} />,
  MergeOrder: (
    <SecureRoute grant="GN" path="/merge-order/:id" component={MergeOrder} />
  ),
  add_token: <SecureRoute grant="GN" path="/add-token" component={add_token} />,

  customer_general_ticket: (
    <SecureRoute
      grant="GN"
      path="/customer-general-tickets"
      component={general_ticket}
    />
  ),
  customer_complain_ticket: (
    <SecureRoute
      grant="GN"
      path="/customer-complain-tickets"
      component={complain_ticket}
    />
  ),
  customer_product_ticket: (
    <SecureRoute
      grant="GN"
      path="/customer-product-tickets"
      component={product_ticket}
    />
  ),
  view_all: (
    <SecureRoute
      grant="S2"
      path="/customer-active-view-all"
      component={view_all}
    />
  ),
  forgot_password: (
    <Route exact path="/customer-forgot-password" component={forgot_password} />
  ),
  archive_view_all: (
    <SecureRoute
      grant="S2"
      path="/customer-archive-view-all"
      component={archive_view_all}
    />
  ),
  inactive_view_all: (
    <SecureRoute
      grant="S2"
      path="/customer-inactive-view-all"
      component={inactive_view_all}
    />
  ),
};

export default Expo;
