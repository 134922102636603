import React, { useEffect } from "react";
import Hoc from "../../Hoc";
import Custom from "../index";
import Controls from "../../Controls";
import Action from "../../../actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const EditProfile = (props) => {
  const act_curr = Action.base02.currency;
  const Act_Opp = Action.base02.oop_msg.view_add;
  const Act_logout = Action.people.root.sign_out;
  const dispatch = useDispatch();
  const H = useHistory();
  const { title, formData, root } = props;
  const { Form } = Custom.useForm;
  const { values, onChange, resetForm } = formData;
  const CKR = (v) => root?.role === v;
  const currencies = useSelector(({ base02: B }) => B.currency.list.data);

  useEffect(() => {
    if (values?.role === "Customer") {
      dispatch(act_curr.list());
    }
    // eslint-disable-next-line
  }, [dispatch, act_curr]);

  const Act = CKR("Company_Manager")
    ? Action.people.C1
    : CKR("Company_Admin")
    ? Action.people.C2
    : CKR("Store_Manager")
    ? Action.people.S1
    : CKR("Store_Employee")
    ? Action.people.S2
    : CKR("Customer")
    ? Action.people.Customer
    : null;

  const onSubmit = () => {
    dispatch(Act.view_edit(root?.id, values));

    resetForm();

    const url = dispatch(Act_logout());
    H.push(url);
    dispatch(
      Act_Opp("Your profile Updated successfully!!. Please Login again.")
    );
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: setup_status(values) }}
      >
        <Controls.Input {...setup.first_name(values.first_name, onChange)} />
        <Controls.Input {...setup.last_name(values.last_name, onChange)} />
        <Controls.Input {...setup.email(values.email, onChange)} />
        {values?.role === "Customer" && (
          <Controls.SearchSelect
            {...setup.CurrencyId(values.CurrencyId, onChange, currencies)}
          />
        )}
        <Controls.PhoneInput {...setup.contact(values.contact, onChange)} />
        <Controls.Input {...setup.role(values.role, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) =>
  !v?.first_name || !v?.last_name || !v?.email || !v?.role;

const setup = {
  first_name: (value, onChange) => ({
    type: "text",
    name: "first_name",
    label: "First Name (s)",
    value,
    onChange,
    required: true,
  }),
  last_name: (value, onChange) => ({
    type: "text",
    name: "last_name",
    label: "Last Name",
    value,
    onChange,
    required: true,
  }),
  email: (value, onChange) => ({
    type: "email",
    name: "email",
    label: "Email Address",
    disabled: true,
    value,
    onChange,
    required: true,
  }),
  role: (value, onChange) => ({
    type: "text",
    name: "role",
    label: "Role",
    disabled: true,
    value,
    onChange,
    required: true,
  }),
  contact: (value, onChange) => ({
    type: "phone",
    name: "contact",
    label: "Contact Number",
    value,
    onChange,
  }),
  CurrencyId: (v, setV, opt) =>
    Hoc.setupSelect(
      v,
      setV,
      "CurrencyId",
      "Select Currency",
      opt.map((v) => ({ id: v.id, title: `${v.name} (${v.currency})` }))
    ),
};

export default EditProfile;
