import React, { useEffect } from "react";
import Hoc from "../../../../comps/Hoc";
import Custom from "../../../../comps/Custom";
import action from "../../../../actions";
import { TableCell as TbCell } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const Details = () => {
  const dispatch = useDispatch();
  const act_payment = action.base.payment;

  const { data, loading, error } = useSelector(({ base: B }) => B.payment.list);
  useEffect(() => {
    dispatch(act_payment.view_all());
  }, [dispatch, act_payment]);

  const refresh = () => dispatch(act_payment.view_all());

  const TBL01 = Custom.useTable(data, cell01);
  const TBL02 = Custom.useTable(data, cell02);
  const TBL03 = Custom.useTable(data, cell03);
  const { TableRow, TableCell } = TBL01;

  const t_acts = (v) => {
    let items = [];
    const onDelete = () => {
      dispatch(act_payment.view_delete(v?.id));
      refresh();
    };
    items = [
      { name: "Edit", to: `/payment-detail-edit/${v?.id}` },
      { name: "Delete", onClick: onDelete },
    ];

    return items;
  };

  const MenuAction = ({ data }) => (
    <TbCell>
      <Hoc.Product.MenuButton actions={t_acts(data)} />
    </TbCell>
  );

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Hoc.EmptyMessage content="Bank Details - First Table" color="green" />

      <TBL01.TblContainer paginate>
        {TBL01?.finalRecords().map((v) => (
          <TableRow key={v.id}>
            <TableCell content={v.name} />
            <TableCell content={v.Account_Type} />
            <TableCell content={v.Account_Holder} />
            <TableCell content={v.Account_Number} />
            <MenuAction data={v} />
          </TableRow>
        ))}
      </TBL01.TblContainer>
      <Hoc.EmptyMessage content="Bank Details - Second Table" color="green" />
      <TBL02.TblContainer paginate>
        {TBL02?.finalRecords().map((v) => (
          <TableRow key={v.id}>
            <TableCell content={v.name} />
            <TableCell content={v.Institution_Name} />
            <TableCell content={v.Routing_Number} />
            <TableCell content={v.Transit_Number} />
            <MenuAction data={v} />
          </TableRow>
        ))}
      </TBL02.TblContainer>
      <Hoc.EmptyMessage content="Bank Details - Third Table" color="green" />

      <TBL03.TblContainer paginate>
        {TBL03?.finalRecords().map((v) => (
          <TableRow key={v.id}>
            <TableCell content={v.name} />
            <TableCell content={v.Bank_Code} />
            <TableCell content={v.Sort_Code} />
            <TableCell content={v.BIC} />
            <TableCell content={v.BAB_Code} />
            <TableCell content={v.IBAN} />
            <MenuAction data={v} />
          </TableRow>
        ))}
      </TBL03.TblContainer>
    </Hoc.UseData>
  );
};

const TOT = (id, label) => ({ id, label });

const cell01 = [
  TOT("name", "name"),
  TOT("Account_Type", "Account_Type"),
  TOT("Account_Holder", "Account_Holder"),
  TOT("Account_Number", "Account_Number"),
  { id: "actions", label: "Actions", disableSorting: true },
];

const cell02 = [
  TOT("name", "name"),
  TOT("Institution_Name", "Institution_Name"),
  TOT("Routing_Number", "Routing_Number"),
  TOT("Transit_Number", "Transit_Number"),
  { id: "actions", label: "Actions", disableSorting: true },
];

const cell03 = [
  TOT("name", "name"),
  TOT("Bank_Code", "Bank_Code"),
  TOT("Sort_Code", "Sort_Code"),
  TOT("BIC", "BIC"),
  TOT("BAB_Code", "BAB_Code"),
  TOT("IBAN", "IBAN"),
  { id: "actions", label: "Actions", disableSorting: true },
];

export default Details;
