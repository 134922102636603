import React from "react";
import Custom from "../../../comps/Custom";
import Action from "../../../actions";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const Act = Action.product.category_01;
  const view_all = useSelector(({ product: v }) => v.category_01.view_all);

  return (
    <Custom.Product.CategoryTrolly
      Act={Act}
      view_all={view_all}
      to="category"
      title="Category"
    />
  );
};

export default ViewAll;
