import React, { useEffect } from "react";
import Hoc from "../../../Hoc";
import Custom from "../../../Custom";
import action from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";

const StoreProductTrolly = () => {
  const act_box = action.product.box;
  const dispatch = useDispatch();

  const { data, error, loading } = useSelector(
    ({ product: P }) => P.box.view_all
  );
  const root = useSelector(({ people: P }) => P.root.data);
  useEffect(() => {
    dispatch(act_box.view_all(`StoreId=${root?.Store?.id}`));
  }, [dispatch, act_box, root]);

  const refresh = () =>
    dispatch(act_box.view_all(`StoreId=${root?.Store?.id}`));
  const TOT = (name, to) => ({ name, to });
  const TOC = (name, onClick) => ({ name, onClick });

  const nav_01 = {
    title: "My Products",
    actions: [
      TOT("All Products", "/product-view-all-trolly"),
      TOC("Refresh", refresh),
    ],
  };

  const TBL = Custom.useTable(data, cells);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;
  const t_acts = (id) => [
    TOT("General View", `/product-variant/${id}`),
    TOT("View As Store", `/store-product-view/${id}`),
  ];
  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData error={error} loading={loading}>
        {!finalRecords().length ? (
          <Hoc.EmptyMessage />
        ) : (
          <TblContainer paginate>
            {finalRecords().map((v) => (
              <TableRow key={v.id}>
                <TableCell content={v.name} />
                <TableCell
                  content={
                    <Hoc.ToCurrency
                      currency={v?.Currency}
                      price={
                        parseFloat(v.BT_total_price) / parseInt(v.total_units)
                      }
                    />
                  }
                />
                <TableCell
                  content={
                    <Hoc.ToCurrency currency={v?.Currency} price={v.S_price} />
                  }
                />
                <TableCell content={v.quantity} />
                <TableCell content={v.total_units} />
                <TableCell content={!v.publish ? "No" : "Yes"} />
                <TableCell
                  content={<Hoc.Product.MenuButton actions={t_acts(v.id)} />}
                />
              </TableRow>
            ))}
          </TblContainer>
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { id: "name", label: "Name" },
  { id: "BT_total_price", label: "Unit Cost (Making)" },
  { id: "S_price", label: "Unit Cost (Selling)" },
  { id: "quantity", label: "Store Name" },
  { id: "total_units", label: "Variants Quantity" },
  { id: "publish", label: "Category Name" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default StoreProductTrolly;
