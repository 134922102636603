import { Box } from "@material-ui/core";
import React from "react";
import Hoc from "../../../../comps/Hoc";
import { useHistory } from "react-router";
import { Button } from "semantic-ui-react";

const PreviousNext = (props) => {
  const { from, to, refresh } = props;
  const { onSubmit = (f) => f } = props;

  return (
    <Box style={{ marginTop: 15 }} {...Hoc.flex("flex-end")}>
      {!!from && <MainBtn refresh={refresh} {...(!!from && { from })} />}
      {!!to && <MainBtn refresh={refresh} to={to} onSubmit={onSubmit} />}
    </Box>
  );
};

const MainBtn = (props) => {
  const { to, from, refresh } = props;
  const { onSubmit = (f) => f } = props;
  const H = useHistory();

  const handler = () => {
    if (!!to) {
      onSubmit();
      !!refresh && refresh();
    } else if (!!from) {
      H.push(`/trolly-${from}`);
      !!refresh && refresh();
    }
  };

  return (
    <Button
      content={!!to ? "Proceed" : "Go Back"}
      color={!!to ? "green" : "orange"}
      onClick={handler}
      inverted
    />
  );
};

export default PreviousNext;
