import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.transaction;
const URL = `${Helper.URL}/transaction`;

//   ---------------------------------------   Transactions

export const pay_stripe = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.PAY_STRIPE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.post(`${URL}/pay-stripe`, initData);

    dispatch({ type: types.PAY_STRIPE_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PAY_STRIPE_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.ADD_TRANSACTION_REQUEST });
    Helper.set_jwt(state());
    const root = state()?.people?.root?.data;

    const CKRole = (v) => root?.role === v;

    const finalData = {
      person_role: root?.role,
      person_id: root?.id,
      ...(CKRole("Customer") && { CustomerId: root?.id }),
      ...(CKRole("CompanyManager") && { CompanyManagerId: root?.id }),
      ...initData,
    };

    const { data } = await axios.post(`${URL}`, finalData);

    dispatch({ type: types.ADD_TRANSACTION_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.ADD_TRANSACTION_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EDIT_TRANSACTION_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.EDIT_TRANSACTION_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EDIT_TRANSACTION_FAIL);
  }
};

export const view = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.GET_TRANSACTION_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`);

    dispatch({ type: types.GET_TRANSACTION_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.GET_TRANSACTION_FAIL);
  }
};

export const view_all = (qryStr) => async (dispatch, state) => {
  try {
    dispatch({ type: types.GET_ALL_TRANSACTION_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${qryStr || ""}`);

    dispatch({
      type: types.GET_ALL_TRANSACTION_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.GET_ALL_TRANSACTION_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRANSACTION_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);

    dispatch({
      type: types.TRANSACTION_DELETE_SUCCESS,
      payload: data?.result,
    });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRANSACTION_DELETE_FAIL);
  }
};
