import SecureRoute from "../../../comps/layout/SecureRoute";
import add from "./add";
import edit from "./edit";
import view from "./view";
import view_all_notices from "./view_all_notices";
import view_all_news from "./view_all_news";
import view_all_adds from "./view_all_adds";

const Expo = {
  add: <SecureRoute grant="S2" path="/add-notices" component={add} />,
  edit: <SecureRoute grant="S2" path="/edit-notices/:id" component={edit} />,
  view: <SecureRoute grant="S2" path="/view-notices/:id" component={view} />,
  view_all_notices: (
    <SecureRoute
      grant="GN"
      path="/view-all-notices"
      component={view_all_notices}
    />
  ),
  view_all_news: (
    <SecureRoute grant="GN" path="/view-all-news" component={view_all_news} />
  ),
  view_all_adds: (
    <SecureRoute grant="GN" path="/view-all-adds" component={view_all_adds} />
  ),
};

export default Expo;
