import axios from "axios";
import { Type } from "../../redux";
import Helper from "../helper";
const types = Type.base_type.subscribes;

const URL = `${Helper.URL}/subscribes`;

export const view_all = (queryString) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SUBSCRIBE_VIEW_ALL_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}?${queryString || ""}`);

    dispatch({ type: types.SUBSCRIBE_VIEW_ALL_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SUBSCRIBE_VIEW_ALL_FAIL);
  }
};

export const view_add = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SUBSCRIBE_ADD_REQUEST });

    const { data } = await axios.post(`${URL}`, initData);

    dispatch({ type: types.SUBSCRIBE_ADD_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SUBSCRIBE_ADD_FAIL);
  }
};

export const view_edit = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SUBSCRIBE_EDIT_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.SUBSCRIBE_EDIT_SUCCESS, payload: data?.status });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SUBSCRIBE_EDIT_FAIL);
  }
};

export const view = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SUBSCRIBE_VIEW_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/${id}`, initData);

    dispatch({ type: types.SUBSCRIBE_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SUBSCRIBE_VIEW_FAIL);
  }
};

export const view_query = (query) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SUBSCRIBE_VIEW_QUERY_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.get(`${URL}/query?${query}`);

    dispatch({
      type: types.SUBSCRIBE_VIEW_QUERY_SUCCESS,
      payload: data?.result,
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SUBSCRIBE_VIEW_QUERY_FAIL);
  }
};

export const view_delete = (id) => async (dispatch, state) => {
  try {
    dispatch({ type: types.SUBSCRIBE_DELETE_REQUEST });
    Helper.set_jwt(state());

    const { data } = await axios.delete(`${URL}/${id}`);
    dispatch({
      type: types.SUBSCRIBE_VIEW_ALL_SUCCESS,
      payload: state().base.subscribes.view_all.data.filter((v) => v.id !== id),
    });

    dispatch({ type: types.SUBSCRIBE_DELETE_SUCCESS, payload: data?.result });
  } catch (err) {
    Helper.errResponse(err, dispatch, types.SUBSCRIBE_DELETE_FAIL);
  }
};
