import SecureRoute from "../../../comps/layout/SecureRoute";
import general_ticket from "./ticket/general_tokens";
import complain_ticket from "./ticket/complain_tokens";
import product_ticket from "./ticket/product_tokens";

const Expo = {
  store_general_ticket: (
    <SecureRoute
      grant="GN"
      path="/root-general-tickets"
      component={general_ticket}
    />
  ),
  store_complain_ticket: (
    <SecureRoute
      grant="GN"
      path="/root-complain-tickets"
      component={complain_ticket}
    />
  ),
  store_product_ticket: (
    <SecureRoute
      grant="GN"
      path="/root-product-tickets"
      component={product_ticket}
    />
  ),
};

export default Expo;
