const types = {
  //
  NOTICE_GET_LIST_REQUEST: "NOTICE_GET_LIST_REQUEST",
  NOTICE_GET_LIST_SUCCESS: "NOTICE_GET_LIST_SUCCESS",
  NOTICE_GET_LIST_FAIL: "NOTICE_GET_LIST_FAIL",
  //
  NOTICE_VIEW_ALL_REQUEST: "NOTICE_VIEW_ALL_REQUEST",
  NOTICE_VIEW_ALL_SUCCESS: "NOTICE_VIEW_ALL_SUCCESS",
  NOTICE_VIEW_ALL_FAIL: "NOTICE_VIEW_ALL_FAIL",
  //
  NOTICE_VIEW_REQUEST: "NOTICE_VIEW_REQUEST",
  NOTICE_VIEW_SUCCESS: "NOTICE_VIEW_SUCCESS",
  NOTICE_VIEW_FAIL: "NOTICE_VIEW_FAIL",
  //
  NOTICE_ADD_REQUEST: "NOTICE_ADD_REQUEST",
  NOTICE_ADD_SUCCESS: "NOTICE_ADD_SUCCESS",
  NOTICE_ADD_FAIL: "NOTICE_ADD_FAIL",
  //
  NOTICE_EDIT_REQUEST: "NOTICE_EDIT_REQUEST",
  NOTICE_EDIT_SUCCESS: "NOTICE_EDIT_SUCCESS",
  NOTICE_EDIT_FAIL: "NOTICE_EDIT_FAIL",
  //
  NOTICE_DELETE_REQUEST: "NOTICE_DELETE_REQUEST",
  NOTICE_DELETE_SUCCESS: "NOTICE_DELETE_SUCCESS",
  NOTICE_DELETE_FAIL: "NOTICE_DELETE_FAIL",
};

export default types;
