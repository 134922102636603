import about from "./about";
import privacy from "./privacy";
import terms from "./terms";

const Expo = {
  about,
  privacy,
  terms,
};

export default Expo;
