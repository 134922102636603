import React, { useEffect } from "react";
import Hoc from "../../../Hoc";
import Action from "../../../../actions";
import Custom from "../../../../comps/Custom";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";

const TermViewAll = (props) => {
  const { to } = props;
  const toL = `${to}`.toLowerCase();
  const dispatch = useDispatch();
  const Act = Action.base.terms;

  const { data } = useSelector(({ base: B }) => B.terms.view_all);

  const CKT = (v) => v === to;
  const { TblContainer, finalRecords } = Custom.useTable(data, cells);

  const qryStr = `category=${to}`;

  useEffect(() => {
    dispatch(Act.view_all(qryStr));
  }, [dispatch, Act, qryStr]);

  const refresh = () => dispatch(Act.view_all(qryStr));

  const title = CKT("About")
    ? "About US"
    : CKT("Privacy")
    ? "Privacy Policy"
    : CKT("Terms")
    ? "Terms and Conditions"
    : null;

  const nav_01 = [
    {
      name: "Create New",
      to: `/add-${toL}`,
    },
    { name: "Refresh", onClick: refresh },
    { name: "Back", goBack: true },
  ];

  const menuActions = (id) => {
    const TOT = (name, to) => ({ name, to });
    const onDelete = () => {
      dispatch(Act.view_delete(id));
      refresh();
    };
    let items = [
      TOT("View", `/view-${toL}/${id}`),
      TOT("Edit", `/edit-${toL}/${id}`),
      { name: "Delete", onClick: onDelete },
    ];

    return items;
  };

  return (
    <Hoc.Dashboard.Container title={title} actions={nav_01}>
      {!!finalRecords().length ? (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell>{v?.category}</TableCell>
              <TableCell>{v?.name}</TableCell>
              {!CKT("News") && <TableCell>{v?.level}</TableCell>}
              <TableCell>
                {`${v?.description}`.substring(0, 15) + ".."}
              </TableCell>
              <TableCell>
                <Hoc.Product.MenuButton actions={menuActions(v?.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TblContainer>
      ) : (
        <Hoc.EmptyMessage />
      )}
    </Hoc.Dashboard.Container>
  );
};

const cells = [
  { id: "category", label: "Category" },
  { id: "name", label: "Name" },
  { id: "level", label: "Level" },
  { id: "description", label: "Description" },
  { id: "action", label: "Actions", disableSorting: true },
];

export default TermViewAll;
