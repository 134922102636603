import React from "react";
import Custom from "../../../../comps/Custom";
import Hoc from "../../../../comps/Hoc";
import Action from "../../../../actions";

const AddProduct = () => {
  const Act = Action.product.product.view_add;
  const { useForm } = Custom.useForm;
  const formData = useForm(initValues);

  return (
    <Hoc.Page title="Listing New Product">
      <Custom.Product.ProductForm
        title="Listing New Product"
        formData={formData}
        submitAction={Act}
      />
    </Hoc.Page>
  );
};

const initValues = {
  title: "",
  slug: "",
  brand: "",
  Category03Id: "",
  StoreId: "",
};

export default AddProduct;
